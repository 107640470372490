import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import Proptype from 'prop-types';
import { formatSortingsArray } from '../utils/filterUtils';
import OrderByString from './OrderByString';

function OrderBy({ color }) {
  const { t } = useTranslation();
  const [search, setSearch] = useSearchParams();
  const [orderBy, setOrderBy] = useState(search?.getAll('orderby') || '');
  const [ticketId, setTicketId] = useState(formatSortingsArray(search?.getAll('orderby'), 'ticketId'));
  const [status, setStatus] = useState(formatSortingsArray(search?.getAll('orderby'), 'status'));
  const [court, setCourt] = useState(formatSortingsArray(search?.getAll('orderby'), 'court'));
  const [startDate, setStartDate] = useState(formatSortingsArray(search?.getAll('orderby'), 'startDate'));

  useEffect(() => {
    setOrderBy(search?.getAll('orderby') || '');
    setTicketId(formatSortingsArray(search?.getAll('orderby'), 'ticketId'));
    setStatus(formatSortingsArray(search?.getAll('orderby'), 'status'));
    setCourt(formatSortingsArray(search?.getAll('orderby'), 'court'));
    setStartDate(formatSortingsArray(search?.getAll('orderby'), 'startDate'));
  }, [search]);

  const handleTicketIdChange = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'ticketId' && search.append('orderby', ord));
    ticketId ? search.append('orderby', `ticketId-${ticketId && ticketId === 'desc' ? 'asc' : 'desc'}`) : search.append('orderby', 'ticketId-desc');
    setTicketId(ticketId && ticketId === 'desc' ? 'asc' : 'desc');
    setSearch(search);
  };
  const handleTicketIdDelete = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'ticketId' && search.append('orderby', ord));
    setTicketId('');
    setSearch(search);
  };
  const handleStatusChange = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'status' && search.append('orderby', ord));
    status ? search.append('orderby', `status-${status && status === 'desc' ? 'asc' : 'desc'}`) : search.append('orderby', 'status-desc');
    setStatus(status && status === 'desc' ? 'asc' : 'desc');
    setSearch(search);
  };
  const handleStatusDelete = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'status' && search.append('orderby', ord));
    setStatus('');
    setSearch(search);
  };
  const handleCourtChange = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'court' && search.append('orderby', ord));
    court ? search.append('orderby', `court-${court && court === 'desc' ? 'asc' : 'desc'}`) : search.append('orderby', 'court-desc');
    setCourt(court && court === 'desc' ? 'asc' : 'desc');
    setSearch(search);
  };
  const handleCourtDelete = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'court' && search.append('orderby', ord));
    setCourt('');
    setSearch(search);
  };
  const handleStartDateChange = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'startDate' && search.append('orderby', ord));
    startDate ? search.append('orderby', `startDate-${startDate && startDate === 'desc' ? 'asc' : 'desc'}`) : search.append('orderby', 'startDate-desc');
    setStartDate(startDate && startDate === 'desc' ? 'asc' : 'desc');
    setSearch(search);
  };
  const handleStartDateDelete = () => {
    search.delete('orderby');
    orderBy.forEach((ord) => ord.split('-')[0] !== 'startDate' && search.append('orderby', ord));
    setStartDate('');
    setSearch(search);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography>{t('trials.orderBy')}:</Typography>
      <OrderByString dir={ticketId} label="trials.orderby.ticketId.label" onDelete={handleTicketIdDelete} onClick={handleTicketIdChange} color={color} />
      <OrderByString dir={status} label="trials.orderby.status.label" onDelete={handleStatusDelete} onClick={handleStatusChange} color={color} />
      <OrderByString dir={court} label="trials.orderby.court.label" onDelete={handleCourtDelete} onClick={handleCourtChange} color={color} />
      <OrderByString dir={startDate} label="trials.orderby.startDate.label" onDelete={handleStartDateDelete} onClick={handleStartDateChange} color={color} />
    </Stack>
  );
}

OrderBy.defaultProps = {
  color: 'primary',
};

OrderBy.propTypes = {
  color: Proptype.string,
};

export default OrderBy;
