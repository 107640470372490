import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDebouncedCallback } from 'use-debounce';
import { dateTimeToReduxFormat } from '../utils/dateTimeHandlers';

function TdsDateTimePicker({ name, label, required, disabled, readOnly, onChange, sx, size, initialValue, debounceInterval, referenceDate }) {
  const [value, setValue] = useState(() => (initialValue ? dayjs(initialValue) : null));
  const { t } = useTranslation();
  const debouncedOnChange = useDebouncedCallback((val, context) => {
    if (onChange) {
      onChange(val, context);
    }
  }, debounceInterval);
  const setValueWithValidation = (val, context) => {
    const computedValue = dayjs(val).isValid() ? dateTimeToReduxFormat(val, 'dateTime') : '';
    setValue(val);
    debouncedOnChange(computedValue, context);
  };

  return (
    <DateTimePicker
      sx={sx}
      slotProps={{
        textField: {
          size,
          inputProps: {
            'data-testid': name,
            placeholder: t('common.datetimes.placeholder'),
          },
          required,
          InputLabelProps: { shrink: true },
          onFocus: (e) => {
            if (e.target.value === '' && referenceDate) setValueWithValidation(referenceDate);
          },
        },
      }}
      referenceDate={referenceDate}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(val, context) => setValueWithValidation(val, context)}
      value={value}
      format="L LT"
      label={label}
    />
  );
}

TdsDateTimePicker.defaultProps = {
  required: false,
  disabled: false,
  readOnly: false,
  initialValue: '',
  onChange: null,
  sx: null,
  size: 'medium',
  debounceInterval: 600,
  referenceDate: null,
};

TdsDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  sx: PropTypes.shape({}),
  size: PropTypes.string,
  debounceInterval: PropTypes.number,
  referenceDate: PropTypes.shape({}),
};

export default TdsDateTimePicker;
