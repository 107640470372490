import { useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tabs, Tab, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import { selectAllMarkers } from '../features/markersSlice';
import { selectAllTurns } from '../features/turnsSlice';

const MarkersTab = lazy(() => import('./MarkersTab'));
const TurnsTab = lazy(() => import('./TurnsTab'));

function RecordingTabs({ recordingId }) {
  const turnsL = useSelector((state) => selectAllTurns(state.turns)).filter((turn) => turn.recordingId === recordingId).length;
  const markersL = useSelector((state) => selectAllMarkers(state.markers)).filter((marker) => marker.recordingId === recordingId).length;
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  return (
    <Box role="presentation">
      <Tabs variant="fullWidth" centered sx={{ minHeight: '20px', bgcolor: 'grey.300' }} value={value} onChange={(e, newValue) => setValue(newValue)}>
        <Tab sx={{ p: 1, minHeight: 0 }} label={`${t('recordings.markers')} (${markersL})`} />
        <Tab sx={{ p: 1, minHeight: 0 }} label={`${t('recordings.turns')} (${turnsL})`} />
      </Tabs>
      <Box sx={{ maxHeight: '20em', overflow: 'auto' }}>
        <Collapse orientation="vertical" in={value === 0}>
          <Suspense fallback={<Spinner />}>
            <MarkersTab recordingId={recordingId} />
          </Suspense>
        </Collapse>
        <Collapse orientation="vertical" in={value === 1}>
          <Suspense fallback={<Spinner />}>
            <TurnsTab recordingId={recordingId} />
          </Suspense>
        </Collapse>
      </Box>
    </Box>
  );
}

RecordingTabs.propTypes = {
  recordingId: PropTypes.string.isRequired,
};

export default RecordingTabs;
