export const toDataSnakeCase = (data) =>
  Object.keys(data).reduce((acc, key) => {
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    return { ...acc, [newKey]: data[key] };
  }, {});

export const mapProfileData = (inputProfileData) => {
  return {
    id: inputProfileData.id ? String(inputProfileData.id) : '',
    username: inputProfileData.username || '',
    email: inputProfileData.email || '',
    firstname: inputProfileData.nome || '',
    lastname: inputProfileData.cognome || '',
    hasSensitiveDataAccess: inputProfileData.flag_dati_sensibili === '1',
    emailVerified: inputProfileData.email_verified === '1',
    lastLoginDateTime: inputProfileData.data_ora_ultimo_accesso || null,
  };
};
