import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Box, TextField } from '@mui/material';
import ProceedingFormTextField from './ProceedingFormTextField';

function RegistrarInput({ registrarText, setRegistrarText, size }) {
  const { t } = useTranslation();
  const [registrarType, setRegistrarType] = useState(() => (registrarText ? registrarText.split(' ')[0] : ''));
  const [registrar, setRegistrar] = useState(() => (registrarText ? registrarText.split(' ').slice(1).join(' ') : ''));
  const registratTypes = ['DOTT.', 'DOTT.SSA', 'SIG.', 'SIG.RA'];

  const handleChange = (type, name) => {
    if (name) setRegistrar(name);
    if (type) setRegistrarType(type);
    if (name !== '' && type !== '') setRegistrarText(`${type} ${name}`);
  };

  return (
    <Box>
      <TextField
        size={size}
        sx={{ width: 2 / 5, paddingRight: 1 }}
        id="registrarType"
        name="registrarType"
        required
        value={registrarType}
        onChange={(e) => handleChange(e.target.value, registrar)}
        select
        label={t('proceedings.form.addProceeding.fields.registrarType')}
        SelectProps={{
          MenuProps: {
            style: { maxHeight: 400 },
          },
          inputProps: { 'data-testid': 'registrarType' },
        }}
      >
        {registratTypes?.map((regT) => (
          <MenuItem key={regT} value={regT}>
            {regT}
          </MenuItem>
        ))}
      </TextField>
      <ProceedingFormTextField
        size={size}
        sx={{ width: 3 / 5 }}
        name="registrar"
        initValue={registrar}
        required
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'data-testid': 'registrar' }}
        onChange={(newVal) => handleChange(registrarType, newVal)}
        type="text"
        placeholder={t('proceedings.form.addProceeding.placeholders.registrar')}
        label={t('proceedings.form.addProceeding.fields.registrar')}
      />
    </Box>
  );
}

RegistrarInput.defaultProps = {
  registrarText: '',
  size: 'medium',
};

RegistrarInput.propTypes = {
  registrarText: PropTypes.string,
  setRegistrarText: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default RegistrarInput;
