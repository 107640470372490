import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Spinner from '../../Spinner';
import PrintCard from './PrintCard';
import usePrintsResult from '../../../hooks/usePrintsResults';

function PrintsResultPage() {
  const { t } = useTranslation();
  const { filteredPrints: prints, isLoading, isFetching, isError } = usePrintsResult();

  const createPrintCard = (proceeding) => <PrintCard key={proceeding.id_procedimento} proceeding={proceeding} />;

  if (isError) return <div>{t('documents.messages.loadingError')}</div>;
  if (isLoading || isFetching) return <Spinner />;

  return (
    <Stack component="section" id="PrintsResultPage" direction="column" my={2} spacing={1}>
      {prints?.map(createPrintCard)}
    </Stack>
  );
}

export default PrintsResultPage;
