async function sha256(str) {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return crypto.subtle.digest('SHA-256', data);
  } catch {
    const { createHash } = await import('crypto');
    return createHash('sha256').update(str).digest();
  }
}

export async function hashObjectByKeysIdentifier(obj, keys = null, length = 16) {
  const usedKeys = keys || Object.keys(obj);
  const str = usedKeys
    .sort()
    .map((key) => {
      return `${obj[key]}`;
    })
    .join('|||');
  const hash = await sha256(str);
  const hex = Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hex.slice(0, length); // return first 16 characters of the hash
}

export default hashObjectByKeysIdentifier;
