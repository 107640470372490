import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';
import { openBulkProceedingsDateDialog, setBulkProceedingsLoading } from '../features/bulkProceedingsSlice';
import { loadAnnotationsHeaders } from '../features/annotationsHeaders';
import { loadAnnotationsRecordings } from '../features/annotationsRecordings';
import { loadAnnotationsMarkers } from '../features/annotationsMarkers';
import { loadAnnotationsTurns } from '../features/annotationsTurns';
import { loadCds } from '../features/cdsSlice';
import { selectTotalProceedings } from '../features/proceedingsSlice';
import { setAlertMessage } from '../features/layoutSlice';
import { createAnnotationsStateFromFiles } from '../lib/cronologia';

export default function useBulkProceedingsAddCdButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { currentData: trial, isLoadingTrial, isFetchingTrial } = useGetTrialQuery(id, { skip: !id });
  const { currentData: proceedings, isLoadingProceedings, isFetchingProceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });

  const proceedingsCDTotal = useSelector((state) => selectTotalProceedings(state.proceedings));
  const proceedingsCount = proceedings?.length || 0;
  const maxProceedingsCount = trial && trial.n_procedimenti ? Number(trial.n_procedimenti) : 0;

  const disableUploadButton = !trial?.data_ora_fine_udienza || !trial?.n_procedimenti || !trial?.n_verbali || !trial?.n_supporti || !trial?.id_aula;
  const trialCompleted = maxProceedingsCount <= proceedingsCount;

  const handleLoadCDs = useCallback(
    async (event) => {
      if (event.target.files.length !== 0) {
        dispatch(setBulkProceedingsLoading(true));
        const files = Object.values(event.target.files);
        const { status, state } = await createAnnotationsStateFromFiles(files);
        status.errors?.forEach((error) => {
          if (error.type === 'missingAnnotationsFile') dispatch(setAlertMessage({ text: t('proceedings.dialogs.bulkUpload.errors.missingAnnotationsFile'), type: 'error', render: 'snack' }));
          if (error.type === 'emptyAnnotationsFile') dispatch(setAlertMessage({ text: t('proceedings.dialogs.bulkUpload.errors.emptyAnnotationsFile_fileName', { fileName: error.fileName }), type: 'error', render: 'snack' }));
          if (error.type === 'noAudioFiles') dispatch(setAlertMessage({ text: t('proceedings.dialogs.bulkUpload.errors.noAudioFiles_fileName', { fileName: error.fileName }), type: 'error', render: 'snack' }));
        });
        dispatch(loadCds(state.cdsInfo));
        dispatch(loadAnnotationsHeaders(state.headers));
        dispatch(loadAnnotationsRecordings(state.recordings));
        dispatch(loadAnnotationsMarkers(state.markers));
        dispatch(loadAnnotationsTurns(state.turns));
        dispatch(setBulkProceedingsLoading(false));
        if (state.cdsInfo.length > 0) dispatch(openBulkProceedingsDateDialog());
      } else dispatch(setAlertMessage({ text: t('proceedings.dialogs.bulkUpload.errors.emptyDirectory'), type: 'error', render: 'snack' }));
      event.target.value = ''; // eslint-disable-line no-param-reassign
    },
    [dispatch, t]
  );

  const handleNewCdButtonClick = (e) => {
    if (trialCompleted) {
      dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'error', render: 'snack' }));
      e.preventDefault();
    }
  };

  return {
    isFetching: isFetchingTrial || isFetchingProceedings,
    isLoading: isLoadingTrial || isLoadingProceedings,
    proceedingsCDTotal,
    disableUploadButton,
    handleLoadCDs,
    handleNewCdButtonClick,
  };
}
