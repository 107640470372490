import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { selectRecordingById } from '../features/recordingsSlice';
import { selectAllTracks } from '../features/tracksSlice';
import { selectBulkProceedingsRecordingsSelectValues } from '../features/bulkProceedingsSlice';

function ProceedingRecording({ recordingId }) {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const track = useSelector((state) => selectAllTracks(state.tracks)).find((trk) => trk.recordingId === recordingId && trk.isMixer);
  const recording = useSelector((state) => selectRecordingById(state.recordings, recordingId));
  const recordingsSelectValues = useSelector(selectBulkProceedingsRecordingsSelectValues);

  useEffect(() => {
    if (recordingsSelectValues?.find((sr) => sr.id === recording.id)) setIsSelected(true);
    else setIsSelected(false);
  }, [recordingsSelectValues, recording]);

  if (recording.passage && recording.section)
    return (
      <Tooltip title={`${t('recordings.track')} ${recording.passage} ${t('recordings.subtrack')} ${recording.section}`} placement="top">
        <Typography variant="caption" color={isSelected ? 'primary.light' : 'inherit'}>
          {track?.fileName}
        </Typography>
      </Tooltip>
    );
  return <Typography variant="caption">{track?.fileName}</Typography>;
}

ProceedingRecording.propTypes = {
  recordingId: PropTypes.string.isRequired,
};

export default ProceedingRecording;
