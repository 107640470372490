import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Divider, CircularProgress, Backdrop } from '@mui/material';
import { selectBulkProceedingsDeleteCdsDialogLoading, selectIsBulkProceedingsDeleteCdsDialogOpen, setBulkProceedingsDeleteCdsDialogOpen } from '../features/bulkProceedingsSlice';
import BulkProceedingsCdData from './BulkProceedingsCdData';
import useDeleteCds from '../hooks/useDeleteCds';

function BulkProceedingsDeleteCdsDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isBulkProceedingsDeleteCdsDialogOpen = useSelector(selectIsBulkProceedingsDeleteCdsDialogOpen);
  const isBulkProceedingsDeleteCdsDialogLoading = useSelector(selectBulkProceedingsDeleteCdsDialogLoading);
  const { cds, deleteCds } = useDeleteCds();

  const backdrop = (
    <Backdrop open={isBulkProceedingsDeleteCdsDialogLoading} sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <Dialog open={isBulkProceedingsDeleteCdsDialogOpen} fullWidth maxWidth="md">
      {backdrop}
      <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>{t('proceedings.dialogs.bulkUpload.deleteCdsDialog.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <BulkProceedingsCdData cds={cds} deleteCd={deleteCds} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button sx={{ mr: 'auto' }} color="warning" variant="contained" onClick={() => deleteCds()}>
          {t('proceedings.dialogs.buttons.deleteAllCds')}
        </Button>
        <Button color="secondary" variant="outlined" onClick={() => dispatch(setBulkProceedingsDeleteCdsDialogOpen(false))}>
          {t('common.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkProceedingsDeleteCdsDialog;
