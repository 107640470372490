import { deserializePositionalStrings, deserializePHParray } from './deserializeUtils';

export const deserializeProceedings = (proceedings) => {
  if (!proceedings) return [];
  const results = proceedings.map((proceeding) => {
    const proc = { ...proceeding };
    proc.upload_audio = deserializeUploadAudio(proceeding.upload_audio);
    proc.pms = deserializePositionalStrings(proceeding.ids_pm, proceeding.pm);
    proc.ids_pm = proceeding.ids_pm && proceeding.ids_pm.split(',').map((id) => id.trim());
    proc.pm = proceeding.pm && proceeding.pm.split(',').map((name) => name.trim());
    return proc;
  });
  return results;
};

export const deserializeUploadAudio = (uploadAudio) => {
  const filesArray = [];
  for (const [key, value] of deserializePHParray(uploadAudio)) {
    filesArray.push({ fileId: key, duration: Number(value), isMp3: value !== 0 });
  }
  return filesArray;
};
