import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';
import { selectProceedingById, removeProceeding } from '../features/proceedingsSlice';
import { selectBulkProceedingsDefaultPP, selectBulkProceedingsDefaultRegistrar } from '../features/bulkProceedingsSlice';
import { resetProceedingFormSlice, setProceeding, setProceedingFormOtherFiles, setProceedingFormRecordings, setProceedingFormSelectedRecordings, setProceedingFormStagedOtherFiles, setProceedingFormStagedRecordings } from '../features/proceedingFormSlice';
import { openSingleProceedingDialog } from '../features/singleProceedingSlice';
import { formatEditingProceedingToProceedingSlice, formatProceedingToProceedingSlice } from '../utils/formatProceedingToProceedingSlice';

export default function useProceedingCard(proceedingId) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentData: trial } = useGetTrialQuery(id, { skip: !id });
  const { currentData: proceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });
  const Proceeding = useSelector((state) => selectProceedingById(state.proceedings, proceedingId));
  const alreadyUploadedProceedings = useMemo(() => proceedings?.filter((proc) => proc.RG === Proceeding.RG && proc.RGNR === Proceeding.RGNR), [proceedings, Proceeding]);
  const defaultRegistrar = useSelector(selectBulkProceedingsDefaultRegistrar);
  const defaultPP = useSelector(selectBulkProceedingsDefaultPP);

  const deleteProceeding = () => dispatch(removeProceeding(proceedingId));

  const editProceedingHandler = (TDSproceedingId) => {
    const proceeding = proceedings.find((proc) => proc.id_procedimento === TDSproceedingId);
    dispatch(resetProceedingFormSlice());
    const formattedProceeding = formatEditingProceedingToProceedingSlice(proceeding);
    dispatch(setProceeding(formattedProceeding));
    dispatch(setProceedingFormStagedRecordings(proceeding.upload_audio?.filter((file) => file.isMp3) || []));
    dispatch(setProceedingFormStagedOtherFiles(proceeding.upload_audio?.filter((file) => !file.isMp3) || []));
    dispatch(openSingleProceedingDialog());
  };

  const formatProceeding = () => {
    dispatch(setProceeding(formatProceedingToProceedingSlice(Proceeding, trial, defaultPP, defaultRegistrar)));
    dispatch(setProceedingFormRecordings(Proceeding.recordings));
    dispatch(setProceedingFormSelectedRecordings(Proceeding.selectedRecordings));
    dispatch(setProceedingFormOtherFiles(Proceeding.otherFiles));
  };

  const createProceedingHandler = () => {
    dispatch(resetProceedingFormSlice());
    formatProceeding();
    dispatch(openSingleProceedingDialog());
  };

  return {
    Proceeding,
    alreadyUploadedProceedingsCount: alreadyUploadedProceedings?.length || 0,
    alreadyUploadedProceedings,
    deleteProceeding,
    editProceeding: editProceedingHandler,
    createProceeding: createProceedingHandler,
  };
}
