import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { selectProceedingById } from '../features/proceedingsSlice';
import ProceedingRecording from './ProceedingRecording';

function ProceedingRecordings({ proceedingId }) {
  const Proceeding = useSelector((state) => selectProceedingById(state.proceedings, proceedingId));

  return (
    <Stack color="gray" component="span" spacing={0} padding={0} margin={0}>
      {Proceeding?.recordings.map((recordingId) => (
        <ProceedingRecording key={recordingId} recordingId={recordingId} />
      ))}
    </Stack>
  );
}

ProceedingRecordings.propTypes = {
  proceedingId: PropTypes.string.isRequired,
};

export default ProceedingRecordings;
