import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { selectProceedingFormOtherFiles, setProceedingFormOtherFiles } from '../features/proceedingFormSlice';
import { selectOtherFileById } from '../features/otherFilesSlice';
import { openFileDialog, setAlertMessage, setFileDialog } from '../features/layoutSlice';
import { fetchFile } from '../utils/fileReader';

export default function useOtherFile(otherFileId) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const otherFile = useSelector((state) => selectOtherFileById(state.otherFiles, otherFileId));
  const proceedingOtherFiles = useSelector(selectProceedingFormOtherFiles);
  const [file, setFile] = useState(null);
  const { getByIndex } = useIndexedDB('files');

  const fetchFileFromIndexedDB = useCallback(async () => {
    setIsLoading(true);
    fetchFile(otherFile?.file, getByIndex)
      .then((resp) => setFile(resp))
      .catch(() => {
        setFile(null);
        dispatch(setAlertMessage({ text: t('recordings.errors.fileNotFound_file_cdId', { fileName: otherFile.fileName, cdId: otherFile.cdId }), type: 'warning', render: 'snack', closeAfter: 10000 }));
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, t, getByIndex, otherFile]);

  const removeOtherFile = () => {
    const otherFiles = proceedingOtherFiles?.filter((rec) => rec !== otherFileId);
    dispatch(setProceedingFormOtherFiles(otherFiles));
  };

  const downloadOtherFile = () => {
    try {
      setIsDownloading(true);
      const url = URL.createObjectURL(file);
      const { name } = file;
      const a = document.createElement('a');
      a.download = name;
      a.href = url;
      a.click();
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      dispatch(setAlertMessage({ text: t('recordings.errors.fileNotFound_file_cdId', { fileName: otherFile.fileName, cdId: otherFile.cdId }), type: 'warning', render: 'snack', closeAfter: 10000 }));
    }
  };

  const openFile = (e) => {
    e.stopPropagation();
    const url = URL.createObjectURL(file);
    dispatch(setFileDialog({ name: file.name, url }));
    dispatch(openFileDialog());
  };

  return {
    otherFile,
    fetchFileFromIndexedDB,
    isLoading,
    isDownloading,
    removeOtherFile,
    downloadOtherFile,
    openFile,
  };
}
