import { TextField, MenuItem } from '@mui/material';
import Proptype from 'prop-types';

function FilterByStatus({ name, label, value, onChange, statusOptions, isLoading, isFetching, color, size }) {
  if (!(isLoading || isFetching))
    return (
      <TextField size={size} sx={{ width: 1 / 6 }} id={name} placeholder={label} label={label} select value={value} onChange={(e) => onChange(e.target.value)} color={color}>
        {statusOptions?.map((s) => (
          <MenuItem key={s.id} value={s.id}>
            {s.descrizione}
          </MenuItem>
        ))}
      </TextField>
    );
  return null;
}

FilterByStatus.defaultProps = {
  value: '',
  color: 'primary',
  statusOptions: [],
  isLoading: false,
  isFetching: false,
  size: 'medium',
};

FilterByStatus.propTypes = {
  name: Proptype.string.isRequired,
  label: Proptype.string.isRequired,
  value: Proptype.string,
  onChange: Proptype.func.isRequired,
  color: Proptype.string,
  statusOptions: Proptype.arrayOf(Proptype.shape({ id: Proptype.string, descrizione: Proptype.string })),
  isLoading: Proptype.bool,
  isFetching: Proptype.bool,
  size: Proptype.string,
};

export default FilterByStatus;
