import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { TextField, ListItemText, Checkbox, Skeleton, Typography, Autocomplete, ListItem, ListItemIcon } from '@mui/material';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { useGetPublicProsecutorsQuery } from '../features/api/tds/apiPeopleSlice';

function PublicProsecutorsSelect({ initValues, setPublicProsecutors, courtId, sectionId, debounceInterval, size }) {
  const { t } = useTranslation();
  const { data: publicProsecutors, isLoading, isFetching, isError } = useGetPublicProsecutorsQuery({ filters: { courtId, sectionId }, page: 1, pSize: 1000 }, { skip: !courtId || !sectionId });
  const [selectedPublicProsecutors, setSelectedPublicProsecutors] = useState(() => initValues);
  const debouncedOnChange = useDebouncedCallback(setPublicProsecutors, debounceInterval);

  const sortedPublicProsecutors = useMemo(() => {
    if (publicProsecutors) {
      return [...publicProsecutors]
        .sort((a, b) => {
          if (a?.cognome < b?.cognome) return -1;
          if (a?.cognome > b?.cognome) return 1;
          if (a?.cognome === b.cognome) {
            if (a?.nome < b?.nome) return -1;
            if (a?.nome > b?.nome) return 1;
          }
          return 0;
        })
        .map((spp) => {
          return { id: spp.id, name: `${spp?.qualifica} ${spp?.cognome} ${spp?.nome}` };
        });
    }
    return [];
  }, [publicProsecutors]);

  useEffect(() => {
    if (selectedPublicProsecutors !== initValues) debouncedOnChange(selectedPublicProsecutors || []);
  }, [selectedPublicProsecutors, debouncedOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  if (isError)
    return (
      <Typography>
        {t('proceedings.form.addProceeding.fields.publicProsecutors')}: {t('common.messages.errors.fetching')}
      </Typography>
    );
  if (isFetching || isLoading) return <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      size={size}
      id="publicProsecutorsIds"
      multiple
      freeSolo
      componentsProps={{ popper: { placement: 'bottom', modifiers: [{ name: 'flip', enabled: false }] } }}
      disableCloseOnSelect
      options={sortedPublicProsecutors}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedPublicProsecutors}
      onChange={(e, val) => setSelectedPublicProsecutors(val)}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            </ListItemIcon>
            <ListItemText>{option.name}</ListItemText>
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            required: selectedPublicProsecutors.length === 0,
          }}
          name="publicProsecutorsIds"
          required
          label={t('proceedings.form.addProceeding.fields.publicProsecutors')}
        />
      )}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

PublicProsecutorsSelect.defaultProps = {
  initValues: [],
  debounceInterval: 600,
  size: 'medium',
};

PublicProsecutorsSelect.propTypes = {
  initValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setPublicProsecutors: PropTypes.func.isRequired,
  courtId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  debounceInterval: PropTypes.number,
  size: PropTypes.string,
};

export default PublicProsecutorsSelect;
