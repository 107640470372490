import apiSlice from './apiSlice';
import { formatParameters, formUrlEncode } from './configApi';

const apiSliceWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Trials', 'Trial'] });

const apiTrialsSlice = apiSliceWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTrialToken: builder.query({
      query: ({ trialId }) => ({
        url: '/getNewToken',
        method: 'POST',
        body: formUrlEncode({ id_udienza: trialId }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      }),
      transformResponse: (response) => response.response.token,
    }),
    getTrials: builder.query({
      query: ({ filters, orderby }) => ({
        url: '/getUdienze',
        method: 'GET',
        params: formatParameters({ filters, orderby }),
      }),
      providesTags: ['Trials'],
    }),
    getTrial: builder.query({
      query: (id) => ({
        url: '/getUdienze',
        method: 'GET',
        params: formatParameters({ filters: { id } }),
      }),
      transformResponse: (response) => response.udienze[0],
      providesTags: ['Trial'],
    }),
    editTrial: builder.mutation({
      query: ({ trialId, dateEnd, roomId, proceedingsCount, proceedingsCountToVerify, CDCount, certificateId, note, operatorArrivalTime }) => ({
        url: '/editUdienza',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formUrlEncode({
          id_udienza: trialId,
          data_ora_fine: dateEnd,
          id_aula: roomId,
          n_procedimenti: proceedingsCount,
          n_verbali: proceedingsCountToVerify,
          n_supporti: CDCount,
          upload_attestato_presenza: certificateId,
          note,
          ora_arrivo_operatore: operatorArrivalTime,
        }),
      }),
      invalidatesTags: ['Trials', 'Trial'],
    }),
    getFile: builder.query({
      query: ({ fileId, type, print }) => {
        const params = { file_id: fileId };
        if (type) {
          params.type = type;
        }
        if (print) {
          params.print = 1;
        }
        return {
          url: '/getFile',
          method: 'GET',
          responseHandler: async (response) => response.blob(),
          params,
        };
      },
      transformResponse: (response, meta, arg) => {
        let extension = '';
        let mimeType = '';
        switch (new Response(response).headers.get('content-type')) {
          case 'audio/mpeg':
            extension = 'mp3';
            mimeType = 'audio/mpeg';
            break;
          case 'application/pdf':
            extension = 'pdf';
            mimeType = 'application/pdf';
            break;
          case 'text/html':
            extension = 'html';
            mimeType = 'text/html';
            break;
          default:
            extension = 'txt';
            mimeType = 'text/plain';
            break;
        }
        return { file: new File([response], `${arg.fileId}.${extension}`, { type: mimeType }) };
      },
    }),
    getFileName: builder.query({
      query: ({ fileId, type }) => ({
        url: '/getFileName',
        method: 'GET',
        params: type ? { type, file_id: fileId } : { file_id: fileId },
      }),
      transformResponse: (response) => response.filename,
    }),
    getCertificateFile: builder.query({
      query: ({ id, type, workAwayFlag }) => ({
        url: '/getAttestato',
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        params: {
          tipo_attestato: type,
          id,
          flag_trasferta: workAwayFlag || '0',
        },
      }),
      transformResponse: (response, meta, arg) => {
        return { file: new File([response], `${arg.id}${arg.type}.pdf`, { type: 'application/pdf' }) };
      },
    }),
    getCertificateName: builder.query({
      query: ({ ticketId, type, workAwayFlag }) => ({
        url: '/getAttestatoName',
        method: 'GET',
        params: {
          tipo_attestato: type,
          id_ticket: ticketId,
        },
      }),
      transformResponse: (response) => response?.name_attestato,
    }),
    addFile: builder.mutation({
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const chunkSize = 1048576;
        const chunks = parseInt(params.file.size / chunkSize, 10) + 1;
        let response;
        for await (const i of Array(chunks).keys()) {
          const formData = new FormData();
          const sliceContent = params.file.slice(chunkSize * i, chunkSize * (i + 1), 'application/octet-stream');
          formData.append('name', params.file.name);
          formData.append('chunk', i);
          formData.append('chunks', chunks);
          formData.append('myfile', sliceContent);
          response = await fetchWithBQ({
            url: 'addFile',
            method: 'POST',
            params: params.type && { type: params.type },
            body: formData,
          });
        }
        return { data: response.data.OK !== 1 ? { status: 'error', message: response.data.info } : { status: 'success', message: response.data.info, fileId: response.data.id_file, name: response.data.name } };
      },
    }),
    getTrialStatus: builder.query({
      query: () => ({
        url: '/getStatiUdienza',
        method: 'GET',
      }),
      transformResponse: (response) => response.stati_udienza,
    }),
    getTrialRites: builder.query({
      query: () => ({
        url: '/getRitiUdienza',
        method: 'GET',
      }),
      transformResponse: (response) => response.riti_udienza,
    }),
  }),
});

export const {
  useLazyGetTrialTokenQuery,
  useGetTrialsQuery,
  useGetTrialQuery,
  useLazyGetTrialQuery,
  useEditTrialMutation,
  useLazyGetFileQuery,
  useGetFileQuery,
  useGetFileNameQuery,
  useLazyGetCertificateFileQuery,
  useLazyGetCertificateNameQuery,
  useAddFileMutation,
  useGetTrialStatusQuery,
  useGetTrialRitesQuery,
} = apiTrialsSlice;

export default apiTrialsSlice;
