import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetProceedingsQuery, useGetProceedingStatusQuery } from '../features/api/tds/apiProceedingsSlice';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { processJudges, filterProceedingsByField, formatSortingsArray } from '../utils/filterUtils';
import {
  getTicketId,
  setTicketId,
  cleanTicketId,
  getStatusId,
  setStatusId,
  cleanStatusId,
  getRG,
  setRG,
  cleanRG,
  getRGNR,
  setRGNR,
  cleanRGNR,
  getOutcome,
  setOutcome,
  cleanOutcome,
  getOutcomeDate,
  getJudge,
  setJudge,
  cleanJudge,
  getDefendant,
  setDefendant,
  cleanDefendant,
  getChancellor,
  setChancellor,
  cleanChancellor,
  getPM,
  setPM,
  cleanPM,
} from '../features/proceedingFiltersSlice';

function useProceedingResults() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [search, setSearch] = useSearchParams();

  // filters from store to pass to the query for a new fetch
  const currentStatusId = useSelector(getStatusId);
  const filters = useMemo(() => {
    const output = {};
    if (currentStatusId) output.statusId = currentStatusId;
    return output;
  }, [currentStatusId]);

  // orderby from URL to pass to the query for a new fetch
  const orderby = useMemo(() => {
    const output = {};
    for (const [sort, dir] of search.getAll('orderby').map((o) => o.split('-'))) output[sort] = dir;
    return output;
  }, [search]);

  const { currentData: trial, isFetching: isTrialFetching, isLoading: isTrialLoading, isSuccess: isTrialSuccess, isError: isTrialError } = useGetTrialQuery(id || null, { skip: !id });
  const { currentData: statusOptions, isLoading: isStatusOptionsLoading, isFetching: isStatusOptionsFetching } = useGetProceedingStatusQuery(undefined, { skip: !id });
  const { data: unfilteredProceedings, isFetching: areUnfilteredProceedingsFetching, isLoading: areUnfilteredProceedingsLoading } = useGetProceedingsQuery({ trialId: id }, { skip: !id });
  const { currentData: proceedings, isFetching: areProceedingsFetching, isLoading: areProceedingsLoading, isSuccess: areProceedingsSuccess, isError: areProceedingsError } = useGetProceedingsQuery({ trialId: id, filters, orderby }, { skip: !id });

  // trial has missing fields
  const hasMissingFields = !trial?.data_ora_fine_udienza || !trial?.n_procedimenti || !trial?.n_verbali || !trial?.n_supporti || !trial?.id_aula;

  // get filters from store to filter the results on the client
  let filteredProceedings = proceedings;
  const currentTicketId = useSelector(getTicketId);
  const currentRG = useSelector(getRG);
  const currentRGNR = useSelector(getRGNR);
  const currentOutcome = useSelector(getOutcome);
  const currentOutcomeDate = useSelector(getOutcomeDate);
  const currentJudge = useSelector(getJudge);
  const currentDefendant = useSelector(getDefendant);
  const currentChancellor = useSelector(getChancellor);
  const currentPM = useSelector(getPM);
  // check if there are filters to apply
  const hasFilters = useMemo(
    () => currentTicketId || currentStatusId || currentRG || currentRGNR || currentOutcome || currentOutcomeDate || currentJudge || currentDefendant || currentChancellor || currentPM,
    [currentTicketId, currentStatusId, currentRG, currentRGNR, currentOutcome, currentOutcomeDate, currentJudge, currentDefendant, currentChancellor, currentPM]
  );
  // filter the results on the client
  if (currentTicketId) filteredProceedings = filteredProceedings?.filter((proceeding) => proceeding.id_ticket?.toLowerCase().includes(currentTicketId.toLowerCase()));
  if (currentRG) filteredProceedings = filteredProceedings?.filter((proceeding) => proceeding.RG?.toLowerCase().includes(currentRG.toLowerCase()));
  if (currentRGNR) filteredProceedings = filteredProceedings?.filter((proceeding) => proceeding.RGNR?.toLowerCase().includes(currentRGNR.toLowerCase()));
  if (currentOutcome) filteredProceedings = filterProceedingsByField('esito', currentOutcome, filteredProceedings);
  if (currentDefendant) filteredProceedings = filterProceedingsByField('imputato', currentDefendant, filteredProceedings);
  if (currentChancellor) filteredProceedings = filterProceedingsByField('cancelliere', currentChancellor, filteredProceedings);
  if (currentPM) filteredProceedings = filterProceedingsByField('pm', currentPM, filteredProceedings);
  let { availableJudges } = processJudges(filteredProceedings);
  if (currentJudge) ({ filteredProceedings, availableJudges } = processJudges(filteredProceedings, currentJudge));

  // FILTERS VIEW VALUES AND SETTERS
  const [ticketIdViewValue, setTicketIdViewValue] = useState('');
  const [statusIdViewValue, setStatusIdViewValue] = useState('');
  const [RGViewValue, setRGViewValue] = useState('');
  const [RGNRViewValue, setRGNRViewValue] = useState('');
  const [judgeViewValue, setJudgeViewValue] = useState('');
  const [outcomeViewValue, setOutcomeViewValue] = useState('');
  const [defendantViewValue, setDefendantViewValue] = useState('');
  const [chancellorViewValue, setChancellorViewValue] = useState('');
  const [pmViewValue, setPMViewValue] = useState('');

  const [timer, setTimer] = useState(null);
  const timeoutUpdate = (setStateFn, setSearchFn, value, timeout) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(setStateFn(value));
      setSearchFn(value.toUpperCase());
    }, timeout);
    setTimer(newTimer);
  };

  const createFilterHandlers = (cleanFilter, setFilter, paramName, timeout = 1000) => {
    const searchSetter = (newVal) => {
      if (!newVal) {
        search.delete(paramName);
        setSearch(search);
        return dispatch(cleanFilter());
      }
      search.set(paramName, newVal);
      setSearch(search);
      return dispatch(setFilter(newVal));
    };
    const setter = (newVal) => timeoutUpdate(setFilter, searchSetter, newVal, timeout);
    const resetter = () => {
      if (search.has(paramName)) {
        search.delete(paramName);
        setSearch(search);
      }
      dispatch(cleanFilter());
    };

    return [setter, resetter];
  };

  // FILTERS HANDLERS
  const [ticketIdSetter, ticketIdResetter] = createFilterHandlers(cleanTicketId, setTicketId, 'ticketId');
  const [statusIdSetter, statusIdResetter] = createFilterHandlers(cleanStatusId, setStatusId, 'statusId', 0);
  const [RGSetter, RGResetter] = createFilterHandlers(cleanRG, setRG, 'RG');
  const [RGNRSetter, RGNRResetter] = createFilterHandlers(cleanRGNR, setRGNR, 'RGNR');
  const [outcomeSetter, outcomeResetter] = createFilterHandlers(cleanOutcome, setOutcome, 'outcome');
  const [judgeSetter, judgeResetter] = createFilterHandlers(cleanJudge, setJudge, 'judge');
  const [defendantSetter, defendantResetter] = createFilterHandlers(cleanDefendant, setDefendant, 'defendant');
  const [chancellorSetter, chancellorResetter] = createFilterHandlers(cleanChancellor, setChancellor, 'chancellor');
  const [pmSetter, pmResetter] = createFilterHandlers(cleanPM, setPM, 'pm');

  const isFetching = areProceedingsFetching || areUnfilteredProceedingsFetching || isTrialFetching || isStatusOptionsFetching;
  const isLoading = areProceedingsLoading || areUnfilteredProceedingsLoading || isTrialLoading || isStatusOptionsLoading;
  const isSuccess = areProceedingsSuccess && isTrialSuccess;
  const isError = areProceedingsError || isTrialError;

  // on page load get filters from URL, if any are set
  useEffect(() => {
    if (search.has('ticketId')) {
      dispatch(setTicketId(search.get('ticketId')));
      setTicketIdViewValue(search.get('ticketId'));
    } else dispatch(cleanTicketId());

    if (search.has('statusId')) {
      dispatch(setStatusId(search.get('statusId')));
      setStatusIdViewValue(search.get('statusId'));
    } else dispatch(cleanStatusId());

    if (search.has('RG')) {
      dispatch(setRG(search.get('RG')));
      setRGViewValue(search.get('RG'));
    } else dispatch(cleanRG());

    if (search.has('RGNR')) {
      dispatch(setRGNR(search.get('RGNR')));
      setRGNRViewValue(search.get('RGNR'));
    } else dispatch(cleanRGNR());

    if (search.has('outcome')) {
      dispatch(setOutcome(search.get('outcome')));
      setOutcomeViewValue(search.get('outcome'));
    } else dispatch(cleanOutcome());

    if (search.has('judge')) {
      dispatch(setJudge(search.get('judge')));
      setJudgeViewValue(search.get('judge'));
    } else dispatch(cleanJudge());

    if (search.has('defendant')) {
      dispatch(setDefendant(search.get('defendant')));
      setDefendantViewValue(search.get('defendant'));
    } else dispatch(cleanDefendant());

    if (search.has('chancellor')) {
      dispatch(setChancellor(search.get('chancellor')));
      setChancellorViewValue(search.get('chancellor'));
    } else dispatch(cleanChancellor());

    if (search.has('pm')) {
      dispatch(setPM(search.get('pm')));
      setPMViewValue(search.get('pm'));
    } else dispatch(cleanPM());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------------------- ORDER BY -------------------------------------------------------

  const [orderBy, setOrderBy] = useState(search?.getAll('orderby') || '');
  const [ticketIdOrderBy, setTicketIdOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'ticketId'));
  const [statusOrderBy, setStatusOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'status'));
  const [startDateOrderby, setStartDateOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'startDate'));
  const [RGOrderBy, setRGOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'RG'));
  const [RGNROrderBy, setRGNROrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'RGNR'));
  const [outcomeOrderBy, setOutcomeOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'outcome'));
  const [outcomeDateOrderBy, setOutcomeDateOrderBy] = useState(formatSortingsArray(search?.getAll('orderby'), 'outcomeDate'));

  useEffect(() => {
    setOrderBy(search?.getAll('orderby') || '');
    setTicketIdOrderBy(formatSortingsArray(search?.getAll('orderby'), 'ticketId'));
    setStatusOrderBy(formatSortingsArray(search?.getAll('orderby'), 'status'));
    setStartDateOrderBy(formatSortingsArray(search?.getAll('orderby'), 'startDate'));
    setRGOrderBy(formatSortingsArray(search?.getAll('orderby'), 'RG'));
    setRGNROrderBy(formatSortingsArray(search?.getAll('orderby'), 'RGNR'));
    setOutcomeOrderBy(formatSortingsArray(search?.getAll('orderby'), 'outcome'));
    setOutcomeDateOrderBy(formatSortingsArray(search?.getAll('orderby'), 'outcomeDate'));
  }, [search]);

  const createOrderbyHandler = (paramName, orderByFieldValue, orderByFieldSetter) => {
    const setter = () => {
      search.delete('orderby');
      orderBy.forEach((ord) => ord.split('-')[0] !== paramName && search.append('orderby', ord));
      orderByFieldValue ? search.append('orderby', `${paramName}-${orderByFieldValue && orderByFieldValue === 'desc' ? 'asc' : 'desc'}`) : search.append('orderby', `${paramName}-desc`);
      orderByFieldSetter(orderByFieldValue && orderByFieldValue === 'desc' ? 'asc' : 'desc');
      setSearch(search);
    };

    const resetter = () => {
      search.delete('orderby');
      orderBy.forEach((ord) => ord.split('-')[0] !== paramName && search.append('orderby', ord));
      orderByFieldSetter('');
      setSearch(search);
    };

    return [setter, resetter];
  };

  const [ticketIdOrderBySetter, ticketIdOrderByResetter] = createOrderbyHandler('ticketId', ticketIdOrderBy, setTicketIdOrderBy);
  const [statusOrderBySetter, statusOrderByResetter] = createOrderbyHandler('status', statusOrderBy, setStatusOrderBy);
  const [startDateOrderBySetter, startDateOrderByResetter] = createOrderbyHandler('startDate', startDateOrderby, setStartDateOrderBy);
  const [RGOrderBySetter, RGOrderByResetter] = createOrderbyHandler('RG', RGOrderBy, setRGOrderBy);
  const [RGNROrderBySetter, RGNROrderByResetter] = createOrderbyHandler('RGNR', RGNROrderBy, setRGNROrderBy);
  const [outcomeOrderBySetter, outcomeOrderByResetter] = createOrderbyHandler('outcome', outcomeOrderBy, setOutcomeOrderBy);
  const [outcomeDateOrderBySetter, outcomeDateOrderByResetter] = createOrderbyHandler('outcomeDate', outcomeDateOrderBy, setOutcomeDateOrderBy);

  return {
    proceedingsAll: unfilteredProceedings || [],
    proceedingsCount: unfilteredProceedings?.length || 0,
    proceedingsWithVerbalFlagCount: unfilteredProceedings?.filter((proceeding) => proceeding.flag_verbale === '1').length || 0,
    filteredProceedings: filteredProceedings || [],
    trial,
    hasMissingFields,
    id,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    hasFilters,
    filters: {
      ticketId: [currentTicketId, ticketIdSetter, ticketIdResetter],
      statusId: [currentStatusId, statusIdSetter, statusIdResetter, { statusOptions, isStatusOptionsLoading, isStatusOptionsFetching }],
      RG: [currentRG, RGSetter, RGResetter],
      RGNR: [currentRGNR, RGNRSetter, RGNRResetter],
      outcome: [currentOutcome, outcomeSetter, outcomeResetter],
      judge: [currentJudge, judgeSetter, judgeResetter, { availableJudges }],
      defendant: [currentDefendant, defendantSetter, defendantResetter],
      chancellor: [currentChancellor, chancellorSetter, chancellorResetter],
      pm: [currentPM, pmSetter, pmResetter],
      cleanAll: () => {
        ticketIdResetter();
        statusIdResetter();
        RGResetter();
        RGNRResetter();
        outcomeResetter();
        judgeResetter();
        defendantResetter();
        chancellorResetter();
        pmResetter();
      },
    },
    viewFilterValues: {
      ticketId: [ticketIdViewValue, setTicketIdViewValue],
      statusId: [statusIdViewValue, setStatusIdViewValue],
      RG: [RGViewValue, setRGViewValue],
      RGNR: [RGNRViewValue, setRGNRViewValue],
      judge: [judgeViewValue, setJudgeViewValue],
      outcome: [outcomeViewValue, setOutcomeViewValue],
      defendant: [defendantViewValue, setDefendantViewValue],
      chancellor: [chancellorViewValue, setChancellorViewValue],
      pm: [pmViewValue, setPMViewValue],
      cleanAll: () => {
        setTicketIdViewValue('');
        setStatusIdViewValue('');
        setRGViewValue('');
        setRGNRViewValue('');
        setJudgeViewValue('');
        setOutcomeViewValue('');
        setDefendantViewValue('');
        setChancellorViewValue('');
        setPMViewValue('');
      },
    },
    orderBy: {
      ticketId: [ticketIdOrderBy, ticketIdOrderBySetter, ticketIdOrderByResetter],
      status: [statusOrderBy, statusOrderBySetter, statusOrderByResetter],
      startDate: [startDateOrderby, startDateOrderBySetter, startDateOrderByResetter],
      RG: [RGOrderBy, RGOrderBySetter, RGOrderByResetter],
      RGNR: [RGNROrderBy, RGNROrderBySetter, RGNROrderByResetter],
      outcome: [outcomeOrderBy, outcomeOrderBySetter, outcomeOrderByResetter],
      outcomeDate: [outcomeDateOrderBy, outcomeDateOrderBySetter, outcomeDateOrderByResetter],
    },
  };
}

export default useProceedingResults;
