import { Stack } from '@mui/material';
import ProceedingCard from './ProceedingCard';
import useBulkProceedingsResults from '../hooks/useBulkProceedingsResults';

function BulkProceedingsList() {
  const { filteredProceedings } = useBulkProceedingsResults();

  return (
    <Stack id="BulkProceedingsCdUpload" direction="column" spacing={1} my={2}>
      {filteredProceedings?.map((proceeding) => createProceedingCard(proceeding.id))}
    </Stack>
  );
}
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
const createProceedingCard = (proceedingId) => <ProceedingCard key={proceedingId} proceedingId={proceedingId} />;
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */

export default BulkProceedingsList;
