function readFile(file) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File || file instanceof Blob)) reject();
    const filereader = new FileReader();
    filereader.onload = () => {
      resolve(filereader.result);
    };
    filereader.onerror = reject;
    filereader.readAsArrayBuffer(file);
  });
}

function checkReadableFile(file) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File || file instanceof Blob)) reject();
    const filereader = new FileReader();
    filereader.onload = () => {
      resolve(filereader.result);
    };
    filereader.onerror = reject;
    filereader.readAsArrayBuffer(file.slice(0, 1));
  });
}

async function storeFile(file, addFunction) {
  return readFile(file)
    .then((result) => new File([result], file.name, { type: file.type }))
    .then((newFile) => addFunction({ name: file.name, file: newFile }))
    .catch(() => null);
}

async function updateFile(file, id, editFunction) {
  return readFile(file)
    .then((result) => new File([result], file.name, { type: file.type }))
    .then((newFile) => editFunction({ name: id, file: newFile }))
    .catch(() => null);
}

async function renameFile(file, newName, renameOnFileSystem) {
  try {
    const result = await readFile(file);
    const renamedFile = new Blob([result], { type: file.type });
    renamedFile.name = newName;
    if (renameOnFileSystem) {
      const filePath = await window.electronAPI.renameFile(file.path, newName);
      renamedFile.path = filePath;
    } else renamedFile.path = file.path || '';
    return renamedFile;
  } catch (e) {
    console.log(e);
    return null;
  }
}

async function fetchFileFull(file, fetchFunction) {
  return readFile(file)
    .then(() => file)
    .catch(() =>
      fetchFunction('name', file.name).then((recordReadFromIndexedDB) => {
        return recordReadFromIndexedDB?.file;
      })
    );
}

async function fetchFile(file, fetchFunction) {
  return checkReadableFile(file)
    .then(() => file)
    .catch(() =>
      fetchFunction('name', file.name).then((recordReadFromIndexedDB) => {
        return recordReadFromIndexedDB.file;
      })
    );
}

export { readFile, storeFile, fetchFile, fetchFileFull, updateFile, renameFile };
