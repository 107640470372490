import { useSearchParams } from 'react-router-dom';
import { useGetRootJobsQuery } from '../features/api/rest/apiJobSlice';

function useJobs() {
  const [search, setSearch] = useSearchParams();

  const page = parseInt(search.get('page'), 10) || 1;
  const pageSize = parseInt(search.get('psize'), 10) || 10;

  const handlePageSizeChange = ({ target }) => {
    const newPageSize = parseInt(target.value, 10);
    search.set('psize', newPageSize);
    search.delete('page');
    setSearch(search);
  };
  const handlePageChange = (e, newPage) => {
    search.set('page', newPage);
    setSearch(search);
  };

  const { jobList, count, error } = useGetRootJobsQuery(
    { page, psize: pageSize },
    {
      selectFromResult: (result) => ({
        ...result,
        count: result?.data?.count,
        jobList: result?.data?.results,
      }),
      pollingInterval: 2000,
    }
  );

  return {
    jobList,
    count,
    error,
    page,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
  };
}

export default useJobs;
