import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@mui/x-date-pickers';
import { useDebouncedCallback } from 'use-debounce';
import { dateTimeToReduxFormat } from '../utils/dateTimeHandlers';

function TdsTimePicker({ name, label, required, disabled, readOnly, onChange, refDate, initialValue, error, helperText, sx, debounceInterval, format, size }) {
  const [value, setValue] = useState(() => initialValue || '');
  const { t } = useTranslation();

  const debouncedOnChange = useDebouncedCallback((val, context) => {
    if (onChange) {
      onChange(val, context);
    }
  }, debounceInterval);

  const setValueWithValidation = (val, context) => {
    let computedValue = '';
    if (initialValue !== '') computedValue = dayjs(val).isValid() ? dateTimeToReduxFormat(val, 'dateTime') : '';
    else computedValue = dayjs(val).isValid() ? dateTimeToReduxFormat(dayjs(val).format('HH:mm:ss'), 'time', refDate) : '';
    setValue(computedValue);
    debouncedOnChange(computedValue, context);
  };

  return (
    <TimePicker
      sx={sx}
      name={name}
      id={name}
      slotProps={{
        textField: {
          size,
          error,
          helperText: error && helperText,
          inputProps: {
            'data-testid': name,
            placeholder: t('common.times.placeholder'),
          },
          required,
          InputLabelProps: { shrink: true },
        },
      }}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(val, context) => setValueWithValidation(val, context)}
      value={dayjs(value).isValid() ? dayjs(value) : null}
      format={format}
      label={label}
    />
  );
}

TdsTimePicker.defaultProps = {
  required: false,
  disabled: false,
  readOnly: false,
  initialValue: '',
  onChange: null,
  refDate: null,
  helperText: null,
  error: false,
  sx: null,
  debounceInterval: 600,
  format: 'LT',
  size: 'medium',
};

TdsTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  refDate: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.shape({}),
  debounceInterval: PropTypes.number,
  format: PropTypes.string,
  size: PropTypes.string,
};

export default TdsTimePicker;
