import { useState, useEffect, useCallback, useRef } from 'react';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useAudio } from '../contexts/audio';
import { fetchFileFull } from '../utils/fileReader';

export default function useAudioPlayer(file, playAudioFallback) {
  const { getByIndex } = useIndexedDB('files');

  const [trackProgress, setTrackProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFile, setCurrentFile] = useState(file);
  const audioRef = useRef(null);
  const { playAudio, pauseAudio } = useAudio();

  useEffect(() => {
    if (file) {
      if (!audioRef.current) {
        audioRef.current = new Audio(URL.createObjectURL(file));
      }
      const handleTimeUpdate = () => {
        setTrackProgress(audioRef.current.currentTime);
      };
      const handleLoadedData = () => {
        setDuration(audioRef.current.duration);
      };
      const handleEnded = () => {
        setTrackProgress(0);
      };
      const handlePlay = () => {
        setIsPlaying(true);
      };
      const handlePause = () => {
        setIsPlaying(false);
      };

      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadeddata', handleLoadedData);
      audioRef.current.addEventListener('ended', handleEnded);
      audioRef.current.addEventListener('play', handlePlay);
      audioRef.current.addEventListener('pause', handlePause);

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
          audioRef.current.removeEventListener('loadeddata', handleLoadedData);
          audioRef.current.removeEventListener('ended', handleEnded);
          audioRef.current.removeEventListener('play', handlePlay);
          audioRef.current.removeEventListener('pause', handlePause);

          pauseAudio();
          audioRef.current = null;
        }
      };
    }
    return () => {};
  }, [file, pauseAudio]);

  const play = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play().catch(async (e) => {
        const newFile = await fetchFileFull(currentFile, getByIndex);
        if (!newFile) playAudioFallback();
        else {
          audioRef.current.src = URL.createObjectURL(newFile);
          setCurrentFile(newFile);
          audioRef.current.play();
        }
      });
      playAudio(audioRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playAudio]);

  const pause = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      pauseAudio();
    }
  }, [pauseAudio]);

  const foreward10 = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(audioRef.current.currentTime + 10, audioRef.current.duration);
    }
  }, []);

  const replay10 = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(audioRef.current.currentTime - 10, 0);
    }
  }, []);

  const scrub = useCallback((value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  }, []);

  return {
    isPlaying,
    trackProgress,
    duration,
    play,
    pause,
    scrub,
    replay10,
    foreward10,
  };
}
