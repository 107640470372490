import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Stack, Card, CardHeader, Grid, DialogContentText } from '@mui/material';

function SimilarProceedingsDialog({ closeHandler, similarProceedings, isOpen }) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: 'warning.main', color: 'warning.contrastText' }}>{t('proceedings.dialogs.similarProceedingsDialog.title')}</DialogTitle>
      <Divider />
      <DialogContentText m={1}>{t('proceedings.dialogs.similarProceedingsDialog.text')}</DialogContentText>
      <DialogContent sx={{ py: 1, px: 2 }}>
        <Stack direction="column" spacing={1}>
          {similarProceedings.map((proceeding) => (
            <Card elevation={3} key={proceeding.id_ticket}>
              <CardHeader
                sx={{ p: 0.5 }}
                titleTypographyProps={{ variant: 'body2' }}
                title={
                  <Grid container>
                    <Grid item xs={3}>
                      {proceeding.id_ticket}
                    </Grid>
                    <Grid item xs={2}>
                      {proceeding.RG}
                    </Grid>
                    <Grid item xs={2}>
                      {proceeding.RGNR}
                    </Grid>
                    <Grid item xs={5}>
                      {proceeding.imputato}
                    </Grid>
                  </Grid>
                }
              />
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogContentText m={1}>{t('proceedings.dialogs.similarProceedingsDialog.question')}</DialogContentText>
      <Divider />
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={() => closeHandler(false)}>
          {t('common.actions.abort')}
        </Button>
        <Button color="primary" variant="contained" onClick={() => closeHandler(true)}>
          {t('common.actions.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimilarProceedingsDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  similarProceedings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SimilarProceedingsDialog;
