import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const annotationsHeadersAdapter = createEntityAdapter();

const annotationsHeadersSlice = createSlice({
  name: 'annotationsHeaders',
  initialState: annotationsHeadersAdapter.getInitialState(),
  reducers: {
    loadAnnotationsHeaders: annotationsHeadersAdapter.setMany,
    editAnnotationsHeader: annotationsHeadersAdapter.updateOne,
    addAnnotationsHeader: annotationsHeadersAdapter.addOne,
    removeAnnotationsHeader: annotationsHeadersAdapter.removeOne,
    removeAnnotationsHeaderByIds: annotationsHeadersAdapter.removeMany,
    removeAnnotationsHeaders: annotationsHeadersAdapter.removeAll,
  },
});

export const { selectById: selectAnnotationsHeaderById, selectAll: selectAllAnnotationsHeaders, selectTotal: selectTotalAnnotationsHeaders } = annotationsHeadersAdapter.getSelectors();

export const { loadAnnotationsHeaders, editAnnotationsHeader, addAnnotationsHeader, removeAnnotationsHeader, removeAnnotationsHeaderByIds, removeAnnotationsHeaders } = annotationsHeadersSlice.actions;

export default annotationsHeadersSlice;
