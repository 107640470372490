import { Box, Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <Box
      data-testid="404"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h1">{t('404.heading')}</Typography>
      <Typography variant="p">{t('404.subheading')}</Typography>
      <Button component={NavLink} to="/" variant="contained" sx={{ mt: 3 }}>
        {t('404.link')}
      </Button>
    </Box>
  );
}

export default PageNotFound;
