import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import PlayerControls from './PlayerControls';
import PlayerBar from './PlayerBar';
import useAudioPlayer from '../hooks/useAudioPlayer';
import BulkProceedingsCdReinsertDialog from './BulkProceedingsCdReinsertDialog';

function AudioPlayer({ file, playAudioFallback, size }) {
  const { t } = useTranslation();
  const { isPlaying, trackProgress, duration, play, pause, scrub, replay10, foreward10 } = useAudioPlayer(file, playAudioFallback);

  if (!file) return <Typography color="error">{t('recordings.errors.fileNotFound')}</Typography>;
  return (
    <>
      <BulkProceedingsCdReinsertDialog tryAgainCallback={() => play()} openFor={file.name} />
      <Stack spacing={size === 'small' ? 1 : 2} direction="row" alignItems="center">
        <PlayerControls size={size} onPlay={play} onPause={pause} onForward10={foreward10} onReplay10={replay10} isPlaying={isPlaying} />
        <PlayerBar size={size} trackProgress={trackProgress} duration={duration} onScrub={scrub} />
      </Stack>
    </>
  );
}
AudioPlayer.defaultProps = {
  file: null,
  playAudioFallback: () => {},
  size: 'medium',
};
AudioPlayer.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  playAudioFallback: PropTypes.func,
  size: PropTypes.string,
};

export default AudioPlayer;
