/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/**
 *
 * LoginSocialTds
 */
import React, { memo, useCallback, useEffect } from 'react';
import { IResolveParams, objectType } from 'reactjs-social-login';

interface Props {
  scope?: string;
  state?: string;
  client_id: string;
  tdsLoginUrl?: string;
  tdsApiUrl?: string;
  className?: string;
  redirect_uri: string;
  response_type?: string;
  response_mode?: string;
  code_challenge?: string;
  children?: React.ReactNode;
  isOnlyGetCode?: boolean;
  isOnlyGetToken?: boolean;
  onLoginStart?: () => void;
  onReject: (reject: string | objectType) => void;
  code_challenge_method?: 'plain' | 's256'[];
  onResolve: ({ provider, data }: IResolveParams) => void;
  tenant?: 'common' | 'organizations' | 'consumers';
  prompt?: 'login' | 'none' | 'consent' | 'select_account';
}

const DEFAULT_TDS_LOGIN_URL = 'https://tds-dev.id1406.com/login';
const DEFAULT_TDS_API_URL = 'https://tds-dev.id1406.com/api';
// const PREVENT_CORS_URL: string = 'https://cors.bridged.cc'

export function LoginSocialTds({
  tenant = 'common',
  state = '',
  client_id,
  className,
  redirect_uri,
  tdsLoginUrl = DEFAULT_TDS_LOGIN_URL,
  tdsApiUrl = DEFAULT_TDS_API_URL,
  scope = 'profile openid email',
  response_type = 'code',
  response_mode = 'query',
  children,
  code_challenge = '19cfc47c216dacba8ca23eeee817603e2ba34fe0976378662ba31688ed302fa9',
  code_challenge_method = 'plain',
  prompt = 'select_account',
  isOnlyGetCode = false,
  isOnlyGetToken = false,
  onLoginStart,
  onReject,
  onResolve,
}: Props) {
  useEffect(() => {
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get('code');
    const stateParam = popupWindowURL.searchParams.get('state');
    if (stateParam?.includes('_tds') && code) {
      localStorage.setItem('tds', code);
      window.close();
    }
  }, []);

  const getProfile = useCallback(
    (data: objectType) => {
      fetch(`${tdsApiUrl}/v1.0/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          onResolve({ provider: 'tds', data: { ...res, ...data } });
        })
        .catch((err) => {
          onReject(err);
        });
    },
    [onReject, onResolve, tdsApiUrl]
  );

  const getAccessToken = useCallback(
    (code: string) => {
      if (isOnlyGetCode) onResolve({ provider: 'tds', data: { code } });
      else {
        const params = {
          code,
          scope,
          client_id,
          redirect_uri,
          code_verifier: code_challenge,
          grant_type: 'authorization_code',
        };
        const headers = new Headers({
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        });
        fetch(`${tdsLoginUrl}/${tenant}/oauth2/v2.0/token`, {
          method: 'POST',
          headers,
          body: new URLSearchParams(params),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.access_token) {
              if (isOnlyGetToken) onResolve({ provider: 'tds', data });
              else getProfile(data);
            } else {
              onReject('no data');
            }
          })
          .catch((err) => {
            onReject(err);
          });
      }
    },
    [isOnlyGetCode, onResolve, scope, client_id, redirect_uri, code_challenge, tdsLoginUrl, tenant, isOnlyGetToken, getProfile, onReject]
  );

  const handlePostMessage = useCallback(async ({ type, code, provider }: objectType) => type === 'code' && provider === 'tds' && code && getAccessToken(code), [getAccessToken]);

  const onChangeLocalStorage = useCallback(
    (storageEvent) => {
      if (storageEvent.key === 'tds') {
        const code = storageEvent.newValue;
        if (code) {
          localStorage.removeItem('tds');
          window.removeEventListener('storage', onChangeLocalStorage, false);
          handlePostMessage({ provider: 'tds', type: 'code', code });
        }
      }
    },
    [handlePostMessage]
  );

  const onLogin = useCallback(() => {
    onLoginStart && onLoginStart();
    window.addEventListener('storage', onChangeLocalStorage, false);
    const oauthUrl = `${tdsLoginUrl}?redirect_url=${redirect_uri}`;
    const width = 1024;
    const height = 768;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(oauthUrl, 'TDS', `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`);
  }, [onLoginStart, onChangeLocalStorage, tdsLoginUrl, redirect_uri]);

  return (
    <div className={className} role="button" tabIndex={0} onClick={onLogin} onKeyDown={onLogin}>
      {children}
    </div>
  );
}

export default memo(LoginSocialTds);
