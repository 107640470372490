import JSZip from 'jszip';

let isBrowser = false;
if (typeof window !== 'undefined' && typeof window.Blob === 'function') {
  isBrowser = true;
}

function isEmptyString(e) {
  switch (e) {
    case '':
    case 0:
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
}

function isEmptyObject(obj) {
  return obj === null || obj === undefined || Object.keys(obj).length === 0;
}

async function readFile(file, encoding) {
  if (isBrowser) {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File)) reject(new TypeError('input is not a file'));
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsText(file, encoding);
    });
  }
  const buffer = Buffer.from(await file.arrayBuffer());
  return buffer.toString(encoding);
}

async function readZipFile(zipFile) {
  const zip = new JSZip();
  return zip.loadAsync(zipFile).then(async (contents) => {
    const files = [];
    const zipFiles = contents.files;
    for (const [filename, file] of Object.entries(zipFiles)) {
      if (!file.dir) {
        const extension = filename.split('.')?.pop();
        const type = extension === 'mp3' ? 'audio/mpeg' : 'plain/text';
        // eslint-disable-next-line no-await-in-loop
        const recreatedFile = await file.async('arraybuffer').then((buffer) => new File([buffer], file.name, { type }));
        files.push(recreatedFile);
      }
    }
    return files;
  });
}

export { isEmptyString, isEmptyObject, readFile, readZipFile };

export default isEmptyString;
