function compareField(valueA, valueB) {
  if (valueA !== undefined && valueB !== undefined) {
    return valueA - valueB;
  }
  if (valueA !== undefined) {
    return -1;
  }
  if (valueB !== undefined) {
    return 1;
  }
  return 0;
}

function reorderRecordings(recordings) {
  if (!recordings) return [];
  return recordings.slice().sort((a, b) => compareField(a.other && a.other.passage, b.other && b.other.passage) || compareField(a.other && a.other.section, b.other && b.other.section) || recordings.indexOf(a) - recordings.indexOf(b));
}

function buildTreeFromOrderedArray(orderedArray) {
  if (!orderedArray) return [];
  const tree = [];
  let currentPassage = null;
  let nextPassageNumber = 0;
  let nextSectionNumber = 1;

  for (const recording of orderedArray) {
    const passageNumber = (recording.other && recording.other.passage) || nextPassageNumber;
    const sectionNumber = (recording.other && recording.other.section) || nextSectionNumber;

    const newPassage = !currentPassage || currentPassage.passageNumber !== passageNumber;
    if (newPassage) {
      currentPassage = { passageNumber, sections: [] };
      tree.push(currentPassage);
      nextPassageNumber = passageNumber + 1;
    }

    currentPassage.sections.push({ sectionNumber, recording });
    nextSectionNumber = newPassage ? 1 : sectionNumber + 1;
  }

  return tree;
}

export { reorderRecordings, buildTreeFromOrderedArray };
