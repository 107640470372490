import { Button, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useProceedingsUploadButton from '../hooks/useProceedingsUploadButton';
import { openBulkProceedingsDialog } from '../features/bulkProceedingsSlice';
import { setAlertMessage } from '../features/layoutSlice';

function BulkProceedingsUploadButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, isFetching, disableUploadButton, trialCompleted } = useProceedingsUploadButton();

  if (isLoading || isFetching) return <Skeleton width="10em" height="2em" variant="rounded" />;

  const openDialog = () => (trialCompleted ? dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'error' })) : dispatch(openBulkProceedingsDialog()));

  return (
    <Button disabled={disableUploadButton} variant="contained" color="primary" component="label" onClick={openDialog}>
      {t('proceedings.proceedingsList.trial.buttons.addBulkFromCDs')}
    </Button>
  );
}

export default BulkProceedingsUploadButton;
