import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableRow, TableCell, Button } from '@mui/material';
import { selectAnnotationsHeaderById } from '../../../../features/annotationsHeaders';
import HeaderForm from './HeaderForm';

function Header({ id }) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const header = useSelector((state) => selectAnnotationsHeaderById(state.annotationsHeaders, id));

  if (editMode) return <HeaderForm id={id} setMode={setEditMode} />;
  return (
    <TableRow key={header.id}>
      <TableCell width="35%">{t(`annotationsEditor.headers.names.${header.name}`)}</TableCell>
      <TableCell width="50%">
        <Button data-testid="edit-button" sx={{ cursor: 'text' }} disableRipple color="inherit" variant="text" onClick={() => setEditMode(true)}>
          {header.value || '-'}
        </Button>
      </TableCell>
    </TableRow>
  );
}

Header.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Header;
