import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { Box } from '@mui/material';
import ProceedingsByTrialNavbar from './ProceedingsByTrialNavbar';
import { openSidebar, closeSidebar } from '../features/layoutSlice';
import ProceedingsByTrialHeader from './ProceedingsByTrialHeader';
import ProceedingsByTrialResult from './ProceedingsByTrialResult';
import TrialDialog from './TrialDialog';
import SingleProceedingDialog from './SingleProceedingDialog';
import { removeRecordings } from '../features/recordingsSlice';
import { removeProceedings } from '../features/proceedingsSlice';
import { removeMarkers } from '../features/markersSlice';
import { removeTurns } from '../features/turnsSlice';
import { removeTracks } from '../features/tracksSlice';
import { removeAnnotations } from '../features/annotationsSlice';
import { removeOtherFiles } from '../features/otherFilesSlice';
import { removeCds } from '../features/cdsSlice';
import { removeLoadedCds } from '../features/loadedCdsSlice';
import BulkProceedingsDialog from './BulkProceedingsDialog';
import BulkProceedingsDateDialog from './BulkProceedingsDateDialog';
import {
  resetBulkProceedingsActiveForm,
  resetBulkProceedingsPP,
  resetBulkProceedingsRegistrar,
  selectBulkProceedingsDefaultPP,
  selectBulkProceedingsDefaultRegistrar,
  setBulkProceedingsPP,
  setBulkProceedingsRecordingsSelectValues,
  setBulkProceedingsRegistrar,
} from '../features/bulkProceedingsSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';
import BulkProceedingsDeleteCdsDialog from './BulkProceedingsDeleteCdsDialog';

function ProceedingsByTrial() {
  const { id } = useParams();
  const { clear } = useIndexedDB('files');
  const dispatch = useDispatch();

  const { currentData: proceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });
  const bulkProceedingsPP = useSelector(selectBulkProceedingsDefaultPP);
  const bulkProceedingsRegistrar = useSelector(selectBulkProceedingsDefaultRegistrar);

  React.useEffect(() => {
    return () => {
      dispatch(removeRecordings());
      dispatch(removeProceedings());
      dispatch(removeMarkers());
      dispatch(removeTurns());
      dispatch(removeTracks());
      dispatch(removeAnnotations());
      dispatch(removeOtherFiles());
      dispatch(removeCds());
      dispatch(removeLoadedCds());
      dispatch(resetBulkProceedingsActiveForm());
      dispatch(resetBulkProceedingsRegistrar());
      dispatch(resetBulkProceedingsPP());
      dispatch(setBulkProceedingsRecordingsSelectValues([]));
      clear().catch(() => null);
    };
  }, [dispatch, clear]);

  React.useEffect(() => {
    if (bulkProceedingsPP.length === 0 && proceedings && proceedings.length > 0) dispatch(setBulkProceedingsPP(proceedings.at(proceedings.length - 1).pms || []));
  }, [dispatch, proceedings]);
  React.useEffect(() => {
    if (bulkProceedingsRegistrar === '' && proceedings && proceedings.length > 0) dispatch(setBulkProceedingsRegistrar(proceedings.at(proceedings.length - 1).cancelliere || ''));
  }, [dispatch, proceedings]);

  React.useEffect(() => {
    dispatch(openSidebar());
    return () => dispatch(closeSidebar());
  }, [dispatch]);

  return (
    <Box component="section" id="ProceedingsByTrialPage">
      <SingleProceedingDialog />
      <BulkProceedingsDialog />
      <BulkProceedingsDateDialog />
      <BulkProceedingsDeleteCdsDialog />
      <TrialDialog />
      <ProceedingsByTrialHeader />
      <ProceedingsByTrialNavbar />
      <ProceedingsByTrialResult />
    </Box>
  );
}

export default ProceedingsByTrial;
