import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardHeader, Collapse, Divider, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, PlayArrow as PlayIcon, Pause as PauseIcon } from '@mui/icons-material';
import Markers from '../Markers';
import Turns from '../Turns';
import { selectAnnotationsRecordingById } from '../../../../features/annotationsRecordings';
import useAudioPlayer from '../../../../hooks/useAudioPlayer';
import PlayerControls from '../../../PlayerControls';
import PlayerBar from '../../../PlayerBar';
import { dateAddSeconds, dateOffset } from '../../../../lib/parse-recordings-annotations/markers/time';

function Recording({ id, isOpened }) {
  const { t } = useTranslation();
  const recording = useSelector((state) => selectAnnotationsRecordingById(state.annotationsRecordings, id));
  const { isPlaying, trackProgress, duration, play, pause, scrub, replay10, foreward10 } = useAudioPlayer(recording?.track, id);
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(() => isOpened);

  const playerHandler = (e) => {
    e.stopPropagation();
    if (isPlaying) pause();
    else {
      setExpanded(true);
      play();
    }
  };
  const playFromTime = (time) => {
    pause();
    const trackProgressFromHere = time ? dateOffset(recording.timeStart, time) : 0;
    scrub(trackProgressFromHere);
    play();
  };

  useEffect(() => {
    setExpanded(isOpened);
  }, [isOpened]);

  const newItemStartingTime = (recordingTimeStart, playerCurrentTime) => (playerCurrentTime ? dateAddSeconds(recordingTimeStart, playerCurrentTime) : recordingTimeStart);

  return (
    <Card elevation={3}>
      <CardHeader
        onClick={() => setExpanded(!expanded)}
        disableTypography
        sx={{ padding: 1, cursor: 'pointer' }}
        title={
          <Stack mr={2} spacing={2} justifyContent="space-between" direction="row">
            <Typography variant="caption">{recording?.track?.name}</Typography>
            <Stack spacing={2} direction="row">
              <Typography variant="body2">{t('annotationsEditor.recordings.recording.track')}</Typography>
              <Typography variant="body2">{recording?.passage}</Typography>
              <Typography variant="body2">{t('annotationsEditor.recordings.recording.subtrack')}</Typography>
              <Typography variant="body2">{recording?.section}</Typography>
            </Stack>
          </Stack>
        }
        action={
          <Stack direction="row">
            {recording?.track && (
              <IconButton size="small" onClick={(e) => playerHandler(e)}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            )}
            <IconButton size="small">{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Stack>
        }
      />
      <Collapse orientation="vertical" unmountOnExit mountOnEnter in={expanded}>
        {recording?.track && (
          <CardContent sx={{ p: 0, mr: 1 }}>
            <Divider />
            <Stack spacing={2} direction="row" alignContent="center">
              <PlayerControls size="small" onPlay={play} onPause={pause} onForward10={foreward10} onReplay10={replay10} isPlaying={isPlaying} />
              <PlayerBar size="small" trackProgress={trackProgress} duration={duration} onScrub={scrub} />
            </Stack>
          </CardContent>
        )}
        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
          <Tabs sx={{ minHeight: '20px', bgcolor: 'grey.300' }} variant="fullWidth" centered value={value} onChange={(e, newValue) => setValue(newValue)}>
            <Tab sx={{ p: 1, minHeight: 0 }} label={t('annotationsEditor.recordings.markers.label')} />
            <Tab sx={{ p: 1, minHeight: 0 }} label={t('annotationsEditor.recordings.turns.label')} />
          </Tabs>
          <Box>
            <Collapse mountOnEnter orientation="vertical" in={value === 0}>
              <Markers playFromTime={playFromTime} newMarkerTime={newItemStartingTime(recording.timeStart, trackProgress)} recordingId={id} cdId={recording.cdId} />
            </Collapse>
            <Collapse mountOnEnter orientation="vertical" in={value === 1}>
              <Turns recordingId={id} cdId={recording.cdId} />
            </Collapse>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}

Recording.propTypes = {
  id: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default Recording;
