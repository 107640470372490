import { isEmptyObject } from '../../parse-recordings-annotations/common';

function formatTurns(turns, recordingId) {
  const formattedTurns = [];
  turns?.forEach((turn) => {
    formattedTurns.push(formatTurn(turn, recordingId));
  });
  return formattedTurns;
}

function formatTurn(turn, recordingId) {
  const formattedTurn = {};
  if (!isEmptyObject(turn)) {
    formattedTurn.id = turn.id;
    formattedTurn.recordingId = recordingId;
    formattedTurn.time = turn.time || '';
    formattedTurn.channel = turn.channel || turn.trackName || '';
  }
  return formattedTurn;
}

export { formatTurns, formatTurn };
