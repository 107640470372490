import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Card, CardActions, CardHeader, IconButton, Box, Tooltip, Typography, Grid, MenuItem, ListItemIcon, ListItemText, Badge } from '@mui/material';
import { Add, Delete, Edit, EditNote } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useProceedingCard from '../hooks/useProceedingCard';
import ProceedingRecordings from './ProceedingRecordings';
import TooltipMenu from './TooltipMenu';

function ProceedingCard({ proceedingId }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { Proceeding, alreadyUploadedProceedingsCount, alreadyUploadedProceedings, deleteProceeding, editProceeding, createProceeding } = useProceedingCard(proceedingId);

  const cardRef = useRef();

  const typographySpanSx = {
    ml: 1,
    color: 'gray',
  };
  const boxSx = {
    display: 'flex',
    color: alreadyUploadedProceedingsCount > 0 && 'success.contrastText',
    bgcolor: alreadyUploadedProceedingsCount > 0 && 'success.light',
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editClick = (idProceeding) => {
    editProceeding(idProceeding);
    handleClose();
  };
  const addClick = () => {
    createProceeding();
    handleClose();
  };

  const tooltipMenuItems = alreadyUploadedProceedings.map((proc) => (
    <MenuItem key={proc.id_procedimento} onClick={() => editClick(proc.id_procedimento)} divider>
      <ListItemIcon>
        <Edit size="small" />
      </ListItemIcon>
      <ListItemText sx={{ textTransform: 'uppercase', px: 1 }} primary={proc.id_ticket} secondary={proc.imputato} />
    </MenuItem>
  ));

  tooltipMenuItems.push(
    <MenuItem key="addProceeding" onClick={() => addClick()} divider>
      <ListItemIcon>
        <Add size="small" />
      </ListItemIcon>
      <ListItemText sx={{ textTransform: 'uppercase', px: 1 }} primary={t('proceedings.proceedingsList.trial.buttons.add')} />
    </MenuItem>
  );

  return (
    <Card ref={cardRef} elevation={3}>
      <Box data-testid="cardHeaderBox" sx={boxSx}>
        <CardHeader
          sx={{ padding: 0, flex: 1 }}
          disableTypography
          title={
            <Grid container padding={0.5}>
              {/* TITLES ROW */}
              <Grid item xs={2}>
                <Typography>
                  {t('proceedings.proceedingsList.proceeding.RG')}
                  <Typography component="span" sx={typographySpanSx}>
                    {Proceeding.RG || '-'}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  {t('proceedings.proceedingsList.proceeding.RGNR')}
                  <Typography component="span" sx={typographySpanSx}>
                    {Proceeding.RGNR || '-'}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{t('proceedings.proceedingsList.proceeding.defendant')}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{t('proceedings.proceedingsList.proceeding.recordings')}</Typography>
              </Grid>
              {/* VALUES ROW */}
              <Grid item xs={4}>
                <Typography>
                  {t('proceedings.proceedingsList.proceeding.outcome')}
                  <Typography component="span" sx={typographySpanSx}>
                    {Proceeding.outcome || '-'}
                    {Proceeding.outcomeDate && (
                      <Tooltip title={dayjs(Proceeding.outcomeDate).format('Z')}>
                        <Typography ml={1} component="span">
                          {dayjs(Proceeding.outcomeDate).format('L LT')}
                        </Typography>
                      </Tooltip>
                    )}
                    {Proceeding.outcomeNotes && (
                      <Typography ml={1} component="span">
                        {Proceeding.outcomeNotes}
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="gray">{Proceeding.defendant || '-'}</Typography>
              </Grid>
              <Grid item xs={5}>
                <ProceedingRecordings proceedingId={proceedingId} />
              </Grid>
            </Grid>
          }
        />
        <CardActions sx={{ py: 0, pl: 1, pr: 2 }} disableSpacing>
          <IconButton data-testid="delete" color="error" onClick={deleteProceeding}>
            <Delete />
          </IconButton>
          {alreadyUploadedProceedingsCount > 0 ? (
            <>
              <IconButton data-testid="edit" onClick={handleClick}>
                <Badge badgeContent={alreadyUploadedProceedingsCount} color="warning">
                  <EditNote />
                </Badge>
              </IconButton>
              <TooltipMenu
                arrowPosition="right"
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                renderedItems={tooltipMenuItems}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            </>
          ) : (
            <IconButton data-testid="add" onClick={createProceeding}>
              <Add />
            </IconButton>
          )}
        </CardActions>
      </Box>
    </Card>
  );
}

ProceedingCard.propTypes = {
  proceedingId: PropTypes.string.isRequired,
};

export default ProceedingCard;
