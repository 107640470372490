function getAudioFileDuration(file) {
  const url = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.addEventListener('loadedmetadata', () => resolve(audio.duration));
    audio.addEventListener('error', () => reject(new Error('Failed to load audio file')));
    audio.src = url;
  });
}

export default getAudioFileDuration;
