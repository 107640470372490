import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const tracksAdapter = createEntityAdapter({
  selectId: (track) => track.file.name,
});

const tracksSlice = createSlice({
  name: 'tracks',
  initialState: tracksAdapter.getInitialState(),
  reducers: {
    loadTracks: tracksAdapter.setMany,
    removeTracks: tracksAdapter.removeAll,
    removeTracksByIds: tracksAdapter.removeMany,
  },
});

export const { selectById: selectTrackById, selectIds: selectTrackIds, selectEntities: selectTrackEntities, selectAll: selectAllTracks, selectTotal: selectTotalTracks } = tracksAdapter.getSelectors();

export const { loadTracks, removeTracks, removeTracksByIds } = tracksSlice.actions;

export default tracksSlice;
