import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function formatTime(date, tz = 'Europe/Rome', fallback = '00:00:00') {
  if (!date) return fallback;
  return dayjs(date).tz(tz).format('HH:mm:ss');
}

function formatTimeShort(date, tz = 'Europe/Rome', fallback = '0:00:00') {
  if (!date) return fallback;
  return dayjs(date).tz(tz).format('H:mm:ss');
}

function formatDate(date, tz = 'Europe/Rome', fallback = '01/01/0001') {
  if (!date) return fallback;
  return dayjs(date).tz(tz).format('DD/MM/YYYY');
}

function formatDateTime(date, tz = 'Europe/Rome', fallback = '01/01/0001 0:00:00') {
  if (!date) return fallback;
  return dayjs(date).tz(tz).format('DD/MM/YYYY H:mm:ss');
}

export { formatTime, formatTimeShort, formatDate, formatDateTime };

export default formatTime;
