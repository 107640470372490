import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import serialize from 'locutus/php/var/serialize';
import apiTrialsSlice, { useAddFileMutation, useGetTrialQuery, useLazyGetTrialQuery, useLazyGetTrialTokenQuery } from '../features/api/tds/apiTrialsSlice';
import { useCreateChildJobMutation, useCreateJobMutation, useLazyGetRootJobsQuery, useSetJobReadyMutation } from '../features/api/rest/apiJobSlice';
import { useGetProceedingsQuery, useLazyGetProceedingsQuery, useSendProceedingMutation } from '../features/api/tds/apiProceedingsSlice';
import { resetProceedingFormSlice, selectProceeding, selectProceedingFormOtherFiles, selectProceedingFormSelectedRecordings, selectProceedingFormStagedOtherFiles, selectProceedingFormStagedRecordings } from '../features/proceedingFormSlice';
import {
  resetBulkProceedingsActiveForm,
  selectBulkProceedingsSubmittingForm,
  setBulkProceedingsReinsertCdDialog,
  setBulkProceedingsReinsertCdDialogFileMissing,
  setBulkProceedingsReinsertCdDialogMissingRecordingId,
  setBulkProceedingsSubmittingForm,
} from '../features/bulkProceedingsSlice';
import { closeSingleProceedingDialog } from '../features/singleProceedingSlice';
import { editProceeding } from '../features/proceedingsSlice';
import { selectAllTracks } from '../features/tracksSlice';
import { selectAllOtherFiles } from '../features/otherFilesSlice';
import { setAlertMessage } from '../features/layoutSlice';
import getAudioFileDuration from '../lib/getAudioFileDuration';
import getFrameAveragePowers from '../lib/getFrameAveragePowers';
import { dateTimeToTdsFormat } from '../utils/dateTimeHandlers';
import { fetchFileFull } from '../utils/fileReader';

export default function useProceedingFormSubmitButton(trialId) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getTrialTokenTrigger] = useLazyGetTrialTokenQuery();
  const { currentData: proceedings } = useGetProceedingsQuery({ trialId }, { skip: !trialId });

  const [addFileTrigger] = useAddFileMutation();
  const [sendProceedingTrigger] = useSendProceedingMutation();
  const { getByIndex } = useIndexedDB('files');

  const Proceeding = useSelector(selectProceeding);
  const { proceedingId, ticketId, timeStart, timeEnd, report, RG, RGNR, defendant, judge, judge1, judge2, judge3, PM, registrar, outcomeId, outcome, outcomeDate, outcomeNotes, pdfDeliveryDate, paperDeliveryDate, note } = Proceeding;
  const isEditing = !!ticketId;
  const isSubmitting = useSelector(selectBulkProceedingsSubmittingForm);

  /* FILES CONFIRMATION */
  const [filesConfirmationDialogOpen, setFilesConfirmationDialogOpen] = useState(false);

  /* SIMILAR PROCEEDINGS */
  const [similarProceedingsDialogOpen, setSimilarProceedingsDialogOpen] = useState(false);
  const similarProceedingSearch = useCallback(
    (proceeding) => proceeding.RG.localeCompare(RG, { sensitivity: 'base' }) === 0 && proceeding.RGNR.localeCompare(RGNR, { sensitivity: 'base' }) === 0 && proceeding.imputato.localeCompare(defendant, { sensitivity: 'base' }) === 0,
    [RG, RGNR, defendant]
  );
  const similarProceedingsFound = useMemo(() => (proceedings ? proceedings.filter(similarProceedingSearch) : []), [proceedings, similarProceedingSearch]);

  /* TRACKS */
  const tracks = useSelector((state) => selectAllTracks(state.tracks));
  const proceedingRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const proceedingStagedRecordings = useSelector(selectProceedingFormStagedRecordings);
  // const proceedingRecordingsIdsToUpload = proceedingRecordings.concat(proceedingStagedRecordings);
  const proceedingTracksToUpload = useMemo(() => tracks.filter((track) => proceedingRecordings?.includes(track.recordingId) && track.isMixer), [tracks, proceedingRecordings]);

  /* OTHER FILES */
  const otherFiles = useSelector((state) => selectAllOtherFiles(state.otherFiles));
  const proceedingOtherFiles = useSelector(selectProceedingFormOtherFiles);
  const proceedingStagedOtherFiles = useSelector(selectProceedingFormStagedOtherFiles);
  const proceedingOtherFilesToUpload = useMemo(() => otherFiles.filter((otherFile) => proceedingOtherFiles?.includes(otherFile.id)), [otherFiles, proceedingOtherFiles]);

  const tracksByRecordingId = useCallback((recordingId) => tracks.filter((track) => track.recordingId === recordingId && !track.isMixer), [tracks]);

  /* CREATE JOB FUNCTIONS */
  const [createJobTrigger] = useCreateJobMutation();
  const [createChildJobTrigger] = useCreateChildJobMutation();
  const [getProceedingsTrigger] = useLazyGetProceedingsQuery();
  const [setJobReadyTrigger] = useSetJobReadyMutation();

  const fetchFilesFromIndexedDB = useCallback(
    async (formattedFiles) => {
      const fetchedFiles = [];
      for (const formFile of formattedFiles) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await fetchFileFull(formFile.file, getByIndex);
        if (resp) fetchedFiles.push(resp);
        else {
          dispatch(setBulkProceedingsReinsertCdDialogFileMissing(formFile.file.name));
          dispatch(setBulkProceedingsReinsertCdDialogMissingRecordingId(formFile.recordingId));
          dispatch(setBulkProceedingsReinsertCdDialog(proceedingId));
          throw Error(t('recordings.errors.fileNotFound_file', { fileName: formFile.file.name }));
        }
      }
      return fetchedFiles;
    },
    [t, dispatch, getByIndex, proceedingId]
  );

  const jobsCreation = useCallback(
    async (createdProceedingTicketId, createdProceedingId) => {
      try {
        let trialToken;

        try {
          trialToken = await getTrialTokenTrigger({ trialId }).unwrap();
        } catch (error) {
          throw new Error(t('jobs.creation.errors.fetchingTrialToken'));
        }

        let createdProceedingJobId;
        try {
          const createProceedingJobTriggerRes = await createJobTrigger({
            reference: createdProceedingTicketId,
            params: { recordings_order: proceedingRecordings, token: trialToken, trialId, proceedingId: createdProceedingId }, // proceedingRecordingIds => proceedingRecordingsIdsToUpload
          }).unwrap();
          createdProceedingJobId = createProceedingJobTriggerRes.id;
        } catch (error) {
          throw new Error(t('jobs.creation.errors.createChildJob.proceeding', { ticketId: createdProceedingTicketId }));
        }
        for (const recordingTrack of proceedingTracksToUpload) {
          try {
            /* eslint-disable no-await-in-loop */
            const trackFiles = [];
            const labels = {};
            for await (const [i, track] of tracksByRecordingId(recordingTrack.recordingId).entries()) {
              const indexedDBFile = await getByIndex('name', track.fileName);
              trackFiles.push(indexedDBFile.file);
              labels[i] = track.label;
            }
            const frameAveragePowers = await getFrameAveragePowers(trackFiles);
            frameAveragePowers.labels = labels;
            await createChildJobTrigger({
              parentId: createdProceedingJobId,
              reference: recordingTrack.recordingId,
              file: recordingTrack.file,
              params: { frame_average_powers: frameAveragePowers },
            }).unwrap();
            /* eslint-enable no-await-in-loop */
          } catch (error) {
            throw new Error(t('jobs.creation.errors.createChildJob.recording', { fileName: recordingTrack.file.name }));
          }
        }

        try {
          await setJobReadyTrigger(createdProceedingJobId).unwrap();
        } catch (error) {
          throw new Error(t('jobs.creation.errors.settingJobReady.proceeding'));
        }

        dispatch(setAlertMessage({ text: t('jobs.creation.success', { ticketId: createdProceedingTicketId }), type: 'success', render: 'snack' }));
      } catch (error) {
        dispatch(setAlertMessage({ text: error.message, type: 'error', render: 'snack' }));
      }
    },
    [dispatch, t, getTrialTokenTrigger, createChildJobTrigger, setJobReadyTrigger, createJobTrigger, trialId, proceedingTracksToUpload, proceedingRecordings, tracksByRecordingId, getByIndex]
  );

  const uploadFiles = useCallback(async () => {
    const uploadFilePromises = [];
    const uploadFileNamesDurations = [];
    let durationsSum = 0;
    if (report === '1' && proceedingStagedRecordings.length === 0 && proceedingTracksToUpload.length === 0) throw new Error(t('proceedings.form.addProceeding.errors.recordings'));
    const files = await fetchFilesFromIndexedDB(proceedingTracksToUpload.concat(proceedingOtherFilesToUpload));
    for (const file of files) {
      try {
        uploadFilePromises.push(addFileTrigger({ file }));
        const duration = file.type === 'audio/mpeg' ? await getAudioFileDuration(file) : 0; // eslint-disable-line no-await-in-loop
        uploadFileNamesDurations[file.name] = duration;
        durationsSum += duration;
      } catch (error) {
        throw new Error(t('proceedings.form.addProceeding.errors.uploadFile'));
      }
    }
    return { uploadFilePromises, uploadFileNamesDurations, audioFilesCount: proceedingTracksToUpload.length, durationsSum };
  }, [t, addFileTrigger, fetchFilesFromIndexedDB, proceedingOtherFilesToUpload, proceedingTracksToUpload, proceedingStagedRecordings, report]);

  const proceedingCodeValidation = (code) => {
    const pattern = /^\d+\/\d\d( (.*))?$/i;
    return pattern.test(code);
  };
  const checkData = useCallback(() => {
    if (!dayjs(timeStart).isValid()) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.invalidDateTimeStart'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!dayjs(timeEnd).isValid()) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.invalidDateTimeEnd'), type: 'error', render: 'snack' }));
      return false;
    }
    if (dayjs(timeEnd).isBefore(dayjs(timeStart))) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.dateTimeEnd'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!report) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.report'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!RG) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.RG'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!RGNR) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.RGNR'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!proceedingCodeValidation(RG) || !proceedingCodeValidation(RGNR)) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.codeMatching'), type: 'error', render: 'snack', closeAfter: 8000 }));
      return false;
    }
    if (!defendant) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.defendant'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!judge?.id) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.judge'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!PM.length > 0) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.PM'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!registrar) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.registrar'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!outcomeId) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.outcome'), type: 'error', render: 'snack' }));
      return false;
    }
    if (outcomeId === '2' && !outcomeDate) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.outcomeDate'), type: 'error', render: 'snack' }));
      return false;
    }
    if (report === '1' && !proceedingStagedRecordings.length > 0 && !proceedingTracksToUpload.length > 0) {
      dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.errors.recordings'), type: 'error', render: 'snack' }));
      return false;
    }
    return true;
  }, [timeStart, timeEnd, report, RG, RGNR, judge, defendant, PM, registrar, outcomeId, outcomeDate, proceedingStagedRecordings, proceedingTracksToUpload, dispatch, t]);

  const saveProceeding = useCallback(async () => {
    dispatch(setBulkProceedingsSubmittingForm(true));
    let sendProceedingResponse = null;
    try {
      const { uploadFilePromises, uploadFileNamesDurations, audioFilesCount, durationsSum } = await uploadFiles();
      const uploadedFilesIdsDurations = {};
      let tracksDuration = 0;
      proceedingStagedRecordings?.forEach((trk) => {
        uploadedFilesIdsDurations[trk.fileId] = trk.duration;
        tracksDuration += trk.duration;
      });
      proceedingStagedOtherFiles?.forEach((oth) => {
        uploadedFilesIdsDurations[oth.fileId] = 0;
      });
      const uploadFileResolvedPromises = await Promise.all(uploadFilePromises);
      uploadFileResolvedPromises.forEach((value) => {
        uploadedFilesIdsDurations[value.data.fileId] = uploadFileNamesDurations[value.data.name];
      });
      try {
        sendProceedingResponse = await sendProceedingTrigger({
          id_procedimento: ticketId ? proceedingId : '',
          id_udienza: trialId,
          ora_inizio: dateTimeToTdsFormat(timeStart, 'time'),
          ora_fine: dateTimeToTdsFormat(timeEnd, 'time'),
          flag_verbale: report,
          RG,
          RGNR,
          imputato: defendant,
          id_giudice_presidente: judge.id,
          id_giudice_1: judge1?.id || null,
          id_giudice_2: judge2?.id || null,
          id_giudice_3: judge3?.id || null,
          cancelliere: registrar,
          id_esito: outcomeId,
          data_ora_rinvio: dateTimeToTdsFormat(outcomeDate, 'dateTime'),
          esito_altro: outcomeNotes || '',
          note,
          scadenza_portale_definitivo: pdfDeliveryDate ? dateTimeToTdsFormat(pdfDeliveryDate, 'dateTime') : '',
          scadenza_cartaceo: paperDeliveryDate ? dateTimeToTdsFormat(paperDeliveryDate, 'dateTime') : '',
          upload_audio: serialize(uploadedFilesIdsDurations),
          n_audio: audioFilesCount + proceedingStagedRecordings.length,
          durata_secondi: Math.floor((durationsSum + tracksDuration) / 60),
          pm: serialize(PM.map((pm) => pm.id)),
          upload_attestato_consegna: '',
          data_upload_attestato_consegna: '',
          data_ora_consegna: '',
        }).unwrap();
        dispatch(
          editProceeding({ id: proceedingId, changes: { RG, RGNR, outcomeId, outcome, recordings: proceedingRecordings, outcomeDate: dayjs(outcomeDate).toJSON(), outcomeNotes, uploaded: true, idTDS: sendProceedingResponse.id_procedimento?.toString() || '' } })
        );
        dispatch(setAlertMessage({ text: t('proceedings.form.addProceeding.saving'), type: 'success', render: 'snack' }));
        dispatch(resetProceedingFormSlice());
        dispatch(resetBulkProceedingsActiveForm());
        dispatch(closeSingleProceedingDialog());
        dispatch(apiTrialsSlice.util.invalidateTags(['Trials', 'Trial']));
      } catch (error) {
        throw new Error(t('proceedings.form.addProceeding.errors.saving'));
      }
    } catch (err) {
      dispatch(setAlertMessage({ text: err.message, type: 'error', render: 'snack' }));
    } finally {
      dispatch(setBulkProceedingsSubmittingForm(false));
    }
    if (sendProceedingResponse) {
      // get the proceeding created with sendProceedingTrigger
      const createdProceedingId = sendProceedingResponse.id_procedimento;
      const getProceedingsTriggerRes = await getProceedingsTrigger({ trialId, filters: { id_procedimento: createdProceedingId } }).unwrap();
      const createdProceedingTicketId = getProceedingsTriggerRes[0]?.id_ticket;

      if (createdProceedingTicketId) jobsCreation(createdProceedingTicketId, createdProceedingId);
      // NEW PROCEEDING NOT FOUND ON TDS --> RETRY
      else dispatch(setAlertMessage({ text: t('jobs.creation.errors.fetchingProceedingTicketId'), type: 'error', render: 'snack' }));
    }
  }, [
    proceedingId,
    ticketId,
    trialId,
    timeStart,
    timeEnd,
    RG,
    RGNR,
    judge,
    judge1,
    judge2,
    judge3,
    defendant,
    PM,
    registrar,
    outcome,
    outcomeId,
    outcomeDate,
    outcomeNotes,
    pdfDeliveryDate,
    paperDeliveryDate,
    note,
    proceedingRecordings,
    proceedingStagedOtherFiles,
    proceedingStagedRecordings,
    dispatch,
    t,
    report,
    sendProceedingTrigger,
    uploadFiles,
    getProceedingsTrigger,
    jobsCreation,
  ]);

  const submitProceeding = useCallback(() => {
    if (checkData()) {
      if (isEditing) saveProceeding();
      else setFilesConfirmationDialogOpen(true);
    }
  }, [checkData, isEditing, saveProceeding]);

  const similarProceedingsDialogCloseHandler = (choice) => {
    setSimilarProceedingsDialogOpen(false);
    if (choice) saveProceeding();
  };

  const filesConfirmationDialogCloseHandler = (choice) => {
    setFilesConfirmationDialogOpen(false);
    if (choice) {
      if (similarProceedingsFound.length > 0) setSimilarProceedingsDialogOpen(true);
      else saveProceeding();
    }
  };

  return {
    similarProceedings: similarProceedingsFound,
    similarProceedingsDialogOpen,
    filesConfirmationDialogOpen,
    isEditing,
    isSubmitting,
    submitProceeding,
    similarProceedingsDialogCloseHandler,
    filesConfirmationDialogCloseHandler,
  };
}
