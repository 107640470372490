import { Description, Gavel } from '@mui/icons-material';
import { Avatar, Badge } from '@mui/material';

function ProceedingsWithReportBadge() {
  return (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      overlap="circular"
      badgeContent={
        <Avatar sx={{ bgcolor: 'inherit' }}>
          <Description color="action" sx={{ bgcolor: 'inherit', fontSize: '14px' }} />
        </Avatar>
      }
    >
      <Gavel color="action" />
    </Badge>
  );
}

export default ProceedingsWithReportBadge;
