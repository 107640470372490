import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { TextField, Skeleton, Typography, Autocomplete } from '@mui/material';
import { useGetJudgesQuery } from '../features/api/tds/apiPeopleSlice';
import { setAlertMessage } from '../features/layoutSlice';

function JudgesSelect({ initValue, setJudgeId, courtId, sectionId, label, name, required, debounceInterval, size }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: judges, isLoading, isFetching, isError } = useGetJudgesQuery({ filters: { courtId, sectionId }, page: 1, pSize: 1000 }, { skip: !(courtId && sectionId) });
  const [selectedJudge, setSelectedJudge] = useState(() => initValue);
  const debouncedOnChange = useDebouncedCallback(setJudgeId, debounceInterval);
  const sortedJudges = useMemo(() => {
    if (judges) {
      return [...judges]
        .sort((a, b) => {
          if (a?.cognome < b?.cognome) return -1;
          if (a?.cognome > b?.cognome) return 1;
          if (a?.cognome === b.cognome) {
            if (a?.nome < b?.nome) return -1;
            if (a?.nome > b?.nome) return 1;
          }
          return 0;
        })
        .map((sj) => {
          return { id: sj.id, name: `${sj?.qualifica} ${sj?.cognome} ${sj?.nome}` };
        });
    }
    return [];
  }, [judges]);

  useEffect(() => {
    if (judges && initValue && !judges.find((judge) => judge.id === initValue?.id)) dispatch(setAlertMessage({ text: t('proceedings.form.addPrcoeeding.errors.judgeFetching'), type: 'warning', render: 'snack' }));
  }, [dispatch, t, initValue, judges]);

  useEffect(() => {
    if (selectedJudge !== initValue) debouncedOnChange(selectedJudge || null);
  }, [selectedJudge, debouncedOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isError)
    return (
      <Typography>
        {label}: {t('common.messages.errors.fetching')}
      </Typography>
    );
  if (isFetching || isLoading) return <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      size={size}
      id={name}
      options={sortedJudges}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={selectedJudge}
      onChange={(e, val) => setSelectedJudge(val)}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} name={name} required={required} label={label} />}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

JudgesSelect.defaultProps = {
  initValue: {
    id: '',
    label: '',
  },
  debounceInterval: 600,
  size: 'medium',
};

JudgesSelect.propTypes = {
  initValue: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  setJudgeId: PropTypes.func.isRequired,
  courtId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  debounceInterval: PropTypes.number,
  size: PropTypes.string,
};

export default JudgesSelect;
