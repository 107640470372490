import apiSlice from './apiSlice';

const apiProfileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: 'profile/me',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetProfileQuery } = apiProfileSlice;

export default apiProfileSlice;
