import React from 'react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

dayjs.extend(LocalizedFormat);

function Clock({ format }) {
  const [time, setTime] = React.useState(dayjs());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box bgcolor="primary.main" color="primary.contrastText" data-testid="Clock" px={2}>
      {time.format(format)}
    </Box>
  );
}

Clock.defaultProps = {
  format: 'L LT',
};

Clock.propTypes = {
  format: PropTypes.string,
};

export default Clock;
