import { isEmptyObject } from './common';
import { detectProceedingEnd, detectProceedingStart, detectProceedingPeople } from './markers/content';

function proceedingKey({ RG, RGNR }) {
  return `${RG}||${RGNR}`;
}
const emptyKey = proceedingKey({});
function isUnknownProceeding(code) {
  return proceedingKey(code) === emptyKey;
}

function flushIntoSeparateProceedings(proceedings, recordings) {
  let recording;
  while ((recording = recordings.shift())) {
    proceedings.push({ recordings: [recording] });
  }
}

function flushToRecordings(procRecordings, recordings) {
  let recording;
  while ((recording = recordings.shift())) {
    procRecordings.push(recording);
  }
}

function addPeopleToProceeding(proceeding, people) {
  if (people && people.length > 0) {
    if (proceeding.people?.length) {
      // eslint-disable-next-line no-param-reassign
      proceeding.people = proceeding.people.concat(people);
    } else {
      // eslint-disable-next-line no-param-reassign
      proceeding.people = people;
    }
  }
}

function detectProceedings(annotations) {
  if (!annotations.recordings) return [];
  const proceedingsDictionary = {};
  const proceedings = [];
  const timezone = annotations?.information?.timezone || 'Europe/Rome';
  const cdReferenceDate = annotations?.information?.startDate || null;
  let lastStartedProceeding = {};
  let prevRecordingDetectedProceedingEnd = false;
  const unassignedRecordings = [];
  annotations.recordings.forEach((recording) => {
    const referenceDate = recording.startDate || cdReferenceDate;
    let startDetected = false;
    let endDetected = false;
    let outcome = {};
    let people = [];
    (recording.markers || []).forEach((marker) => {
      const start = detectProceedingStart(marker.note, marker.phase);
      people = detectProceedingPeople(marker.note, !!start);
      // if (people.length > 0) {
      //   console.log(marker);
      //   console.log(people);
      // }
      // console.log(`\n\n\n===>\n${marker.note},${!!start}\n---\n${JSON.stringify(people)}\n===<\n\n\n`);
      if (start) {
        startDetected = true;
        prevRecordingDetectedProceedingEnd = false;
        flushIntoSeparateProceedings(proceedings, unassignedRecordings);
        if (endDetected) {
          if (isUnknownProceeding(lastStartedProceeding)) {
            const proceeding = { recordings: [recording], outcome };
            proceedings.push(proceeding);
          } else {
            lastStartedProceeding.outcome = outcome;
          }
          outcome = {};
          endDetected = false;
        }
        const key = proceedingKey(start);
        if (!(key in proceedingsDictionary)) {
          proceedingsDictionary[key] = { RG: start.RG, RGNR: start.RGNR, recordings: [] };
          proceedings.push(proceedingsDictionary[key]);
        }
        lastStartedProceeding = proceedingsDictionary[key];
        if (lastStartedProceeding.recordings[lastStartedProceeding.recordings.length - 1] !== recording) {
          lastStartedProceeding.recordings.push(recording);
        }
      }
      const end = detectProceedingEnd(marker.note, marker.phase, marker?.time || referenceDate, timezone);
      if (end) {
        if (end.date) outcome.date = end.date.date;
        if (end.subtype) outcome.type = end.subtype;
        endDetected = true;
      }
      addPeopleToProceeding(lastStartedProceeding, people);
    });
    if (!startDetected && !endDetected && prevRecordingDetectedProceedingEnd) {
      lastStartedProceeding = {};
    }
    if (!startDetected) {
      if (isUnknownProceeding(lastStartedProceeding)) {
        // Before any labeled proceeding, create unnamed proceedings
        const proceeding = { recordings: [recording] };
        if (endDetected) {
          proceeding.outcome = outcome;
        }
        addPeopleToProceeding(proceeding, people);
        proceedings.push(proceeding);
      } else if (!endDetected) {
        // If no mark detected, postpone assignment
        unassignedRecordings.push(recording);
      } else {
        // If endMark was detected, assign all unassigned recordings to lastStartedProceeding and update outcome
        flushToRecordings(lastStartedProceeding.recordings, unassignedRecordings);
        lastStartedProceeding.recordings.push(recording);
        lastStartedProceeding.outcome = outcome;
      }
    } else if (!isUnknownProceeding(lastStartedProceeding)) {
      if (!isEmptyObject(outcome)) {
        lastStartedProceeding.outcome = outcome;
      }
    }
    if (endDetected) {
      prevRecordingDetectedProceedingEnd = true;
    }
  });
  flushIntoSeparateProceedings(proceedings, unassignedRecordings);
  return proceedings;
}

export { detectProceedings };

export default detectProceedings;
