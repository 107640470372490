import { timeEndRetrieve, timeStartRetrieve } from '../utils';

const defaultChecked = false;
const defaultWithRecording = true;

function formatProceedings(proceedings, annotationsFiles, cdId) {
  const formattedProceedings = [];
  proceedings?.forEach((proceeding, index) => {
    formattedProceedings.push(formatProceeding(proceeding, annotationsFiles, cdId, index, false, true));
  });
  return formattedProceedings;
}

function proceedingId(RG, RGNR, cdId, index) {
  return typeof RG !== 'undefined' && typeof RGNR !== 'undefined' ? `${RG || 'UNKNOWN'}|${RGNR || 'UNKNOWN'}` : `${cdId || 'UNKNOWN'}|${index}`;
}

function formatOutcome(outcome) {
  if (outcome === 'SENTENZA') return '1';
  if (outcome === 'RINVIO') return '2';
  if (outcome === 'ATTI') return '3';
  return '';
}

function formatDefendantsList(people) {
  return people?.map((person) => person.name) || [];
}

function resolveProceedingPeople(people) {
  const defendants = formatDefendantsList(people?.filter((person) => person.role === 'defendant'));
  return { defendants };
}

function formatProceeding(proceeding, annotationsFiles, cdId, index, checked = defaultChecked, withRecordings = defaultWithRecording) {
  const formattedProceeding = {};
  formattedProceeding.id = proceedingId(proceeding.RG, proceeding.RGNR, cdId, index);
  const { defendants } = resolveProceedingPeople(proceeding.people);
  formattedProceeding.report = '';
  formattedProceeding.defendant = defendants?.at(0) || '';
  formattedProceeding.defendantsList = defendants;
  formattedProceeding.judge = '';
  formattedProceeding.judge1 = '';
  formattedProceeding.judge2 = '';
  formattedProceeding.judge3 = '';
  formattedProceeding.PM = [];
  formattedProceeding.registrar = '';
  formattedProceeding.note = '';
  formattedProceeding.RG = proceeding.RG || '';
  formattedProceeding.RGNR = proceeding.RGNR || '';
  formattedProceeding.timeStart = proceeding.recordings ? timeStartRetrieve(proceeding.recordings.at(0)) : null;
  formattedProceeding.timeEnd = proceeding.recordings ? timeEndRetrieve(proceeding.recordings?.at(proceeding.recordings.length - 1)) : null;
  formattedProceeding.uploaded = checked;
  formattedProceeding.outcomeId = formatOutcome(proceeding?.outcome?.type || '');
  formattedProceeding.outcome = proceeding?.outcome?.type || '';
  formattedProceeding.outcomeDate = proceeding?.outcome?.date || null;
  formattedProceeding.outcomeNotes = '';
  formattedProceeding.pdfDeliveryDate = '';
  formattedProceeding.paperDeliveryDate = '';
  if (withRecordings) formattedProceeding.recordings = proceeding.recordings ? proceeding.recordings.map((rec) => rec.id) : [];
  formattedProceeding.selectedRecordings = [];
  formattedProceeding.otherFiles = !annotationsFiles || !annotationsFiles.length > 0 || !cdId ? [] : annotationsFiles.filter((aFile) => aFile.cdId === cdId).map((aFile) => aFile.id);
  formattedProceeding.idTDS = '';
  return formattedProceeding;
}

export { proceedingId, formatProceeding, formatProceedings, formatDefendantsList, resolveProceedingPeople };

export default formatProceedings;
