const judgeMapping = (tdsJudgeField) => {
  switch (tdsJudgeField) {
    case 'id_giudice_1':
      return 'judge1';
    case 'id_giudice_2':
      return 'judge2';
    case 'id_giudice_3':
      return 'judge3';
    default: // 'id_giudice_presidente'
      return 'judge';
  }
};

const judgesMapping = (tdsJudgesFields) => {
  if (!tdsJudgesFields) return {};
  const judges = tdsJudgesFields?.map((judge) => judgeMapping(judge.field));
  return {
    judge: judges?.includes('judge'),
    judge1: judges?.includes('judge1'),
    judge2: judges?.includes('judge2'),
    judge3: judges?.includes('judge3'),
  };
};

export { judgeMapping, judgesMapping };

export default judgesMapping;
