import { isEmptyObject } from '../../parse-recordings-annotations/common';

function formatMarkers(markers, recordingId) {
  const formattedMarkers = [];
  markers?.forEach((marker) => {
    formattedMarkers.push(formatMarker(marker, recordingId));
  });
  return formattedMarkers;
}

function formatMarker(marker, recordingId) {
  const formattedMarker = {};
  if (!isEmptyObject(marker)) {
    formattedMarker.id = marker.id;
    formattedMarker.recordingId = recordingId;
    formattedMarker.time = marker.time || '';
    formattedMarker.note = marker.note || '';
    formattedMarker.phase = marker.phase || '';
  }
  return formattedMarker;
}

export { formatMarkers, formatMarker };
