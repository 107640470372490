import Cookies from 'js-cookie';
import apiSlice from './apiSlice';

const apiSignUpSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: ({ username, email, password1, password2 }) => ({
        url: 'dj-rest-auth/registration/',
        method: 'POST',
        body: { username, email, password1, password2 },
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
    verifyEmail: builder.mutation({
      query: ({ key }) => ({
        url: 'dj-rest-auth/registration/verify-email/',
        method: 'POST',
        body: { key },
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
    resendEmailVerification: builder.mutation({
      query: ({ email }) => ({
        url: 'dj-rest-auth/registration/resend-email/',
        method: 'POST',
        body: { email },
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
  }),
});

export const { useSignUpMutation, useVerifyEmailMutation, useResendEmailVerificationMutation } = apiSignUpSlice;

export default apiSignUpSlice;
