import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function getReferenceDate(referenceDate = null, tz = 'Europe/Rome') {
  if (referenceDate === null) return dayjs().tz(tz);
  if (typeof referenceDate === 'string' || referenceDate instanceof String || referenceDate instanceof Date) {
    return dayjs.tz(referenceDate, tz);
  }
  if (dayjs.isDayjs(referenceDate)) {
    return referenceDate.tz(tz);
  }
  return dayjs().tz(tz);
}

function timeIsBeforeByStrings(timeString, refTimeString) {
  const timeTokens = timeString.split(':').map((i) => parseInt(i, 10));
  const refTimeTokens = refTimeString.split(':').map((i) => parseInt(i, 10));
  return timeTokens[0] < refTimeTokens[0] || (timeTokens[0] === refTimeTokens[0] && (timeTokens[1] < refTimeTokens[1] || (timeTokens[1] === refTimeTokens[1] && timeTokens[2] < refTimeTokens[2])));
}

function timeToDate(string, referenceDate = null, tz = 'Europe/Rome') {
  let refDate = getReferenceDate(referenceDate, tz);
  if (timeIsBeforeByStrings(string, refDate.format('HH:mm:ss'))) {
    refDate = refDate.add(1, 'day');
  }
  return dayjs.tz(`${refDate.format('YYYY-MM-DD')} ${string}`, tz).format();
}

function datetimeToDate(string, tz = 'Europe/Rome', format = 'DD/MM/YYYY HH:mm:ss') {
  return dayjs.tz(string, format, tz).format();
}

function dateAddSeconds(string, offset, tz = 'Europe/Rome') {
  return dayjs(string).add(offset, 'second').tz(tz).format();
}

function dateOffset(baseDateString, targetDateString) {
  if (!baseDateString || !targetDateString) return NaN;
  return dayjs(targetDateString).diff(baseDateString, 'seconds');
}

export { timeToDate, datetimeToDate, getReferenceDate, dateAddSeconds, dateOffset, timeIsBeforeByStrings };

export default timeToDate;
