const defaultMixerTrackNumber = true;
const defaultTrackLabel = 'Mixer';

function formatAudioList(audioList) {
  const formattedAudio = [];
  for (const [key, value] of Object.entries(audioList)) {
    const trks = formatTracks(value.tracks, value.labels, key, value.mainTrack);
    if (trks) formattedAudio.push(trks);
  }
  return formattedAudio.flat();
}

function formatTracks(tracks, labels, recordingId, mixerTrackNumber) {
  const formattedTracks = [];
  for (const [key, value] of Object.entries(tracks)) {
    formattedTracks.push(formatTrack(value, recordingId, labels[key], Number(key) === mixerTrackNumber));
  }
  return formattedTracks;
}

function formatTrack(track, recordingId, label = defaultTrackLabel, isMixerTrack = defaultMixerTrackNumber) {
  const formattedTrack = {};
  formattedTrack.file = track;
  formattedTrack.fileName = track.name || '';
  formattedTrack.label = label;
  formattedTrack.isMixer = isMixerTrack;
  formattedTrack.recordingId = recordingId;
  return formattedTrack;
}

export { formatTrack, formatAudioList };
