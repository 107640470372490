import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import MainNavBar from './MainNavBar';
import MessAlert from './MessAlert';
import Footer from './Footer';
import Sidebar from './Sidebar';
import LogoutDialog from './LogoutDialog';
import { selectSidebar } from '../../features/layoutSlice';
import FileDialog from '../FileDialog';

function Layout({ hasMainNavBar, maxContainerWidth, hasFooter, navItems, navBarHeight, maximizeContainer }) {
  const isSidebarOpen = useSelector(selectSidebar);

  return (
    <Box className="App" height="100vh" display="flex" flexDirection="column">
      <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} position="fixed" width="100%">
        {hasMainNavBar && <MainNavBar navItems={navItems} height={navBarHeight} />}
        <MessAlert />
      </Box>
      <Sidebar navBarHeight={65} />
      <LogoutDialog />
      <Box component="main" flexGrow={1} display="flex" flexDirection="column" sx={{ pl: isSidebarOpen ? '350px' : 0, pt: maximizeContainer ? `${navBarHeight}px` : '100px' }}>
        <Container component="main" maxWidth={maxContainerWidth} disableGutters={maximizeContainer} style={{ flexGrow: 1 }}>
          <Outlet />
        </Container>
      </Box>
      <FileDialog />
      {hasFooter && <Footer />}
    </Box>
  );
}

Layout.defaultProps = {
  hasMainNavBar: false,
  maxContainerWidth: 'lg',
  hasFooter: false,
  navItems: [],
  navBarHeight: 65,
  maximizeContainer: false,
};

Layout.propTypes = {
  hasMainNavBar: PropTypes.bool,
  maxContainerWidth: PropTypes.string,
  maximizeContainer: PropTypes.bool,
  hasFooter: PropTypes.bool,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  navBarHeight: PropTypes.number,
};

export default Layout;
