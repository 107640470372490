import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './configApi';

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
});

export default apiSlice;
