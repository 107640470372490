import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import compareByTime from '../utils/compareByTime';

const turnsAdapter = createEntityAdapter({ sortComparer: compareByTime });

const turnsSlice = createSlice({
  name: 'turns',
  initialState: turnsAdapter.getInitialState(),
  reducers: {
    loadTurns: turnsAdapter.setMany,
    editTurn: turnsAdapter.updateOne,
    addTurn: turnsAdapter.addOne,
    removeTurn: turnsAdapter.removeOne,
    removeTurns: turnsAdapter.removeAll,
    removeTurnsByIds: turnsAdapter.removeMany,
  },
});

export const { selectById: selectTurnById, selectIds: selectTurnsIds, selectAll: selectAllTurns, selectTotal: selectTotalTurns } = turnsAdapter.getSelectors();

export const { loadTurns, editTurn, addTurn, removeTurn, removeTurns, removeTurnsByIds } = turnsSlice.actions;

export default turnsSlice;
