import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardContent, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Collapse, IconButton, Divider } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Header from './Header';
import { selectAllAnnotationsHeaders } from '../../../../features/annotationsHeaders';
import CdDateHandler from '../../../CdDateHandler';

function Headers({ cdId, isOpened }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(() => isOpened);
  const headers = useSelector((state) => selectAllAnnotationsHeaders(state.annotationsHeaders));
  const headersByCdId = useMemo(() => headers?.filter((header) => header.cdId === cdId), [cdId, headers]);

  useEffect(() => {
    setExpanded(isOpened);
  }, [isOpened]);

  return (
    <Card sx={{ mb: 1 }} elevation={3}>
      <CardHeader
        onClick={() => setExpanded(!expanded)}
        sx={{ cursor: 'pointer', padding: 1 }}
        titleTypographyProps={{ variant: 'body1' }}
        title={t('annotationsEditor.headers.label')}
        action={<IconButton size="small">{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      />
      <Collapse mountOnEnter orientation="vertical" in={expanded}>
        <Divider />
        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="40%" sx={{ fontWeight: 'bold' }}>
                    {t('annotationsEditor.headers.header.name')}
                  </TableCell>
                  <TableCell width="60%" sx={{ fontWeight: 'bold' }}>
                    {t('annotationsEditor.headers.header.value')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {headersByCdId?.map((header) => (
                  <Header key={header.id} id={header.id} />
                ))}
                <TableRow key="startDate">
                  <TableCell width="35%">{t('annotationsEditor.headers.names.startDate')}</TableCell>
                  <TableCell width="50%">
                    <CdDateHandler id={cdId} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
}

Headers.propTypes = {
  cdId: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default Headers;
