import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import useTrialEdit from '../hooks/useTrialEdit';

function TrialEditSubmit() {
  const { t } = useTranslation();
  const { saveTrial } = useTrialEdit();

  return (
    <Button color="primary" variant="contained" onClick={saveTrial}>
      {t('common.actions.save')}
    </Button>
  );
}

export default TrialEditSubmit;
