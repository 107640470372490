import { createSlice } from '@reduxjs/toolkit';

const tempDataSlice = createSlice({
  name: 'tempData',
  initialState: { data: [] },
  reducers: {
    setTempData(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },
    removeTempData(state, action) {
      return {
        ...state,
        data: [],
      };
    },
    editCdDate(state, action) {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id !== action.payload.id) return item;
          return {
            ...item,
            startDate: action.payload.date,
          };
        }),
      };
    },
  },
});

export const getTempData = (state) => state.data;

export const getCdData = (state, id) => state.data.find((cd) => cd.id === id);

export const { setTempData, editCdDate, removeTempData } = tempDataSlice.actions;

export default tempDataSlice;
