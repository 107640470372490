import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { closeBulkProceedingsDateDialog, setBulkProceedingsDateDialogLoading, setBulkProceedingsRecordingsSelectLastItems, setBulkProceedingsRecordingsSelectFocus } from '../features/bulkProceedingsSlice';
import { removeAnnotationsHeaderByIds, selectAllAnnotationsHeaders } from '../features/annotationsHeaders';
import { removeAnnotationsRecordingsByIds, selectAllAnnotationsRecordings } from '../features/annotationsRecordings';
import { removeAnnotationsMarkersByIds, selectAllAnnotationsMarkers } from '../features/annotationsMarkers';
import { removeAnnotationsTurnsByIds, selectAllAnnotationsTurns } from '../features/annotationsTurns';
import { removeCdsByIds, selectAllCds } from '../features/cdsSlice';
import { loadProceedings } from '../features/proceedingsSlice';
import { loadRecordings } from '../features/recordingsSlice';
import { loadMarkers } from '../features/markersSlice';
import { loadTurns } from '../features/turnsSlice';
import { loadTracks } from '../features/tracksSlice';
import { loadAnnotations } from '../features/annotationsSlice';
import { loadOtherFiles } from '../features/otherFilesSlice';
import { setAlertMessage } from '../features/layoutSlice';
import { createState, saveCdFromReduxStore } from '../lib/cronologia';
import { storeFile } from '../utils/fileReader';
import { addLoadedCds, selectAllLoadedCds } from '../features/loadedCdsSlice';

export default function useCdData() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { add } = useIndexedDB('files');

  const filesQueue = useMemo(() => [], []);
  const [isStoring, setIsStoring] = useState(false);
  const [ids, setIds] = useState([]);
  const [existingCds, setExistingCds] = useState([]);
  const [reloadCdsDialogOpen, setReloadCdsDialogOpen] = useState(false);
  const reduxHeaders = useSelector((state) => selectAllAnnotationsHeaders(state.annotationsHeaders));
  const reduxRecordings = useSelector((state) => selectAllAnnotationsRecordings(state.annotationsRecordings));
  const reduxMarkers = useSelector((state) => selectAllAnnotationsMarkers(state.annotationsMarkers));
  const reduxTurns = useSelector((state) => selectAllAnnotationsTurns(state.annotationsTurns));
  const cdsInfo = useSelector((state) => selectAllCds(state.cds));
  const loadedCdsInfo = useSelector((state) => selectAllLoadedCds(state.loadedCds));

  const handleFilesQueue = useCallback(async () => {
    if (filesQueue.length > 0) {
      const file = filesQueue.shift();
      await storeFile(file, add);
      handleFilesQueue();
    } else setIsStoring(false);
  }, [filesQueue, add]);

  const storeFilesOnIndexedDB = useCallback(
    (files) => {
      filesQueue.push(...files);
      if (!isStoring) {
        setIsStoring(true);
        handleFilesQueue();
      }
    },
    [filesQueue, isStoring, handleFilesQueue]
  );

  /* ABORT CDS LOADING FUNCTIONS */
  const removeCdAnnotations = useCallback(
    (cdIds = []) => {
      const headersToRemoveIds = reduxHeaders?.filter((h) => cdIds.includes(h.cdId))?.map((h) => h.id);
      dispatch(removeAnnotationsHeaderByIds(headersToRemoveIds));
      const recordingsToRemoveIds = reduxRecordings?.filter((rec) => cdIds.includes(rec.cdId))?.map((rec) => rec.id);
      dispatch(removeAnnotationsRecordingsByIds(recordingsToRemoveIds));
      const markersToRemoveIds = reduxMarkers?.filter((mrk) => recordingsToRemoveIds.includes(mrk.recordingId))?.map((mrk) => mrk.id);
      dispatch(removeAnnotationsMarkersByIds(markersToRemoveIds));
      const turnsToRemoveIds = reduxTurns?.filter((trn) => recordingsToRemoveIds.includes(trn.recordingId))?.map((trn) => trn.id);
      dispatch(removeAnnotationsTurnsByIds(turnsToRemoveIds));
    },
    [dispatch, reduxHeaders, reduxMarkers, reduxTurns, reduxRecordings]
  );
  const cancelCds = (cdIds = []) => {
    const filteredCdsInfo = cdIds.length > 0 ? cdsInfo.filter((cd) => cdIds.includes(cd.id)) : cdsInfo;
    removeCdAnnotations(filteredCdsInfo.map((cd) => cd.id));
    dispatch(removeCdsByIds(filteredCdsInfo.map((cd) => cd.id)));
    if (cdsInfo.length === 1 || cdIds.length === 0) dispatch(closeBulkProceedingsDateDialog());
  };

  /* SAVE CDS FUNCTIONS */
  const saveCds = useCallback(
    async (cdIds = []) => {
      dispatch(setBulkProceedingsDateDialogLoading(true));
      const filteredCdsInfo = cdIds.length > 0 ? cdsInfo.filter((cd) => cdIds.includes(cd.id)) : cdsInfo;
      const cdsData = filteredCdsInfo?.map((cdInfo) => saveCdFromReduxStore(cdInfo, reduxRecordings, reduxHeaders, reduxMarkers, reduxTurns));
      createState(cdsData)
        .then((state) => {
          dispatch(loadRecordings(state.recordings));
          dispatch(loadProceedings(state.proceedings));
          dispatch(loadMarkers(state.markers));
          dispatch(loadTurns(state.turns));
          dispatch(loadTracks(state.tracks));
          dispatch(loadAnnotations(state.annotations));
          dispatch(loadOtherFiles(state.annotations));
          dispatch(setBulkProceedingsRecordingsSelectLastItems(state.tracks.filter((tr) => tr.isMixer).map((trk) => trk.file?.name)));
          storeFilesOnIndexedDB(state.tracks.map((track) => track.file).concat(state.annotations.map((annotation) => annotation.file)));
        })
        .finally(() => {
          dispatch(setBulkProceedingsDateDialogLoading(false));
          if (cdsInfo.length === 1 || cdIds.length === 0) {
            dispatch(closeBulkProceedingsDateDialog());
            dispatch(setBulkProceedingsRecordingsSelectFocus(true));
          }
          dispatch(addLoadedCds(filteredCdsInfo));
          removeCdAnnotations(filteredCdsInfo.map((cd) => cd.id));
          dispatch(removeCdsByIds(filteredCdsInfo.map((cd) => cd.id)));
        })
        .catch((e) => {
          dispatch(setAlertMessage({ text: t('proceedings.dialogs.errors.readingCD'), type: 'warning', render: 'snack' }));
        });
    },
    [dispatch, storeFilesOnIndexedDB, t, removeCdAnnotations, cdsInfo, reduxRecordings, reduxHeaders, reduxMarkers, reduxTurns]
  );
  const saveHandler = (cdIds = []) => {
    const existingCdsFound = cdIds.length > 0 ? loadedCdsInfo.filter((lcd) => cdIds.includes(lcd.id)) : loadedCdsInfo.filter((lcd) => cdsInfo.map((cd) => cd.id).includes(lcd.id));
    if (existingCdsFound.length > 0) {
      setIds(cdIds);
      setExistingCds(existingCdsFound);
      setReloadCdsDialogOpen(true);
    } else saveCds(cdIds);
  };

  const closeHandler = (choice) => {
    setExistingCds([]);
    setReloadCdsDialogOpen(false);
    if (choice) {
      saveCds(ids);
      setIds([]);
    }
  };

  return {
    existingCds,
    reloadCdsDialogOpen,
    closeHandler,
    cds: cdsInfo,
    saveCds: saveHandler,
    cancelCds,
  };
}
