import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TextField, TableRow, TableCell, ClickAwayListener } from '@mui/material';
import TdsTimePicker from '../../../TdsTimePicker';
import { addAnnotationsMarker, editAnnotationsMarker } from '../../../../features/annotationsMarkers';
import { setAlertMessage } from '../../../../features/layoutSlice';
import { computeMarkerId } from '../../../../lib/parse-recordings-annotations/adjust';

function MarkerForm({ marker, refDate, recordingId, setMode, newMarkerTime }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [time, setTime] = useState(marker?.time || newMarkerTime);
  const [note, setNote] = useState(marker?.note || '');
  const [phase, setPhase] = useState(marker?.phase || '');

  const saveMarker = async () => {
    if (note !== '' && time !== '') {
      if (marker) dispatch(editAnnotationsMarker({ id: marker.id, changes: { time, phase, note } }));
      else {
        const newMarker = { time, phase, note, recordingId };
        const newMarkerId = await computeMarkerId(newMarker, recordingId);
        const markerToLoad = { ...newMarker, id: newMarkerId };
        dispatch(addAnnotationsMarker(markerToLoad));
      }
    } else dispatch(setAlertMessage({ text: t('annotationsEditor.errors.requiredFields'), type: 'warning', render: 'snack' }));
    setMode(false);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={saveMarker}>
      <TableRow>
        <TableCell colSpan={2} width="20%">
          <TdsTimePicker name="markerTime" label={t('annotationsEditor.recordings.markers.marker.time')} required onChange={(newVal) => setTime(newVal)} initialValue={time} refDate={refDate} format="LTS" />
        </TableCell>
        <TableCell width="20%">
          <TextField inputProps={{ 'data-testid': 'phase' }} label={t('annotationsEditor.recordings.markers.marker.phase')} onChange={(e) => setPhase(e.target.value)} value={phase} variant="outlined" />
        </TableCell>
        <TableCell colSpan={2} width="60%">
          <TextField inputProps={{ 'data-testid': 'notes' }} fullWidth multiline required label={t('annotationsEditor.recordings.markers.marker.notes')} onChange={(e) => setNote(e.target.value)} value={note} variant="outlined" />
        </TableCell>
      </TableRow>
    </ClickAwayListener>
  );
}

MarkerForm.defaultProps = {
  marker: null,
  refDate: null,
  newMarkerTime: '',
};

MarkerForm.propTypes = {
  marker: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recordingId: PropTypes.string,
    phase: PropTypes.string,
    note: PropTypes.string,
    time: PropTypes.string,
  }),
  refDate: PropTypes.string,
  recordingId: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  newMarkerTime: PropTypes.string,
};

export default MarkerForm;
