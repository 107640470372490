import Proptype from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

function FilterByString({ value, onInputChange, label, name, options, color, size }) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      componentsProps={{ paper: { sx: { minWidth: '100%', width: 'fit-content', whiteSpace: 'nowrap' } } }}
      size={size}
      sx={{ width: 1 / 6 }}
      freeSolo
      value={value !== '' ? value : null}
      inputValue={value}
      onInputChange={(e, newValue) => onInputChange(newValue)}
      options={options}
      isOptionEqualToValue={(option, val) => val === option && true}
      renderInput={(params) => <TextField {...params} id={name} label={label} color={color} />}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

FilterByString.defaultProps = {
  value: '',
  options: [],
  color: 'primary',
  size: 'medium',
};

FilterByString.propTypes = {
  name: Proptype.string.isRequired,
  label: Proptype.string.isRequired,
  value: Proptype.string,
  onInputChange: Proptype.func.isRequired,
  options: Proptype.arrayOf(Proptype.string),
  color: Proptype.string,
  size: Proptype.string,
};

export default FilterByString;
