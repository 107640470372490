import { XMLBuilder } from 'fast-xml-parser';
import { formatTimeShort, formatDate } from '../txt/dateTime';
import { dateOffset } from '../../../../markers/time';

const alwaysArray = [
  'Dibattimento.Cronologia.Brano',
  'Dibattimento.Cronologia.Brano.SubBrano',
  'Dibattimento.Cronologia.Brano.SubBrano.Intervento',
  'Dibattimento.Cronologia.Brano.SubBrano.Marker',
  'Dibattimento.Audio.Brano',
  'Dibattimento.Audio.Brano.SubBrano',
  'Dibattimento.Audio.Brano.SubBrano.Intervento',
  'Dibattimento.Audio.Brano.SubBrano.Marker',
];

const nameToTrackMap = {
  Giudice: 3,
  'P. M.': 4,
  'Avvocato 1': 5,
  'Avvocato 2': 6,
  Testimone: 8,
};

const builder = new XMLBuilder({
  ignoreAttributes: false,
  suppressEmptyNode: true,
  attributeNamePrefix: '@_', // eslint-disable-next-line no-unused-vars
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (alwaysArray.indexOf(jpath) !== -1) return true;
    return false;
  },
});

function generateRecordingXML(recording, date) {
  const combinedItems = [...recording.turns, ...recording.markers].sort((a, b) => {
    return new Date(a.time) - new Date(b.time);
  });

  if (!recording) return null;
  if (recording.other?.duration) {
    return {
      '@_Id': recording.other.passage,
      '@_Date': formatDate(date),
      '@_Time': recording.other?.startDate ? formatTimeShort(recording.other?.startDate) : formatTimeShort(date),
      '@_Duration': recording.other.duration,
      SubBrano: {
        '@_Id': recording.other.section,
        '@_Duration': recording.other.duration,
        ...combinedItems.reduce((acc, item) => {
          if ('note' in item) {
            if (!acc.Marker) {
              acc.Marker = [];
            }
            if (recording.other.startDate) {
              acc.Marker.push({
                '@_Position': dateOffset(recording.other.startDate, item.time),
                '@_Fase': item.phase,
                '@_Note': item.note,
              });
            } else {
              acc.Marker.push({
                '@_Fase': item.phase,
                '@_Note': item.note,
                '@_Time': item.time,
              });
            }
          } else {
            if (!acc.Intervento) {
              acc.Intervento = [];
            }
            if (recording.other.startDate) {
              acc.Intervento.push({
                '@_Position': dateOffset(recording.other.startDate, item.time),
                '@_Channel': nameToTrackMap[item.trackName],
              });
            } else {
              acc.Intervento.push({
                '@_Time': item.time,
                '@_Channel': nameToTrackMap[item.trackName],
              });
            }
          }
          return acc;
        }, {}),
      },
    };
  }
  return {
    '@_Id': recording.other.passage,
    '@_Date': formatDate(date),
    '@_Time': recording.other?.startDate ? formatTimeShort(recording.other?.startDate) : formatTimeShort(date),
    SubBrano: {
      '@_Id': recording.other.section,
      '@_Time': formatDate(date),
      ...combinedItems.reduce((acc, item) => {
        if ('note' in item) {
          if (!acc.Marker) {
            acc.Marker = [];
          }
          if (recording.other.startDate) {
            acc.Marker.push({
              '@_Position': dateOffset(recording.other.startDate, item.time),
              '@_Fase': item.phase,
              '@_Note': item.note,
            });
          } else {
            acc.Marker.push({
              '@_Fase': item.phase,
              '@_Note': item.note,
              '@_Time': item.time,
            });
          }
        } else {
          if (!acc.Intervento) {
            acc.Intervento = [];
          }
          if (recording.other.startDate) {
            acc.Intervento.push({
              '@_Position': dateOffset(recording.other.startDate, item.time),
              '@_Channel': nameToTrackMap[item.trackName],
            });
          } else {
            acc.Intervento.push({
              '@_Time': item.time,
              '@_Channel': nameToTrackMap[item.trackName],
            });
          }
        }
        return acc;
      }, {}),
    },
  };
}

function formatCdAnnotations(annotations) {
  if (!annotations || Object.keys(annotations).length === 0) return '';
  const adaptedJSON = {
    Dibattimento: {
      Cronologia: {
        '@_Date': formatDate(annotations.information.startDate),
        '@_Time': formatTimeShort(annotations.information.startDate),
        Brano: annotations.recordings.map((recording) => generateRecordingXML(recording, annotations.information.startDate)),
      },
    },
  };
  const xdata = builder.build(adaptedJSON);
  return xdata;
}

export { formatCdAnnotations, generateRecordingXML };

export default formatCdAnnotations;
