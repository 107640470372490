import { createSlice } from '@reduxjs/toolkit';

const initialProceeding = {
  trialId: '',
  proceedingId: '',
  ticketId: '',
  status: '',
  dateStart: '',
  timeStart: '',
  timeEnd: '',
  report: '',
  RG: '',
  RGNR: '',
  sectionId: '',
  courtId: '',
  riteTypeId: '',
  defendant: '',
  defendantsList: [],
  judge: null,
  judge1: null,
  judge2: null,
  judge3: null,
  PM: [],
  registrar: '',
  outcomeId: '',
  outcome: '',
  outcomeDate: '',
  outcomeNotes: '',
  pdfDeliveryDate: '',
  paperDeliveryDate: '',
  note: '',
};
const initialState = {
  proceeding: initialProceeding,
  otherFiles: [],
  recordings: [],
  selectedRecordings: [],
  stagedRecordings: [], // edit proceeding
  stagedOtherFiles: [], // edit proceeding
};

const options = {
  name: 'proceedingForm',
  initialState,
  reducers: {
    resetProceedingFormSlice: (state) => ({
      ...initialState,
    }),
    setProceeding: (state, action) => ({
      ...state,
      proceeding: action.payload,
    }),
    setProceedingFormField: (state, action) => ({
      ...state,
      proceeding: {
        ...state.proceeding,
        ...action.payload,
      },
    }),
    setProceedingFormStagedRecordings: (state, action) => ({
      ...state,
      stagedRecordings: action.payload,
    }),
    setProceedingFormSelectedRecordings: (state, action) => ({
      ...state,
      selectedRecordings: action.payload,
    }),
    setProceedingFormRecordings: (state, action) => ({
      ...state,
      recordings: action.payload,
    }),
    setProceedingFormStagedOtherFiles: (state, action) => ({
      ...state,
      stagedOtherFiles: action.payload,
    }),

    setProceedingFormOtherFiles: (state, action) => ({
      ...state,
      otherFiles: action.payload,
    }),
  },
};

const proceedingFormSlice = createSlice(options);
export const selectProceeding = (state) => state.proceedingForm.proceeding;
export const selectProceedingFormRecordings = (state) => state.proceedingForm.recordings;
export const selectProceedingFormSelectedRecordings = (state) => state.proceedingForm.selectedRecordings;
export const selectProceedingFormOtherFiles = (state) => state.proceedingForm.otherFiles;
export const selectProceedingFormStagedRecordings = (state) => state.proceedingForm.stagedRecordings; // edit proceeding
export const selectProceedingFormStagedOtherFiles = (state) => state.proceedingForm.stagedOtherFiles; // edit proceeding
export const { resetProceedingFormSlice, setProceeding, setProceedingFormField, setProceedingFormOtherFiles, setProceedingFormRecordings, setProceedingFormSelectedRecordings, setProceedingFormStagedOtherFiles, setProceedingFormStagedRecordings } =
  proceedingFormSlice.actions;

export default proceedingFormSlice;
