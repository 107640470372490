import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';
import { AddCircle as AddIcon } from '@mui/icons-material';
import Turn from './Turn';
import { selectAllAnnotationsTurns } from '../../../../features/annotationsTurns';
import { selectCdById } from '../../../../features/cdsSlice';
import TurnForm from './TurnForm';

function Turns({ recordingId, cdId }) {
  const { t } = useTranslation();
  const [addMode, setAddMode] = useState(false);
  const turns = useSelector((state) => selectAllAnnotationsTurns(state.annotationsTurns));
  const turnsByRecordingId = useMemo(() => turns?.filter((trn) => trn.recordingId === recordingId), [recordingId, turns]);
  const cdData = useSelector((state) => selectCdById(state.cds, cdId));
  const nameToTrackMap = useMemo(() => cdData.nameToTrackMap || {}, [cdData]);
  const currentStartingDate = useMemo(() => cdData.newStartDate, [cdData]);
  const channels = useMemo(() => Object.keys(nameToTrackMap), [nameToTrackMap]);

  return (
    <TableContainer sx={{ maxHeight: '20em' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell width="40%" sx={{ fontWeight: 'bold' }}>
              {t('annotationsEditor.recordings.turns.turn.time')}
            </TableCell>
            <TableCell width="50%" sx={{ fontWeight: 'bold' }}>
              {t('annotationsEditor.recordings.turns.turn.trackName')}
            </TableCell>
            <TableCell width="10%" sx={{ fontWeight: 'bold' }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {turnsByRecordingId?.map((turn) => (
            <Turn key={turn.id} id={turn.id} channels={channels} />
          ))}
        </TableBody>
        <TableFooter>
          {addMode ? (
            <TurnForm recordingId={recordingId} channels={channels} refDate={currentStartingDate} setMode={setAddMode} />
          ) : (
            <TableRow>
              <TableCell sx={{ borderBottom: 'unset' }} colSpan={4}>
                <IconButton size="small" onClick={() => setAddMode(true)}>
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

Turns.propTypes = {
  recordingId: PropTypes.string.isRequired,
  cdId: PropTypes.string.isRequired,
};

export default Turns;
