import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Divider, Typography, TextField, Switch } from '@mui/material';
import TdsDatePicker from './TdsDatePicker';
import TdsTimePicker from './TdsTimePicker';
import Spinner from './Spinner';
import { setEditingTrialField } from '../features/trialEditingSlice';
import TrialCertificate from './TrialCertificate';
import RoomsSelect from './RoomsSelect';
import useTrialEdit from '../hooks/useTrialEdit';
import TrialSignedCertificate from './TrialSignedCertificate';

function TrialForm() {
  const { trial, trialId, isSubmitting } = useTrialEdit();
  const { ticketId, away, roomId, courtId, dateStart, timeStart, timeEnd, proceedingsCount, proceedingsCountToVerify, CDCount, certificateId, notes, operatorArrivalTime, trasferta } = trial;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setRoomId = (newRoomId) => {
    dispatch(setEditingTrialField({ roomId: newRoomId }));
  };
  const setCertificateId = (newTrialCertificateId) => {
    dispatch(setEditingTrialField({ certificateId: newTrialCertificateId }));
  };
  const setTimeEnd = (newTimeEnd) => {
    dispatch(setEditingTrialField({ timeEnd: newTimeEnd }));
  };
  const setOperatorArrivalTime = (newOperatorArrivalTime) => {
    dispatch(setEditingTrialField({ operatorArrivalTime: newOperatorArrivalTime }));
  };

  if (isSubmitting) return <Spinner />;

  return (
    <Stack spacing={1} mx={2} my={1} direction="column" component="form">
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography>{t('trials.form.editTrial.fields.away')}</Typography>
        <Stack direction="row" alignItems="center">
          <Typography>{t('trials.form.editTrial.fields.awayNo')}</Typography>
          <Switch size="small" inputProps={{ 'data-testid': 'away' }} checked={away} disabled color="secondary" />
          <Typography>{t('trials.form.editTrial.fields.awayYes')}</Typography>
        </Stack>
      </Stack>
      <TdsDatePicker size="small" sx={{ width: 1 / 3 }} name="dateStart" initialValue={dateStart} label={t('trials.form.editTrial.labels.date')} required disabled readOnly />
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <TdsTimePicker
          size="small"
          name="timeFrom"
          sx={{ width: 1 / 2 }}
          label={t('trials.form.editTrial.labels.timeFrom')}
          required
          disabled
          readOnly
          initialValue={timeStart}
          refDate={dateStart}
          error={dayjs(timeEnd).isBefore(dayjs(timeStart))}
          helperText={t('trials.form.editTrial.errors.dateTimeStart')}
        />
        <Typography>-</Typography>
        <TdsTimePicker
          size="small"
          name="timeTo"
          sx={{ width: 1 / 2 }}
          label={t('trials.form.editTrial.labels.timeTo')}
          required
          onChange={setTimeEnd}
          initialValue={timeEnd}
          error={dayjs(timeEnd).isBefore(dayjs(timeStart))}
          helperText={t('trials.form.editTrial.errors.dateTimeEnd')}
          refDate={dateStart}
        />
      </Stack>
      <Divider variant="middle" />
      <RoomsSelect size="small" required roomId={roomId} setRoomId={setRoomId} courtId={courtId} label={t('trials.form.editTrial.labels.room')} name="roomId" />
      <Divider variant="middle" />
      <TextField
        size="small"
        id="proceedingsCount"
        name="proceedingsCount"
        required
        inputProps={{
          min: 0,
          inputMode: 'numeric',
          pattern: '[0-9]*',
          'data-testid': 'proceedingsCount',
        }}
        value={proceedingsCount}
        error={proceedingsCount < proceedingsCountToVerify}
        helperText={proceedingsCount < proceedingsCountToVerify ? t('trials.form.editTrial.errors.proceedingsCount') : ''}
        onChange={(e) => dispatch(setEditingTrialField({ proceedingsCount: isNaN(parseInt(e.target.value, 10)) ? '' : parseInt(e.target.value, 10) }))}
        type="number"
        placeholder={t('trials.form.editTrial.placeholders.numbers')}
        label={t('trials.form.editTrial.labels.proceedingsCount')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        size="small"
        id="proceedingsCountToVerify"
        name="proceedingsCountToVerify"
        required
        inputProps={{
          min: 0,
          inputMode: 'numeric',
          pattern: '[0-9]*',
          'data-testid': 'proceedingsCountToVerify',
        }}
        value={proceedingsCountToVerify}
        error={proceedingsCountToVerify > proceedingsCount}
        helperText={proceedingsCountToVerify > proceedingsCount ? t('trials.form.editTrial.errors.proceedingsCountToVerify') : ''}
        onChange={(e) => dispatch(setEditingTrialField({ proceedingsCountToVerify: isNaN(parseInt(e.target.value, 10)) ? '' : parseInt(e.target.value, 10) }))}
        type="number"
        placeholder={t('trials.form.editTrial.placeholders.numbers')}
        label={t('trials.form.editTrial.labels.proceedingsCountToVerify')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        size="small"
        id="CDCount"
        name="CDCount"
        required
        inputProps={{
          min: 0,
          inputMode: 'numeric',
          pattern: '[0-9]*',
          'data-testid': 'cdCount',
        }}
        value={CDCount}
        error={proceedingsCountToVerify > 0 && CDCount <= 0}
        helperText={proceedingsCountToVerify > 0 && CDCount <= 0 ? t('trials.form.editTrial.errors.cdCount') : ''}
        onChange={(e) => dispatch(setEditingTrialField({ CDCount: isNaN(parseInt(e.target.value, 10)) ? '' : parseInt(e.target.value, 10) }))}
        type="number"
        placeholder={t('trials.form.editTrial.placeholders.numbers')}
        label={t('trials.form.editTrial.labels.cdCount')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <Divider variant="middle" />
      <Stack direction="column" spacing={2}>
        <TrialCertificate ticketId={ticketId} trialService={trial.service} trialId={trialId} workAwayFlag={trasferta} />
        <TrialSignedCertificate ticketId={ticketId} certificateId={certificateId} setCertificateId={setCertificateId} />
      </Stack>
      <Divider variant="middle" />
      <TextField
        size="small"
        id="notes"
        name="notes"
        InputLabelProps={{ shrink: true }}
        multiline
        inputProps={{ 'data-testid': 'notes' }}
        value={notes}
        onChange={(e) => dispatch(setEditingTrialField({ notes: e.target.value }))}
        type="text"
        placeholder={t('trials.form.editTrial.placeholders.notes')}
        variant="outlined"
        label={t('trials.form.editTrial.labels.notes')}
      />
      <Divider variant="middle" />
      <TdsTimePicker size="small" name="operatorArrivalTime" sx={{ width: 1 / 2 }} label={t('trials.form.editTrial.labels.operatorArrivalTime')} onChange={setOperatorArrivalTime} initialValue={operatorArrivalTime} refDate={dateStart} />
    </Stack>
  );
}

export default TrialForm;
