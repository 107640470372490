import apiSlice from './apiSlice';
import { removeToken, setToken, formUrlEncode } from './configApi';

const apiAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    validateToken: builder.query({
      query: () => ({
        url: '/checkValid',
        method: 'GET',
      }),
    }),
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: '/login',
        method: 'POST',
        body: formUrlEncode({ username, password }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      }),
      transformResponse: async (response) => {
        if (response?.response) {
          if (response.response.token) {
            await setToken(response.response.token);
          }
        }
        return response;
      },
    }),
    logout: builder.mutation({
      queryFn: async () => {
        await removeToken();
        return { data: {} };
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useValidateTokenQuery } = apiAuthSlice;

export default apiAuthSlice;
