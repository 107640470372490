import { formatAudioList, formatTrack } from './format-recordings-annotations/tracks/content';
import { formatProceedings } from './format-recordings-annotations/proceedings/content';
import { formatRecording, formatRecordings } from './format-recordings-annotations/recordings/content';
import { formatAnnotationsFiles } from './format-recordings-annotations/annotations/content';
import { cdAddMissingIds, computeInformationId, computeRecordingId } from './parse-recordings-annotations/adjust';
import { detectAndParseCds } from './parse-recordings-annotations/formats/rt7000/cd';
import { detectAndParseTrexCds } from './parse-recordings-annotations/formats/trex/cd';
import { detectProceedings } from './parse-recordings-annotations/proceedings';
import { annotationsToTxt } from './parse-recordings-annotations/formats/rt7000/encoders/annotations';
import { encodeToLatin1 } from './parse-recordings-annotations/formats/rt7000/encoders/common/encode';
import { isEmptyObject } from './parse-recordings-annotations/common';

const cdsAudioMap = {};

async function handleFiles(files, cdId = null) {
  const state = {
    recordings: [],
    tracks: [],
  };
  const infoId = await computeInformationId({});
  await Promise.all(
    files.map(async (file, i) => {
      const recordingId = await computeRecordingId('', infoId, i);
      const recording = formatRecording({ id: recordingId }, cdId);
      const track = formatTrack(file, recording.id, 'Mixer', true);
      state.tracks.push(track);
      state.recordings.push(recording);
    })
  );
  return state;
}

async function createAnnotationsFromParsedCd(cd, validHeadersNames = ['recorder', 'court', 'room', 'rite', 'prosecutor', 'judge', 'note']) {
  const { audio, annotations } = cd;
  const adjustedAnnotations = await cdAddMissingIds(annotations);
  const { information, recordings } = adjustedAnnotations;
  const { recordings: formattedRecordings, markers, turns } = formatRecordings(recordings, information.id);
  const recordingsToLoad = formattedRecordings.map((recording) => {
    return { ...recording, track: audio[recording.id]?.tracks[audio[recording.id]?.mainTrack] || null };
  });
  const headers = validHeadersNames.map((name, index) => ({ name, value: information[name], id: `${information.id}|${index}`, cdId: information.id }));
  return {
    headers,
    recordings: recordingsToLoad,
    markers,
    turns,
  };
}

async function handleCd(cdData) {
  const adjustedCd = { ...cdData };
  adjustedCd.annotations = await cdAddMissingIds(adjustedCd.annotations);
  const { annotations, audio, annotationsFiles, otherAudio } = adjustedCd;
  const formattedAnnotationsFiles = formatAnnotationsFiles(annotationsFiles, cdData.annotations.information.id);
  const tracks = formatAudioList(audio);
  const { recordings, markers, turns } = formatRecordings(annotations.recordings, cdData.annotations.information.id);
  const proceedings = formatProceedings(detectProceedings(annotations), formattedAnnotationsFiles, cdData.annotations.information.id);
  const stateFromOtherAudio = await handleFiles(otherAudio, cdData.annotations.information.id);
  return {
    annotations: formattedAnnotationsFiles,
    tracks: [...tracks, ...stateFromOtherAudio.tracks],
    recordings: [...recordings, ...stateFromOtherAudio.recordings],
    markers,
    turns,
    proceedings,
  };
}

async function createState(cdsData) {
  const state = {
    recordings: [],
    markers: [],
    turns: [],
    proceedings: [],
    tracks: [],
    annotations: [],
  };
  // ToDo: handle errors
  await Promise.all(
    cdsData.map(async (cdData) => {
      const { annotations, tracks, recordings, markers, turns, proceedings } = await handleCd(cdData);
      state.annotations = state.annotations.concat(annotations);
      state.tracks = state.tracks.concat(tracks);
      state.recordings = state.recordings.concat(recordings);
      state.markers = state.markers.concat(markers);
      state.turns = state.turns.concat(turns);
      state.proceedings = state.proceedings.concat(proceedings);
    })
  );
  return state;
}

async function createAnnotationsStateFromFiles(files) {
  const parsedRT7000Cds = await detectAndParseCds(files);
  const parsedTrexCds = await detectAndParseTrexCds(files);
  const parsedCds = parsedRT7000Cds.concat(parsedTrexCds);
  const status = {
    errors: [],
  };
  const state = {
    cdsInfo: [],
    headers: [],
    recordings: [],
    markers: [],
    turns: [],
  };
  for (const parsedCd of parsedCds) {
    if (parsedCd.annotations && !isEmptyObject(parsedCd.audio)) {
      // eslint-disable-next-line no-await-in-loop
      const annotations = await createAnnotationsFromParsedCd(parsedCd);
      const cd = { ...annotations, cdInfo: { ...parsedCd.annotations.information, moveDate: true, newStartDate: parsedCd.annotations.information.startDate } };
      state.cdsInfo = [...state.cdsInfo, cd.cdInfo];
      state.headers = [...state.headers, ...cd.headers];
      state.recordings = [...state.recordings, ...cd.recordings];
      state.markers = [...state.markers, ...cd.markers];
      state.turns = [...state.turns, ...cd.turns];
      cdsAudioMap[parsedCd.annotations.information.id] = {
        audio: parsedCd.audio,
        otherAudio: parsedCd.otherAudioFiles || [],
        annotationsFiles: parsedCd.annotationsFiles,
      };
    } else if (!parsedCd.annotationsFiles.find((annotationFile) => annotationFile.file.type === 'text/plain')) status.errors.push({ type: 'missingAnnotationsFile' });
    else {
      const fileName = parsedCd.annotationsFiles.find((annotationFile) => annotationFile.file.type === 'text/plain')?.file.name;
      if (isEmptyObject(parsedCd.otherAudioFiles)) status.errors.push({ type: 'noAudioFiles', fileName });
      else {
        status.errors.push({ type: 'emptyAnnotationsFile', fileName });
      }
    }
  }
  return { status, state };
}

function saveAnnotationsFromReduxStore(cdInfo, recordings, headers, markers, turns) {
  const recordingsOutputArray = [];
  recordings
    .filter((rec) => rec.cdId === cdInfo.id)
    .forEach((sRecording) => {
      const recording = { id: sRecording.id, timeStart: sRecording.timeStart, timeEnd: sRecording.timeEnd, markers: sRecording.markers, turns: sRecording.turns, other: { section: sRecording.section, passage: sRecording.passage } };
      recording.markers = markers?.filter((marker) => marker.recordingId === sRecording.id) || [];
      recording.turns = turns?.filter((turn) => turn.recordingId === sRecording.id) || [];
      recordingsOutputArray.push(recording);
    });
  const informationsOutputObj = {};
  headers
    .filter((rec) => rec.cdId === cdInfo.id)
    .forEach((header) => {
      informationsOutputObj[header.name] = header.value;
    });
  informationsOutputObj.id = cdInfo.id;
  informationsOutputObj.startDate = cdInfo.newStartDate;
  informationsOutputObj.timezone = cdInfo.timezone;
  informationsOutputObj.other = { 'Tribunale e Aula': `${informationsOutputObj.court} - ${informationsOutputObj.room}`, NRG: cdInfo.other?.NRG || '', Imputato: cdInfo.other?.Imputato || '' };
  return {
    information: informationsOutputObj,
    recordings: recordingsOutputArray,
  };
}

function createAnnotationsFile(annotations, fileName = '') {
  const txtString = annotationsToTxt(annotations);
  const txtStringEncoded = encodeToLatin1(txtString);
  return new File([txtStringEncoded], fileName, { type: 'text/plain' });
}
function createTxsAnnotationsFileName(annotationsFiles) {
  const annotationsFileName = annotationsFiles
    .map((annFile) => annFile.file.name)
    .sort((a, b) => a.localeCompare(b))
    .at(0);
  const match = annotationsFileName.match(/^(.*)\.(txs)$/i);
  return match ? `${match[1]}.txt` : annotationsFileName;
}
function saveCdFromReduxStore(cdInfo, recordings, headers, markers, turns) {
  const editedAnnotations = saveAnnotationsFromReduxStore(cdInfo, recordings, headers, markers, turns);
  const editedAnnotationsFilesArray = [];
  const txsToReplace = [];
  cdsAudioMap[cdInfo.id].annotationsFiles.forEach((annFile) => {
    if (annFile.file.type === 'application/xml') editedAnnotationsFilesArray.push(annFile);
    if (annFile.file.type === 'text/plain') editedAnnotationsFilesArray.push({ file: createAnnotationsFile(editedAnnotations, annFile.file.name), type: 'txt' });
    if (annFile.file.type === '' && annFile.file.name.match(/^(.*)\.(txs)$/i)) txsToReplace.push(annFile);
  });
  if (txsToReplace.length > 0) editedAnnotationsFilesArray.push({ file: createAnnotationsFile(editedAnnotations, createTxsAnnotationsFileName(txsToReplace)), type: 'txt' });

  const cdData = {
    audio: cdsAudioMap[cdInfo.id].audio,
    otherAudio: cdsAudioMap[cdInfo.id].otherAudio,
    annotations: editedAnnotations,
    annotationsFiles: editedAnnotationsFilesArray,
  };
  delete cdsAudioMap[cdInfo.id];
  return cdData;
}

export { createState, handleFiles, createAnnotationsStateFromFiles, saveCdFromReduxStore };

export default createState;
