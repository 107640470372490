import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { closeFileDialog, selectFileDialogFile, selectIsFileDialogOpen } from '../features/layoutSlice';

function FileDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsFileDialogOpen);
  const file = useSelector(selectFileDialogFile);

  const closeHandler = () => dispatch(closeFileDialog());
  if (file?.url)
    return (
      <Dialog PaperProps={{ style: { minHeight: '90%' } }} fullWidth maxWidth="lg" open={isOpen} onClose={closeHandler}>
        <DialogTitle sx={{ bgcolor: 'primary.light', color: 'primary.contrastText', p: 1 }} alignItems="center" display="flex" justifyContent="space-between">
          <Typography>{file.name}</Typography>
          <IconButton onClick={closeHandler} color="inherit">
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0, overflowY: 'hidden' }}>
          <iframe width="100%" style={{ height: '85vh' }} src={file.url} title="file" />
        </DialogContent>
      </Dialog>
    );
}

export default FileDialog;
