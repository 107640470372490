import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, CardHeader, Tooltip, Button, CardContent, Collapse, IconButton, Grid, CardActions, Box } from '@mui/material';
import { Edit, ExpandLess, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import Headers from './Pages/Annotations/Headers';
import Recordings from './Pages/Annotations/Recordings';
import CdDateHandler from './CdDateHandler';

function BulkProceedingsCdCard({ cd, deleteCd, saveCd, cancelCd }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [expandAll, setExpandAll] = useState(true);

  return (
    <Card elevation={3}>
      <Box sx={{ display: 'flex' }}>
        <CardHeader
          sx={{ p: 0, pl: 1, flex: 1 }}
          disableTypography
          title={
            <Grid container direction="column">
              <Grid item container alignItems="center">
                <Grid item xs={2}>
                  {cd.recorder && (
                    <Tooltip title={t('proceedings.dialogs.bulkUpload.recorder')}>
                      <Typography component="span" variant="body2">
                        {cd.recorder}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item container alignItems="center" xs={10}>
                  <Grid item xs={3}>
                    <Typography component="span" variant="body2">
                      {t('proceedings.dialogs.bulkUpload.recorded')}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {deleteCd ? <Typography>{cd.newStartDate ? dayjs(cd.newStartDate).format('L LT') : ''}</Typography> : <CdDateHandler color="primary" id={cd.id} />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={5}>
                  {cd.court && (
                    <Tooltip title={t('proceedings.dialogs.bulkUpload.court')}>
                      <Typography component="span" variant="body2">
                        {cd.court}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={7}>
                  {cd.room && (
                    <Tooltip title={t('proceedings.dialogs.bulkUpload.room')}>
                      <Typography component="span" variant="body2">
                        {cd.room}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardActions>
          {deleteCd && (
            <Button sx={{ mr: 1 }} variant="contained" color="warning" onClick={() => deleteCd([cd.id])}>
              {t('common.actions.delete')}
            </Button>
          )}
          {cancelCd && (
            <Button sx={{ mr: 1 }} variant="outlined" color="warning" onClick={() => cancelCd([cd.id])}>
              {t('common.actions.abort')}
            </Button>
          )}
          {saveCd && (
            <Button sx={{ mr: 1 }} variant="contained" onClick={() => saveCd([cd.id])}>
              {t('common.actions.confirm')}
            </Button>
          )}
          {cancelCd && saveCd && (
            <>
              {open && (
                <Tooltip title={expandAll ? t('annotationsEditor.actions.decreseAll') : t('annotationsEditor.actions.expandAll')}>
                  <IconButton onClick={() => setExpandAll(!expandAll)}>{expandAll ? <UnfoldLess /> : <UnfoldMore />}</IconButton>
                </Tooltip>
              )}
              <Tooltip title={open ? t('common.actions.close') : t('common.actions.edit')}>
                <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <Edit />}</IconButton>
              </Tooltip>
            </>
          )}
        </CardActions>
      </Box>
      {cancelCd && saveCd && (
        <Collapse mountOnEnter orientation="vertical" in={open}>
          <CardContent sx={{ maxHeight: '30em', overflow: 'auto', p: 1, pr: 3 }}>
            <Headers cdId={cd.id} isOpened={expandAll} />
            <Recordings cdId={cd.id} expandAll={expandAll} />
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}

BulkProceedingsCdCard.defaultProps = {
  deleteCd: null,
  saveCd: null,
  cancelCd: null,
};

BulkProceedingsCdCard.propTypes = {
  cd: PropTypes.shape({
    id: PropTypes.string,
    recorder: PropTypes.string,
    court: PropTypes.string,
    room: PropTypes.string,
    newStartDate: PropTypes.string,
  }).isRequired,
  deleteCd: PropTypes.func,
  saveCd: PropTypes.func,
  cancelCd: PropTypes.func,
};

export default BulkProceedingsCdCard;
