import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'printsFilters',
  initialState: {
    ticketId: '',
    trialTicketId: '',
    court: '',
    section: '',
    downloadedSignedPdf: '',
  },
  reducers: {
    setTicketId: (state, action) => ({ ...state, ticketId: action.payload }),
    cleanTicketId: (state) => ({ ...state, ticketId: '' }),
    setTrialTicketId: (state, action) => ({ ...state, trialTicketId: action.payload }),
    cleanTrialTicketId: (state) => ({ ...state, trialTicketId: '' }),
    setCourt: (state, action) => ({ ...state, court: action.payload }),
    cleanCourt: (state) => ({ ...state, court: '' }),
    setSection: (state, action) => ({ ...state, section: action.payload }),
    cleanSection: (state) => ({ ...state, section: '' }),
    setDownloadedSignedPdf: (state, action) => ({ ...state, downloadedSignedPdf: action.payload }),
    cleanDownloadedSignedPdf: (state) => ({ ...state, downloadedSignedPdf: '' }),
  },
};
const printsFiltersSlice = createSlice(options);

export const getTicketId = (state) => state.printsFilters.ticketId;
export const getTrialTicketId = (state) => state.printsFilters.trialTicketId;
export const getCourt = (state) => state.printsFilters.court;
export const getSection = (state) => state.printsFilters.section;
export const getDownloadedSignedPdf = (state) => state.printsFilters.downloadedSignedPdf;

export const { setTicketId, cleanTicketId, setTrialTicketId, cleanTrialTicketId, setCourt, cleanCourt, setSection, cleanSection, setDownloadedSignedPdf, cleanDownloadedSignedPdf } = printsFiltersSlice.actions;
export default printsFiltersSlice;
