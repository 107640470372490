import { Button, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useProceedingsUploadButton from '../hooks/useProceedingsUploadButton';
import { openSingleProceedingDialog } from '../features/singleProceedingSlice';
import { resetProceedingFormSlice, setProceeding } from '../features/proceedingFormSlice';
import { selectBulkProceedingsDefaultPP, selectBulkProceedingsDefaultRegistrar } from '../features/bulkProceedingsSlice';
import { formatTrialToProceedingSlice } from '../utils/formatProceedingToProceedingSlice';
import { setAlertMessage } from '../features/layoutSlice';

function SingleProceedingUploadButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { trial, isLoading, isFetching, disableUploadButton, trialCompleted } = useProceedingsUploadButton();
  const defaultRegistrar = useSelector(selectBulkProceedingsDefaultRegistrar);
  const defaultPP = useSelector(selectBulkProceedingsDefaultPP);

  if (isLoading || isFetching) return <Skeleton width="10em" height="2em" variant="rounded" />;

  const openDialog = () => {
    if (trialCompleted) dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'error' }));
    else {
      dispatch(resetProceedingFormSlice());
      const formattedProceeding = formatTrialToProceedingSlice(trial, defaultPP, defaultRegistrar);
      dispatch(setProceeding(formattedProceeding));
      dispatch(openSingleProceedingDialog());
    }
  };

  return (
    <Button disabled={disableUploadButton} variant="contained" color="primary" component="label" onClick={openDialog}>
      {t('proceedings.proceedingsList.trial.buttons.add')}
    </Button>
  );
}

export default SingleProceedingUploadButton;
