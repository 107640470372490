const detectNameToTitleNumberRegex = /^\s*html_nomi_tracce\[(\d+)\]\s*=\s*'([^']*)'\s*;.*$/;

async function parseNameToTitleNumberMapFromHtml(HTMLdata) {
  const lines = HTMLdata.split(/\r?\n/);
  const titleToTitleNumberMap = new Map();
  for (let index = 0; index < lines.length; index += 1) {
    const found = lines[index].match(detectNameToTitleNumberRegex);
    if (found) {
      titleToTitleNumberMap.set(found[2], parseInt(found[1], 10));
    }
  }
  return titleToTitleNumberMap;
}

export { parseNameToTitleNumberMapFromHtml };

export default parseNameToTitleNumberMapFromHtml;
