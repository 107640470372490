import { Box } from '@mui/material';
import PrintsFilters from './PrintsFilters';

function PrintsNavigationBar() {
  return (
    <Box component="aside" id="PrintsFilterPage">
      <PrintsFilters color="primary" />
    </Box>
  );
}

export default PrintsNavigationBar;
