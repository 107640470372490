import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Stack, Typography, Chip, Divider, Tooltip, Backdrop, CircularProgress, Badge, Box } from '@mui/material';
import { Gavel } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import BulkProceedingsList from './BulkProceedingsList';
import { selectIsBulkProceedingsDialogOpen, selectBulkProceedingsSubmittingForm, selectBulkProceedingsLoading } from '../features/bulkProceedingsSlice';
import useBulkProceedingsDialog from '../hooks/useBulkProceedingsDialog';
import BulkProceedingsAddCdButton from './BulkProceedingsAddCdButton';
import BulkProceedingsFilters from './BulkProceedingsFilters';
import ProceedingsWithReportBadge from './ProceedingsWithReportBadge';

function BulkProceedingsDialog() {
  const { t } = useTranslation();
  const isBulkDialogOpen = useSelector(selectIsBulkProceedingsDialogOpen);
  const isSubmitting = useSelector(selectBulkProceedingsSubmittingForm);
  const isLoading = useSelector(selectBulkProceedingsLoading);

  const {
    trial,
    trialCompleted,
    proceedingsCDTotal,
    proceedingsCDUploaded,
    proceedingsCount,
    maxProceedingsCount,
    proceedingsWithVerbalFlagCount,
    maxProceedingsWithVerbalFlagCount,
    handleTrialCompleted,
    cancelLoading,
    deleteCdsHandler,
    disableDeleteCdButton,
  } = useBulkProceedingsDialog();

  useEffect(() => {
    if (isBulkDialogOpen && trialCompleted) handleTrialCompleted();
  }, [isBulkDialogOpen, trialCompleted, handleTrialCompleted]);

  const countColor = (count, totalCount, isTrial) => {
    if (Number(count) === Number(totalCount)) return isTrial ? 'secondary.dark' : 'primary.dark';
    if (Number(count) > Number(totalCount)) return 'error.main';
    return 'grey.500';
  };

  const backdrop = (
    <Backdrop open={isSubmitting || isLoading} sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <Stack spacing={3} alignItems="center">
        {isSubmitting && <Typography variant="button">{t('proceedings.dialogs.saving')}</Typography>}
        <CircularProgress color="inherit" />
      </Stack>
    </Backdrop>
  );

  return (
    <Dialog
      onClose={(e, reason) => reason === 'backdropClick' && !isSubmitting && !isLoading && cancelLoading()}
      PaperProps={{
        sx: {
          minHeight: '70%',
        },
      }}
      open={isBulkDialogOpen}
      fullWidth
      maxWidth="lg"
    >
      {backdrop}
      <DialogTitle sx={{ padding: 1, backgroundColor: 'primary.light', color: 'primary.contrastText' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography>{t('proceedings.dialogs.bulkUpload.title')}</Typography>
          {proceedingsCDTotal > 0 && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <Tooltip title={t('proceedings.dialogs.uploadedCDProceedings')}>
                <Chip data-testid="proceedingsCDUploaded" label={proceedingsCDUploaded || 0} color="primary" sx={{ backgroundColor: countColor(proceedingsCDUploaded, proceedingsCDTotal) }} size="small" />
              </Tooltip>
              <Tooltip title={t('proceedings.dialogs.totalCDProceedings')}>
                <Chip data-testid="proceedingsCDTotal" label={proceedingsCDTotal || 0} color="primary" sx={{ backgroundColor: 'primary.dark' }} size="small" />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </DialogTitle>
      <DialogTitle sx={{ padding: 1, backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Tooltip title={t('trials.trialsList.trial.ticketId')}>
            <Typography>{trial?.id_ticket}</Typography>
          </Tooltip>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} pr={3}>
            {/* COUNT PROCEDIMENTI CARICATI */}
            <Tooltip title={t('trials.trialsList.trial.uploadedProceedingsCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsCount, maxProceedingsCount) }} component="span" variant="caption">
                      {proceedingsCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {maxProceedingsCount || 0}
                    </Typography>
                  </Box>
                }
              >
                <Gavel color="action" />
              </Badge>
            </Tooltip>
            {/* COUNT PROCEDIMENTI CARICATI DA VERBALIZZARE */}
            <Tooltip title={t('trials.trialsList.trial.proceedingsToBeTranscribedCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsWithVerbalFlagCount, maxProceedingsWithVerbalFlagCount) }} component="span" variant="caption">
                      {proceedingsWithVerbalFlagCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {maxProceedingsWithVerbalFlagCount || 0}
                    </Typography>
                  </Box>
                }
              >
                <ProceedingsWithReportBadge />
              </Badge>
            </Tooltip>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 1 }}>
        <BulkProceedingsFilters />
        <BulkProceedingsList />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack direction="row" spacing={1} sx={{ mr: 'auto' }}>
          <BulkProceedingsAddCdButton />
          <Button color="warning" variant="contained" disabled={disableDeleteCdButton} onClick={() => deleteCdsHandler()}>
            {t('proceedings.dialogs.buttons.deleteCd')}
          </Button>
        </Stack>
        <Button color="secondary" variant="outlined" onClick={cancelLoading}>
          {t('common.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkProceedingsDialog;
