import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, ClickAwayListener, Box, Checkbox, FormControlLabel } from '@mui/material';
import TdsDateTimePicker from './TdsDateTimePicker';
import useCdDateHandler from '../hooks/useCdDateHandler';

function CdDateHandler({ id, color }) {
  const { t } = useTranslation();

  const { cd, moveCdDate, setCdDate } = useCdDateHandler(id);
  const [isEditingDate, setIsEditingDate] = useState(false);

  return !isEditingDate ? (
    <Tooltip title={cd.newStartDate ? dayjs(cd.newStartDate).format('Z') : ''}>
      <Button color={color} data-testid="editButton" variant="text" onClick={() => setIsEditingDate(true)}>
        {cd.newStartDate ? dayjs(cd.newStartDate).format('L LT') : ''}
      </Button>
    </Tooltip>
  ) : (
    <ClickAwayListener onClickAway={() => setIsEditingDate(false)}>
      <Box sx={{ display: 'flex', pt: 1 }}>
        <TdsDateTimePicker name="startDate" initialValue={cd.newStartDate} onChange={setCdDate} label={t('proceedings.dialogs.bulkUpload.moveStartDate')} required error={!dayjs(cd.newStartDate).isValid()} size="small" />
        <FormControlLabel
          componentsProps={{ typography: { variant: 'caption' } }}
          sx={{ pl: 1 }}
          control={<Checkbox size="small" checked={cd.moveDate} onChange={(e) => moveCdDate(e.target.checked)} />}
          label={t('proceedings.dialogs.bulkUpload.moveAllStartDate')}
        />
      </Box>
    </ClickAwayListener>
  );
}

CdDateHandler.defaultProps = {
  color: 'inherit',
};
CdDateHandler.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
};
export default CdDateHandler;
