import dayjs from 'dayjs';
import { timeToDate, datetimeToDate } from '../lib/parse-recordings-annotations/markers/time';

function dateTimeToTdsFormat(dateTime, format) {
  if (!dateTime) return '';
  switch (format) {
    case 'dateTime': // YYYY-MM-DDTHH:mm:ss+HH:mm -> YYYY-MM-DD HH:mm:ss
      return dayjs(dateTime).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss');
    case 'time': // YYYY-MM-DDTHH:mm:ss+HH:mm -> HH:mm:ss
      return dayjs(dateTime).tz('Europe/Rome').format('HH:mm:ss');
    case 'date': // YYYY-MM-DDTHH:mm:ss+HH:mm -> YYYY-MM-DD
      return dayjs(dateTime).tz('Europe/Rome').format('YYYY-MM-DD');
    default:
      return dayjs(dateTime).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss');
  }
}

function dateTimeToReduxFormat(dateTime, format, refDate = null) {
  if (!dateTime) return '';
  switch (format) {
    case 'dateTime': // 'YYYY-MM-DD HH:mm:ss' -> YYYY-MM-DDTHH:mm:ss+HH:mm
      return datetimeToDate(dateTime, 'Europe/Rome', 'YYYY-MM-DD HH:mm:ss');
    case 'time': // 'HH:mm:ss' -> YYYY-MM-DDTHH:mm:ss+HH:mm
      return timeToDate(dateTime, refDate ? dayjs(refDate).startOf('d') : dayjs().startOf('d'));
    case 'date': // 'YYYY-MM-DD' -> YYYY-MM-DDTHH:mm:ss+HH:mm
      return datetimeToDate(dateTime, 'Europe/Rome', 'YYYY-MM-DD');
    default:
      return timeToDate(dateTime, refDate ? dayjs(refDate).startOf('d') : dayjs().startOf('d'));
  }
}

export { dateTimeToTdsFormat, dateTimeToReduxFormat };
