import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import compareByTime from '../utils/compareByTime';

const markersAdapter = createEntityAdapter({ sortComparer: compareByTime });

const markersSlice = createSlice({
  name: 'markers',
  initialState: markersAdapter.getInitialState(),
  reducers: {
    loadMarkers: markersAdapter.setMany,
    editMarker: markersAdapter.updateOne,
    addMarker: markersAdapter.addOne,
    removeMarker: markersAdapter.removeOne,
    removeMarkers: markersAdapter.removeAll,
    removeMarkersByIds: markersAdapter.removeMany,
  },
});

export const { selectById: selectMarkerById, selectAll: selectAllMarkers, selectTotal: selectTotalMarkers } = markersAdapter.getSelectors();

export const { loadMarkers, editMarker, addMarker, removeMarker, removeMarkers, removeMarkersByIds } = markersSlice.actions;

export default markersSlice;
