import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useGetJobStatusTreeQuery } from '../../../../features/api/rest/apiJobSlice';
import TreeView from './TreeView';
import TDSConnector from '../../../TDSConnector';

dayjs.extend(LocalizedFormat);

function JobPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: jobStatusTree, error } = useGetJobStatusTreeQuery(id, {
    skip: !id,
    pollingInterval: 2000,
  });

  return (
    <Box component="section" id="JobStatusTree" data-testid="JobStatusTree" sx={{ width: '100%', height: '100%' }}>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }} alignItems="center" justifyContent="space-between">
        <IconButton onClick={() => navigate('/jobs')} color="primary">
          <ArrowBack />
        </IconButton>
        <TDSConnector />
      </Stack>
      {jobStatusTree && !error?.status && <TreeView statusTree={jobStatusTree} />}
    </Box>
  );
}

export default JobPage;
