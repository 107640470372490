import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetTrialsQuery } from '../features/api/tds/apiTrialsSlice';
import { getCourt, getRequestingCourt, getRequestingSection, getSection, getTicketId } from '../features/trialFiltersSlice';

export default function useTrialsResults() {
  const [search] = useSearchParams();

  const filters = {};
  const orderby = {};

  for (const [sort, dir] of search.getAll('orderby').map((o) => o.split('-'))) orderby[sort] = dir;

  for (const [key, value] of search.entries()) if (key.includes('statusId')) filters[key] = value;

  const { currentData, isFetching, isSuccess, isError } = useGetTrialsQuery({ filters, orderby });

  let trials = currentData?.udienze;
  const ticketId = useSelector(getTicketId);
  const court = useSelector(getCourt);
  const section = useSelector(getSection);
  const requestingCourt = useSelector(getRequestingCourt);
  const requestingSection = useSelector(getRequestingSection);

  if (ticketId) trials = trials?.filter((trial) => trial.id_ticket?.toLowerCase().includes(ticketId.toLowerCase()));
  if (court) trials = trials?.filter((trial) => trial.tribunale?.toLowerCase().includes(court.toLowerCase()));
  if (section) trials = trials?.filter((trial) => trial.sezione?.toLowerCase().includes(section.toLowerCase()));
  if (requestingCourt) trials = trials?.filter((trial) => trial.tribunale_richiedente?.toLowerCase().includes(requestingCourt.toLowerCase()));
  if (requestingSection) trials = trials?.filter((trial) => trial.sezione_richiedente?.toLowerCase().includes(requestingSection.toLowerCase()));

  return {
    trials,
    trialsCount: trials?.count,
    isFetching,
    isSuccess,
    isError,
    filters,
    orderby,
  };
}
