import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'layout',
  initialState: {
    alertMessage: {
      text: '',
      type: '',
      render: 'alert',
      closeAfter: 5000,
    },
    sidebar: {
      isOpen: false,
    },
    logoutDialog: {
      isOpen: false,
    },
    fileDialog: {
      isOpen: false,
      file: {
        url: null,
        name: '',
      },
    },
  },
  reducers: {
    setAlertMessage: (state, action) => ({ ...state, alertMessage: action.payload }),
    cleanAlertMessage: (state) => ({ ...state, alertMessage: { text: '', type: '', render: 'alert' } }),
    openSidebar: (state) => ({ ...state, sidebar: { isOpen: true } }),
    closeSidebar: (state) => ({ ...state, sidebar: { isOpen: false } }),
    openLogoutDialog: (state) => ({ ...state, logoutDialog: { isOpen: true } }),
    closeLogoutDialog: (state) => ({ ...state, logoutDialog: { isOpen: false } }),
    openFileDialog: (state) => ({ ...state, fileDialog: { ...state.fileDialog, isOpen: true } }),
    closeFileDialog: (state) => ({ ...state, fileDialog: { ...state.fileDialog, isOpen: false } }),
    setFileDialog: (state, action) => ({ ...state, fileDialog: { ...state.fileDialog, file: action.payload } }),
  },
};

const layoutSlice = createSlice(options);

export const selectAlertMessage = (state) => state.layout.alertMessage;
export const selectSidebar = (state) => state.layout.sidebar.isOpen;
export const selectIsLogoutDialogOpen = (state) => state.layout.logoutDialog.isOpen;
export const selectIsFileDialogOpen = (state) => state.layout.fileDialog.isOpen;
export const selectFileDialogFile = (state) => state.layout.fileDialog.file;
export const { setAlertMessage, cleanAlertMessage, openSidebar, closeSidebar, openLogoutDialog, closeLogoutDialog, openFileDialog, closeFileDialog, setFileDialog } = layoutSlice.actions;
export default layoutSlice;
