import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';
import { AddCircle as AddIcon } from '@mui/icons-material';
import Marker from './Marker';
import MarkerForm from './MarkerForm';
import { selectAllAnnotationsMarkers } from '../../../../features/annotationsMarkers';
import { selectCdById } from '../../../../features/cdsSlice';

function Markers({ recordingId, cdId, newMarkerTime, playFromTime }) {
  const { t } = useTranslation();
  const [addMode, setAddMode] = useState(false);
  const markers = useSelector((state) => selectAllAnnotationsMarkers(state.annotationsMarkers));
  const marksersByRecordingId = useMemo(() => markers?.filter((mrk) => mrk.recordingId === recordingId), [recordingId, markers]);
  const cdData = useSelector((state) => selectCdById(state.cds, cdId));
  const currentStartingDate = useMemo(() => cdData.newStartDate, [cdData]);

  return (
    <TableContainer sx={{ maxHeight: '20em' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell width="5%" sx={{ fontWeight: 'bold' }} align="right" />
            <TableCell width="20%" sx={{ fontWeight: 'bold' }}>
              {t('annotationsEditor.recordings.markers.marker.time')}
            </TableCell>
            <TableCell width="20%" sx={{ fontWeight: 'bold' }}>
              {t('annotationsEditor.recordings.markers.marker.phase')}
            </TableCell>
            <TableCell width="50%" sx={{ fontWeight: 'bold' }}>
              {t('annotationsEditor.recordings.markers.marker.notes')}
            </TableCell>
            <TableCell width="5%" sx={{ fontWeight: 'bold' }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {marksersByRecordingId?.map((marker) => (
            <Marker key={marker.id} id={marker.id} playFromTime={playFromTime} />
          ))}
        </TableBody>
        <TableFooter>
          {addMode ? (
            <MarkerForm newMarkerTime={newMarkerTime} refDate={currentStartingDate} recordingId={recordingId} setMode={setAddMode} />
          ) : (
            <TableRow>
              <TableCell sx={{ borderBottom: 'unset' }} colSpan={4}>
                <IconButton size="small" onClick={() => setAddMode(true)}>
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

Markers.defaultProps = {
  newMarkerTime: '',
};
Markers.propTypes = {
  recordingId: PropTypes.string.isRequired,
  cdId: PropTypes.string.isRequired,
  newMarkerTime: PropTypes.string,
  playFromTime: PropTypes.func.isRequired,
};

export default Markers;
