import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Proptype from 'prop-types';
import OrderByString from './OrderByString';
import useProceedingResults from '../hooks/useProceedingResults';

function ProceedingsOrderBy({ color }) {
  const { t } = useTranslation();
  const { proceedingsCount, orderBy } = useProceedingResults();
  const [ticketIdOrderBy, setTicketIdOrderBy, resetTicketIdOrderBy] = orderBy.ticketId;
  const [statusOrderBy, setStatusOrderBy, resetStatusOrderBy] = orderBy.status;
  const [startDateOrderBy, setStartDateOrderBy, resetStartDateOrderBy] = orderBy.startDate;
  const [RGOrderBy, setRGOrderBy, resetRGOrderBy] = orderBy.RG;
  const [RGNROrderBy, setRGNROrderBy, resetRGNROrderBy] = orderBy.RGNR;
  const [outcomeOrderBy, setOutcomeOrderBy, resetOutcomeOrderBy] = orderBy.outcome;
  const [outcomeDateOrderBy, setOutcomeDateOrderBy, resetOutcomeDateOrderBy] = orderBy.outcomeDate;

  if (proceedingsCount <= 1) return null;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography>{t('proceedings.proceedingsList.orderby.label')}:</Typography>
      <OrderByString dir={ticketIdOrderBy} label="proceedings.proceedingsList.orderby.ticketId.label" onDelete={resetTicketIdOrderBy} onClick={setTicketIdOrderBy} color={color} />
      <OrderByString dir={statusOrderBy} label="proceedings.proceedingsList.orderby.status.label" onDelete={resetStatusOrderBy} onClick={setStatusOrderBy} color={color} />
      <OrderByString dir={startDateOrderBy} label="proceedings.proceedingsList.orderby.startDate.label" onDelete={resetStartDateOrderBy} onClick={setStartDateOrderBy} color={color} />
      <OrderByString dir={RGOrderBy} label="proceedings.proceedingsList.orderby.RG.label" onDelete={resetRGOrderBy} onClick={setRGOrderBy} color={color} />
      <OrderByString dir={RGNROrderBy} label="proceedings.proceedingsList.orderby.RGNR.label" onDelete={resetRGNROrderBy} onClick={setRGNROrderBy} color={color} />
      <OrderByString dir={outcomeOrderBy} label="proceedings.proceedingsList.orderby.outcome.label" onDelete={resetOutcomeOrderBy} onClick={setOutcomeOrderBy} color={color} />
      <OrderByString dir={outcomeDateOrderBy} label="proceedings.proceedingsList.orderby.outcomeDate.label" onDelete={resetOutcomeDateOrderBy} onClick={setOutcomeDateOrderBy} color={color} />
    </Stack>
  );
}

ProceedingsOrderBy.defaultProps = {
  color: 'primary',
};

ProceedingsOrderBy.propTypes = {
  color: Proptype.string,
};

export default ProceedingsOrderBy;
