import { isEmptyString } from '../../common';

function mapTxtIdIoXmlId(txtid) {
  const txtIdToXmlIdMap = {
    'Codice Dispositivo': 'Macchina',
    'Collegio Giudicante': 'Collegio',
    "Nome del Tribunale e dell'aula": 'Tribunale e Aula',
    'Pubblico Ministero': 'PM',
    Presidente: 'Presidente',
    Data: 'Data',
    'Numero di Registro Generale': 'NRG',
    Imputato: 'Imputato',
    'Note a testo libero': 'Note',
    Brano: '@_Brano',
  };
  const result = txtIdToXmlIdMap[txtid];
  return typeof result !== 'undefined' ? result : txtid;
}

async function parseCronologiaFromTxt(TXTdata) {
  if (!TXTdata) return null;
  const lines = TXTdata.split(/\r?\n/);
  const matchHeaderField = /^\s{8}([^\s][^:]*)\s*:\s+(.*)$/;

  let brano = 1;
  let sottobrano = 1;
  let inHeader = true;
  let inField = false;
  let fieldName = '';
  let inMarker = false;
  let hour = null;
  let prevHour = null;
  const data = {
    Brano: [],
  };
  let branoData = {
    SubBrano: [],
  };
  let events = [];
  let markers = [];
  for (let index = 0; index < lines.length; index += 1) {
    const line = lines[index].replace('\t', '        ').replace('\r', '');
    if (inHeader) {
      if (line === '') {
        inField = false;
      } else if (line.match(/^\s+CRONOLOGIA\s+INTERVENTI\s+NOTE\s+AI\s+MARKER\s*$/)) {
        inHeader = false;
        index += 1;
      } else if (inField) {
        if (line === '') {
          inField = false;
        } else if (line.slice(0, 52).match(/^\s{52}$/)) {
          data[fieldName] += `\n${line.slice(52)}`;
        } else if (line.match(matchHeaderField)) {
          // Broken syntax, probably a new field, so we assume
          // that it is a new field and reduce index in order to
          // reanalyze the line
          index -= 1;
          inField = false;
          // console.log('Unrecognized line in header (1.a)');
        } else {
          // Broken syntax, probably this is a continuation of
          // the previous field value
          data[fieldName] += `\n${line.trim()}`;
          // console.log('Unrecognized line in header (1.b)');
        }
      } else {
        const found = line.match(matchHeaderField);
        if (found) {
          fieldName = mapTxtIdIoXmlId(found[1]);
          // eslint-disable-next-line prefer-destructuring
          data[fieldName] = found[2];
          inField = true;
        } else if (fieldName === 'Note') {
          data[fieldName] += `\n${line.replace(/^\s+/, '')}`;
        } else {
          // console.log('Unrecognized line in header (2)');
          // console.log(line);
        }
      }
    } else if (line.match(/^\*+\s+Fine\s+/)) {
      const found = line.match(/^\*+\s+Fine\s+(Sottobrano.*(\d+)\sdel brano|Brano)\s+..\s*(\d+)/);
      // assert(brano === found[3]);
      if (found[2]) {
        const subBranoData = {
          '@_Id': sottobrano,
        };
        if (events.length > 0) {
          subBranoData.Intervento = events;
        }
        if (markers.length > 0) {
          subBranoData.Marker = markers;
        }
        branoData.SubBrano.push(subBranoData);
        // assert(sottobrano === found[2]);
        sottobrano += 1;
        events = [];
        markers = [];
      } else {
        branoData['@_Id'] = brano;
        data.Brano.push(branoData);
        sottobrano = 1;
        brano += 1;
        branoData = {
          SubBrano: [],
        };
        prevHour = null;
      }
    } else if (line === '') {
      if (inMarker) {
        if (index + 2 < lines.length && lines[index + 1].replace('\r', '') === '' && lines[index + 2].replace('\r', '') === '') {
          markers[markers.length - 1]['@_Note'] += '\n';
        }
      }
      inField = false;
      inMarker = false;
    } else {
      const hourCandidate = line.slice(0, 8);
      if (hourCandidate.match(/^\d\d:\d\d:\d\d$/)) {
        inField = true;
        hour = hourCandidate;
        prevHour = hour;
      }
      if (inField) {
        const type = line.slice(10, 16);
        if (type === 'MARKER') {
          markers.push({
            '@_Fase': line.slice(18, 45).trim(),
            '@_Note': line
              .slice(18)
              .replace(/^(\s[^\s]+)+\s\s+/, '')
              .trimStart(),
            '@_Time': hour,
          });
          inMarker = true;
          // if (line.slice(18, 45).trim() !== "") {
          //   console.log(line.slice(18, 45).trim())
          // }
        } else if (type === 'Canale') {
          events.push({
            '@_ChannelName': line.slice(17, 37).trim(),
            '@_Time': hour,
          });
          if (line.match(/^\d\d:\d\d:\d\d\s\sCanale(\s[^\s]+)+\s\s+/)) {
            markers.push({
              '@_Fase': '',
              '@_Note': line.replace(/^\d\d:\d\d:\d\d\s\sCanale(\s[^\s]+)+\s\s+/, ''),
              '@_Time': hour,
            });
            inMarker = true;
          }
        } else if (inMarker) {
          if (line.slice(0, 48).trim() !== '') {
            markers[markers.length - 1]['@_Note'] += `\n${line.trim()}`;
          } else {
            markers[markers.length - 1]['@_Note'] += `\n${line.slice(48)}`;
          }
        } else if (prevHour != null) {
          markers.push({
            '@_Fase': '',
            '@_Note': line.replace(/^\s+/, ''),
            '@_Time': prevHour,
          });
          inMarker = true;
        } else {
          console.log(`Unrecognized line in body\n${line}`); // eslint-disable-line no-console
        }
      } else {
        const note = line.replace(/^\s+/, '');
        if (note !== '') {
          const marker = {
            '@_Fase': '',
            '@_Note': line.replace(/^\s+/, ''),
          };
          if (prevHour != null) {
            marker['@_Time'] = prevHour;
          }
          markers.push(marker);
          inMarker = true;
        }
      }
    }
  }
  const courtAndRoom = data['Tribunale e Aula'];
  if (!isEmptyString(courtAndRoom)) {
    const [court, room] = courtAndRoom.split(' - ', 2);
    if (!isEmptyString(court) && court !== 'Non Configurato') {
      data.Tribunale = court;
    }
    if (!isEmptyString(room) && room !== 'Non Configurato') {
      data.Aula = room;
    }
  }
  return { Dibattimento: { Cronologia: data } };
}

export { parseCronologiaFromTxt };

export default parseCronologiaFromTxt;
