import { formatMarkers } from '../markers/content';
import { formatTurns } from '../turns/content';
import { timeStartRetrieve, timeEndRetrieve } from '../utils';

const defaultSelected = false;

function formatRecordings(recordings, cdId = null) {
  const formattedRecordings = [];
  const markers = [];
  const turns = [];
  recordings?.forEach((recording) => {
    formattedRecordings.push(formatRecording(recording, cdId));
    const mrks = formatMarkers(recording.markers, recording.id);
    if (mrks) markers.push(mrks);
    const trns = formatTurns(recording.turns, recording.id);
    if (trns) turns.push(trns);
  });
  return { recordings: formattedRecordings, markers: markers.flat(2), turns: turns.flat(2) };
}

function formatRecording(recording, cdId = null, selected = defaultSelected) {
  const formattedRecording = {};
  formattedRecording.id = recording.id;
  formattedRecording.cdId = cdId;
  formattedRecording.timeStart = timeStartRetrieve(recording);
  formattedRecording.timeEnd = timeEndRetrieve(recording);
  formattedRecording.selected = selected;
  formattedRecording.passage = recording?.other?.passage || null;
  formattedRecording.section = recording?.other?.section || null;
  return formattedRecording;
}

export { formatRecording, formatRecordings };

export default formatRecordings;
