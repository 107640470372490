import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, IconButton, Skeleton, Checkbox, Box, CardActions, Grid } from '@mui/material';
import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AudioPlayer from './AudioPlayer';
import RecordingTab from './RecordingTab';
import useRecording from '../hooks/useRecording';

function Recording({ recordingId }) {
  const { t } = useTranslation();

  const { recording, isSelected, track, file, fetchFileFromIndexedDB, isLoading, removeRecording, checkRecording, uncheckRecording, playAudioFallback } = useRecording(recordingId);
  const [expanded, setExpanded] = useState(false);

  /* const recordingPassage = recording && recording.passage ? `${t('recordings.track')} ${recording.passage}` : '-';
  const recordingSection = recording && recording.section ? `${t('recordings.subtrack')} ${recording.section}` : '-'; */

  useEffect(() => {
    fetchFileFromIndexedDB();
  }, [fetchFileFromIndexedDB]);

  if (isLoading) return <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
  return (
    <Card elevation={3}>
      <Box sx={{ display: 'flex' }}>
        <CardHeader
          disableTypography
          sx={{ p: 0.5, pr: 1, flex: 1, '& .MuiCardHeader-avatar': { mr: 1 } }}
          title={
            <Grid container alignItems="center">
              <Grid item container xs={7}>
                <Grid typography="caption" item xs={12}>
                  {track?.fileName}
                </Grid>
                {recording && recording.passage && (
                  <Grid typography="body2" item xs={4}>
                    {`${t('recordings.track')} ${recording.passage}`}
                  </Grid>
                )}
                {recording && recording.section && (
                  <Grid typography="body2" item xs={8}>
                    {`${t('recordings.subtrack')} ${recording.section}`}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={5}>
                <AudioPlayer size="small" file={file} playAudioFallback={playAudioFallback} />
              </Grid>
            </Grid>
          }
          avatar={
            <IconButton size="small" onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        />
        <CardActions sx={{ padding: 0 }} disableSpacing>
          <IconButton size="small" color="error" onClick={removeRecording}>
            <Delete />
          </IconButton>
          <Checkbox onClick={isSelected ? uncheckRecording : checkRecording} checked={isSelected} size="small" />
        </CardActions>
      </Box>
      {expanded && (
        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
          <RecordingTab recordingId={recordingId} />
        </CardContent>
      )}
    </Card>
  );
}

Recording.propTypes = {
  recordingId: PropTypes.string.isRequired,
};

export default Recording;
