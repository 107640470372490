import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Portal, Snackbar } from '@mui/material';
import { selectAlertMessage, cleanAlertMessage } from '../../features/layoutSlice';

function MessAlert() {
  const dispatch = useDispatch();
  const messageFromStore = useSelector(selectAlertMessage);

  React.useEffect(() => {
    let timeout = 0;
    if (messageFromStore.closeAfter === 0) return () => {};
    if (!messageFromStore.closeAfter) timeout = 5000;
    if (typeof messageFromStore.closeAfter === 'number') timeout = messageFromStore.closeAfter;

    let timerID;
    if (messageFromStore.text) {
      timerID = setTimeout(() => {
        dispatch(cleanAlertMessage());
      }, timeout);
    }
    return () => {
      clearTimeout(timerID);
    };
  }, [dispatch, messageFromStore.text, messageFromStore.closeAfter]);

  const handleClose = () => {
    dispatch(cleanAlertMessage());
  };

  if (!messageFromStore.text) return null;

  if (messageFromStore.render === 'snack')
    return (
      <Portal>
        <Snackbar data-testid="SnackBarAlert" open onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert data-testid="Alert" severity={messageFromStore.type} onClose={handleClose} sx={{ width: '100%' }}>
            {messageFromStore.text}
          </Alert>
        </Snackbar>
      </Portal>
    );
  return (
    <Alert data-testid="MessAlert" severity={messageFromStore.type} onClose={handleClose} sx={{ width: '100%' }}>
      {messageFromStore.text}
    </Alert>
  );
}

export default MessAlert;
