import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { selectProceedingFormOtherFiles } from '../features/proceedingFormSlice';
import OtherFile from './OtherFile';
import AddOtherFilesCard from './AddOtherFilesCard';

function ProceedingOtherFiles() {
  const proceedingOtherFiles = useSelector(selectProceedingFormOtherFiles);
  return (
    <Stack spacing={1} direction="column">
      {proceedingOtherFiles.map((otherFileId) => (
        <OtherFile key={otherFileId} otherFileId={otherFileId} />
      ))}
      <AddOtherFilesCard />
    </Stack>
  );
}

export default ProceedingOtherFiles;
