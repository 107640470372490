const detectTitleNumberToTrackRegex = /^\s*case\s+(\d+):\s+traccia_effettiva\s+=\s+(\d+);.*$/;

async function parseTitleNumberToTrackMapFromHtml(HTMLdata) {
  const lines = HTMLdata.split(/\r?\n/);
  const titleNumberToTrackMap = new Map();
  for (let index = 0; index < lines.length; index += 1) {
    const found = lines[index].match(detectTitleNumberToTrackRegex);
    if (found) {
      titleNumberToTrackMap.set(parseInt(found[1], 10), parseInt(found[2], 10));
    }
  }
  return titleNumberToTrackMap;
}

export { parseTitleNumberToTrackMapFromHtml };

export default parseTitleNumberToTrackMapFromHtml;
