import { useCallback, useMemo, useState } from 'react';
import { useIndexedDB } from 'react-indexed-db-hook';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllTracks } from '../features/tracksSlice';
import { selectProceedingFormRecordings, selectProceedingFormSelectedRecordings, setProceedingFormRecordings, setProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';
import { selectRecordingById } from '../features/recordingsSlice';
import { setAlertMessage } from '../features/layoutSlice';
import { fetchFile } from '../utils/fileReader';
import { setBulkProceedingsReinsertCdDialog, setBulkProceedingsReinsertCdDialogMissingRecordingId, setBulkProceedingsReinsertCdDialogFileMissing } from '../features/bulkProceedingsSlice';

function useRecording(recordingId) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const track = useSelector((state) => selectAllTracks(state.tracks)).find((trk) => trk.recordingId === recordingId && trk.isMixer);
  const recording = useSelector((state) => selectRecordingById(state.recordings, recordingId));
  const recordings = useSelector(selectProceedingFormRecordings);
  const selectedRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const isSelected = useMemo(() => selectedRecordings.includes(recordingId), [selectedRecordings, recordingId]);
  const [file, setFile] = useState(track?.file);
  const { getByIndex } = useIndexedDB('files');

  const fetchFileFromIndexedDB = useCallback(async () => {
    setIsLoading(true);
    fetchFile(track?.file, getByIndex)
      .then((resp) => setFile(resp))
      .catch(() => {
        dispatch(setAlertMessage({ text: t('recordings.errors.fileNotFound_file_cdId', { fileName: track.fileName, cdId: recording.cdId || '' }), type: 'warning', render: 'snack', closeAfter: 10000 }));
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, t, getByIndex, track, recording]);

  const playAudioFallback = () => {
    dispatch(setBulkProceedingsReinsertCdDialogFileMissing(track?.file.name));
    dispatch(setBulkProceedingsReinsertCdDialogMissingRecordingId(recordingId));
    dispatch(setBulkProceedingsReinsertCdDialog(track?.file.name));
  };

  const removeRecording = () => {
    const newSelectedRecordings = selectedRecordings?.filter((rec) => rec !== recordingId) || [];
    const newRecordings = recordings?.filter((rec) => rec !== recordingId);
    dispatch(setProceedingFormSelectedRecordings(newSelectedRecordings));
    dispatch(setProceedingFormRecordings(newRecordings));
  };
  const uncheckRecording = () => {
    const newSelectedRecordings = selectedRecordings?.filter((rec) => rec !== recordingId) || [];
    dispatch(setProceedingFormSelectedRecordings(newSelectedRecordings));
  };

  const checkRecording = () => {
    dispatch(setProceedingFormSelectedRecordings([...selectedRecordings, recordingId]));
  };

  return {
    recording,
    isSelected,
    track,
    file,
    fetchFileFromIndexedDB,
    isLoading,
    removeRecording,
    uncheckRecording,
    checkRecording,
    playAudioFallback,
  };
}

export default useRecording;
