export const maxWithIndex = (arr) => {
  if (!arr.length > 0) return { tracks: [], powers: [] };
  const max = [...arr[0]];
  const maxIndex = new Array(arr[0].length).fill(0);

  for (let i = 1; i < arr.length; i += 1) {
    for (let j = 0; j < arr[i].length; j += 1) {
      if (arr[i][j] > max[j]) {
        maxIndex[j] = i;
        max[j] = parseFloat(arr[i][j].toFixed(5));
      }
    }
  }
  return { tracks: maxIndex, powers: max };
};

async function getFrameAveragePowers(files) {
  const frameDuration = 200; // ms
  const getFrameAveragePowersPromise = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const context = new OfflineAudioContext(1, 1, 8000);
        function success(buffer) {
          const framePower = [];
          // Get the length of the audio buffer in samples
          const bufferLength = buffer.length;

          // Set the size of the audio frames (200ms)
          const frameSize = context.sampleRate / (1000 / frameDuration);

          const data = buffer.getChannelData(0);
          // Loop through the audio buffer, calculating the average power of each frame
          for (let i = 0; i < bufferLength; i += frameSize) {
            let frameTotal = 0;
            for (let j = 0; j < frameSize; j += 1) {
              // Add the power of the current sample to the frame total
              frameTotal += data[i + j] * data[i + j];
            }
            // Divide the frame total by the number of samples to get the average power
            const averagePower = frameTotal / frameSize;

            // Add the average power to the array of frame powers
            framePower.push(averagePower);
          }
          resolve(framePower);
        }
        context.decodeAudioData(e.target.result).then(success, reject);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });

  try {
    const outputPromises = files.map((f) => getFrameAveragePowersPromise(f));
    const outputArray = await Promise.all(outputPromises);
    const finalOutput = maxWithIndex(outputArray);
    return { ...finalOutput, frameDuration };
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
  return null;
}

export default getFrameAveragePowers;
