import { parseTitleNumberToTrackMapFromHtml } from './markerHtml';
import { parseNameToTitleNumberMapFromHtml } from './rtHtml';
import { parseCronologiaFromTxt } from './cronologiaTxt';
import { parseCronologiaFromXml } from './cronologiaXml';
import { detectCds } from './files';
import { readFile, isEmptyObject } from '../../common';
import { datetimeToDate, timeToDate, dateAddSeconds } from '../../markers/time';

const defaultNameToTrackMap = new Map(
  Object.entries({
    Mixer: 1,
  })
);
async function parseNameToTrackNumber(markerHtmlData = null, rtHtmlData = null) {
  if (markerHtmlData == null || rtHtmlData == null) return defaultNameToTrackMap;
  const titleNumberToTrack = await parseTitleNumberToTrackMapFromHtml(markerHtmlData);
  const nameToTitleNumber = await parseNameToTitleNumberMapFromHtml(rtHtmlData);
  const nameToTrackNumber = nameToTitleNumber;
  nameToTitleNumber.forEach((_, key) => {
    nameToTrackNumber.set(key, titleNumberToTrack.get(nameToTitleNumber.get(key)));
  });
  return nameToTrackNumber;
}

function sameLengths(array1, array2) {
  if (Array.isArray(array1) && Array.isArray(array2)) {
    return array1.length === array2.length;
  }
  if (array1 === null || array1 === undefined) return array2 === null || array2 === undefined;
  return false;
}

function mergeInformation(cronologiaFromTxt, cronologiaFromXml) {
  const tbrano = cronologiaFromTxt.Dibattimento.Cronologia.Brano;
  const xbrano = cronologiaFromXml ? cronologiaFromXml.Dibattimento.Cronologia.Brano : null;
  if (xbrano !== null && !sameLengths(xbrano, tbrano)) return null;
  if (xbrano !== null) {
    tbrano.map((b, idx) => {
      if (b['@_Id'] === parseInt(xbrano[idx]['@_Id'], 10)) {
        tbrano[idx]['@_Duration'] = parseInt(xbrano[idx]['@_Duration'], 10);
        tbrano[idx]['@_Date'] = xbrano[idx]['@_Date'];
        tbrano[idx]['@_Time'] = xbrano[idx]['@_Time'];
      }
      const tsubbrano = tbrano[idx].SubBrano;
      const xsubbrano = xbrano[idx].SubBrano;
      if (!sameLengths(xsubbrano, tsubbrano)) return null;
      tsubbrano.map((s, sidx) => {
        if (s['@_Id'] === parseInt(xsubbrano[sidx]['@_Id'], 10)) {
          tsubbrano[sidx]['@_Duration'] = parseInt(xsubbrano[sidx]['@_Duration'], 10);
        }
        const tmarker = tsubbrano[sidx].Marker;
        const xmarker = xsubbrano[sidx].Marker;
        if (!sameLengths(xmarker, tmarker)) return null;
        if (tmarker) {
          tmarker.map((m, midx) => {
            tmarker[midx]['@_Position'] = parseInt(xmarker[midx]['@_Position'], 10);
            return m;
          });
        }
        return s;
      });
      return b;
    });
  }
  // console.log(cronologiaFromTxt.Dibattimento.Cronologia.Brano);
  // console.log(cronologiaFromXml.Dibattimento.Cronologia.Brano);
  return cronologiaFromTxt;
}

const mapInformation = {
  Macchina: 'recorder',
  Collegio: 'rite',
  Tribunale: 'court',
  Aula: 'room',
  PM: 'prosecutor',
  Presidente: 'judge',
  Note: 'note',
  Data: [
    'startDate',
    (date, info) => {
      return datetimeToDate(date, info.timezone);
    },
  ],
  Brano: null,
};
async function normalizeAnnotations(cronologiaData, nameToTrackMap = defaultNameToTrackMap, tz = 'Europe/Rome') {
  if (!cronologiaData || !cronologiaData.Dibattimento || !cronologiaData.Dibattimento.Cronologia) return null;
  const cronologia = cronologiaData.Dibattimento.Cronologia;
  const information = {
    timezone: tz,
    nameToTrackMap: Object.fromEntries(nameToTrackMap),
    type: 'RT7000',
  };
  Object.entries(cronologia).forEach(([key, value]) => {
    const mapTo = mapInformation[key];
    if (mapTo !== null) {
      if (typeof mapTo === 'string' || mapTo instanceof String) {
        information[mapTo] = value;
      } else if (typeof mapTo !== 'undefined' && mapTo.length === 2 && (typeof mapTo[0] === 'string' || mapTo[0] instanceof String) && typeof mapTo[1] === 'function') {
        information[mapTo[0]] = mapTo[1](value, information);
      } else {
        if (!information.other) information.other = {};
        information.other[key] = value;
      }
    }
  });
  information.id = `${information.type}|${information.recorder}|${information.startDate}`;
  const brani = cronologia.Brano || [];
  const recordings = [];
  for (const brano of brani) {
    const sottobrani = brano.SubBrano || [];
    const bId = brano['@_Id'];
    // The following are not in TXT file
    const bDateAvailable = '@_Date' in brano && '@_Time' in brano;
    const bDate = bDateAvailable ? datetimeToDate(`${brano['@_Date']} ${brano['@_Time']}`, tz) : null;
    let startOffset = 0;
    for (const sottobrano of sottobrani) {
      const sId = sottobrano['@_Id'];
      const rId = `${information.id}|${bId}|${sId}`;
      // This is not available in TXT file
      const rDurationAvailable = '@_Duration' in sottobrano;
      const rDuration = rDurationAvailable ? sottobrano['@_Duration'] : null;
      const rDateAvailable = bDateAvailable && rDurationAvailable;
      const rDate = rDateAvailable ? dateAddSeconds(bDate, startOffset, tz) : null;
      if (rDurationAvailable) startOffset += rDuration;
      const annotationReferenceTime = rDateAvailable ? rDate : information.startDate;
      const rTurns = (sottobrano.Intervento || []).map(async (turn) => {
        const time = timeToDate(turn['@_Time'], annotationReferenceTime, tz);
        const outTurn = {
          time,
          trackName: turn['@_ChannelName'],
        };
        if (nameToTrackMap.has(outTurn.trackName)) outTurn.track = nameToTrackMap.get(outTurn.trackName);
        return outTurn;
      });
      const rMarkers = (sottobrano.Marker || []).map(async (marker) => {
        const outMarker = {
          phase: marker['@_Fase'],
          note: marker['@_Note'],
        };
        if ('@_Time' in marker) outMarker.time = timeToDate(marker['@_Time'], annotationReferenceTime, tz);
        if ('@_Position' in marker) outMarker.position = marker['@_Position'];
        return outMarker;
      });
      const recording = {
        other: {
          passage: bId,
          section: sId,
        },
        // eslint-disable-next-line no-await-in-loop
        turns: await Promise.all(rTurns),
        // eslint-disable-next-line no-await-in-loop
        markers: await Promise.all(rMarkers),
        id: rId,
      };
      if (rDateAvailable) recording.startDate = rDate;
      if (rDurationAvailable) recording.duration = rDuration;
      recordings.push(recording);
    }
  }
  return { information, recordings };
}

async function parseCdAnnotations({ pathPrefix = '', cronologiaTxtData = null, cronologiaXmlData = null, nameToTrackMap = defaultNameToTrackMap, tz = 'Europe/Rome' }) {
  if (!cronologiaTxtData) return null;
  const tdata = parseCronologiaFromTxt(cronologiaTxtData);
  const xdata = parseCronologiaFromXml(cronologiaXmlData);
  const data = mergeInformation(await tdata, await xdata);
  return normalizeAnnotations(data, nameToTrackMap, tz);
}

const parseAudioFilenameRegex = /.*_(\d+)_(\d{1,2})_(\d+)\.mp3$/i;
async function detectAndParseCds(files, tz = 'Europe/Rome') {
  const cds = [];
  for (const item of Object.values(await detectCds(files))) {
    // eslint-disable-next-line no-await-in-loop
    const markerHtmlData = await readFile(item.marker, 'latin1').catch(() => null);
    // eslint-disable-next-line no-await-in-loop
    const rtHtmlData = await readFile(item.rt, 'latin1').catch(() => null);
    // eslint-disable-next-line no-await-in-loop
    const nameToTrackMap = await parseNameToTrackNumber(markerHtmlData, rtHtmlData);
    const trackToNameMap = new Map(Array.from(nameToTrackMap, (a) => a.reverse()));
    for (const cd of Object.values(item.cds)) {
      const annotationsFiles = [];
      const params = {
        nameToTrackMap,
        tz,
      };
      if (cd.txt) {
        // eslint-disable-next-line no-await-in-loop
        params.cronologiaTxtData = await readFile(cd.txt, 'latin1').catch(() => null);
        annotationsFiles.push({
          file: cd.txt,
          type: 'txt',
        });
      }
      if (cd.xml) {
        // eslint-disable-next-line no-await-in-loop
        params.cronologiaXmlData = await readFile(cd.xml, 'latin1').catch(() => null);
        annotationsFiles.push({
          file: cd.xml,
          type: 'xml',
        });
      }
      const element = {
        // eslint-disable-next-line no-await-in-loop
        annotations: await parseCdAnnotations(params),
      };
      if (cd.audio) element.audio = cd.audio;
      if (!isEmptyObject(cd.audio)) {
        const recordingsIds = element.annotations?.recordings?.map((rec) => rec.id);
        const mixerTrack = nameToTrackMap.get('Mixer');
        const baseRecordingId = element.annotations?.information.id;
        const audio = {};
        const unknown = [];
        for (const [key, value] of Object.entries(cd.audio)) {
          const match = key.match(parseAudioFilenameRegex);
          if (match) {
            const brano = parseInt(match[1], 10);
            const trackNumber = parseInt(match[2], 10);
            const sottobrano = parseInt(match[3], 10);
            const recordingId = `${baseRecordingId}|${brano}|${sottobrano}`;
            if (recordingsIds && recordingsIds.includes(recordingId)) {
              if (typeof audio[recordingId] === 'undefined') audio[recordingId] = { tracks: {}, labels: {} };
              audio[recordingId].tracks[trackNumber] = value;
              audio[recordingId].labels[trackNumber] = trackToNameMap.get(trackNumber);
              if (typeof mixerTrack !== 'undefined' && trackNumber === mixerTrack) {
                audio[recordingId].mainTrack = trackNumber;
              }
            } else unknown.push(value);
          } else {
            unknown.push(value);
          }
        }
        // console.log(audio);
        element.audio = audio;
        if (unknown.length > 0) {
          element.otherAudioFiles = unknown;
        }
      }
      element.annotationsFiles = annotationsFiles;
      cds.push(element);
    }
  }
  // console.log(JSON.stringify(cds, null, '  '));
  return cds;
}
export { parseNameToTrackNumber, parseCdAnnotations, detectAndParseCds };

export default parseCdAnnotations;
