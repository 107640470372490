import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const proceedingsAdapter = createEntityAdapter();

const proceedingsSlice = createSlice({
  name: 'proceedings',
  initialState: proceedingsAdapter.getInitialState(),
  reducers: {
    loadProceedings: proceedingsAdapter.setMany,
    editProceeding: proceedingsAdapter.updateOne,
    editProceedings: proceedingsAdapter.updateMany,
    addProceeding: proceedingsAdapter.addOne,
    removeProceeding: proceedingsAdapter.removeOne,
    removeProceedings: proceedingsAdapter.removeAll,
    removeProceedingsByIds: proceedingsAdapter.removeMany,
  },
});

export const { selectById: selectProceedingById, selectIds: selectProceedingIds, selectEntities: selectProceedingEntities, selectAll: selectAllProceedings, selectTotal: selectTotalProceedings } = proceedingsAdapter.getSelectors();

export const { loadProceedings, editProceeding, editProceedings, addProceeding, removeProceeding, removeProceedings, removeProceedingsByIds } = proceedingsSlice.actions;

export default proceedingsSlice;
