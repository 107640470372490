import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Pagination as MuiPagination, Stack, TextField, Typography } from '@mui/material';

function Pagination({ count, pageSizeOptions, page, pageSize, handlePageChange, handlePageSizeChange }) {
  const { t } = useTranslation();
  const pageCount = Math.ceil(count / pageSize);

  return (
    <Stack marginY={2} alignItems="center" direction="row" justifyContent="space-between">
      <MuiPagination data-testid="pagination" disabled={pageCount < 2} hidePrevButton={page === 1} hideNextButton={page === pageCount} color="primary" page={page} count={pageCount} onChange={handlePageChange} />
      <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center">
        <Typography data-testid="resultCount" fontSize="small" color="gray">
          {t('pagination.resultCount', { count })}
        </Typography>
        <TextField inputProps={{ 'data-testid': 'pageSizeSelect' }} size="small" sx={{ minWidth: 150 }} id="pageSizeSelect" select label={t('pagination.pageSize.label')} value={pageSize} onChange={handlePageSizeChange}>
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
}

Pagination.defaultProps = {
  pageSizeOptions: [10, 20, 50],
  count: 0,
};

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};

export default Pagination;
