import { Box } from '@mui/material';
import PrintsNavigationBar from './PrintsNavigationBar';
import PrintsResultPage from './PrintsResultPage';

function PrintsPage() {
  return (
    <Box component="section" id="Prints">
      <PrintsNavigationBar />
      <PrintsResultPage />
    </Box>
  );
}

export default PrintsPage;
