import { useDispatch } from 'react-redux';
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '../features/api/rest/apiProfileSlice';
import { useSignUpMutation } from '../features/api/rest/apiSignUpSlice';
import { setAlertMessage } from '../features/layoutSlice';

function SignUp() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signUp] = useSignUpMutation();

  const { currentData, status, isSuccess } = useGetProfileQuery(undefined, { refetchOnMountOrArgChange: true });

  const handleClickShowPassword = (labelPassword) => {
    if (labelPassword === 'password1') setShowPwd1(!showPwd1);
    else setShowPwd2(!showPwd2);
  };

  const getErrors = (err) => {
    const tmpErrors = {};
    if (err?.username) tmpErrors.username = err.username;
    if (err?.email) tmpErrors.email = err.email;
    if (err?.password1) tmpErrors.password1 = err.password1;
    if (err?.password2) tmpErrors.password2 = err.password2;
    if (err?.non_field_errors) tmpErrors.non_field_errors = err.non_field_errors;
    return tmpErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp({ username, email, password1, password2 }).unwrap();
      navigate('account-confirm-email', { state: { email } });
    } catch (err) {
      setErrors(getErrors(err?.data));
      dispatch(setAlertMessage({ text: t('auth.signUp.form.errorMessage'), type: 'error' }));
    }
  };

  if (status === 'fulfilled' && currentData && isSuccess) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <Typography variant="h3">{t('auth.signUp.heading')}</Typography>
      <Stack component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
          {t('auth.signUp.form.fields.username.label')}
        </Typography>
        <FormControl>
          <OutlinedInput sx={{ mb: 2 }} type="text" placeholder={t('auth.signUp.form.fields.username.placeholder')} required value={username} onChange={(e) => setUsername(e.target.value)} error={!!errors.username} />
        </FormControl>
        <Divider variant="middle" sx={{ mb: 2, mt: 1 }} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          {t('auth.signUp.form.fields.email.label')}
        </Typography>
        <FormControl>
          <OutlinedInput sx={{ mb: 2 }} type="email" placeholder={t('auth.signUp.form.fields.email.placeholder')} required value={email} onChange={(e) => setEmail(e.target.value)} error={!!errors.email} />
        </FormControl>
        <Divider variant="middle" sx={{ mb: 2, mt: 1 }} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          {t('auth.signUp.form.fields.password1.label')}
        </Typography>
        <FormControl>
          <OutlinedInput
            sx={{ mb: 2 }}
            type={showPwd1 ? 'text' : 'password'}
            placeholder={t('auth.signUp.form.fields.password1.placeholder')}
            required
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={t('aria.icons.togglePasswordVisibility')} onClick={() => handleClickShowPassword('password1')} onMouseDown={() => handleClickShowPassword('password1')} edge="end">
                  {showPwd1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            error={!!errors.password1}
          />
        </FormControl>
        <Divider variant="middle" sx={{ mb: 2, mt: 1 }} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          {t('auth.signUp.form.fields.password2.label')}
        </Typography>
        <FormControl>
          <OutlinedInput
            sx={{ mb: 2 }}
            type={showPwd2 ? 'text' : 'password'}
            placeholder={t('auth.signUp.form.fields.password2.placeholder')}
            required
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={t('aria.icons.togglePasswordVisibility')} onClick={() => handleClickShowPassword('password2')} onMouseDown={() => handleClickShowPassword('password2')} edge="end">
                  {showPwd2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            error={!!errors.password2}
          />
        </FormControl>
        <Divider variant="middle" sx={{ mb: 2, mt: 1 }} />
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
          {t('auth.signUp.form.fields.submit')}
        </Button>
      </Stack>
    </Box>
  );
}

export default SignUp;
