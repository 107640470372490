import Cookies from 'js-cookie';
import apiSlice from './apiSlice';

const apiPasswordSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestPasswordReset: builder.mutation({
      query: ({ email }) => ({
        url: 'dj-rest-auth/password/reset/',
        method: 'POST',
        body: { email },
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
    changePassword: builder.mutation({
      // eslint-disable-next-line camelcase
      query: ({ new_password1, new_password2, uid, token }) => ({
        url: 'dj-rest-auth/password/reset/confirm/',
        method: 'POST',
        body: { new_password1, new_password2, uid, token }, // eslint-disable-line camelcase
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
  }),
});

export const { useRequestPasswordResetMutation, useChangePasswordMutation } = apiPasswordSlice;

export default apiPasswordSlice;
