import peg from 'pegjs';
import SyntacticActionsPlugin from 'pegjs-syntactic-actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const dateParser = peg.generate(
  `
  start = ( $( !datetimelocation integer / !datetimelocation .)* datetimelocation )+ $(remainder)? / remainder
  
  datetimelocation = (date $(ws*) location $(timeintroduction) time / date $(timeintroduction) time / date) ($(ws*) location)?
  
  timeintroduction = $(ws* ('hn'i / 'h.'i / 'h'i / 'ore'i ':'? / '0ore'i ':'? / 'or e'i ':'? / 'ire'i ':'? / 'alle'i (ws 'ore'i ':'?)? ) ws* / ws)
  
  date = day $(datesep) month $(datesep) year
       / day $(datesep) month
  
  datesep = [ /.-]
  
  day = $([12] [0-9] / '3' [01] / [1-9] / '0' [1-9]) ! digit
  
  month = $(monthnum / monthname)
  
  monthnum = ('1' [012] / [1-9] / '0' [1-9]) ! digit
  
  monthname = 'gennaio'i / 'febbraio'i / 'marzo'i / 'aprile'i / 'maggio'i / 'giugno'i
            / 'luglio'i / 'agosto'i / 'settembre'i / 'ottobre'i / 'novembre'i / 'dicembre'i
            / 'settebre'i
            / 'gen'i / 'feb'i / 'mar'i / 'apr'i / 'mag'i / 'giu'i
            / 'lug'i / 'ago'i / 'set'i / 'ott'i / 'nov'i / 'dic'i
  
  year = $( fullyear / !longtime compactyear )
  
  fullyear = $( digit digit digit digit )
  
  compactyear = $( digit digit )
  
  time = longtime
       / hours
  
  longtime = hours $(timesep) minutes $(timesep) seconds
           / hours $(timesep) minutes
  
  timesep = [:.,]
  
  hours = $('2' [0-4] / [0-1]? [0-9])
  
  minutes = $([0-5]? [0-9])
  
  seconds = $(minutes)
  
  location = $('aula'i ws+ (integer / word))
  
  ws = $([ \\t\\n\\r])
  
  integer = $(digit+)
  
  digit = $([0-9])
  
  word = $(wchar+)
  
  wchar = $( latinGlyph / extendedGlyph )
  
  latinGlyph = [a-z]i
  
  extendedGlyph = [\u00C0-\uFFFF]
  
  remainder = $(.*)
  `,
  {
    plugins: [new SyntacticActionsPlugin()],
  }
);

const monthToIntMap = {
  gennaio: 1,
  febbraio: 2,
  marzo: 3,
  aprile: 4,
  maggio: 5,
  giugno: 6,
  luglio: 7,
  agosto: 8,
  settembre: 9,
  ottobre: 10,
  novembre: 11,
  dicembre: 12,
  settebre: 9,
  gen: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  mag: 5,
  giu: 6,
  lug: 7,
  ago: 8,
  set: 9,
  ott: 10,
  nov: 11,
  dic: 12,
};

function processMonthRule(node) {
  if (node.text !== undefined) {
    let value = null;
    const monthkey = node.text.toLowerCase();
    if (monthkey in monthToIntMap) {
      value = monthToIntMap[monthkey];
    } else {
      value = parseInt(monthkey, 10);
    }
    return { ...node, value };
  }
  return node;
}

function processIntValue(node) {
  if (node.text !== undefined) {
    return { ...node, value: parseInt(node.text, 10) };
  }
  return node;
}

function processStringValue(node) {
  if (node.text !== undefined) {
    return { ...node, value: node.text };
  }
  return node;
}

function processTime(node) {
  if ('children' in node) {
    let value = {};
    node.children.forEach((item) => {
      if (item.rule === 'hours' && item.value !== undefined) {
        value.hours = item.value;
      }
      if (item.rule === 'minutes' && item.value !== undefined) {
        value.minutes = item.value;
      }
      if (item.rule === 'seconds' && item.value !== undefined) {
        value.seconds = item.value;
      }
      if (item.rule === 'longtime' && item.value !== undefined) {
        value = processTime(item).value;
      }
    });
    return { ...node, value };
  }
  return node;
}

function processDate(node) {
  if ('children' in node) {
    const value = {};
    node.children.forEach((item) => {
      if (item.rule === 'day' && item.value !== undefined) {
        value.day = item.value;
      }
      if (item.rule === 'month' && item.value !== undefined) {
        value.month = item.value;
      }
      if (item.rule === 'year' && item.value !== undefined) {
        value.year = item.value;
      }
    });
    return { ...node, value };
  }
  return node;
}

function processDateTimeLocation(node) {
  if ('children' in node) {
    const value = {};
    node.children.forEach((item) => {
      if (item.rule === 'date' && item.value !== undefined) {
        value.date = item.value;
      }
      if (item.rule === 'time' && item.value !== undefined) {
        value.time = item.value;
      }
      if (item.rule === 'location' && item.value !== undefined) {
        value.location = item.value;
      }
    });
    const result = { ...node, value };
    delete result.children;
    return result;
  }
  return node;
}

function processStart(node, skipStrings = false) {
  if ('children' in node) {
    const result = [];
    node.children.forEach((item) => {
      if (item.rule === 'datetimelocation' && item.value !== undefined) {
        result.push(item);
      } else if (!skipStrings) {
        if (typeof item === 'string' || item instanceof String) {
          result.push(item);
        } else if (item.rule === 'remainder' && item.text !== undefined) {
          result.push(item.text);
        }
      }
    });
    return result;
  }
  return node;
}

function refactorTree(node, skipStrings = true) {
  if (node === null || node === undefined || node === '') {
    return null;
  }
  if (typeof node === 'string' || node instanceof String) {
    if (skipStrings) {
      return null;
    }
    return node;
  }
  if (node instanceof Array) {
    const values = node
      .flat(2)
      .map((x) => refactorTree(x, skipStrings))
      .filter((x) => x !== null);
    return values;
  }
  if ('children' in node) {
    let children = refactorTree(node.children, skipStrings);
    if (!(children instanceof Array)) {
      if (children !== null) {
        children = [children];
      } else {
        children = [];
      }
    }
    let result = { ...node, children };
    if (result.rule === 'month') {
      result = processMonthRule(result);
    }
    if (result.rule === 'day') {
      result = processIntValue(result);
    }
    if (result.rule === 'year') {
      result = processIntValue(result);
    }
    if (result.rule === 'hours') {
      result = processIntValue(result);
    }
    if (result.rule === 'minutes') {
      result = processIntValue(result);
    }
    if (result.rule === 'seconds') {
      result = processIntValue(result);
    }
    if (result.rule === 'date') {
      result = processDate(result);
    }
    if (result.rule === 'time') {
      result = processTime(result);
    }
    if (result.rule === 'longtime') {
      result = processTime(result);
    }
    if (result.rule === 'location') {
      result = processStringValue(result);
    }
    if (result.rule === 'datetimelocation') {
      result = processDateTimeLocation(result);
    }
    if (result.rule === 'start') {
      result = processStart(result, skipStrings);
    }
    return result;
  }
  return node;
}

function basicParseDates(text, skipStrings = false) {
  try {
    return refactorTree(dateParser.parse(text), skipStrings);
  } catch (error) {
    if (skipStrings) return [];
    return [text];
  }
}

function getReferenceDate(referenceDate = null, tz = 'Europe/Rome') {
  if (referenceDate === null) return dayjs();
  if (typeof referenceDate === 'string' || referenceDate instanceof String || referenceDate instanceof Date) {
    return dayjs.tz(referenceDate, tz);
  }
  if (dayjs.isDayjs(referenceDate)) {
    return referenceDate;
  }
  return dayjs();
}

function jsonToDateString(json, tz = 'Europe/Rome') {
  const date = dayjs(new Date(json.year, json.month, json.day, json.hours || 0, json.minutes || 0, json.seconds || 0));
  return dayjs.tz(date.format('YYYY-MM-DDTHH:mm:ss'), tz);
}

function dateTimeLocationToDate(dtl, referenceDate = null, tz = 'Europe/Rome') {
  if (!dtl.value) return null;
  const dateJson = dtl.value.date;
  Object.assign(dateJson, dtl.value.time);
  if (dateJson) {
    if (dateJson.year < 100) {
      dateJson.year += 2000;
    }
    dateJson.month -= 1;
    if (typeof dateJson.year === 'undefined') {
      const refDate = getReferenceDate(referenceDate, tz);
      dateJson.year = refDate.year();
      let date = jsonToDateString(dateJson, tz);
      if (dateJson.day === 29 && dateJson.month === 1) {
        while (date.daysInMonth() !== 29 || date.isBefore(refDate)) {
          dateJson.year += 1;
          date = jsonToDateString(dateJson, tz);
        }
        return date;
      }
      if (date.isBefore(refDate)) {
        return date.year(date.year() + 1);
      }
    }
    return jsonToDateString(dateJson, tz);
  }
  return null;
}

function convertDates(dates, referenceDate = null, tz = 'Europe/Rome') {
  return dates.map((dtl) => {
    if (dtl.value) {
      const ret = {};
      const date = dateTimeLocationToDate(dtl, referenceDate, tz).format();
      if (date) ret.date = date;
      if (dtl.value.location) ret.location = dtl.value.location;
      if (typeof dtl.text !== 'undefined') ret.text = dtl.text;
      if (typeof dtl.start !== 'undefined') ret.start = dtl.start;
      if (typeof dtl.end !== 'undefined') ret.end = dtl.end;
      return ret;
    }
    return dtl;
  });
}

function parseDate(string, skipStrings = true, referenceDate = null, tz = 'Europe/Rome') {
  return convertDates(basicParseDates(string, skipStrings), referenceDate, tz);
}

export { parseDate };

export default parseDate;
