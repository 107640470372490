import { Box, Button, FormControl, OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRequestPasswordResetMutation } from '../features/api/rest/apiPasswordSlice';
import MainNavBar from './Layout/MainNavBar';
import Spinner from './Spinner';

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const emailSent = location.state?.email;
  const { t } = useTranslation();
  const [requestPasswordReset, { isLoading }] = useRequestPasswordResetMutation();

  const [email, setEmail] = useState(emailSent);

  const handleSumbit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset({ email }).unwrap();
      navigate(`/reset/password/`, { state: { email } });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err); // todo: check err.status and provide feedback to the user
    }
  };

  if (isLoading) return <Spinner />;

  if (emailSent)
    return (
      <>
        <MainNavBar />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '90vh',
          }}
        >
          <Typography variant="h3">{t('auth.passwordReset.heading')}</Typography>
          <Stack maxWidth="80%" textAlign="center">
            <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
              {t('auth.passwordReset.message')}
            </Typography>
          </Stack>
          <Button onClick={handleSumbit} variant="contained" sx={{ mt: 1 }}>
            {t('common.actions.send')}
          </Button>
        </Box>
      </>
    );

  return (
    <>
      <MainNavBar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '90vh',
        }}
      >
        <Typography variant="h3">{t('auth.passwordReset.heading')}</Typography>
        <Stack component="form" onSubmit={handleSumbit}>
          <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
            {t('auth.passwordReset.form.fields.email.label')}
          </Typography>
          <FormControl>
            <OutlinedInput type="email" placeholder={t('auth.passwordReset.form.fields.email.placeholder')} required value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            {t('common.actions.send')}
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default ResetPassword;
