import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardContent, IconButton, Divider, Tooltip, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore, Save, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import Recordings from './Recordings';
import Headers from './Headers';

function AnnotationsEditor({ cdId }) {
  const { t } = useTranslation();

  const [expandAll, setExpandAll] = useState(true);
  const [open, setOpen] = useState(false);

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{ padding: 1 }}
        titleTypographyProps={{ variant: 'body1' }}
        title={t('annotationsEditor.label')}
        action={
          <>
            <Tooltip title={t('common.actions.save')}>
              <IconButton>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title={expandAll ? t('annotationsEditor.actions.decreseAll') : t('annotationsEditor.actions.expandAll')}>
              <IconButton onClick={() => setExpandAll(!expandAll)}>{expandAll ? <UnfoldLess /> : <UnfoldMore />}</IconButton>
            </Tooltip>
            <Tooltip title={open ? t('common.actions.close') : t('common.actions.open')}>
              <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </Tooltip>
          </>
        }
      />
      <Divider />
      <Collapse mountOnEnter unmountOnExit orientation="vertical" in={open}>
        <CardContent sx={{ maxHeight: '30em', overflow: 'auto', p: 1, pr: 3 }}>
          <Headers cdId={cdId} isOpened={expandAll} />
          <Recordings cdId={cdId} expandAll={expandAll} />
        </CardContent>
        <Divider />
      </Collapse>
    </Card>
  );
}

AnnotationsEditor.propTypes = {
  cdId: PropTypes.string.isRequired,
};

export default AnnotationsEditor;
