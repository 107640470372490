import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem, Skeleton, Typography } from '@mui/material';
import { useGetRoomsQuery } from '../features/api/tds/apiCourtsSlice';

function RoomsSelect({ roomId, setRoomId, courtId, label, name, required, size }) {
  const { currentData: rooms, isLoading, isFetching, isError } = useGetRoomsQuery({ filters: { courtId }, orderby: { room: 'asc' }, pSize: 100 }, { skip: !courtId });
  const { t } = useTranslation();

  if (isError)
    return (
      <Typography>
        {label}: {t('common.messages.errors.fetching')}
      </Typography>
    );
  if (isFetching || isLoading) return <Skeleton variant="rectangular" height="3em" animation="pulse" />;

  return (
    <TextField
      size={size}
      name={name}
      required={required}
      value={roomId}
      onChange={(e) => setRoomId(e.target.value)}
      select
      label={label}
      SelectProps={{
        MenuProps: {
          style: { maxHeight: 400 },
        },
        inputProps: { 'data-testid': name },
      }}
    >
      {rooms ? (
        rooms?.map((room) => (
          <MenuItem key={room.id} value={room.id}>
            {room.aula}
          </MenuItem>
        ))
      ) : (
        <MenuItem />
      )}
    </TextField>
  );
}
RoomsSelect.defaultProps = {
  size: 'medium',
};

RoomsSelect.propTypes = {
  roomId: PropTypes.string.isRequired,
  setRoomId: PropTypes.func.isRequired,
  courtId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

export default RoomsSelect;
