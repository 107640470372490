import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectTDSAuth, selectTranscriptionAuth, setTranscriptionAuth, setTDSAuth, setShouldLogoutASAPFromTDS, setShouldLoginASAPOnTranscription } from '../features/authSlice';
import { useValidateTokenQuery } from '../features/api/tds/apiAuthSlice';
import { useGetProfileQuery } from '../features/api/rest/apiProfileSlice';
import { setAlertMessage } from '../features/layoutSlice';
import appConfig from '../app/config';
import apiSlice from '../features/api/rest/apiSlice';
import apiSliceTds from '../features/api/tds/apiSlice';
import { useLoginWithTdsMutation } from '../features/api/rest/apiAuthSlice';
import { getToken } from '../features/api/tds/configApi';

function RequireAuth() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { isAuth: isTDSAuth, netWorkError: isTDSNetworkError, shouldLogoutASAP: shouldLogoutASAPFromTDS } = useSelector(selectTDSAuth);
  const { netWorkError: isTranscriptionNetworkError, shouldLoginASAP: shouldLoginASAPOnTranscription } = useSelector(selectTranscriptionAuth);
  const [logout] = apiSlice.useLogoutMutation();
  const [logoutTds] = apiSliceTds.useLogoutMutation();
  const [loginWithTds] = useLoginWithTdsMutation();

  useValidateTokenQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: isTDSNetworkError ? 2000 : appConfig.api.backend.checkSessionInterval,
  });
  useGetProfileQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: isTranscriptionNetworkError ? 2000 : appConfig.api.backend.checkSessionInterval,
  });

  const handleLoginWithTds = useCallback(async () => {
    try {
      const accessToken = await getToken();
      const response = await loginWithTds({ accessToken }).unwrap();
      dispatch(setTranscriptionAuth({ isAuth: true, user: response.user }));
      return dispatch(setAlertMessage({ text: t('auth.messages.transcriptionBackend.success'), type: 'success' }));
    } catch (err) {
      dispatch(setTranscriptionAuth({ isAuth: false, user: null }));
      return dispatch(setAlertMessage({ text: t('auth.messages.transcriptionBackend.connectionError'), type: 'error' }));
    } finally {
      dispatch(setShouldLoginASAPOnTranscription(false));
    }
  }, [dispatch, loginWithTds, t]);

  const autoLogout = useCallback(async () => {
    await logoutTds().finally(() => {
      dispatch(setTDSAuth({ isAuth: false, user: null }));
      dispatch(setShouldLogoutASAPFromTDS(false));
    });
    await logout().finally(() => {
      dispatch(setTranscriptionAuth({ isAuth: false, user: null }));
      dispatch(setShouldLoginASAPOnTranscription(false));
    });
  }, [dispatch, logout, logoutTds]);

  useEffect(() => {
    if (!isTDSAuth) dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.loginFirst.error') }));
  }, [dispatch, isTDSAuth, t]);

  useEffect(() => {
    if (shouldLogoutASAPFromTDS) autoLogout();
  }, [autoLogout, shouldLogoutASAPFromTDS]);

  useEffect(() => {
    if (isTDSAuth && !isTranscriptionNetworkError && shouldLoginASAPOnTranscription) handleLoginWithTds();
  }, [handleLoginWithTds, isTranscriptionNetworkError, shouldLoginASAPOnTranscription, isTDSAuth]);

  if (!isTDSAuth) return <Navigate to="/login" replace state={location.state?.logout ? null : { next: location.pathname, nextSearch: location.search }} />;
  return <Outlet />;
}

export default RequireAuth;
