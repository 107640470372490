import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsLogoutDialogOpen, openLogoutDialog, setAlertMessage, closeLogoutDialog } from '../features/layoutSlice';
import apiSlice from '../features/api/rest/apiSlice';
import apiSliceTds from '../features/api/tds/apiSlice';
import { selectTranscriptionAuth, setTDSAuth, setTranscriptionAuth } from '../features/authSlice';

const useLogout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isDialogOpen = useSelector(selectIsLogoutDialogOpen);
  const [logout] = apiSlice.useLogoutMutation();
  const [logoutTds] = apiSliceTds.useLogoutMutation();
  const { isAuth: isTranscriptionAuth } = useSelector(selectTranscriptionAuth);

  const handleTranscriptionLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(setTranscriptionAuth({ isAuth: false, user: null }));
      if (isDialogOpen) dispatch(closeLogoutDialog());
    } catch (error) {
      if (!isDialogOpen) dispatch(openLogoutDialog());
      // eslint-disable-next-line no-console
      console.log('Transcription logout error', error);
      throw error;
    }
  };

  const handleTDSLogout = async () => {
    try {
      await logoutTds().unwrap();
      location.state = { logout: true };
      dispatch(setTDSAuth({ isAuth: false, user: null }));
      dispatch(setAlertMessage({ type: 'success', text: t('auth.messages.logout.success') }));
      if (isDialogOpen) dispatch(closeLogoutDialog());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('TDS logout error', error);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      if (isTranscriptionAuth) await handleTranscriptionLogout();
      await handleTDSLogout();
      dispatch(setAlertMessage({ text: t('auth.messages.logout.success'), type: 'success' }));
    } catch (error) {
      dispatch(setAlertMessage({ text: t('auth.messages.logout.error'), type: 'error', render: 'snack' }));
      // eslint-disable-next-line no-console
      console.log('Logout error', error);
    }
  };

  return { isDialogOpen, handleLogout, handleTDSLogout };
};

export default useLogout;
