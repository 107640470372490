import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Chip, Skeleton, Typography, Box } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  cleanCourt,
  cleanRequestingCourt,
  cleanRequestingSection,
  cleanSection,
  cleanStatusId,
  cleanTicketId,
  getCourt,
  getRequestingCourt,
  getRequestingSection,
  getSection,
  getStatusId,
  getTicketId,
  setCourt,
  setRequestingCourt,
  setRequestingSection,
  setSection,
  setStatusId,
  setTicketId,
} from '../features/trialFiltersSlice';
import FilterByString from './FilterByString';
import FilterByStatus from './Filters/FilterByStatus';
import useTrialsResults from '../hooks/useTrialsResult';
import StatusChip from './Filters/StatusChip';
import { useGetTrialStatusQuery } from '../features/api/tds/apiTrialsSlice';

function TrialFilters({ color }) {
  const { trials, isLoading, isFetching } = useTrialsResults();
  const { currentData: statusOptions, isLoading: isStatusOptionsLoading, isFetching: isStatusOptionsFetching } = useGetTrialStatusQuery();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useSearchParams();
  const [timer, setTimer] = useState(null);
  const filters = search.has('ticketId') || search.has('statusId') || search.has('court') || search.has('section') || search.has('trich') || search.has('srich');

  const ticketId = useSelector(getTicketId);
  const statusId = useSelector(getStatusId);
  const court = useSelector(getCourt);
  const section = useSelector(getSection);
  const requestingCourt = useSelector(getRequestingCourt);
  const requestingSection = useSelector(getRequestingSection);

  // sync filters and querystring
  useEffect(() => {
    if (search?.get('ticketId')) dispatch(setTicketId(search?.get('ticketId')));
    else dispatch(setTicketId(''));
    if (search?.get('statusId')) dispatch(setStatusId(search?.get('statusId')));
    else dispatch(setStatusId(''));
    if (search?.get('court')) dispatch(setCourt(search?.get('court')));
    else dispatch(setCourt(''));
    if (search?.get('section')) dispatch(setSection(search?.get('section')));
    else dispatch(setSection(''));
    if (search?.get('trich')) dispatch(setRequestingCourt(search?.get('trich')));
    else dispatch(setRequestingCourt(''));
    if (search?.get('srich')) dispatch(setRequestingSection(search?.get('srich')));
    else dispatch(setRequestingSection(''));
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const timeoutUpdate = (setStateFn, setSearchFn, value) => {
    dispatch(setStateFn(value));
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setSearchFn(value.toUpperCase());
    }, 1000);
    setTimer(newTimer);
  };

  // ticketId
  const handleTicketIdChange = (newVal) => {
    newVal ? search.set('ticketId', newVal) : search.delete('ticketId');
    setSearch(search);
  };
  const handleTicketIdInputChange = (newVal) => timeoutUpdate(setTicketId, handleTicketIdChange, newVal);
  const handleTicketIdReset = () => {
    dispatch(cleanTicketId());
    search.delete('ticketId');
    setSearch(search);
  };
  // court
  const handleCourtChange = (newVal) => {
    newVal ? search.set('court', newVal) : search.delete('court');
    setSearch(search);
  };
  const handleCourtInputChange = (newVal) => timeoutUpdate(setCourt, handleCourtChange, newVal);
  const handleCourtReset = () => {
    dispatch(cleanCourt());
    search.delete('court');
    setSearch(search);
  };
  // section
  const handleSectionChange = (newVal) => {
    newVal ? search.set('section', newVal) : search.delete('section');
    setSearch(search);
  };
  const handleSectionInputChange = (newVal) => timeoutUpdate(setSection, handleSectionChange, newVal);
  const handleSectionReset = () => {
    dispatch(cleanSection());
    search.delete('section');
    setSearch(search);
  };
  // requestingCourt
  const handleRequestingCourtChange = (newVal) => {
    newVal ? search.set('trich', newVal) : search.delete('trich');
    setSearch(search);
  };
  const handleRequestingCourtInputChange = (newVal) => timeoutUpdate(setRequestingCourt, handleRequestingCourtChange, newVal);
  const handleRequestingCourtReset = () => {
    dispatch(cleanRequestingCourt());
    search.delete('trich');
    setSearch(search);
  };
  // requestingSection
  const handleRequestingSectionChange = (newVal) => {
    newVal ? search.set('srich', newVal) : search.delete('srich');
    setSearch(search);
  };
  const handleRequestingSectionInputChange = (newVal) => timeoutUpdate(setRequestingSection, handleRequestingSectionChange, newVal);
  const handleRequestingSectionReset = () => {
    dispatch(cleanRequestingSection());
    search.delete('srich');
    setSearch(search);
  };
  // statusId
  const handleStatusIdChange = (newVal) => {
    newVal ? search.set('statusId', newVal) : search.delete('statusId');
    setSearch(search);
  };
  const handleResetStatusId = () => {
    dispatch(cleanStatusId());
    search.delete('statusId');
    setSearch(search);
  };
  const handleFiltersReset = () => {
    handleTicketIdReset();
    handleResetStatusId();
    handleCourtReset();
    handleSectionReset();
    handleRequestingCourtReset();
    handleRequestingSectionReset();
  };

  if (isLoading || isFetching) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} marginY={2}>
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
      </Stack>
    );
  }
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <FilterByString
          size="small"
          name="ticketId"
          label={t('trials.filters.ticketId.label')}
          value={ticketId}
          onChange={handleTicketIdChange}
          onInputChange={handleTicketIdInputChange}
          options={[...new Set(trials?.flatMap((trial) => trial.id_ticket || []))]}
          color={color}
        />
        <FilterByStatus
          size="small"
          name="statusId"
          label={t('trials.filters.status.label')}
          value={statusId}
          onChange={handleStatusIdChange}
          color={color}
          statusOptions={statusOptions}
          isLoading={isStatusOptionsLoading}
          isFetching={isStatusOptionsFetching}
        />
        <FilterByString
          size="small"
          name="court"
          label={t('trials.filters.court.label')}
          value={court}
          onChange={handleCourtChange}
          onInputChange={handleCourtInputChange}
          options={[...new Set(trials?.flatMap((trial) => trial.tribunale || []))]}
          color={color}
        />
        <FilterByString
          size="small"
          name="section"
          label={t('trials.filters.section.label')}
          value={section}
          onChange={handleSectionChange}
          onInputChange={handleSectionInputChange}
          options={[...new Set(trials?.flatMap((trial) => trial.sezione || []))]}
          color={color}
        />
        <FilterByString
          size="small"
          name="section"
          label={t('trials.filters.requestingCourt.label')}
          value={requestingCourt}
          onChange={handleRequestingCourtChange}
          onInputChange={handleRequestingCourtInputChange}
          options={[...new Set(trials?.flatMap((trial) => trial.tribunale_richiedente || []))]}
          color={color}
        />
        <FilterByString
          size="small"
          name="section"
          label={t('trials.filters.requestingSection.label')}
          value={requestingSection}
          onChange={handleRequestingSectionChange}
          onInputChange={handleRequestingSectionInputChange}
          options={[...new Set(trials?.flatMap((trial) => trial.sezione_richiedente || []))]}
          color={color}
        />
      </Stack>
      {filters && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography marginRight={2}>{t('trials.filterBy')}:</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {search?.get('ticketId') && <Chip variant="filled" color={color} label={`${t('trials.filters.ticketId.label')}: ${search?.get('ticketId')}`} onDelete={handleTicketIdReset} />}
            <StatusChip statusId={search?.get('statusId')} statusOptions={statusOptions} isLoading={isStatusOptionsLoading} isFetching={isStatusOptionsFetching} label={t('trials.filters.status.label')} onDelete={handleResetStatusId} color={color} />
            {search?.get('court') && <Chip variant="filled" color={color} label={`${t('trials.filters.court.label')}: ${search?.get('court')}`} onDelete={handleCourtReset} />}
            {search?.get('section') && <Chip variant="filled" color={color} label={`${t('trials.filters.section.label')}: ${search?.get('section')}`} onDelete={handleSectionReset} />}
            {search?.get('trich') && <Chip variant="filled" color={color} label={`${t('trials.filters.requestingCourt.label')}: ${search?.get('trich')}`} onDelete={handleRequestingCourtReset} />}
            {search?.get('srich') && <Chip variant="filled" color={color} label={`${t('trials.filters.requestingSection.label')}: ${search?.get('srich')}`} onDelete={handleRequestingSectionReset} />}
          </Stack>
          <IconButton sx={{ marginLeft: 'auto' }} variant="contained" onClick={handleFiltersReset}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

TrialFilters.defaultProps = {
  color: 'primary',
};

TrialFilters.propTypes = {
  color: PropTypes.string,
};

export default TrialFilters;
