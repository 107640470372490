import { useSelector, useDispatch } from 'react-redux';
import { useGetFileNameQuery, useGetFileQuery } from '../features/api/tds/apiTrialsSlice';
import { selectProceedingFormStagedRecordings, setProceedingFormStagedRecordings } from '../features/proceedingFormSlice';

export default function useProceedingStagedTrack(trackId) {
  const dispatch = useDispatch();

  const tracks = useSelector(selectProceedingFormStagedRecordings);
  const { currentData: track, isLoading: isLoadingFile, isFetching: isFetchingFile, isError: isErrorFile } = useGetFileQuery({ fileId: trackId });
  const { currentData: fileName, isLoading: isLoadingFileName, isFetching: isFetchingFileName, isError: isErrorFileName } = useGetFileNameQuery({ fileId: trackId });

  const removeTrack = () => {
    const newTracks = tracks?.filter((trk) => trk.fileId !== trackId);
    dispatch(setProceedingFormStagedRecordings(newTracks));
  };

  return {
    track,
    removeTrack,
    fileName,
    isLoading: isLoadingFile || isLoadingFileName,
    isFetching: isFetchingFile || isFetchingFileName,
    isError: isErrorFile || isErrorFileName,
  };
}
