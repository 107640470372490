import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentActionButton from './DocumentActionButton';
import TrialSignedAttendanceCertificateLoadButton from './CertificateLoadButton';

function TrialSignedCertificate({ ticketId, certificateId, setCertificateId, trialService }) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Typography sx={{ width: '30%' }}>{trialService === 'RITIRO EX-POST' ? t('trials.form.editTrial.fields.compiledWithdrawalCertificate') : t('trials.form.editTrial.fields.compiledAttendanceCertificate')}</Typography>
      {certificateId ? (
        <Stack spacing={2} direction="row" alignItems="center">
          <DocumentActionButton documentId={certificateId} actionType="print" fileType="p" title={t('common.actions.print')} />
          <DocumentActionButton documentId={certificateId} actionType="download" fileType="p" ticketId={ticketId}>
            {t('common.actions.download', { what: 'pdf' })}
          </DocumentActionButton>
          <DocumentActionButton documentId={certificateId} actionType="open" fileType="p" ticketId={ticketId}>
            {t('common.actions.open', { what: 'pdf' })}
          </DocumentActionButton>
          <Button color="error" onClick={() => setCertificateId('')} variant="contained">
            {t('common.actions.delete')}
          </Button>
        </Stack>
      ) : (
        <TrialSignedAttendanceCertificateLoadButton ticketId={ticketId} onUploadSuccessCallback={setCertificateId} />
      )}
    </Stack>
  );
}

TrialSignedCertificate.defaultProps = {
  certificateId: '',
  trialService: '',
};

TrialSignedCertificate.propTypes = {
  ticketId: PropTypes.string.isRequired,
  certificateId: PropTypes.string,
  setCertificateId: PropTypes.func.isRequired,
  trialService: PropTypes.string,
};

export default TrialSignedCertificate;
