const metadataFilesRegex = /^(RT_Cronologia_([^_]*).*(\d{8}_\d{4}).*\.(txt|xml)|RT_([^_]*).*(\d{8}_\d{4})\d{2}_\d{5}_\d{1,2}_\d{5}\.(mp3)|rt.html|marker_1.html)$/i;
const rtRegex = /^rt.html$/i;
const markerRegex = /^marker_1.html$/i;

async function detectCds(files) {
  const dirs = {};
  files.forEach((file) => {
    const match = file.name.match(metadataFilesRegex);
    if (match) {
      const directory = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf(file.name));
      const cdId = `${match[2] || match[5]}_${match[3] || match[6]}`;
      if (!(directory in dirs)) {
        dirs[directory] = { cds: {} };
      }
      if (file.type === 'text/plain') {
        if (!(cdId in dirs[directory].cds)) {
          dirs[directory].cds[cdId] = { audio: {} };
        }
        dirs[directory].cds[cdId].txt = file;
      } else if (file.type === 'application/xml' || file.type === 'text/xml') {
        if (!(cdId in dirs[directory].cds)) {
          dirs[directory].cds[cdId] = { audio: {} };
        }
        dirs[directory].cds[cdId].xml = file;
      } else if (file.type === 'audio/mpeg') {
        if (!(cdId in dirs[directory].cds)) {
          dirs[directory].cds[cdId] = { audio: {} };
        }
        dirs[directory].cds[cdId].audio[file.name] = file;
      } else if (file.name.match(rtRegex)) {
        dirs[directory].rt = file;
      } else if (file.name.match(markerRegex)) {
        dirs[directory].marker = file;
      } else {
        return true;
      }
      return false;
    }
    return true;
  });
  return dirs;
}

export { detectCds };

export default detectCds;
