import { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import NodeRow from './NodeRow';
import JobProgressBar from '../JobProgressBar';
import RescheduleJobButton from '../RescheduleJobButton';

dayjs.extend(LocalizedFormat);

const rowStructure = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  last_updated: PropTypes.string.isRequired,
  original_file_name: PropTypes.string,
  request_reference: PropTypes.string.isRequired,
  progress: PropTypes.number,
  children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
});

function TreeTable({ rootRow, rows, onSelectRow, selectedRow }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer' }} onClick={() => onSelectRow(rootRow)} selected={selectedRow?.id === rootRow.id}>
        <TableCell>
          {rows?.length > 0 && (
            <IconButton sx={{ p: 0 }} onClick={() => setIsExpanded((prev) => !prev)}>
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          )}
        </TableCell>
        <TableCell sx={{ pl: '5px' }}>{t(`jobs.status.value.${rootRow.status}`)}</TableCell>
        <TableCell>{rootRow.created ? dayjs(rootRow.created).format('L LT') : ''}</TableCell>
        <TableCell>{rootRow.last_updated ? dayjs(rootRow.last_updated).format('L LT') : ''}</TableCell>
        <TableCell>{rootRow.request_reference}</TableCell>
        <TableCell>{rootRow.original_file_name}</TableCell>
        <TableCell>
          <JobProgressBar progress={rootRow.progress} />
        </TableCell>
        <TableCell>{rootRow.status === 'failed' && <RescheduleJobButton job={{ ...rootRow, children: rows }} />}</TableCell>
      </TableRow>
      {rows?.length > 0 && isExpanded && rows.map((childNode) => <NodeRow key={childNode.id} row={childNode} onSelectRow={onSelectRow} selectedRow={selectedRow} />)}
    </>
  );
}
TreeTable.defaultProps = {
  selectedRow: null,
  rootRow: null,
};

TreeTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      last_updated: PropTypes.string.isRequired,
      original_file_name: PropTypes.string,
      request_reference: PropTypes.string.isRequired,
      progress: PropTypes.number,
      children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    })
  ).isRequired,
  onSelectRow: PropTypes.func.isRequired,
  selectedRow: rowStructure,
  rootRow: rowStructure,
};

export default TreeTable;
