import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'trialFilters',
  initialState: {
    ticketId: '',
    statusId: '',
    court: '',
    section: '',
    requestingCourt: '',
    requestingSection: '',
  },
  reducers: {
    setTicketId: (state, action) => ({ ...state, ticketId: action.payload }),
    cleanTicketId: (state) => ({ ...state, ticketId: '' }),
    setStatusId: (state, action) => ({ ...state, statusId: action.payload }),
    cleanStatusId: (state) => ({ ...state, statusId: '' }),
    setCourt: (state, action) => ({ ...state, court: action.payload }),
    cleanCourt: (state) => ({ ...state, court: '' }),
    setSection: (state, action) => ({ ...state, section: action.payload }),
    cleanSection: (state) => ({ ...state, section: '' }),
    setRequestingCourt: (state, action) => ({ ...state, requestingCourt: action.payload }),
    cleanRequestingCourt: (state) => ({ ...state, requestingCourt: '' }),
    setRequestingSection: (state, action) => ({ ...state, requestingSection: action.payload }),
    cleanRequestingSection: (state) => ({ ...state, requestingSection: '' }),
  },
};
const filtersSlice = createSlice(options);

export const getFilters = (state) => state.trialFilters;
export const getTicketId = (state) => state.trialFilters.ticketId;
export const getStatusId = (state) => state.trialFilters.statusId;
export const getCourt = (state) => state.trialFilters.court;
export const getSection = (state) => state.trialFilters.section;
export const getRequestingCourt = (state) => state.trialFilters.requestingCourt;
export const getRequestingSection = (state) => state.trialFilters.requestingSection;
export const { setTicketId, cleanTicketId, setStatusId, cleanStatusId, setCourt, cleanCourt, setSection, cleanSection, setRequestingCourt, cleanRequestingCourt, setRequestingSection, cleanRequestingSection } = filtersSlice.actions;
export default filtersSlice;
