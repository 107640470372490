import { createSlice } from '@reduxjs/toolkit';

const initialTrial = {
  trialId: '',
  statusId: '',
  status: '',
  ticketId: '',
  away: false,
  roomId: '',
  courtId: '',
  dateStart: '',
  timeStart: '',
  timeEnd: '',
  proceedingsCount: 0,
  proceedingsCountToVerify: 0,
  CDCount: 0,
  certificateId: '',
  notes: '',
  operatorArrivalTime: '',
};
const options = {
  name: 'trialEditing',
  initialState: {
    dialog: {
      open: false,
    },
    trial: initialTrial,
  },
  reducers: {
    setEditingTrial: (state, action) => ({
      ...state,
      trial: action.payload,
    }),
    setEditingTrialField: (state, action) => ({
      ...state,
      trial: {
        ...state.trial,
        ...action.payload,
      },
    }),
    resetTrial: (state) => ({
      ...state,
      trial: initialTrial,
    }),
    openTrialDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: true,
      },
    }),
    closeTrialDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: false,
      },
    }),
  },
};

const trialEditingSlice = createSlice(options);
export const selectIsTrialDialogOpen = (state) => state.trialEditing.dialog.open;
export const selectEditingTrial = (state) => state.trialEditing.trial;
export const { openTrialDialog, closeTrialDialog, setEditingTrialField, setEditingTrial, resetTrial } = trialEditingSlice.actions;

export default trialEditingSlice;
