import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, TableRow, TableCell, ClickAwayListener } from '@mui/material';
import { editAnnotationsHeader, selectAnnotationsHeaderById } from '../../../../features/annotationsHeaders';

function HeaderForm({ id, setMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const header = useSelector((state) => selectAnnotationsHeaderById(state.annotationsHeaders, id));
  const [value, setValue] = useState(header?.value || '');
  const editHeader = () => {
    dispatch(editAnnotationsHeader({ id, changes: { value } }));
    setMode(false);
  };

  return (
    <ClickAwayListener onClickAway={editHeader}>
      <TableRow>
        <TableCell width="40%">{header.name}</TableCell>
        <TableCell width="60%">
          <TextField inputProps={{ 'data-testid': 'value' }} label={t('annotationsEditor.headers.header.value')} onChange={(e) => setValue(e.target.value)} value={value} variant="outlined" />
        </TableCell>
      </TableRow>
    </ClickAwayListener>
  );
}

HeaderForm.propTypes = {
  id: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};

export default HeaderForm;
