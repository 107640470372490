import { XMLParser } from 'fast-xml-parser';

const alwaysArray = [
  'sessionlist.session',
  'sessionlist.session.recordinglist.recording',
  'sessionlist.session.recordinglist.recording.userfieldlist.userfield',
  'sessionlist.session.recordinglist.recording.tracklist.track',
  'sessionlist.session.recordinglist.recording.tracklist.track.medialist.media',
  'sessionlist.session.recordinglist.recording.markerlist.marker',
];

const parser = new XMLParser({
  ignoreAttributes: false,
  trimValues: true,
  attributeNamePrefix: '@_',

  // eslint-disable-next-line no-unused-vars
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (alwaysArray.indexOf(jpath) !== -1) return true;
    return false;
  },
});

async function parseSessionFromTxs(XMLdata) {
  if (!XMLdata) return null;
  return parser.parse(XMLdata);
}

export { parseSessionFromTxs };

export default parseSessionFromTxs;
