import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import JobProgressBar from '../JobProgressBar';
import RescheduleJobButton from '../RescheduleJobButton';

dayjs.extend(LocalizedFormat);

const rowStructure = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  last_updated: PropTypes.string.isRequired,
  original_file_name: PropTypes.string,
  request_reference: PropTypes.string.isRequired,
  progress: PropTypes.number,
  children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
});

function SubRow({ row, selectedRow, onSelectRow }) {
  const { t } = useTranslation();

  return (
    <TableRow key={row.id} hover sx={{ cursor: 'pointer' }} onClick={() => onSelectRow(row)} selected={selectedRow?.id === row.id}>
      <TableCell />
      <TableCell sx={{ pl: '25px' }}>{t(`jobs.status.value.${row.status}`)}</TableCell>
      <TableCell>{row.created ? dayjs(row.created).format('L LT') : ''}</TableCell>
      <TableCell>{row.last_updated ? dayjs(row.last_updated).format('L LT') : ''}</TableCell>
      <TableCell>{row.request_reference}</TableCell>
      <TableCell>{row.original_file_name}</TableCell>
      <TableCell>
        <JobProgressBar progress={row.progress} />
      </TableCell>
      <TableCell>{row.status === 'failed' && <RescheduleJobButton job={row} />}</TableCell>
    </TableRow>
  );
}

SubRow.defaultProps = {
  selectedRow: null,
};

SubRow.propTypes = {
  row: rowStructure.isRequired,
  selectedRow: rowStructure,
  onSelectRow: PropTypes.func.isRequired,
};

export default SubRow;
