import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { LocalizationProvider, itIT as datetimeIT, enUS as datetimeEN } from '@mui/x-date-pickers';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS, itIT } from '@mui/material/locale';
import App from './app/App';
import config from './app/config';
import Spinner from './components/Spinner';
import store, { persistor } from './app/store';
import theme from './app/theme';
import { AudioProvider } from './contexts/audio';
import './assets/scss/index.scss';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import reportWebVitals from './reportWebVitals';
import { initDB } from './app/indexedDbConfig';

dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);

function Index() {
  const locales = { enUS, itIT };
  const dateTimeLocales = { itIT: datetimeIT.components.MuiLocalizationProvider.defaultProps.localeText, enUS: datetimeEN.components.MuiLocalizationProvider.defaultProps.localeText };
  const { i18n } = useTranslation();

  const i18nextLocaleId = React.useMemo(() => i18n.resolvedLanguage || 'en-US', [i18n.resolvedLanguage]);
  const dayjsLocaleId = React.useMemo(() => i18nextLocaleId.split('-')[0], [i18nextLocaleId]);
  const muiLocaleId = React.useMemo(() => i18nextLocaleId.replace('-', ''), [i18nextLocaleId]);
  const muiDateTimeLocale = React.useMemo(() => dateTimeLocales[i18nextLocaleId.replace('-', '')], [i18nextLocaleId]);

  React.useEffect(() => {
    dayjs.locale(dayjsLocaleId);
  }, [dayjsLocaleId]);

  // Add locale to muitheme for text localization
  const themeWithLocale = React.useMemo(() => createTheme(theme, locales[muiLocaleId]), [muiLocaleId, theme]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (config.debug) {
      /* eslint-disable no-console */
      console.log('i18n:', i18n);
      console.log('i18nextLocaleId:', i18nextLocaleId, 'dayjsLocaleId:', dayjsLocaleId, 'muiLocaleId:', muiLocaleId);
      console.log('Current dayjs locale:', dayjs.locale());
      console.log('themeWithLocale', themeWithLocale);
      console.log('muiDateTimeLocale', muiDateTimeLocale);
      /* eslint-enable no-console */
    }
  }, [i18n, i18nextLocaleId, dayjsLocaleId, muiLocaleId, themeWithLocale, muiDateTimeLocale]);

  return (
    <React.StrictMode>
      <React.Suspense fallback={<Spinner />}>
        <Provider store={store}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <ThemeProvider theme={themeWithLocale}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocaleId} localeText={muiDateTimeLocale}>
                <HashRouter>
                  <AudioProvider>
                    <App />
                  </AudioProvider>
                </HashRouter>
              </LocalizationProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>
    </React.StrictMode>
  );
}

async function initializeApp() {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  try {
    const i18nextPromise = i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(Backend)
      .init({
        backend: {
          loadPath: `${import.meta.env.BASE_URL}static/locales/{{lng}}/{{ns}}.json`,
        },
        debug: config.debug,
        fallbackLng: {
          it: ['it-IT'],
          default: ['en-US'],
        },
      });

    const initDBPromise = initDB();

    await Promise.all([i18nextPromise, initDBPromise]);
  } catch (error) {
    console.error(error);
  }
  root.render(<Index />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

initializeApp();

export default Index;
