import { dateTimeToReduxFormat } from './dateTimeHandlers';

function formatJudge(proceedingJudge, trialJudge) {
  if (proceedingJudge) return proceedingJudge;
  if (trialJudge) return trialJudge;
  return '';
}

function formatTrialToProceedingSlice(trial, defaultPP = [], defaultRegistrar = '') {
  return {
    trialId: trial.id,
    ticketId: '',
    proceedingId: '',
    dateStart: trial.data_ora_inizio_udienza ? dateTimeToReduxFormat(trial.data_ora_inizio_udienza, 'dateTime') : '',
    timeStart: trial.data_ora_inizio_udienza ? dateTimeToReduxFormat(trial.data_ora_inizio_udienza, 'dateTime') : '',
    timeEnd: '',
    report: '',
    RG: '',
    RGNR: '',
    sectionId: trial.id_sezione || '',
    courtId: trial.id_tribunale || '',
    riteTypeId: trial.id_tipo_rito || '',
    defendant: '',
    defendantsList: [],
    judge: { id: formatJudge(null, trial.id_giudice_presidente), name: formatJudge(null, trial.giudice_presidente) },
    judge1: { id: formatJudge(null, trial.id_giudice_1), name: formatJudge(null, trial.giudice_1) },
    judge2: { id: formatJudge(null, trial.id_giudice_2), name: formatJudge(null, trial.giudice_2) },
    judge3: { id: formatJudge(null, trial.id_giudice_3), name: formatJudge(null, trial.giudice_3) },
    PM: defaultPP || [],
    registrar: defaultRegistrar || '',
    outcomeId: '',
    outcome: '',
    outcomeDate: '',
    outcomeNotes: '',
    pdfDeliveryDate: '',
    paperDeliveryDate: '',
    note: '',
  };
}

function formatProceedingToProceedingSlice(proceeding, trial, defaultPP = [], defaultRegistrar = '') {
  return {
    trialId: trial.id,
    ticketId: '',
    proceedingId: proceeding.id,
    dateStart: proceeding.timeStart || '',
    timeStart: proceeding.timeStart || '',
    timeEnd: proceeding.timeEnd || '',
    report: proceeding.report,
    RG: proceeding.RG,
    RGNR: proceeding.RGNR,
    sectionId: trial.id_sezione,
    courtId: trial.id_tribunale,
    riteTypeId: trial.id_tipo_rito || '',
    defendant: proceeding.defendant,
    defendantsList: proceeding.defendantsList,
    judge: { id: formatJudge(proceeding.judge?.id, trial.id_giudice_presidente), name: formatJudge(proceeding.judge?.name, trial.giudice_presidente) },
    judge1: { id: formatJudge(proceeding.judge1?.id, trial.id_giudice_1), name: formatJudge(proceeding.judge1?.name, trial.giudice_1) },
    judge2: { id: formatJudge(proceeding.judge2?.id, trial.id_giudice_2), name: formatJudge(proceeding.judge2?.name, trial.giudice_2) },
    judge3: { id: formatJudge(proceeding.judge3?.id, trial.id_giudice_3), name: formatJudge(proceeding.judge3?.name, trial.giudice_3) },
    PM: proceeding.PM.length !== 0 ? proceeding.PM : defaultPP,
    registrar: proceeding.registrar ? proceeding.registrar : defaultRegistrar,
    outcomeId: proceeding.outcomeId,
    outcome: proceeding.outcome,
    outcomeDate: proceeding.outcomeDate || '',
    outcomeNotes: proceeding.outcomeNotes || '',
    pdfDeliveryDate: proceeding.pdfDeliveryDate || '',
    paperDeliveryDate: proceeding.paperDeliveryDate || '',
    note: proceeding.note || '',
  };
}

function formatEditingProceedingToProceedingSlice(proceeding) {
  return {
    trialId: proceeding.id_udienza,
    proceedingId: proceeding.id_procedimento,
    ticketId: proceeding.id_ticket,
    status: proceeding.stato_procedimento,
    dateStart: dateTimeToReduxFormat(proceeding.data_ora_inizio_udienza, 'dateTime'),
    timeStart: dateTimeToReduxFormat(proceeding.ora_inizio, 'time', proceeding.data_ora_inizio_udienza),
    timeEnd: dateTimeToReduxFormat(proceeding.ora_fine, 'time', proceeding.data_ora_inizio_udienza),
    report: proceeding.flag_verbale,
    RG: proceeding.RG,
    RGNR: proceeding.RGNR,
    sectionId: proceeding.id_sezione,
    courtId: proceeding.id_tribunale,
    riteTypeId: proceeding.id_tipo_rito || '',
    defendant: proceeding.imputato,
    defendantsList: [],
    judge: { id: formatJudge(proceeding.id_giudice_presidente, null), name: formatJudge(proceeding.giudice_presidente, null) },
    judge1: { id: formatJudge(proceeding.id_giudice_1, null), name: formatJudge(proceeding.giudice_1, null) },
    judge2: { id: formatJudge(proceeding.id_giudice_2, null), name: formatJudge(proceeding.giudice_2, null) },
    judge3: { id: formatJudge(proceeding.id_giudice_3, null), name: formatJudge(proceeding.giudice_3, null) },
    PM: proceeding.pms || [],
    registrar: proceeding.cancelliere || '',
    outcomeId: proceeding.id_esito,
    outcome: proceeding.esito,
    outcomeDate: dateTimeToReduxFormat(proceeding.data_ora_rinvio, 'dateTime'),
    outcomeNotes: proceeding.esito_altro,
    pdfDeliveryDate: proceeding.scadenza_portale_definitivo ? dateTimeToReduxFormat(proceeding.scadenza_portale_definitivo, 'dateTime') : '',
    paperDeliveryDate: proceeding.scadenza_cartaceo ? dateTimeToReduxFormat(proceeding.scadenza_cartaceo, 'dateTime') : '',
    note: proceeding.note_procedimento || '',
  };
}

export { formatProceedingToProceedingSlice, formatEditingProceedingToProceedingSlice, formatTrialToProceedingSlice };
export default formatEditingProceedingToProceedingSlice;
