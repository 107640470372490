import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { selectProceedingFormStagedRecordings } from '../features/proceedingFormSlice';
import ProceedingStagedTrack from './ProceedingStagedTrack';

function ProceedingStagedTracks() {
  const proceedingStagedRecordings = useSelector(selectProceedingFormStagedRecordings);

  if (proceedingStagedRecordings.length > 0)
    return (
      <Stack spacing={1} direction="column">
        {proceedingStagedRecordings?.map((recording) => (
          <ProceedingStagedTrack key={recording.fileId} trackId={recording.fileId} />
        ))}
      </Stack>
    );
}

export default ProceedingStagedTracks;
