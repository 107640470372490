import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const recordingsAdapter = createEntityAdapter();

const recordingsSlice = createSlice({
  name: 'recordings',
  initialState: recordingsAdapter.getInitialState(),
  reducers: {
    loadRecordings: recordingsAdapter.setMany,
    editRecording: recordingsAdapter.updateOne,
    removeRecordings: recordingsAdapter.removeAll,
    removeRecordingsByIds: recordingsAdapter.removeMany,
  },
});

export const { selectById: selectRecordingById, selectIds: selectRecordingIds, selectEntities: selectRecordingEntities, selectAll: selectAllRecordings, selectTotal: selectTotalRecordings } = recordingsAdapter.getSelectors();

export const { loadRecordings, editRecording, removeRecordings, removeRecordingsByIds } = recordingsSlice.actions;

export default recordingsSlice;
