function compareByBranoSubBranoAsc(a, b) {
  if (a.BranoId > b.BranoId) return 1;
  if (a.BranoId < b.BranoId) return -1;
  if (a.SubBranoId > b.SubBranoId) return 1;
  if (a.SubBranoId < b.SubBranoId) return -1;
  if (a.Traccia > b.Traccia) return 1;
  if (a.Traccia < b.Traccia) return -1;
  return 0;
}

function compareByBranoSubBranoDesc(a, b) {
  if (a.BranoId > b.BranoId) return -1;
  if (a.BranoId < b.BranoId) return 1;
  if (a.SubBranoId > b.SubBranoId) return -1;
  if (a.SubBranoId < b.SubBranoId) return 1;
  if (a.Traccia > b.Traccia) return -1;
  if (a.Traccia < b.Traccia) return 1;
  return 0;
}

export { compareByBranoSubBranoAsc, compareByBranoSubBranoDesc };
