import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Edit as EditIcon, MoreVert as MoreVertIcon, DirectionsWalk as DirectionsWalkIcon, Gavel, Album } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useProceedingResults from '../../hooks/useProceedingResults';
import { selectSidebar } from '../../features/layoutSlice';
import TooltipMenu from '../TooltipMenu';
import { openTrialDialog, setEditingTrial } from '../../features/trialEditingSlice';
import formatTrialToTrialEditingSlice from '../../utils/formatTrialToTrialEditingSlice';
import ProceedingsWithReportBadge from '../ProceedingsWithReportBadge';

function Sidebar({ navBarHeight, drawerWidth, drawerHeaderHeight }) {
  const isSidebarOpen = useSelector(selectSidebar);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trial, hasMissingFields, proceedingsCount, proceedingsWithVerbalFlagCount, isFetching } = useProceedingResults();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const textSkeleton = <Skeleton variant="text" sx={{ fontSize: '14px', width: '100%' }} />;
  const skeletonListItems = [...Array(3)].map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <ListItem key={index} sx={{ height: drawerHeaderHeight / 3 }}>
      <ListItemText primary={textSkeleton} />
    </ListItem>
  ));

  React.useEffect(() => {
    if (trial?.id_ticket && trial?.stato_udienza && hasMissingFields) {
      dispatch(openTrialDialog());
      dispatch(setEditingTrial(formatTrialToTrialEditingSlice(trial)));
    }
  }, [trial, hasMissingFields, dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    dispatch(openTrialDialog());
    dispatch(setEditingTrial(formatTrialToTrialEditingSlice(trial)));
    handleClose();
  };

  const countColor = (count, totalCount) => {
    if (Number(count) === Number(totalCount)) return 'white';
    if (Number(count) > Number(totalCount)) return 'error.main';
    return 'grey.500';
  };
  const documentActionsMenuItems = [
    {
      fileType: trial && trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      actionType: 'print',
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial?.id || null,
      workAwayFlag: trial?.trasferta || '',
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.printWithdrawalCertificate') : t('trials.trialsList.trial.buttons.printAttendanceCertificate'),
    },
    {
      documentId: trial?.upload_attestato_presenza || '',
      fileType: 'p',
      asMenuItem: true,
      actionType: 'print',
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.printCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.printCompiledAttendanceCertificate'),
    },
    {
      fileType: trial && trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      actionType: 'download',
      ticketId: trial?.id_ticket || '',
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial?.id || null,
      workAwayFlag: trial?.trasferta || '',
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.downloadWithdrawalCertificate') : t('trials.trialsList.trial.buttons.downloadAttendanceCertificate'),
    },
    {
      documentId: trial?.upload_attestato_presenza || '',
      fileType: 'p',
      actionType: 'download',
      ticketId: trial?.id_ticket || null,
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.downloadCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.downloadCompiledAttendanceCertificate'),
    },
    {
      fileType: trial && trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      actionType: 'open',
      ticketId: trial?.id_ticket || '',
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial?.id || null,
      workAwayFlag: trial?.trasferta || '',
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.openWithdrawalCertificate') : t('trials.trialsList.trial.buttons.openAttendanceCertificate'),
    },
    {
      documentId: trial?.upload_attestato_presenza || '',
      fileType: 'p',
      actionType: 'open',
      ticketId: trial?.id_ticket || '',
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: trial && trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.openCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.openCompiledAttendanceCertificate'),
    },
  ];
  const othersActionsMenuItems = [{ onClick: handleEdit, icon: <EditIcon fontSize="small" />, label: t('common.actions.edit') }];

  return (
    <Drawer
      id="Sidebar"
      data-testid="Sidebar"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflow: 'hidden',
        },
      }}
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      PaperProps={{
        'data-testid': 'SidebarPaper',
      }}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <Stack
        id="SidebarHeader"
        data-testid="SidebarHeader"
        direction="column"
        justifyContent="space-between"
        sx={{ height: drawerHeaderHeight, width: '100%', position: 'absolute', top: 65, left: 0, px: 2, py: 1, backgroundColor: 'secondary.main', color: 'secondary.contrastText', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height={drawerHeaderHeight / 3}>
          {/* ID TICKET */}
          <Tooltip title={t('trials.trialsList.trial.ticketId')} width="40%">
            <Typography variant="body2">{isFetching ? textSkeleton : trial?.id_ticket}</Typography>
          </Tooltip>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} width="50%">
            {/* COUNT PROCEDIMENTI CARICATI */}
            <Tooltip title={t('trials.trialsList.trial.uploadedProceedingsCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsCount, trial?.n_procedimenti) }} component="span" variant="caption">
                      {proceedingsCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {trial?.n_procedimenti || 0}
                    </Typography>
                  </Box>
                }
              >
                <Gavel color="action" />
              </Badge>
            </Tooltip>
            {/* COUNT PROCEDIMENTI CARICATI DA VERBALIZZARE */}
            <Tooltip title={t('trials.trialsList.trial.proceedingsToBeTranscribedCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsWithVerbalFlagCount, trial?.n_procedimenti) }} component="span" variant="caption">
                      {proceedingsWithVerbalFlagCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {trial?.n_verbali || 0}
                    </Typography>
                  </Box>
                }
              >
                <ProceedingsWithReportBadge />
              </Badge>
            </Tooltip>
            {/* NUMERO CD/DVD */}
            <Tooltip title={t('trials.trialsList.trial.cdNumber')}>
              <Badge showZero badgeContent={trial?.n_supporti || 0}>
                <Album color="action" />
              </Badge>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width="100%" height={drawerHeaderHeight / 3}>
          {/* DATA UDIENZA */}
          <Tooltip title={t('trials.trialsList.trial.date')} sx={{ flexGrow: 1 }}>
            <Typography variant="body2">{isFetching ? textSkeleton : trial?.data_ora_inizio_udienza && dayjs(trial.data_ora_inizio_udienza).format('L')}</Typography>
          </Tooltip>
          {/* ORARIO INIZIO UDIENZA */}
          <Tooltip title={t('trials.trialsList.trial.timeStart')} sx={{ flexGrow: 1 }}>
            <Typography variant="body2">{isFetching ? textSkeleton : trial?.data_ora_inizio_udienza && dayjs(trial.data_ora_inizio_udienza).format('LT')}</Typography>
          </Tooltip>
          {/* ORARIO FINE UDIENZA */}
          <Tooltip title={t('trials.trialsList.trial.timeEnd')} sx={{ flexGrow: 1 }}>
            <Typography variant="body2">{isFetching ? textSkeleton : trial?.data_ora_fine_udienza && dayjs(trial.data_ora_fine_udienza).format('LT')}</Typography>
          </Tooltip>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width="100%" height={drawerHeaderHeight / 3}>
          {/* ORARIO ARRIVO OPERATORE */}
          <Tooltip title={t('trials.trialsList.trial.operatorArrivalTime')} width="40%">
            <Stack direction="row" alignItems="center">
              <DirectionsWalkIcon fontSize="small" />
              {isFetching ? (
                textSkeleton
              ) : (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {trial?.ora_arrivo_operatore ? dayjs(trial.ora_arrivo_operatore, 'HH:mm:ss').format('LT') : '-'}
                </Typography>
              )}
            </Stack>
          </Tooltip>
          {/* STATO */}
          <Tooltip title={t('trials.trialsList.trial.status')} width="40%" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">{isFetching ? textSkeleton : trial?.stato_udienza && trial.stato_udienza}</Typography>
          </Tooltip>
          <Tooltip>
            <span>
              <IconButton size="small" onClick={handleClick}>
                <MoreVertIcon fontSize="small" sx={{ color: 'secondary.contrastText' }} />
              </IconButton>
            </span>
          </Tooltip>
          <TooltipMenu documentActionItems={documentActionsMenuItems} otherItems={othersActionsMenuItems} anchorEl={anchorEl} handleClose={handleClose} />
        </Stack>
      </Stack>
      <Box id="SidebarBody" data-testid="SidebarBody" sx={{ height: `calc(100vh - ${drawerHeaderHeight + navBarHeight}px)`, width: '100%', position: 'absolute', top: drawerHeaderHeight + navBarHeight, left: 0, overflow: 'auto', py: 3 }}>
        <List disablePadding>
          {/* TRIBUNALE */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.court')} />
          </ListItem>
          {isFetching
            ? skeletonListItems
            : trial?.tribunale && (
                <ListItem sx={{ py: 0 }}>
                  <Tooltip title={t('trials.trialsList.trial.court')}>
                    <ListItemText secondary={isFetching ? textSkeleton : trial.tribunale} />
                  </Tooltip>
                </ListItem>
              )}
          {/* SEZIONE */}
          {trial?.sezione && (
            <ListItem sx={{ py: 0 }}>
              <Tooltip title={t('trials.trialsList.trial.section')}>
                <ListItemText secondary={isFetching ? textSkeleton : trial.sezione} />
              </Tooltip>
            </ListItem>
          )}
          {/* AULA */}
          {trial?.aula && (
            <ListItem sx={{ py: 0 }}>
              <Tooltip title={t('trials.trialsList.trial.room')}>
                <ListItemText secondary={isFetching ? textSkeleton : trial.aula} />
              </Tooltip>
            </ListItem>
          )}
          {/* RITO */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.rite')} />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary={isFetching ? textSkeleton : trial?.rito || '-'} />
          </ListItem>
          {/* GIUDICI */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.judges')} />
          </ListItem>
          {trial?.giudice_presidente && (
            // GIUDICE PRESIDENTE
            <ListItem sx={{ py: 0 }}>
              <Tooltip title={t('trials.trialsList.trial.presidentJudge')}>
                <ListItemText secondary={isFetching ? textSkeleton : trial.giudice_presidente} />
              </Tooltip>
            </ListItem>
          )}
          {trial?.giudice_1 && (
            // GIUDICE 1
            <ListItem sx={{ py: 0 }}>
              <ListItemText secondary={isFetching ? textSkeleton : trial.giudice_1} />
            </ListItem>
          )}
          {trial?.giudice_2 && (
            // GIUDICE 2
            <ListItem sx={{ py: 0 }}>
              <ListItemText secondary={isFetching ? textSkeleton : trial.giudice_2} />
            </ListItem>
          )}
          {trial?.giudice_3 && (
            // GIUDICE 3
            <ListItem sx={{ py: 0 }}>
              <ListItemText secondary={isFetching ? textSkeleton : trial.giudice_3} />
            </ListItem>
          )}
          <Divider sx={{ my: 3 }} />
          {/* SERVIZIO */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.service')} secondary={isFetching ? textSkeleton : trial?.servizio || '-'} />
          </ListItem>
          {/* TRASFERTA */}
          {/* eslint-disable */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.workAway.label')} secondary={isFetching ? textSkeleton : trial?.trasferta === '1' ? t('trials.trialsList.trial.workAway.values.yes') : t('trials.trialsList.trial.workAway.values.no')} />
          </ListItem>
          {/* DATA/ORA RICHIESTA */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.requestDateTime')} secondary={isFetching ? textSkeleton : trial?.data_ora_richiesta ? dayjs(trial.data_ora_richiesta).format('L LT') : '-'} />
          </ListItem>
          {/* eslint-enable */}
          {/* SEZIONE RICHIEDENTE */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.requestSection')} secondary={isFetching ? textSkeleton : trial?.sezione_richiedente || '-'} />
          </ListItem>
          {/* TRIBUNALE RICHIEDENTE */}
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary={t('trials.trialsList.trial.requestCourt')} secondary={isFetching ? textSkeleton : trial?.tribunale_richiedente || '-'} />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

Sidebar.defaultProps = {
  drawerHeaderHeight: 150,
  drawerWidth: 350,
};

Sidebar.propTypes = {
  navBarHeight: PropTypes.number.isRequired,
  drawerHeaderHeight: PropTypes.number,
  drawerWidth: PropTypes.number,
};

export default Sidebar;
