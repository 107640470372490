/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["ToChange$"] }] */
import { v4 as uuidv4 } from 'uuid';
import { dateAddSeconds, dateOffset } from './markers/time';
import { hashObjectByKeysIdentifier } from './markers/utils';

function fixDates(objToChange, offset, timezone, keys = ['startDate', 'time', 'endDate']) {
  keys.forEach((key) => {
    if (key in objToChange) {
      const date = dateAddSeconds(objToChange[key], offset, timezone);
      if (date !== 'Invalid Date') {
        objToChange[key] = date;
      }
    }
  });
}

function cdAddTimeOffset(annotationsToChange, offset) {
  if (!annotationsToChange?.information?.timezone) return annotationsToChange;
  const { information, recordings } = annotationsToChange;
  const { timezone } = annotationsToChange.information;
  fixDates(information, offset, timezone);
  recordings?.forEach((recording) => {
    fixDates(recording, offset, timezone);
    recording.turns?.forEach((turn) => {
      fixDates(turn, offset, timezone);
    });
    recording.markers?.forEach((marker) => {
      fixDates(marker, offset, timezone);
    });
  });
  return annotationsToChange;
}

function cdMoveStartDateTo(annotationsToChange, targetDateString) {
  if (!annotationsToChange?.information?.timezone || !annotationsToChange?.information?.startDate) return annotationsToChange;
  const offset = dateOffset(annotationsToChange?.information?.startDate, targetDateString);
  const { information, recordings } = annotationsToChange;
  const { timezone } = annotationsToChange.information;
  fixDates(information, offset, timezone);
  recordings?.forEach((recording) => {
    fixDates(recording, offset, timezone);
    recording.turns?.forEach((turn) => {
      fixDates(turn, offset, timezone);
    });
    recording.markers?.forEach((marker) => {
      fixDates(marker, offset, timezone);
    });
  });
  return annotationsToChange;
}

async function computeInformationId(information) {
  const timeId = typeof information?.startDate !== 'undefined' ? information.startDate : uuidv4();
  return `${information?.type || 'UNKNOWN'}|${information?.recorder || 'UNKNOWN'}|${timeId}`;
}

async function computeRecordingId(recording, informationId, position) {
  return `${informationId}|U|${position}`;
}

async function computeTurnId(turn, recordingId) {
  return `${recordingId}|${await hashObjectByKeysIdentifier(turn, ['time', 'trackName'])}`;
}

async function computeMarkerId(marker, recordingId) {
  return `${recordingId}|${await hashObjectByKeysIdentifier(marker, ['time', 'phase', 'note'])}`;
}

async function cdAddMissingIds(annotationsToChange, targetDateString) {
  const { information, recordings } = annotationsToChange;
  const informationId = typeof information?.id !== 'undefined' ? information.id : await computeInformationId(information);

  await Promise.all(
    recordings?.map(async (recordingToChange, position) => {
      if (typeof recordingToChange?.id === 'undefined') recordingToChange.id = await computeRecordingId(recordingToChange, informationId, position);
      const turnPromises = Promise.all(
        recordingToChange.turns?.map(async (turnToChange) => {
          if (!('id' in turnToChange)) turnToChange.id = await computeTurnId(turnToChange, recordingToChange.id);
        }) || []
      );
      const markerPromises = Promise.all(
        recordingToChange.markers?.map(async (markerToChange) => {
          if (!('id' in markerToChange)) markerToChange.id = await computeMarkerId(markerToChange, recordingToChange.id);
        }) || []
      );
      await turnPromises;
      await markerPromises;
    }) || []
  );
  try {
    annotationsToChange.information.id = informationId;
  } catch {
    /* empty */
  }
  return annotationsToChange;
}

export { cdAddTimeOffset, cdMoveStartDateTo, cdAddMissingIds, computeInformationId, computeRecordingId, computeMarkerId, computeTurnId };

export default cdMoveStartDateTo;
