import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useLogout from '../../hooks/useLogout';

function LogoutDialog() {
  const { t } = useTranslation();
  const { isDialogOpen, handleLogout, handleTDSLogout } = useLogout();

  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle>{t('auth.logout.dialog.title')}</DialogTitle>
      <DialogContent>{t('auth.logout.dialog.message')}</DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} color="primary">
          {t('auth.logout.dialog.retryButton')}
        </Button>
        <Button onClick={handleTDSLogout} color="primary" autoFocus>
          {t('auth.logout.dialog.forceButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutDialog;
