import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Badge, Card, CardActions, CardContent, CardHeader, Grid, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { ExpandMore, ExpandLess, FileOpen as FileOpenIcon, Edit, Article, HeadsetMic } from '@mui/icons-material';
import { formatEditingProceedingToProceedingSlice } from '../utils/formatProceedingToProceedingSlice';
import TooltipMenu from './TooltipMenu';
import { openSingleProceedingDialog } from '../features/singleProceedingSlice';
import { resetProceedingFormSlice, setProceeding, setProceedingFormStagedOtherFiles, setProceedingFormStagedRecordings } from '../features/proceedingFormSlice';

dayjs.extend(LocalizedFormat);

function ProceedingResultCard({ proceeding, hasSensitiveDataAccess, workAwayFlag }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [detailsExpanded, setDetailsExpanded] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listItemSx = {
    display: 'block',
    py: 0,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openDialog = () => {
    dispatch(resetProceedingFormSlice());
    const formattedProceeding = formatEditingProceedingToProceedingSlice(proceeding);
    dispatch(setProceeding(formattedProceeding));
    dispatch(setProceedingFormStagedRecordings(proceeding.upload_audio?.filter((file) => file.isMp3) || []));
    dispatch(setProceedingFormStagedOtherFiles(proceeding.upload_audio?.filter((file) => !file.isMp3) || []));
    dispatch(openSingleProceedingDialog());
  };

  const outcomeFormattedDateTime = proceeding.esito?.includes('RINVI') && proceeding.data_ora_rinvio ? dayjs(proceeding.data_ora_rinvio).format('L LT') : '';
  const outcomeNotes = proceeding.esito?.includes('ALTRO') && proceeding.esito_altro ? proceeding.esito_altro : '';
  const recordingsCount = proceeding.upload_audio.filter((audio) => audio.isMp3).length;
  const otherFilesCount = proceeding.upload_audio.filter((audio) => !audio.isMp3).length;
  const timeDuration = proceeding.ora_fine && proceeding.ora_inizio ? dayjs(proceeding.ora_fine, 'HH:mm').diff(dayjs(proceeding.ora_inizio, 'HH:mm'), 'minutes') : '-';
  const audioDuration = proceeding.durata_secondi && proceeding.durata_secondi !== '' && proceeding.durata_secondi !== null && proceeding.durata_secondi !== 'null' ? proceeding.durata_secondi : '-';
  const reportText =
    proceeding.flag_verbale && `${t('proceedings.proceedingsList.proceeding.verbalFlag.label')} ${proceeding.flag_verbale === '1' ? t('proceedings.form.addProceeding.fields.report.values.yes') : t('proceedings.form.addProceeding.fields.report.values.no')}`;
  const documentActionsMenuItems = [
    { documentId: proceeding.upload_pdf_firmato, fileType: 'pdf', actionType: 'print', asMenuItem: true, label: t('proceedings.proceedingsList.proceeding.buttons.printSignedReport') },
    {
      fileType: 'AC',
      actionType: 'print',
      ticketId: proceeding.id_ticket,
      asMenuItem: true,
      endpoint: 'certificate',
      proceedingId: proceeding.id_procedimento,
      workAwayFlag,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.printDeliveryCertificate'),
    },
    {
      documentId: proceeding.upload_attestato_consegna,
      fileType: 'pdf',
      actionType: 'print',
      ticketId: proceeding.id_ticket,
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.printSignedDeliveryCertificate'),
    },
    { documentId: proceeding.upload_pdf_firmato, fileType: 'ac', actionType: 'download', ticketId: proceeding.id_ticket, asMenuItem: true, actionSuccessCallback: handleClose, label: t('proceedings.proceedingsList.proceeding.buttons.downloadSignedReport') },
    {
      proceedingId: proceeding.id_procedimento,
      ticketId: proceeding.id_ticket,
      actionType: 'download',
      fileType: 'AC',
      asMenuItem: true,
      endpoint: 'certificate',
      workAwayFlag,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.downloadDeliveryCertificate'),
    },
    {
      documentId: proceeding.upload_attestato_consegna,
      ticketId: proceeding.id_ticket,
      actionType: 'download',
      fileType: 'ac',
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.downloadSignedDeliveryCertificate'),
    },
    { documentId: proceeding.upload_pdf_firmato, ticketId: proceeding.id_ticket, actionType: 'open', fileType: 'pdf', asMenuItem: true, actionSuccessCallback: handleClose, label: t('proceedings.proceedingsList.proceeding.buttons.openSignedReport') },
    {
      proceedingId: proceeding.id_procedimento,
      ticketId: proceeding.id_ticket,
      actionType: 'open',
      fileType: 'AC',
      asMenuItem: true,
      endpoint: 'certificate',
      workAwayFlag,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.openDeliveryCertificate'),
    },
    {
      documentId: proceeding.upload_attestato_consegna,
      ticketId: proceeding.id_ticket,
      actionType: 'open',
      fileType: 'ac',
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.openSignedDeliveryCertificate'),
    },
  ];

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{ padding: 0.5, textTransform: 'uppercase', backgroundColor: 'primary.light', color: 'primary.contrastText' }}
        title={
          <List sx={{ display: 'flex', py: 0, justifyContent: 'space-between' }}>
            {/* ID TICKET */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.ticketId')} placement="bottom-start">
                <ListItemText primary={proceeding.id_ticket} />
              </Tooltip>
            </ListItem>
            {/* STATUS */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.status')} placement="bottom-start">
                <ListItemText primary={proceeding.stato_procedimento} />
              </Tooltip>
            </ListItem>
            {/* DATE */}
            {proceeding.data_ora_inizio_udienza && (
              <ListItem sx={listItemSx}>
                <Tooltip title={t('proceedings.proceedingsList.proceeding.date')} placement="bottom-start">
                  <ListItemText primary={dayjs(proceeding.data_ora_inizio_udienza).format('L')} />
                </Tooltip>
              </ListItem>
            )}
            {/* TIME START */}
            {proceeding.ora_inizio && (
              <ListItem sx={listItemSx}>
                <Tooltip title={t('proceedings.proceedingsList.proceeding.timeStart')} placement="bottom-start">
                  <ListItemText primary={proceeding.ora_inizio && dayjs(proceeding.ora_inizio, 'HH:mm:ss').format('LT')} />
                </Tooltip>
              </ListItem>
            )}
            {/* TIME END */}
            {proceeding.ora_fine && (
              <ListItem sx={listItemSx}>
                <Tooltip title={t('proceedings.proceedingsList.proceeding.timeEnd')} placement="bottom-start">
                  <ListItemText primary={proceeding.ora_fine && dayjs(proceeding.ora_fine, 'HH:mm:ss').format('LT')} />
                </Tooltip>
              </ListItem>
            )}
            {/* DURATION */}
            <ListItem sx={listItemSx}>
              <ListItemText
                primary={
                  <>
                    <Tooltip title={t('proceedings.proceedingsList.proceeding.audioDuration')} placement="bottom-start">
                      <Typography component="span">{audioDuration}</Typography>
                    </Tooltip>
                    <Typography component="span" px={0.5}>
                      /
                    </Typography>
                    <Tooltip title={t('proceedings.proceedingsList.proceeding.duration')} placement="bottom-start">
                      <Typography component="span">{timeDuration}</Typography>
                    </Tooltip>
                  </>
                }
              />
            </ListItem>
            {/* VERBAL FLAG */}
            {proceeding.flag_verbale && (
              <ListItem sx={listItemSx}>
                <Tooltip title={proceeding.flag_verbale === '1' ? t('proceedings.proceedingsList.proceeding.verbalFlag.values.yes') : t('proceedings.proceedingsList.proceeding.verbalFlag.values.no')} placement="bottom-start">
                  <ListItemText primary={reportText} />
                </Tooltip>
              </ListItem>
            )}
            <ListItem sx={listItemSx}>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                    {/* RECORDINGS */}
                    <Tooltip title={t('proceedings.proceedingsList.proceeding.recordingsCount')}>
                      <Badge showZero badgeContent={recordingsCount}>
                        <HeadsetMic color="action" />
                      </Badge>
                    </Tooltip>
                    {/* OTHER FILES */}
                    <Tooltip title={t('proceedings.proceedingsList.proceeding.otherFilesCount')}>
                      <Badge showZero badgeContent={otherFilesCount}>
                        <Article color="action" />
                      </Badge>
                    </Tooltip>
                  </Stack>
                }
              />
            </ListItem>
          </List>
        }
      />
      {detailsExpanded && (
        <CardContent sx={{ padding: 0.5 }}>
          <List sx={{ display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
            <ListItem sx={listItemSx}>
              {/* COURT */}
              {proceeding.tribunale && (
                <Tooltip title={t('proceedings.proceedingsList.proceeding.court')} placement="bottom-start">
                  <ListItemText primary={proceeding.tribunale} />
                </Tooltip>
              )}
              {/* SECTION */}
              {proceeding.sezione && (
                <Tooltip title={t('proceedings.proceedingsList.proceeding.section')} placement="bottom-start">
                  <ListItemText secondary={proceeding.sezione} />
                </Tooltip>
              )}
              {/* ROOM */}
              {proceeding.aula && (
                <Tooltip title={t('proceedings.proceedingsList.proceeding.room')} placement="bottom-start">
                  <ListItemText secondary={proceeding.aula} />
                </Tooltip>
              )}
            </ListItem>
            <ListItem sx={listItemSx}>
              {/* PRESIDENT JUDGE */}
              {proceeding.giudice_presidente && (
                <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.presidentJudge')}>
                  <ListItemText primary={proceeding.giudice_presidente} />
                </Tooltip>
              )}
              {/* OTHER JUDGE 1 */}
              {proceeding.giudice_1 && (
                <Tooltip title={t('proceedings.form.addProceeding.fields.honoraryJudge')} placement="bottom-start">
                  <ListItemText secondary={proceeding.giudice_1} />
                </Tooltip>
              )}
              {/* OTHER JUDGE 2 */}
              {proceeding.giudice_2 && (
                <Tooltip title={t('proceedings.form.addProceeding.fields.honoraryJudge')} placement="bottom-start">
                  <ListItemText secondary={proceeding.giudice_2} />
                </Tooltip>
              )}
              {/* OTHER JUDGE 3 */}
              {proceeding.giudice_3 && (
                <Tooltip title={t('proceedings.form.addProceeding.fields.honoraryJudge')} placement="bottom-start">
                  <ListItemText secondary={proceeding.giudice_3} />
                </Tooltip>
              )}
            </ListItem>
          </List>
        </CardContent>
      )}
      <CardActions sx={{ padding: 0.5 }} disableSpacing>
        <IconButton onClick={handleClick} size="small" disabled={!hasSensitiveDataAccess}>
          <FileOpenIcon />
        </IconButton>
        <IconButton onClick={openDialog} size="small">
          <Edit />
        </IconButton>
        <TooltipMenu documentActionItems={documentActionsMenuItems} anchorEl={anchorEl} handleClose={handleClose} />
        <Grid px={1} container typography="body2" sx={{ textTransform: 'uppercase', color: 'gray' }} alignItems="flex-start">
          <Grid item xs={1}>
            {/* RG */}
            <Tooltip placement="bottom-start" title={t('proceedings.proceedingsList.proceeding.RG')}>
              <Typography component="span" variant="body2">
                {proceeding.RG || '-'}
              </Typography>
            </Tooltip>
          </Grid>
          {/* RGNR */}
          <Grid item xs={1}>
            <Tooltip placement="bottom-start" title={t('proceedings.proceedingsList.proceeding.RGNR')}>
              <Typography component="span" variant="body2">
                {proceeding.RGNR || '-'}
              </Typography>
            </Tooltip>
          </Grid>
          {/* OUTCOME & OUTCAME DATE */}
          <Grid item xs={2}>
            <Tooltip title={t('proceedings.proceedingsList.proceeding.outcome')} placement="bottom-start">
              <Typography component="span" variant="body2">{`${proceeding.esito} ${outcomeFormattedDateTime} ${outcomeNotes}`}</Typography>
            </Tooltip>
          </Grid>
          {/* DEFENDANT */}
          <Grid item xs={2}>
            <Tooltip placement="bottom-start" title={t('proceedings.proceedingsList.proceeding.defendant')}>
              <Typography component="span" variant="body2">
                {proceeding.imputato || '-'}
              </Typography>
            </Tooltip>
          </Grid>
          {/* CHANCELLOR */}
          <Grid item xs={3}>
            <Tooltip placement="bottom-start" title={t('proceedings.proceedingsList.proceeding.chancellor')}>
              <Typography component="span" variant="body2">
                {proceeding.cancelliere || '-'}
              </Typography>
            </Tooltip>
          </Grid>
          {/* PM */}
          <Grid item xs={3} container direction="column">
            {proceeding.pms?.length > 0
              ? proceeding.pms.map((pm) => (
                  <Grid key={pm.id} item xs>
                    <Tooltip placement="bottom-start" title={t('proceedings.proceedingsList.proceeding.pm')}>
                      <Typography component="span" variant="body2">
                        {pm.name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ))
              : '-'}
          </Grid>
        </Grid>
        <IconButton onClick={() => setDetailsExpanded(!detailsExpanded)} aria-expanded={detailsExpanded} size="small">
          {detailsExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </CardActions>
    </Card>
  );
}

ProceedingResultCard.defaultProps = {
  proceeding: {
    data_ora_inizio_udienza: null,
    data_ora_fine_udienza: null,
    ora_inizio: null,
    ora_fine: null,
    durata_secondi: null,
    data_ora_richiesta: null,
    ora_arrivo_operatore: null,
    sezione_richiedente: '',
    tribunale_richiedente: '',
    id_tribunale: '',
    tribunale: '',
    id_sezione: '',
    sezione: '',
    aula: '',
    giudice_presidente: '',
    id_giudice_1: null,
    giudice_1: '',
    id_giudice_2: null,
    giudice_2: '',
    id_giudice_3: null,
    giudice_3: '',
    imputato: '',
    id_esito: '',
    esito: '',
    esito_altro: '',
    ids_pm: [],
    pms: [],
    upload_pdf_firmato: null,
    upload_attestato_consegna: null,
    rito: '',
    flag_verbale: '0',
    data_ora_rinvio: null,
    upload_audio: [],
    note_procedimento: '',
  },
  hasSensitiveDataAccess: false,
  workAwayFlag: '',
};

ProceedingResultCard.propTypes = {
  proceeding: PropTypes.shape({
    id_procedimento: PropTypes.string.isRequired,
    id_udienza: PropTypes.string.isRequired,
    stato_procedimento: PropTypes.string.isRequired,
    id_ticket: PropTypes.string.isRequired,
    data_ora_inizio_udienza: PropTypes.string,
    data_ora_fine_udienza: PropTypes.string,
    ora_inizio: PropTypes.string,
    ora_fine: PropTypes.string,
    durata_secondi: PropTypes.string,
    data_ora_richiesta: PropTypes.string,
    ora_arrivo_operatore: PropTypes.string,
    sezione_richiedente: PropTypes.string,
    tribunale_richiedente: PropTypes.string,
    RG: PropTypes.string.isRequired,
    RGNR: PropTypes.string.isRequired,
    id_tribunale: PropTypes.string,
    tribunale: PropTypes.string,
    id_sezione: PropTypes.string,
    sezione: PropTypes.string,
    aula: PropTypes.string,
    id_giudice_presidente: PropTypes.string.isRequired,
    giudice_presidente: PropTypes.string.isRequired,
    id_giudice_1: PropTypes.string,
    giudice_1: PropTypes.string,
    id_giudice_2: PropTypes.string,
    giudice_2: PropTypes.string,
    id_giudice_3: PropTypes.string,
    giudice_3: PropTypes.string,
    imputato: PropTypes.string,
    cancelliere: PropTypes.string,
    id_esito: PropTypes.string,
    esito: PropTypes.string,
    esito_altro: PropTypes.string,
    ids_pm: PropTypes.arrayOf(PropTypes.string),
    pms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    upload_pdf_firmato: PropTypes.string,
    upload_attestato_consegna: PropTypes.string,
    rito: PropTypes.string,
    flag_verbale: PropTypes.string,
    data_ora_rinvio: PropTypes.string,
    upload_audio: PropTypes.arrayOf(
      PropTypes.shape({
        fileId: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        isMp3: PropTypes.bool.isRequired,
      })
    ),
    note_procedimento: PropTypes.string,
  }),
  hasSensitiveDataAccess: PropTypes.bool,
  workAwayFlag: PropTypes.string,
};

export default ProceedingResultCard;
