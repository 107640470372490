import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import DocumentActionButton from './DocumentActionButton';

function TooltipMenu({ documentActionItems, otherItems, renderedItems, anchorEl, anchorOrigin, transformOrigin, arrowPosition, handleClose }) {
  const open = Boolean(anchorEl);

  const leftArrow = {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: -5,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  };
  const rightArrow = {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: -5,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="TooltipMenu"
      data-testid="TooltipMenu"
      open={open}
      onClose={handleClose}
      MenuListProps={{
        sx: {
          minWidth: '20em',
          maxHeight: '18em',
          overflowY: 'scroll',
        },
      }}
      slotProps={{
        paper: {
          elevation: 3,
          sx: { overflow: 'visible', '&:before': arrowPosition === 'right' ? rightArrow : leftArrow },
        },
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {documentActionItems?.map((item) => (
        <DocumentActionButton
          key={`${item.documentId} ${item.fileType} ${item.actionType}`}
          documentId={item.documentId}
          ticketId={item.ticketId}
          actionType={item.actionType}
          fileType={item.fileType}
          fileName={item.fileName}
          asMenuItem={item.asMenuItem}
          setPrinted={item.setPrinted}
          endpoint={item.endpoint}
          proceedingId={item.proceedingId}
          trialId={item.trialId}
          title={item.title}
          workAwayFlag={item.workAwayFlag}
          actionSuccessCallback={item.actionSuccessCallback}
        >
          {item.label}
        </DocumentActionButton>
      ))}
      {renderedItems}
      {otherItems?.map((item) => (
        <MenuItem data-testid="OtherMenuItem" key={item.label} onClick={item.onClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </MenuItem>
      ))}
    </Menu>
  );
}

TooltipMenu.defaultProps = {
  anchorEl: null,
  documentActionItems: [],
  otherItems: [],
  renderedItems: null,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  arrowPosition: 'left',
};

TooltipMenu.propTypes = {
  renderedItems: PropTypes.arrayOf(PropTypes.element),
  documentActionItems: PropTypes.arrayOf(PropTypes.shape({})),
  otherItems: PropTypes.arrayOf(PropTypes.shape({})),
  anchorEl: PropTypes.shape({}),
  handleClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({}),
  transformOrigin: PropTypes.shape({}),
  arrowPosition: PropTypes.string,
};

export default TooltipMenu;
