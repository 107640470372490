import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Person as PersonIcon, Error as ErrorIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ProfileAvatar from '../ProfileAvatar';
import LogoutButton from '../LogoutButton';
import { selectTranscriptionAuth, selectTDSAuth } from '../../features/authSlice';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuth: isTranscriptionAuth, netWorkError: isTranscriptionNetworkError } = useSelector(selectTranscriptionAuth);
  const { netWorkError: isTDSNetworkError } = useSelector(selectTDSAuth);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tooltipTitle = () => {
    if (!isTranscriptionAuth) return t('auth.messages.transcriptionBackend.unLogged');
    if (isTDSNetworkError) return t('auth.messages.connection.error');
    if (isTranscriptionNetworkError) return t('auth.messages.transcriptionBackend.connectionError');
    return null;
  };

  return (
    <>
      <Tooltip title={tooltipTitle()} placement="bottom">
        <IconButton onClick={handleClick} size="small">
          <Badge color="error" badgeContent=" " variant="dot" invisible={isTranscriptionAuth && !isTranscriptionNetworkError && !isTDSNetworkError}>
            <ProfileAvatar />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="AccountMenu"
        data-testid="AccountMenu"
        open={open}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          {t('navigation.accountMenu.items.profile')}
          {(!isTranscriptionAuth || isTranscriptionNetworkError || isTDSNetworkError) && (
            <ListItemIcon sx={{ ml: 1 }}>
              <ErrorIcon fontSize="small" color="error" />
            </ListItemIcon>
          )}
        </MenuItem>
        <Divider />
        <LogoutButton />
      </Menu>
    </>
  );
}
