import { dateTimeToReduxFormat } from './dateTimeHandlers';

function formatTrialToTrialEditingSlice(trial) {
  return {
    trialId: trial.id,
    statusId: trial.id_stato || '',
    status: trial.stato_udienza || '',
    ticketId: trial.id_ticket,
    away: trial.fuori_aula && trial.fuori_aula === '1',
    roomId: trial.id_aula || '',
    courtId: trial.id_tribunale || '',
    dateStart: dateTimeToReduxFormat(trial.data_ora_inizio_udienza, 'dateTime'),
    timeStart: dateTimeToReduxFormat(trial.data_ora_inizio_udienza, 'dateTime'),
    timeEnd: dateTimeToReduxFormat(trial.data_ora_fine_udienza, 'dateTime'),
    proceedingsCount: trial.n_procedimenti ? parseInt(trial.n_procedimenti, 10) : 0,
    proceedingsCountToVerify: trial.n_verbali ? parseInt(trial.n_verbali, 10) : 0,
    CDCount: trial.n_supporti ? parseInt(trial.n_supporti, 10) : 0,
    certificateId: trial.upload_attestato_presenza || '',
    notes: trial.note || '',
    operatorArrivalTime: dateTimeToReduxFormat(trial.ora_arrivo_operatore, 'time', trial.data_ora_inizio_udienza),
    service: trial.servizio || '',
    trasferta: trial.trasferta || '0',
  };
}

export default formatTrialToTrialEditingSlice;
