import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'auth',
  initialState: {
    TDS: {
      netWorkError: false,
      isAuth: false,
      user: null,
      lastFullfilledTimeStamp: null,
      shouldLogoutASAP: false,
    },
    Transcription: {
      netWorkError: false,
      isAuth: false,
      user: null,
      lastFullfilledTimeStamp: null,
      shouldLogoutASAP: false,
      shouldLoginASAP: false,
    },
  },
  reducers: {
    setTDSNetWorkError: (state, action) => ({
      ...state,
      TDS: {
        ...state.TDS,
        netWorkError: action.payload,
      },
    }),
    setTranscriptionNetWorkError: (state, action) => ({
      ...state,
      Transcription: {
        ...state.Transcription,
        netWorkError: action.payload,
      },
    }),
    setTDSAuth: (state, action) => ({
      ...state,
      TDS: {
        ...state.TDS,
        isAuth: action.payload.isAuth,
        user: action.payload.user,
      },
    }),
    setTranscriptionAuth: (state, action) => ({
      ...state,
      Transcription: {
        ...state.Transcription,
        isAuth: action.payload.isAuth,
        user: action.payload.user,
      },
    }),
    setTDSLastFullfilledTimeStamp: (state, action) => ({
      ...state,
      TDS: {
        ...state.TDS,
        lastFullfilledTimeStamp: action.payload,
      },
    }),
    setTranscriptionLastFullfilledTimeStamp: (state, action) => ({
      ...state,
      Transcription: {
        ...state.Transcription,
        lastFullfilledTimeStamp: action.payload,
      },
    }),
    setShouldLogoutASAPFromTDS: (state, action) => ({
      ...state,
      TDS: {
        ...state.TDS,
        shouldLogoutASAP: action.payload,
      },
    }),
    setShouldLogoutASAPFromTranscription: (state, action) => ({
      ...state,
      Transcription: {
        ...state.Transcription,
        shouldLogoutASAP: action.payload,
      },
    }),
    setShouldLoginASAPOnTranscription: (state, action) => ({
      ...state,
      Transcription: {
        ...state.Transcription,
        shouldLoginASAP: action.payload,
      },
    }),
  },
};

const authSlice = createSlice(options);

export const selectTDSAuth = (state) => state.auth.TDS;
export const selectTranscriptionAuth = (state) => state.auth.Transcription;
export const {
  setTDSAuth,
  setTranscriptionAuth,
  setTDSNetWorkError,
  setTranscriptionNetWorkError,
  setTDSLastFullfilledTimeStamp,
  setTranscriptionLastFullfilledTimeStamp,
  setShouldLogoutASAPFromTDS,
  setShouldLogoutASAPFromTranscription,
  setShouldLoginASAPOnTranscription,
} = authSlice.actions;

export default authSlice;
