import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from './config';
import restApiSlice from '../features/api/rest/apiSlice';
import tdsApiSlice from '../features/api/tds/apiSlice';
import apiSessionMonitoringMiddleware from '../features/api/middleware/apiSessionMonitoring';
import trialSlice from '../features/trialSlice';
import recordingsSlice from '../features/recordingsSlice';
import proceedingsSlice from '../features/proceedingsSlice';
import tracksSlice from '../features/tracksSlice';
import markersSlice from '../features/markersSlice';
import turnsSlice from '../features/turnsSlice';
import annotationsSlice from '../features/annotationsSlice';
import proceedingTicketsSlice from '../features/proceedingTicketsSlice';
import layoutSlice from '../features/layoutSlice';
import tempDataSlice from '../features/tempDataSlice';
import trialFiltersSlice from '../features/trialFiltersSlice';
import proceedingFiltersSlice from '../features/proceedingFiltersSlice';
import printsFiltersSlice from '../features/printsFilterSlice';
import bulkProceedingsSlice from '../features/bulkProceedingsSlice';
import trialEditingSlice from '../features/trialEditingSlice';
import singleProceedingSlice from '../features/singleProceedingSlice';
import otherFilesSlice from '../features/otherFilesSlice';
import authSlice from '../features/authSlice';
import annotationsTurnsSlice from '../features/annotationsTurns';
import annotationsRecordingsSlice from '../features/annotationsRecordings';
import annotationsMarkersSlice from '../features/annotationsMarkers';
import annotationsHeadersSlice from '../features/annotationsHeaders';
import cdsSlice from '../features/cdsSlice';
import proceedingFormSlice from '../features/proceedingFormSlice';
import loadedCdsSlice from '../features/loadedCdsSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
};
const rootReducer = combineReducers({
  [restApiSlice.reducerPath]: restApiSlice.reducer,
  [tdsApiSlice.reducerPath]: tdsApiSlice.reducer,
  [trialSlice.name]: trialSlice.reducer,
  [recordingsSlice.name]: recordingsSlice.reducer,
  [proceedingsSlice.name]: proceedingsSlice.reducer,
  [turnsSlice.name]: turnsSlice.reducer,
  [markersSlice.name]: markersSlice.reducer,
  [tracksSlice.name]: tracksSlice.reducer,
  [annotationsSlice.name]: annotationsSlice.reducer,
  [otherFilesSlice.name]: otherFilesSlice.reducer,
  [proceedingTicketsSlice.name]: proceedingTicketsSlice.reducer,
  layout: layoutSlice.reducer,
  proceedingFilters: proceedingFiltersSlice.reducer,
  trialFilters: trialFiltersSlice.reducer,
  [tempDataSlice.name]: tempDataSlice.reducer,
  printsFilters: printsFiltersSlice.reducer,
  bulkProceedings: bulkProceedingsSlice.reducer,
  trialEditing: trialEditingSlice.reducer,
  singleProceeding: singleProceedingSlice.reducer,
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  annotationsRecordings: annotationsRecordingsSlice.reducer,
  annotationsTurns: annotationsTurnsSlice.reducer,
  annotationsMarkers: annotationsMarkersSlice.reducer,
  annotationsHeaders: annotationsHeadersSlice.reducer,
  cds: cdsSlice.reducer,
  proceedingForm: proceedingFormSlice.reducer,
  loadedCds: loadedCdsSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions. Default reported: 'meta.arg', 'meta.baseQueryMeta'
        ignoredActionPaths: ['meta.arg', 'meta.baseQueryMeta', 'payload', 'payload.tracks', 'payload.meta.request', 'payload.meta.response', 'tds.queries.addFile', 'tds.queries.getFile'],
        // Ignore these paths in the state
        ignoredPaths: ['tracks', 'annotations', 'otherFiles', 'recordings', 'markers', 'turns', 'annotationsRecordings', 'annotationsHeaders', 'annotationsMarkers', 'annotationsTurns', 'api.mutations', 'tds.mutations', 'tds.queries'],
      },
    }).concat([restApiSlice.middleware, tdsApiSlice.middleware, apiSessionMonitoringMiddleware]),
  devTools: config.debug,
});

export const persistor = persistStore(store);

export default store;
