import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { useDebouncedCallback } from 'use-debounce';
import { dateTimeToReduxFormat } from '../utils/dateTimeHandlers';

function TdsDatePicker({ name, label, required, disabled, readOnly, onChange, sx, initialValue, timezone, size, debounceInterval }) {
  const [value, setValue] = useState(() => initialValue || '');
  const { t } = useTranslation();
  const debouncedOnChange = useDebouncedCallback((val, context) => {
    if (onChange) {
      onChange(val, context);
    }
  }, debounceInterval);
  const setValueWithValidation = (val, context) => {
    const computedValue = dayjs(val).isValid() ? dateTimeToReduxFormat(val, 'date') : '';
    setValue(computedValue);
    debouncedOnChange(computedValue, context);
  };

  return (
    <DatePicker
      sx={sx}
      slotProps={{
        textField: {
          size,
          inputProps: {
            'data-testid': name,
            placeholder: t('common.dates.placeholder'),
          },
          required,
          InputLabelProps: { shrink: true },
        },
      }}
      disabled={disabled}
      readOnly={readOnly}
      onChange={(val, context) => setValueWithValidation(val, context)}
      value={dayjs(value).isValid() ? dayjs(value) : null}
      format="L"
      label={label}
      timezone={timezone}
    />
  );
}

TdsDatePicker.defaultProps = {
  required: false,
  disabled: false,
  readOnly: false,
  onChange: null,
  sx: null,
  timezone: 'Europe/Rome',
  debounceInterval: 600,
  initialValue: '',
  size: 'medium',
};

TdsDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.shape({}),
  timezone: PropTypes.string,
  debounceInterval: PropTypes.number,
  initialValue: PropTypes.string,
  size: PropTypes.string,
};

export default TdsDatePicker;
