import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isEmptyObject, readFile, readZipFile } from '../../common';
import { timeToDate, datetimeToDate } from '../../markers/time';
import { detectCds } from './files';
import { parseSessionFromTxs } from './sessionTxs';

dayjs.extend(duration);

const defaultLabelToTrackMap = new Map(
  Object.entries({
    MIX: 1,
  })
);
const parseMediaTrackNameRegex = /.*\.mp3$/i;
function parseMediaList(mediaArray = []) {
  const track = {};
  const otherMedia = [];
  mediaArray.forEach((media) => {
    const mName = media['#text'];
    const mTimeOffset = media['@_timeoffset'];
    const match = mName.match(parseMediaTrackNameRegex);
    if (match) {
      track.name = mName;
      track.timeOffset = mTimeOffset;
    } else {
      otherMedia.push({ name: mName, timeOffset: mTimeOffset });
    }
  });
  return { track, otherMedia };
}
const defaultUserFieldToNameMap = {
  'PROCEDIMENTO NR': 'NR',
  GIUDICE: 'judge',
  'P.M.': 'prosecutor',
  AVVOCATI: 'layers',
  TESTIMONI: 'witnesses',
  IMPUTATI: 'defendants',
  AULA: 'room',
  DATA: 'data',
};
function parseUserField(userfield = []) {
  if (!userfield || userfield.length === 0) return null;
  const outUserField = {};
  userfield.forEach((uf) => {
    const ufName = uf['@_name'].endsWith(':') ? uf['@_name'].slice(0, -1) : uf['@_name'];
    const ufValue = uf['@_value'];
    const map = defaultUserFieldToNameMap[ufName];
    if (!map) {
      if (!outUserField.other) outUserField.other = {};
      outUserField.other[ufName] = ufValue;
    } else outUserField[map] = ufValue;
  });
  return outUserField;
}
async function normalizeTxsAnnotations(txsData, tz = 'Europe/Rome', labelToTrackMap = defaultLabelToTrackMap) {
  if (!txsData || !txsData.sessionlist || !txsData.sessionlist.session) return null;
  const sessions = txsData.sessionlist.session[0];
  const recordings = [];
  const information = {
    timezone: tz,
    type: 'trex',
    startDate: '',
  };
  for (const recording of sessions.recordinglist.recording) {
    const rStartDateAvailable = 'startdate' in recording && 'starttime' in recording;
    const rStartDate = rStartDateAvailable ? datetimeToDate(`${recording.startdate} ${recording.starttime}`, tz, 'YYYY-MM-DD HH:mm:ss') : null;
    if (information.startDate === '' && rStartDateAvailable) information.startDate = rStartDate;
    const rEndDateAvailable = 'stopdate' in recording && 'stoptime' in recording;
    const rEndDate = rEndDateAvailable ? datetimeToDate(`${recording.stopdate} ${recording.stoptime}`, tz, 'YYYY-MM-DD HH:mm:ss') : null;
    const rDurationAvailable = 'recordingtime' in recording;
    let rDuration = rDurationAvailable ? recording.recordingtime : null;
    if (rDuration !== null) {
      const [hours, minutes, seconds] = rDuration.split(':');
      rDuration = dayjs.duration({ hours, minutes, seconds });
      rDuration = rDuration.as('seconds');
    }
    const rId = `${rStartDate}|${rEndDate}`;
    const annotationReferenceTime = rStartDateAvailable ? rStartDate : null;
    const archiveFile = recording?.archivefile || null;
    const rMarkers = (recording.markerlist?.marker || []).map(async (marker) => {
      const mTime = timeToDate(marker['@_time'], annotationReferenceTime, tz);
      const mTimeOffset = marker['@_timeoffset'];
      const mName = marker['@_name'];
      const minfo = marker['@_info'];
      const outMarker = {
        phase: mName,
        note: minfo,
        time: mTime,
        timeOffset: mTimeOffset,
      };
      return outMarker;
    });
    let trackNumber = 2;
    const rTracks = (recording.tracklist?.track || []).map(async (rTrack) => {
      const tLabel = rTrack['@_name'];
      if (!labelToTrackMap.get(tLabel)) {
        labelToTrackMap.set(tLabel, trackNumber);
        trackNumber += 1;
      }
      const tMediaList = rTrack.medialist.media || [];
      const { track, otherMedia } = parseMediaList(tMediaList);
      const outTrack = {
        label: tLabel,
        fileName: track.name,
        timeOffset: track.timeOffset,
        otherMedia,
      };
      return outTrack;
    });
    const rUserField = parseUserField(recording.userfieldlist?.userfield);
    information.id = `${information.type}|${rUserField.NR || 'UNKNOWN'}|${rUserField.data || 'UNKNOWN'}`;
    const outRecording = {
      id: rId,
      // eslint-disable-next-line no-await-in-loop
      markers: await Promise.all(rMarkers),
      // eslint-disable-next-line no-await-in-loop
      userFields: rUserField,
      // eslint-disable-next-line no-await-in-loop
      tracks: await Promise.all(rTracks),
      archiveFile,
      trackMap: Object.fromEntries(labelToTrackMap),
    };
    if (rStartDateAvailable) outRecording.startDate = rStartDate;
    if (rEndDateAvailable) outRecording.endDate = rEndDate;
    if (rDuration) outRecording.duration = rDuration;
    recordings.push(outRecording);
  }
  return { information, recordings };
}

function recordingSortCompareFunction(recA, recB) {
  if (dayjs(recA.startDate).isBefore(recB.startDate)) return -1;
  if (dayjs(recA.startDate).isAfter(recB.startDate)) return 1;
  return 0;
}
function mergeDataAnnotations(annotationsArray = []) {
  if (annotationsArray.length === 0) return null;
  let recordings = [];
  let information = {};
  const wasteAnnotations = [];
  annotationsArray.forEach((annotation) => {
    if (isEmptyObject(information)) information = { ...annotation.information };
    if (information.id !== annotation.information.id) wasteAnnotations.push(annotation);
    if (dayjs(annotation.information.startDate).isBefore(dayjs(information.startDate))) information.startDate = annotation.information.startDate;
    if (annotation.recordings.length > 0) recordings = recordings.concat(annotation.recordings);
  });
  return { information, recordings: recordings.sort(recordingSortCompareFunction) };
}

async function parseTxsAnnotations({ txsXmlData = null, tz = 'Europe/Rome' }) {
  if (!txsXmlData) return null;
  const data = await parseSessionFromTxs(txsXmlData);
  return normalizeTxsAnnotations(data, tz);
}

async function parseDataAnnotations(data = [], tz = 'Europe/Rome') {
  if (data.length === 0) return null;
  const annotationsArray = [];
  for (const cd of data) {
    const params = {
      tz,
      // eslint-disable-next-line no-await-in-loop
      txsXmlData: await readFile(cd.txs, 'utf16le').catch(() => null),
    };
    // eslint-disable-next-line no-await-in-loop
    const txsAnnotation = await parseTxsAnnotations(params);
    if (txsAnnotation) annotationsArray.push(txsAnnotation);
  }
  return mergeDataAnnotations(annotationsArray);
}

const trexAudioFilesRegex = /^(.*)\.(mp3)$/i;
async function detectAndParseZipFiles(zip = {}) {
  if (isEmptyObject(zip)) return null;
  const zipAudio = {};
  const unknownZipAudio = [];
  for (const zipFile of Object.values(zip)) {
    // eslint-disable-next-line no-await-in-loop
    const containedFiles = await readZipFile(zipFile);
    containedFiles.forEach((containedFile) => {
      const match = containedFile.name.match(trexAudioFilesRegex);
      if (match) zipAudio[containedFile.name] = containedFile;
      else unknownZipAudio.push(containedFile);
    });
  }
  return { zipAudio, unknownZipAudio };
}
async function detectAndParseCds(files, tz = 'Europe/Rome') {
  const cds = [];
  for (const item of Object.values(await detectCds(files))) {
    const { data, audio, zip } = item;
    const annotationsFiles = (data || []).map((cd) => {
      return { file: cd.txs, type: 'txs' };
    });
    // eslint-disable-next-line no-await-in-loop
    const annotations = await parseDataAnnotations(data);
    const element = {
      annotations,
      annotationsFiles,
    };
    // eslint-disable-next-line no-await-in-loop
    const audioFromZips = await detectAndParseZipFiles(zip);
    const audioComplete = audioFromZips ? { ...audio, ...audioFromZips.zipAudio } : audio;
    if (audio) element.audio = audioComplete;
    if (!isEmptyObject(audioComplete)) {
      const mixerTrack = defaultLabelToTrackMap.get('MIX');
      const tracks = annotations?.recordings
        ?.map((rec) => {
          return rec.tracks.map((trk) => {
            return { ...trk, recordingId: rec.id, trackMap: rec.trackMap };
          });
        })
        ?.flat();
      const outAudio = {};
      const unknown = [];
      for (const [key, value] of Object.entries(audioComplete)) {
        const match = tracks?.find((trk) => trk.fileName === key);
        if (match) {
          if (typeof outAudio[match.recordingId] === 'undefined') outAudio[match.recordingId] = { tracks: {}, labels: {} };
          const trackNumber = match.trackMap[match.label];
          outAudio[match.recordingId].tracks[trackNumber] = value;
          outAudio[match.recordingId].labels[trackNumber] = match.label;
          if (typeof mixerTrack !== 'undefined' && trackNumber === mixerTrack) {
            outAudio[match.recordingId].mainTrack = trackNumber;
          }
        } else unknown.push(value);
      }
      element.audio = outAudio;
      const otherAudioComplete = audioFromZips ? unknown.concat(audioFromZips.unknownZipAudio) : unknown;
      if (unknown.length > 0) element.otherAudioFiles = otherAudioComplete;
    }
    cds.push(element);
  }

  return cds;
}

export { parseTxsAnnotations, detectAndParseCds as detectAndParseTrexCds, detectAndParseZipFiles };

export default parseTxsAnnotations;
