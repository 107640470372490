import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useGetJudgesFieldsQuery } from '../features/api/tds/apiProceedingsSlice';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { setProceedingFormField } from '../features/proceedingFormSlice';
import JudgesSelect from './JudgesSelect';

function ProceedingJudges({ trialId, judge, judge1, judge2, judge3 }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentData: trial, isFetchingTrial } = useGetTrialQuery(trialId, { skip: !trialId });
  const { currentData: judgesFields, isFetchingJudgesFields } = useGetJudgesFieldsQuery({ riteTypeId: trial?.id_tipo_rito }, { skip: !trial });
  const setField = useCallback(
    (fieldName, newValue) => {
      dispatch(setProceedingFormField({ [fieldName]: newValue }));
    },
    [dispatch]
  );

  if (isFetchingTrial || isFetchingJudgesFields) {
    return (
      <>
        <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
        <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
        <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
        <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
      </>
    );
  }

  return (
    <>
      {judgesFields?.judge && (
        <JudgesSelect size="small" required initValue={judge} sectionId={trial?.id_sezione} courtId={trial?.id_tribunale} setJudgeId={(newVal) => setField('judge', newVal)} label={t('proceedings.form.addProceeding.fields.judge')} name="presidentJudge" />
      )}
      {judgesFields?.judge1 && (
        <JudgesSelect
          size="small"
          required
          initValue={judge1}
          sectionId={trial?.id_sezione}
          courtId={trial?.id_tribunale}
          setJudgeId={(newVal) => setField('judge1', newVal)}
          label={t('proceedings.form.addProceeding.fields.honoraryJudge')}
          name="honoraryJudge"
        />
      )}
      {judgesFields?.judge2 && (
        <JudgesSelect
          size="small"
          required
          initValue={judge2}
          sectionId={trial?.id_sezione}
          courtId={trial?.id_tribunale}
          setJudgeId={(newVal) => setField('judge2', newVal)}
          label={t('proceedings.form.addProceeding.fields.honoraryJudge')}
          name="honoraryJudge"
        />
      )}
      {judgesFields?.judge3 && (
        <JudgesSelect
          size="small"
          required
          initValue={judge3}
          sectionId={trial?.id_sezione}
          courtId={trial?.id_tribunale}
          setJudgeId={(newVal) => setField('judge3', newVal)}
          label={t('proceedings.form.addProceeding.fields.honoraryJudge')}
          name="honoraryJudge"
        />
      )}
    </>
  );
}

ProceedingJudges.defaultProps = {
  judge: null,
  judge1: null,
  judge2: null,
  judge3: null,
};
ProceedingJudges.propTypes = {
  trialId: PropTypes.string.isRequired,
  judge: PropTypes.shape({}),
  judge1: PropTypes.shape({}),
  judge2: PropTypes.shape({}),
  judge3: PropTypes.shape({}),
};

export default ProceedingJudges;
