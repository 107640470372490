import unserialize from 'locutus/php/var/unserialize';

export const deserializePHParray = (PHParray) => {
  if (!PHParray) return [];
  return Object.entries(unserialize(PHParray));
};

export const deserializePositionalStrings = (idsString, namesString) => {
  if (!idsString || !namesString) return [];
  const idsArray = idsString.split(',').map((id) => id.trim());
  const namesArray = namesString.split(',').map((name) => name.trim());
  const resultArray = [];
  if (idsArray.length !== namesArray.length) return [];
  for (let i = 0; i < idsArray.length; i += 1) resultArray.push({ id: idsArray[i], name: namesArray[i] });
  return resultArray;
};
