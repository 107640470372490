import { createSlice, createSelector } from '@reduxjs/toolkit';
import { compareByBranoSubBranoAsc } from '../utils/compareByBranoSubBrano';

const initialState = {
  Brano: [],
  Proceedings: [],
  Files: [],
  Outcomes: ['RINVIO', 'SENTENZA', 'ATTI'],
};
function nextId(array) {
  let maxId = 0;
  array.map((elem) => {
    maxId = elem['@_Id'] > maxId ? elem['@_Id'] : maxId;
    return null;
  });
  return maxId + 1;
}
const trialSlice = createSlice({
  name: 'trial',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    addTurn(state, action) {
      if (!state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Intervento) {
        state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Intervento = [];
      }
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Intervento.push({
          '@_Id': nextId(state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Intervento),
          '@_Time': action.payload.time,
          '@_ChannelName': action.payload.channel,
        });
    },
    editTurn(state, action) {
      const index = state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Intervento.findIndex((intervento) => intervento['@_Id'] === action.payload.turnId);
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Intervento.splice(index, 1, { '@_Id': action.payload.turnId, '@_ChannelName': action.payload.channel, '@_Time': action.payload.time });
    },
    deleteTurn(state, action) {
      const index = state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Intervento.findIndex((intervento) => intervento['@_Id'] === action.payload.turnId);
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Intervento.splice(index, 1);
    },
    addMarker(state, action) {
      if (!state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Marker) {
        state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Marker = [];
      }
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Marker.push({
          '@_Id': nextId(state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId).SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId).Marker),
          '@_Time': action.payload.time,
          '@_Fase': action.payload.fase,
          '@_Note': action.payload.note,
        });
    },
    editMarker(state, action) {
      const index = state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Marker.findIndex((marker) => marker['@_Id'] === action.payload.markerId);
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Marker.splice(index, 1, { '@_Id': action.payload.markerId, '@_Note': action.payload.note, '@_Time': action.payload.time, '@_Fase': action.payload.fase });
    },
    deleteMarker(state, action) {
      const index = state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Marker.findIndex((marker) => marker['@_Id'] === action.payload.markerId);
      state.Brano.find((brano) => brano['@_Id'] === action.payload.branoId)
        .SubBrano.find((subbrano) => subbrano['@_Id'] === action.payload.subbranoId)
        .Marker.splice(index, 1);
    },
    updateProceedingStatus(state, action) {
      state.Proceedings.find((proceeding) => proceeding['@_Id'] === action.payload.proceedingId).isUploaded = true;
    },
    changeRGProceedingCode(state, action) {
      state.Proceedings.find((proceeding) => proceeding['@_Id'] === action.payload.proceedingId).Code.RG = action.payload.value;
    },
    changeRGProceedingByCode(state, action) {
      state.Proceedings.map((proceeding) => {
        if (proceeding.Code.RG === action.payload.RG && proceeding.Code.RGNR === action.payload.RGNR && !proceeding.isUploaded) proceeding.Code.RG = action.payload.value;
        return null;
      });
    },
    changeRGNRProceedingCode(state, action) {
      state.Proceedings.find((proceeding) => proceeding['@_Id'] === action.payload.proceedingId).Code.RGNR = action.payload.value;
    },
    changeRGNRProceedingByCode(state, action) {
      state.Proceedings.map((proceeding) => {
        if (proceeding.Code.RG === action.payload.RG && proceeding.Code.RGNR === action.payload.RGNR && !proceeding.isUploaded) proceeding.Code.RGNR = action.payload.value;
        return null;
      });
    },
    changeOutcomeType(state, action) {
      state.Proceedings.find((proceeding) => proceeding['@_Id'] === action.payload.proceedingId).Outcome.Type = action.payload.value;
    },
    changeOutcomeTypeByCode(state, action) {
      state.Proceedings.map((proceeding) => {
        if (proceeding.Code.RG === action.payload.RG && proceeding.Code.RGNR === action.payload.RGNR && !proceeding.isUploaded) proceeding.Outcome.Type = action.payload.value;
        return null;
      });
    },
    changeOutcomeDate(state, action) {
      state.Proceedings.find((proceeding) => proceeding['@_Id'] === action.payload.proceedingId).Outcome.Date = action.payload.value;
    },
    changeOutcomeDateByCode(state, action) {
      state.Proceedings.map((proceeding) => {
        if (proceeding.Code.RG === action.payload.RG && proceeding.Code.RGNR === action.payload.RGNR && !proceeding.isUploaded) proceeding.Outcome.Date = action.payload.value;
        return null;
      });
    },
    loadCronologia(state, action) {
      action.payload.Dibattimento.Cronologia.Brano.forEach((brano) => {
        if (!state.Brano.find((brn) => brn['@_Id'] === brano['@_Id'])) state.Brano.push(brano);
        else
          brano.SubBrano.forEach((subbrano) => {
            if (!state.Brano.find((brn) => brn['@_Id'] === brano['@_Id'] && brn.SubBrano.find((sbrn) => sbrn['@_Id'] === subbrano['@_Id']))) {
              state.Brano.find((brn) => brn['@_Id'] === brano['@_Id']).SubBrano.push(subbrano);
            } else {
              if (!state.Brano.find((brn) => brn['@_Id'] === brano['@_Id'] && brn.SubBrano.find((sbrn) => sbrn['@_Id'] === subbrano['@_Id'])).Marker && subbrano.Marker) {
                state.Brano.find((brn) => brn['@_Id'] === brano['@_Id']).SubBrano.find((sbrn) => sbrn['@_Id'] === subbrano['@_Id']).Marker = subbrano.Marker;
              }
              if (!state.Brano.find((brn) => brn['@_Id'] === brano['@_Id'] && brn.SubBrano.find((sbrn) => sbrn['@_Id'] === subbrano['@_Id'])).Intervento && subbrano.Intervento)
                state.Brano.find((brn) => brn['@_Id'] === brano['@_Id']).SubBrano.find((sbrn) => sbrn['@_Id'] === subbrano['@_Id']).Intervento = subbrano.Intervento;
            }
          });
      });
      action.payload.Dibattimento.Cronologia.Proceedings.forEach((proceeding) => {
        if (!state.Proceedings.find((prc) => prc.BranoId === proceeding.BranoId && prc.SubBranoId === proceeding.SubBranoId && prc.Code.RG === proceeding.Code.RG && prc.Code.RGNR === proceeding.Code.RGNR)) state.Proceedings.push(proceeding);
      });
      action.payload.Dibattimento.Cronologia.Files.forEach((file) => {
        if (!state.Files.find((fl) => fl.name === file.name)) state.Files.push(file);
      });
    },
    /* eslint-enable no-param-reassign */
  },
});

// Outcoms list
export const getOutcomesList = (state) => state.Outcomes;

// Brano list
export const getBranoList = (state) => state.Brano;

// Proceeding list
export const getProceedingsList = (state) => state.Proceedings;

// Proceeding by Id
export const getProceedingById = (state, proceedingId) => getProceedingsList(state).find((proceeding) => proceeding['@_Id'] === proceedingId);

// Proceeding by BranoId SubBranoId
export const getProceedingsByBranoSubbrano = (state, branoId, subbranoId) => getProceedingsList(state).filter((proceeding) => proceeding.BranoId === branoId && proceeding.SubBranoId === subbranoId);

// Files list
export const getFilesList = (state) => state.Files;

// Files list sorted
export const getFilesOrderedList = createSelector(getFilesList, (Files) => {
  return [...Files].sort(compareByBranoSubBranoAsc);
});

// Files by BranoId SubBranoId
export const getFileByBranoSubbrano = (state, branoId, subbranoId) => getFilesList(state).find((file) => file.BranoId === branoId && file.SubBranoId === subbranoId);

// Brano by Id
export const getBranoById = (state, id) => getBranoList(state).find((brano) => brano['@_Id'] === id);

// SubBrano list
export const getSubBranoList = (state, id) => getBranoById(state, id).SubBrano;

// SubBrano by Id
export const getSubBranoById = (state, branoId, sottobranoId) => getSubBranoList(state, branoId).find((subbrano) => subbrano['@_Id'] === sottobranoId);

// Markers list
export const getMarkers = (state, branoId, sottobranoId) => getSubBranoById(state, branoId, sottobranoId).Marker;

// Markers by Id
export const getMarkerById = (state, branoId, sottobranoId, markerId) => getMarkers(state, branoId, sottobranoId).find((marker) => marker['@_Id'] === markerId);

// Turns list
export const getTurns = (state, branoId, sottobranoId) => getSubBranoById(state, branoId, sottobranoId).Intervento;

// Turns by Id
export const getTurnById = (state, branoId, sottobranoId, turnId) => getTurns(state, branoId, sottobranoId).find((turn) => turn['@_Id'] === turnId);

export const {
  addTurn,
  editTurn,
  deleteTurn,
  addMarker,
  editMarker,
  deleteMarker,
  updateProceedingStatus,
  submitProceeding,
  changeRGProceedingCode,
  changeRGProceedingByCode,
  changeRGNRProceedingCode,
  changeRGNRProceedingByCode,
  changeOutcomeType,
  changeOutcomeTypeByCode,
  changeOutcomeDate,
  changeOutcomeDateByCode,
  loadCronologia,
  createProceedingRGCode,
  createProceedingRGNRCode,
} = trialSlice.actions;

export default trialSlice;
