import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAlertMessage } from '../features/layoutSlice';
import { editProceeding, selectAllProceedings, selectTotalProceedings } from '../features/proceedingsSlice';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';
import { setBulkProceedingsLoading, closeBulkProceedingsDialog, resetBulkProceedingsActiveForm, setBulkProceedingsDeleteCdsDialogOpen } from '../features/bulkProceedingsSlice';
import { resetProceedingFormSlice, selectProceeding, selectProceedingFormOtherFiles, selectProceedingFormRecordings, selectProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';
import { selectAllLoadedCds } from '../features/loadedCdsSlice';

export default function useBulkProceedingsDialog() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentData: trial, isLoadingTrial, isFetchingTrial } = useGetTrialQuery(id, { skip: !id });
  const { currentData: proceedings, isLoadingProceedings, isFetchingProceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });
  const loadedCds = useSelector((state) => selectAllLoadedCds(state.loadedCds));
  const disableDeleteCdButton = !(loadedCds && loadedCds.length > 0);

  const stagedProceeding = useSelector(selectProceeding);
  const recordings = useSelector(selectProceedingFormRecordings);
  const selectedRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const otherFiles = useSelector(selectProceedingFormOtherFiles);

  const proceedingsCDTotal = useSelector((state) => selectTotalProceedings(state.proceedings));
  const proceedingsCDUploaded = useSelector((state) => selectAllProceedings(state.proceedings)).filter((proceeding) => proceeding.uploaded).length;
  const proceedingsWithVerbalFlagCount = proceedings?.filter((proc) => proc.flag_verbale && proc.flag_verbale === '1')?.length || 0;
  const maxProceedingsWithVerbalFlagCount = trial && trial.n_verbali ? Number(trial.n_verbali) : 0;
  const proceedingsCount = proceedings?.length || 0;
  const maxProceedingsCount = trial && trial.n_procedimenti ? Number(trial.n_procedimenti) : 0;
  const proceedingsWithOutVerbalFlagCount = proceedings?.filter((proc) => proc.flag_verbale && proc.flag_verbale === '0')?.length || 0;
  const maxProceedingsWithOutVerbalFlagCount = maxProceedingsCount - maxProceedingsWithVerbalFlagCount;

  const disableUploadButton = !trial?.data_ora_fine_udienza || !trial?.n_procedimenti || !trial?.n_verbali || !trial?.n_supporti || !trial?.id_aula;
  const trialCompleted = maxProceedingsCount <= proceedingsCount;

  const handleTrialCompleted = useCallback(() => {
    dispatch(closeBulkProceedingsDialog());
    dispatch(setBulkProceedingsLoading(false));
    dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'success', render: 'snack' }));
  }, [dispatch, t]);
  const cancelLoading = useCallback(() => {
    if (stagedProceeding.proceedingId !== '') {
      dispatch(editProceeding({ id: stagedProceeding.proceedingId, changes: { ...stagedProceeding, recordings, selectedRecordings, otherFiles } }));
      dispatch(resetBulkProceedingsActiveForm());
      dispatch(resetProceedingFormSlice());
    }
    dispatch(closeBulkProceedingsDialog());
    dispatch(setBulkProceedingsLoading(false));
  }, [dispatch, otherFiles, recordings, selectedRecordings, stagedProceeding]);
  const deleteCdsHandler = useCallback(() => {
    dispatch(setBulkProceedingsDeleteCdsDialogOpen(true));
  }, [dispatch]);

  return {
    trial,
    proceedings,
    isFetching: isFetchingTrial || isFetchingProceedings,
    isLoading: isLoadingTrial || isLoadingProceedings,
    proceedingsCDTotal,
    proceedingsCDUploaded,
    proceedingsCount,
    maxProceedingsCount,
    proceedingsWithVerbalFlagCount,
    maxProceedingsWithVerbalFlagCount,
    proceedingsWithOutVerbalFlagCount,
    maxProceedingsWithOutVerbalFlagCount,
    disableUploadButton,
    trialCompleted,
    handleTrialCompleted,
    cancelLoading,
    deleteCdsHandler,
    disableDeleteCdButton,
  };
}
