function formatAnnotationsFiles(annotationsFilesList, cdId) {
  const formattedAnnotationsFiles = [];
  annotationsFilesList?.forEach((annotationFile) => {
    formattedAnnotationsFiles.push(formatAnnotationFile(annotationFile, cdId));
  });
  return formattedAnnotationsFiles;
}
function formatAnnotationFile(annotationFile, cdId) {
  const formattedAnnotationFile = {};
  if (annotationFile) {
    formattedAnnotationFile.id = annotationFile.file.name;
    formattedAnnotationFile.cdId = cdId;
    formattedAnnotationFile.file = annotationFile.file;
    formattedAnnotationFile.fileName = annotationFile.file.name || '';
    formattedAnnotationFile.type = annotationFile.type;
  }
  return formattedAnnotationFile;
}

export { formatAnnotationFile, formatAnnotationsFiles };

export default formatAnnotationsFiles;
