export function processJudges(proceedings = [], judge = null) {
  if (proceedings === null) {
    return {
      availableJudges: [],
      filteredProceedings: [],
    };
  }

  const availableJudges = new Set();

  const filteredProceedings = proceedings.filter((proceeding) => {
    let hasJudge = false;

    ['giudice_presidente', 'giudice_1', 'giudice_2', 'giudice_3'].forEach((key) => {
      if (proceeding[key] && proceeding[key] !== 'null') {
        availableJudges.add(proceeding[key]);
        if (judge && proceeding[key] === judge) {
          hasJudge = true;
        }
      }
    });

    return judge ? hasJudge : true;
  });

  return {
    availableJudges: Array.from(availableJudges),
    filteredProceedings,
  };
}

export function filterProceedingsByField(field, search = '', inputProceedings = []) {
  if (inputProceedings === null || inputProceedings.length === 0) return [];
  if (search === null || search.trim() === '') return inputProceedings;
  const trimmedSearch = search.trim().toUpperCase();

  const filteredProceedings = inputProceedings.filter((proceeding) => {
    if (field === 'pm') return proceeding[field] && proceeding[field].join(' ').toUpperCase().includes(trimmedSearch);
    return proceeding[field] && proceeding[field].replace(/,/g, '').toUpperCase().includes(trimmedSearch);
  });

  return filteredProceedings;
}

export const formatSortingsArray = (arr, field) => {
  return (
    arr
      ?.map((o) => {
        return { sort: o.split('-')[0], dir: o.split('-')[1] };
      })
      ?.find((s) => s.sort === field)?.dir || ''
  );
};
