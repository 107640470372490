import { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import SubRow from './SubRow';
import JobProgressBar from '../JobProgressBar';
import RescheduleJobButton from '../RescheduleJobButton';

dayjs.extend(LocalizedFormat);

const rowStructure = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  last_updated: PropTypes.string.isRequired,
  original_file_name: PropTypes.string,
  request_reference: PropTypes.string.isRequired,
  progress: PropTypes.number,
  children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
});

function NodeRow({ row, onSelectRow, selectedRow }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <TableRow key={row.id} hover sx={{ cursor: 'pointer' }} onClick={() => onSelectRow(row)} selected={selectedRow?.id === row.id}>
        <TableCell sx={{ pl: '20px' }}>
          {row.children?.length > 0 && (
            <IconButton sx={{ p: 0 }} onClick={() => setIsExpanded((prev) => !prev)}>
              {isExpanded ? <KeyboardArrowDown fontSize="small" /> : <KeyboardArrowUp fontSize="small" />}
            </IconButton>
          )}
        </TableCell>
        <TableCell sx={{ pl: '15px' }}>{t(`jobs.status.value.${row.status}`)}</TableCell>
        <TableCell>{row.created ? dayjs(row.created).format('L LT') : ''}</TableCell>
        <TableCell>{row.last_updated ? dayjs(row.last_updated).format('L LT') : ''}</TableCell>
        <TableCell>{row.request_reference}</TableCell>
        <TableCell>{row.original_file_name}</TableCell>
        <TableCell>
          <JobProgressBar progress={row.progress} />
        </TableCell>
        <TableCell>{row.status === 'failed' && <RescheduleJobButton job={row} />}</TableCell>
      </TableRow>
      {row.children?.length > 0 && isExpanded && row.children.map((childNode) => <SubRow key={childNode.id} row={childNode} onSelectRow={onSelectRow} selectedRow={selectedRow} />)}
    </>
  );
}

rowStructure.children = PropTypes.arrayOf(rowStructure);

NodeRow.defaultProps = {
  selectedRow: null,
};

NodeRow.propTypes = {
  row: rowStructure.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  selectedRow: rowStructure,
};

export default NodeRow;
