import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { IconButton, TableRow, TableCell } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { removeAnnotationsTurn, selectAnnotationsTurnsById } from '../../../../features/annotationsTurns';
import TurnForm from './TurnForm';

function Turn({ id, channels }) {
  const dispatch = useDispatch();
  const turn = useSelector((state) => selectAnnotationsTurnsById(state.annotationsTurns, id));
  const deleteTurn = () => dispatch(removeAnnotationsTurn(id));
  const [editMode, setEditMode] = useState(false);

  if (editMode) return <TurnForm turn={turn} channels={channels} recordingId={turn.recordingId} setMode={setEditMode} />;
  return (
    <TableRow onClick={() => setEditMode(true)} hover key={turn.id}>
      <TableCell width="40%">{turn.time ? dayjs(turn.time).format('LTS') : '-'}</TableCell>
      <TableCell width="50%">{turn.channel || '-'}</TableCell>
      <TableCell width="10%" align="right">
        <IconButton size="small" data-testid="delete-button" onClick={() => deleteTurn(turn.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Turn.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};

export default Turn;
