import { dateAddSeconds } from '../parse-recordings-annotations/markers/time';

function timeStartRetrieve(recording) {
  if (recording.timeStart) return recording.timeStart;
  if (recording.startDate) return recording.startDate;
  if (recording.markers?.length) return recording.markers.at(0).time;
  if (recording.turns?.length) return recording.turns.at(0).time;
  return null;
}

function timeEndRetrieve(recording) {
  if (recording.timeEnd) return recording.timeEnd;
  if (recording.endDate) return recording.endDate;
  if (recording.startDate && recording.duration) return dateAddSeconds(recording.startDate, recording.duration);
  if (recording.markers?.length) return recording.markers.at(recording.markers.length - 1).time;
  if (recording.turns?.length) return recording.turns.at(recording.turns.length - 1).time;
  return null;
}

export { timeStartRetrieve, timeEndRetrieve };
