import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'bulkProceedings',
  initialState: {
    step: 0,
    dialog: {
      open: false,
    },
    reinsertCdDialog: {
      open: '',
      missingFile: '',
      missingRecordingId: '',
    },
    deleteCdsDialog: {
      open: false,
      loading: false,
    },
    dateDialog: {
      open: false,
      loading: false,
    },
    filters: {
      recordings: [],
    },
    active: '',
    submitting: false,
    loading: false,
    pp: [],
    registrar: '',
    recordingsSelect: {
      values: [],
      focus: false,
      lastItems: [],
    },
  },
  reducers: {
    setBulkProceedingsDeleteCdsDialogOpen: (state, action) => ({
      ...state,
      deleteCdsDialog: {
        ...state.deleteCdsDialog,
        open: action.payload,
      },
    }),
    setBulkProceedingsDeleteCdsDialogLoading: (state, action) => ({
      ...state,
      deleteCdsDialog: {
        ...state.deleteCdsDialog,
        loading: action.payload,
      },
    }),
    setBulkProceedingsReinsertCdDialog: (state, action) => ({
      ...state,
      reinsertCdDialog: {
        ...state.reinsertCdDialog,
        open: action.payload,
      },
    }),
    setBulkProceedingsReinsertCdDialogMissingRecordingId: (state, action) => ({
      ...state,
      reinsertCdDialog: {
        ...state.reinsertCdDialog,
        missingRecordingId: action.payload,
      },
    }),
    setBulkProceedingsReinsertCdDialogFileMissing: (state, action) => ({
      ...state,
      reinsertCdDialog: {
        ...state.reinsertCdDialog,
        missingFile: action.payload,
      },
    }),
    openBulkProceedingsDateDialog: (state) => ({
      ...state,
      dateDialog: {
        ...state.dateDialog,
        open: true,
      },
    }),
    closeBulkProceedingsDateDialog: (state) => ({
      ...state,
      dateDialog: {
        ...state.dateDialog,
        open: false,
      },
    }),
    setBulkProceedingsDateDialogLoading: (state, action) => ({
      ...state,
      dateDialog: {
        ...state.dateDialog,
        loading: action.payload,
      },
    }),
    setBulkProceedingsSubmittingForm: (state, action) => ({
      ...state,
      submitting: action.payload,
    }),
    setBulkProceedingsLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setBulkProceedingsActiveForm: (state, action) => ({
      ...state,
      active: action.payload,
    }),
    resetBulkProceedingsActiveForm: (state) => ({
      ...state,
      active: '',
    }),
    setBulkProceedingsStep: (state, action) => ({
      ...state,
      step: action.payload,
    }),
    nextBulkProceedingsStep: (state) => ({
      ...state,
      step: state.step + 1,
    }),
    previousBulkProceedingsStep: (state) => ({
      ...state,
      step: state.step - 1,
    }),
    openBulkProceedingsDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: true,
      },
    }),
    closeBulkProceedingsDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: false,
      },
    }),
    setBulkProceedingsPP: (state, action) => ({
      ...state,
      pp: action.payload,
    }),
    resetBulkProceedingsPP: (state) => ({
      ...state,
      pp: [],
    }),
    setBulkProceedingsRegistrar: (state, action) => ({
      ...state,
      registrar: action.payload,
    }),
    resetBulkProceedingsRegistrar: (state) => ({
      ...state,
      registrar: '',
    }),
    setBulkProceedingsSelectedRecordings: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        recordings: action.payload,
      },
    }),
    setBulkProceedingsRecordingsSelectValues: (state, action) => ({
      ...state,
      recordingsSelect: {
        ...state.recordingsSelect,
        values: action.payload,
      },
    }),
    setBulkProceedingsRecordingsSelectFocus: (state, action) => ({
      ...state,
      recordingsSelect: {
        ...state.recordingsSelect,
        focus: action.payload,
      },
    }),
    setBulkProceedingsRecordingsSelectLastItems: (state, action) => ({
      ...state,
      recordingsSelect: {
        ...state.recordingsSelect,
        lastItems: action.payload,
      },
    }),
  },
};

const bulkProceedingsSlice = createSlice(options);
export const selectBulkProceedingsStep = (state) => state.bulkProceedings.step;
export const selectBulkProceedingsActiveForm = (state) => state.bulkProceedings.active;
export const selectBulkProceedingsSubmittingForm = (state) => state.bulkProceedings.submitting;
export const selectBulkProceedingsLoading = (state) => state.bulkProceedings.loading;
export const selectIsBulkProceedingsDialogOpen = (state) => state.bulkProceedings.dialog.open;
export const selectIsBulkProceedingsDateDialogOpen = (state) => state.bulkProceedings.dateDialog.open;
export const selectIsBulkProceedingsReinsertCdDialogOpen = (state) => state.bulkProceedings.reinsertCdDialog.open;
export const selectBulkProceedingsReinsertCdDialogFileMissing = (state) => state.bulkProceedings.reinsertCdDialog.missingFile;
export const selectBulkProceedingsReinsertCdDialogMissingRecordingId = (state) => state.bulkProceedings.reinsertCdDialog.missingRecordingId;
export const selectBulkProceedingsDateDialogLoading = (state) => state.bulkProceedings.dateDialog.loading;
export const selectBulkProceedingsDefaultRegistrar = (state) => state.bulkProceedings.registrar;
export const selectBulkProceedingsDefaultPP = (state) => state.bulkProceedings.pp;
export const selectBulkProceedingsSelectedRecordings = (state) => state.bulkProceedings.filters.recordings;
export const selectBulkProceedingsRecordingsSelectValues = (state) => state.bulkProceedings.recordingsSelect.values;
export const selectBulkProceedingsRecordingsSelectFocus = (state) => state.bulkProceedings.recordingsSelect.focus;
export const selectBulkProceedingsRecordingsSelectLastItems = (state) => state.bulkProceedings.recordingsSelect.lastItems;
export const selectIsBulkProceedingsDeleteCdsDialogOpen = (state) => state.bulkProceedings.deleteCdsDialog.open;
export const selectBulkProceedingsDeleteCdsDialogLoading = (state) => state.bulkProceedings.deleteCdsDialog.loading;
export const {
  setBulkProceedingsDeleteCdsDialogLoading,
  setBulkProceedingsDeleteCdsDialogOpen,
  setBulkProceedingsReinsertCdDialog,
  setBulkProceedingsReinsertCdDialogMissingRecordingId,
  setBulkProceedingsReinsertCdDialogFileMissing,
  openBulkProceedingsDateDialog,
  closeBulkProceedingsDateDialog,
  setBulkProceedingsDateDialogLoading,
  setBulkProceedingsLoading,
  setBulkProceedingsSubmittingForm,
  setBulkProceedingsActiveForm,
  resetBulkProceedingsActiveForm,
  setBulkProceedingsStep,
  nextBulkProceedingsStep,
  previousBulkProceedingsStep,
  openBulkProceedingsDialog,
  closeBulkProceedingsDialog,
  setBulkProceedingsPP,
  resetBulkProceedingsPP,
  setBulkProceedingsRegistrar,
  resetBulkProceedingsRegistrar,
  setBulkProceedingsSelectedRecordings,
  setBulkProceedingsRecordingsSelectValues,
  setBulkProceedingsRecordingsSelectFocus,
  setBulkProceedingsRecordingsSelectLastItems,
} = bulkProceedingsSlice.actions;

export default bulkProceedingsSlice;
