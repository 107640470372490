import { useSelector, useDispatch } from 'react-redux';
import { useGetFileNameQuery } from '../features/api/tds/apiTrialsSlice';
import { selectProceedingFormStagedOtherFiles, setProceedingFormStagedOtherFiles } from '../features/proceedingFormSlice';

export default function useProceedingStagedOtherFile(fileId) {
  const dispatch = useDispatch();

  const otherFiles = useSelector(selectProceedingFormStagedOtherFiles);
  const { data: fileName, isFetching, isError } = useGetFileNameQuery({ fileId });

  const handleDelete = () => {
    const newFiles = otherFiles.filter((file) => file.fileId !== fileId);
    dispatch(setProceedingFormStagedOtherFiles(newFiles));
  };

  return {
    fileName,
    handleDelete,
    isFetching,
    isError,
  };
}
