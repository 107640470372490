import { ListItemIcon, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import useLogout from '../hooks/useLogout';

function LogoutButton() {
  const { t } = useTranslation();
  const { handleLogout } = useLogout();

  return (
    <MenuItem onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      {t('auth.logout.button')}
    </MenuItem>
  );
}

export default LogoutButton;
