import { Stack } from '@mui/material';
import RecordingsSelect from './RecordingsSelect';

function BulkProceedingsFilters() {
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <RecordingsSelect />
    </Stack>
  );
}

export default BulkProceedingsFilters;
