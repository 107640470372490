import { t } from 'i18next';
import { mapProfileData } from '../../../../utils/profileUtils';

export const decodeJWT = (jwt) => {
  function base64UrlDecode(base64Url) {
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const buf = Buffer.from(base64, 'base64');
    return buf.toString('utf-8');
  }

  const [header, payload, signature] = jwt.split('.');

  if (!header || !payload || !signature) {
    throw new Error(t('messages.errors.invalidJWT'));
  }

  const decodedPayload = JSON.parse(base64UrlDecode(payload));

  return decodedPayload;
};

const processProfile = (jwt) => {
  const inputProfile = decodeJWT(jwt);

  if (inputProfile?.user) {
    const { user } = inputProfile;
    return mapProfileData(user);
  }
  throw new Error(t('messages.errors.invalidProfile'));
};

export default processProfile;
