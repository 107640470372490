import { createSlice } from '@reduxjs/toolkit';

const options = {
  name: 'proceedingFilters',
  initialState: {
    ticketId: '',
    statusId: '',
    RG: '',
    RGNR: '',
    outcome: '',
    outcomeDate: '',
    judge: '',
    defendant: '',
    chancellor: '',
    pm: '',
  },
  reducers: {
    setTicketId: (state, action) => ({ ...state, ticketId: action.payload }),
    cleanTicketId: (state) => ({ ...state, ticketId: '' }),
    setStatusId: (state, action) => ({ ...state, statusId: action.payload }),
    cleanStatusId: (state) => ({ ...state, statusId: '' }),
    setRG: (state, action) => ({ ...state, RG: action.payload }),
    cleanRG: (state) => ({ ...state, RG: '' }),
    setRGNR: (state, action) => ({ ...state, RGNR: action.payload }),
    cleanRGNR: (state) => ({ ...state, RGNR: '' }),
    setOutcome: (state, action) => ({ ...state, outcome: action.payload }),
    cleanOutcome: (state) => ({ ...state, outcome: '' }),
    setOutcomeDate: (state, action) => ({ ...state, outcomeDate: action.payload }),
    cleanOutcomeDate: (state) => ({ ...state, outcomeDate: '' }),
    setJudge: (state, action) => ({ ...state, judge: action.payload }),
    cleanJudge: (state) => ({ ...state, judge: '' }),
    setDefendant: (state, action) => ({ ...state, defendant: action.payload }),
    cleanDefendant: (state) => ({ ...state, defendant: '' }),
    setChancellor: (state, action) => ({ ...state, chancellor: action.payload }),
    cleanChancellor: (state) => ({ ...state, chancellor: '' }),
    setPM: (state, action) => ({ ...state, pm: action.payload }),
    cleanPM: (state) => ({ ...state, pm: '' }),
  },
};
const proceedingFiltersSlice = createSlice(options);

export const getFilters = (state) => state.proceedingFilters;
export const getTicketId = (state) => state.proceedingFilters.ticketId;
export const getStatusId = (state) => state.proceedingFilters.statusId;
export const getRG = (state) => state.proceedingFilters.RG;
export const getRGNR = (state) => state.proceedingFilters.RGNR;
export const getOutcome = (state) => state.proceedingFilters.outcome;
export const getOutcomeDate = (state) => state.proceedingFilters.outcomeDate;
export const getJudge = (state) => state.proceedingFilters.judge;
export const getDefendant = (state) => state.proceedingFilters.defendant;
export const getChancellor = (state) => state.proceedingFilters.chancellor;
export const getPM = (state) => state.proceedingFilters.pm;

export const {
  setTicketId,
  cleanTicketId,
  setStatusId,
  cleanStatusId,
  setRG,
  cleanRG,
  setRGNR,
  cleanRGNR,
  setOutcome,
  cleanOutcome,
  setOutcomeDate,
  cleanOutcomeDate,
  setJudge,
  cleanJudge,
  setDefendant,
  cleanDefendant,
  setChancellor,
  cleanChancellor,
  setPM,
  cleanPM,
} = proceedingFiltersSlice.actions;
export default proceedingFiltersSlice;
