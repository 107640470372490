import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { selectProceedingFormStagedOtherFiles } from '../features/proceedingFormSlice';
import ProceedingStagedOtherFile from './ProceedingStagedOtherFile';

function ProceedingStagedOtherFiles() {
  const proceedingStagedOtherFiles = useSelector(selectProceedingFormStagedOtherFiles);

  if (proceedingStagedOtherFiles.length > 0)
    return (
      <Stack spacing={1} direction="column">
        {proceedingStagedOtherFiles?.map((otherFile) => (
          <ProceedingStagedOtherFile key={otherFile.fileId} fileId={otherFile.fileId} />
        ))}
      </Stack>
    );
}

export default ProceedingStagedOtherFiles;
