import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Skeleton, Typography, Stack, Autocomplete } from '@mui/material';
import TdsDateTimePicker from './TdsDateTimePicker';
import { useGetProceedingOutcomesQuery } from '../features/api/tds/apiProceedingsSlice';
import ProceedingFormTextField from './ProceedingFormTextField';

function OutcomesSelect({ outcome, setOutcome, labelDate, labelNotes, label, name, required, size }) {
  const { t } = useTranslation();
  const { currentData: outcomes, isLoading, isFetching, isError } = useGetProceedingOutcomesQuery();
  const options = useMemo(
    () =>
      outcomes
        ? outcomes.map((oc) => {
            return { id: oc.id, description: oc.descrizione };
          })
        : [],
    [outcomes]
  );
  useEffect(() => {
    if (outcomes && outcome.id !== '' && !outcomes.map((oc) => oc.id).includes(outcome.id)) setOutcome({ id: '', description: '', date: null, notes: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomes]);

  if (isError)
    return (
      <Typography>
        {label}: {t('common.messages.errors.fetching')}
      </Typography>
    );
  if (isFetching || isLoading) return <Skeleton variant="rectangular" height="3em" animation="pulse" />;

  return (
    <Stack spacing={2} direction="row" alignItems="flex-start" justifyContent="space-between">
      <Autocomplete
        size={size}
        sx={{ width: 2 / 5 }}
        freeSolo
        isOptionEqualToValue={(op, val) => op.id === val.id}
        onChange={(e, val) => setOutcome({ id: val?.id || '', description: val?.description || '', notes: '', date: null })}
        getOptionLabel={(option) => option.description}
        options={options}
        value={outcome}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, 'data-testid': name }} name={name} label={label} required={required} />}
      />
      {outcome.description.toUpperCase().indexOf('RINVIO') !== -1 && <TdsDateTimePicker size={size} sx={{ width: 3 / 5 }} name="outcomeDate" initialValue={outcome.date} onChange={(val) => setOutcome({ ...outcome, date: val })} label={labelDate} required />}
      {outcome.description.toUpperCase().indexOf('ALTRO') !== -1 && (
        <ProceedingFormTextField size={size} sx={{ width: 3 / 5 }} inputProps={{ 'data-testid': 'outcomeNotes' }} name="outcomeNotes" multiline initValue={outcome.notes} onChange={(val) => setOutcome({ ...outcome, notes: val })} label={labelNotes} />
      )}
    </Stack>
  );
}

OutcomesSelect.defaultProps = {
  outcome: {
    id: '',
    descrizione: '',
    date: '',
    notes: '',
  },
  size: 'medium',
};

OutcomesSelect.propTypes = {
  outcome: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    notes: PropTypes.string,
  }),
  setOutcome: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelDate: PropTypes.string.isRequired,
  labelNotes: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

export default OutcomesSelect;
