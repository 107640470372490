import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editCd, selectCdById } from '../features/cdsSlice';
import { setBulkProceedingsDateDialogLoading } from '../features/bulkProceedingsSlice';
import { editAnnotationsRecordings, selectAllAnnotationsRecordings } from '../features/annotationsRecordings';
import { editAnnotationsMarkers, selectAllAnnotationsMarkers } from '../features/annotationsMarkers';
import { editAnnotationsTurns, selectAllAnnotationsTurns } from '../features/annotationsTurns';
import { dateAddSeconds, dateOffset } from '../lib/parse-recordings-annotations/markers/time';

function useCdDateHandler(id) {
  const dispatch = useDispatch();

  const cd = useSelector((state) => selectCdById(state.cds, id));
  const annotationsRecordings = useSelector((state) => selectAllAnnotationsRecordings(state.annotationsRecordings));
  const annotationsRecordingsByCdId = useMemo(() => annotationsRecordings.filter((recording) => recording.cdId === id), [annotationsRecordings, id]);
  const annotationsMarkers = useSelector((state) => selectAllAnnotationsMarkers(state.annotationsMarkers));
  const annotationsTurns = useSelector((state) => selectAllAnnotationsTurns(state.annotationsTurns));

  const moveStartDate = useCallback(
    (newCdDate) => {
      dispatch(setBulkProceedingsDateDialogLoading(true));
      const offset = dateOffset(cd.startDate, newCdDate);
      if (offset) {
        const recordingsToEdit = [];
        const markersToEdit = [];
        const turnsToEdit = [];
        annotationsRecordingsByCdId.forEach((recording) => {
          markersToEdit.push(
            annotationsMarkers
              ?.filter((marker) => marker.recordingId === recording.id)
              ?.map((marker) => {
                return { id: marker.id, changes: { time: dateAddSeconds(marker.time, offset, cd.timezone) } };
              })
          );
          turnsToEdit.push(
            annotationsTurns
              ?.filter((turn) => turn.recordingId === recording.id)
              ?.map((turn) => {
                return { id: turn.id, changes: { time: dateAddSeconds(turn.time, offset, cd.timezone) } };
              })
          );
          recordingsToEdit.push({ id: recording.id, changes: { timeStart: dateAddSeconds(recording.timeStart, offset, cd.timezone), timeEnd: dateAddSeconds(recording.timeEnd, offset, cd.timezone) } });
        });
        if (recordingsToEdit.length !== 0) dispatch(editAnnotationsRecordings(recordingsToEdit.flat()));
        if (markersToEdit.length !== 0) dispatch(editAnnotationsMarkers(markersToEdit.flat()));
        if (turnsToEdit.length !== 0) dispatch(editAnnotationsTurns(turnsToEdit.flat()));
        dispatch(editCd({ id, changes: { startDate: newCdDate } }));
      }
      dispatch(setBulkProceedingsDateDialogLoading(false));
    },
    [cd, id, annotationsRecordingsByCdId, annotationsMarkers, annotationsTurns, dispatch]
  );

  const setCdDate = useCallback(
    (newCdDate) => {
      dispatch(editCd({ id, changes: { newStartDate: newCdDate } }));
      if (cd.moveDate) moveStartDate(newCdDate);
    },
    [cd.moveDate, id, moveStartDate, dispatch]
  );
  const moveCdDate = (value) => dispatch(editCd({ id, changes: { moveDate: value } }));

  return {
    cd,
    setCdDate,
    moveCdDate,
    moveStartDate,
  };
}

export default useCdDateHandler;
