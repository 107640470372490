import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { Autocomplete, Button, Checkbox, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon, UploadFile, DriveFolderUpload } from '@mui/icons-material';
import { selectProceedingFormRecordings, selectProceedingFormSelectedRecordings, setProceedingFormRecordings, setProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';
import { loadTracks, selectAllTracks } from '../features/tracksSlice';
import { loadRecordings } from '../features/recordingsSlice';
import { handleFiles } from '../lib/cronologia';
import { storeFile } from '../utils/fileReader';
import useBulkProceedingsAddCdButton from '../hooks/useBulkProceedingsAddCdButton';
import { selectBulkProceedingsRecordingsSelectLastItems, selectBulkProceedingsRecordingsSelectFocus, setBulkProceedingsRecordingsSelectFocus } from '../features/bulkProceedingsSlice';

function AddTracksCard() {
  const Tracks = useSelector((state) => selectAllTracks(state.tracks)).filter((trk) => trk.isMixer);
  const recordings = useSelector(selectProceedingFormRecordings);
  const selectedRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const { add } = useIndexedDB('files');
  const { handleLoadCDs } = useBulkProceedingsAddCdButton();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let selectRef;
  const inputFileRef = useRef();
  const inputCdsRef = useRef();
  const [tracksToAdd, setTracksToAdd] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isFocused = useSelector(selectBulkProceedingsRecordingsSelectFocus);
  const lastItems = useSelector(selectBulkProceedingsRecordingsSelectLastItems);
  useEffect(() => {
    if (isFocused) selectRef.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const storeFilesOnIndexedDB = useCallback((files) => Promise.all(files.map(async (file) => storeFile(file, add))), [add]);

  const sortTracks = useCallback((tracks) => {
    return tracks.sort((a, b) => {
      if (a.file?.name < b.file?.name) return -1;
      if (a.file?.name > b.file?.name) return 1;
      return 0;
    });
  }, []);

  const options = useMemo(() => {
    const opts = [];
    const sortedTrack = sortTracks(Tracks);
    sortedTrack.forEach((track) => {
      if (!recordings?.includes(track.recordingId)) opts.push(track);
    });
    opts.push('addFiles');
    opts.push('addCds');
    return opts;
  }, [recordings, sortTracks, Tracks]);
  const firstChoice = lastItems.length > 0 ? options.find((st) => lastItems.includes(st.file?.name))?.file.name : '';

  const addHandler = (e) => {
    e.preventDefault();
    setIsOpen(false);
    addRecordings();
  };
  const addRecordings = () => {
    dispatch(setProceedingFormRecordings(recordings.concat(tracksToAdd.map((trk) => trk.recordingId))));
    dispatch(setProceedingFormSelectedRecordings(selectedRecordings.concat(tracksToAdd.map((trk) => trk.recordingId))));
    setTracksToAdd([]);
  };
  const handleClick = (e) => {
    inputFileRef.current.click();
  };
  const handleLoadCdsClick = (e) => {
    inputCdsRef.current.click();
  };
  const handleLoadFiles = useCallback(
    async (e) => {
      const files = Object.values(e.target.files);
      const state = await handleFiles(files);
      dispatch(loadRecordings(state.recordings));
      dispatch(loadTracks(state.tracks));
      storeFilesOnIndexedDB(state.tracks.map((track) => track.file));
      setTracksToAdd(tracksToAdd.concat(state.tracks));
      setIsOpen(true);
    },
    [dispatch, tracksToAdd, storeFilesOnIndexedDB]
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const addSearchIconToEndAdornment = (endAdornment) => {
    const children = React.Children.toArray(endAdornment.props.children);
    const button = (
      <Button variant="contained" onClick={addHandler} size="small" key="addFile" sx={{ ml: 2, verticalAlign: 'middle' }} disabled={tracksToAdd.length === 0}>
        {t('recordings.addTracksForm.addSelectedTracks')}
      </Button>
    );
    children.push(button);
    return React.cloneElement(endAdornment, {}, children);
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <Autocomplete
        size="small"
        open={isOpen}
        fullWidth
        multiple
        limitTags={3}
        id="searchTracks"
        options={options}
        value={tracksToAdd}
        onOpen={() => setIsOpen(true)}
        onFocus={() => {
          if (isFocused && firstChoice) {
            setTimeout(() => {
              const target = document.querySelector(`[data-name-choice="${firstChoice}"]`);
              target.parentNode.scrollTop = target.offsetTop;
            }, 500);
            dispatch(setBulkProceedingsRecordingsSelectFocus(false));
          }
        }}
        onClose={() => {
          setIsOpen(false);
          addRecordings();
        }}
        onChange={(e, newVal) => setTracksToAdd(sortTracks(newVal))}
        disableCloseOnSelect
        openOnFocus
        getOptionLabel={(option) => option.file?.name || ''}
        renderOption={(props, option, { selected }) => {
          if (option === 'addFiles') {
            return (
              <ListItem {...props} key="addFiles" onClick={handleClick}>
                <ListItemIcon sx={{ p: 1 }}>
                  <UploadFile />
                </ListItemIcon>
                <ListItemText sx={{ textTransform: 'uppercase' }}>{t('recordings.addTracksForm.addFiles')}</ListItemText>
              </ListItem>
            );
          }
          if (option === 'addCds') {
            return (
              <ListItem {...props} key="addCds" onClick={handleLoadCdsClick}>
                <ListItemIcon sx={{ p: 1 }}>
                  <DriveFolderUpload />
                </ListItemIcon>
                <ListItemText sx={{ textTransform: 'uppercase' }}>{t('proceedings.dialogs.buttons.newCd')}</ListItemText>
              </ListItem>
            );
          }
          return (
            <ListItem {...props} key={option.file?.name} data-name-choice={option.file?.name}>
              <ListItemIcon>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              </ListItemIcon>
              <ListItemText>{option.file?.name}</ListItemText>
            </ListItem>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={t('recordings.addTracksForm.label')}
              inputRef={(input) => {
                selectRef = input;
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: addSearchIconToEndAdornment(params.InputProps.endAdornment),
                slotProps: { root: { style: { paddingRight: '12em' } } },
              }}
              placeholder={t('recordings.addTracksForm.placeholder')}
            />
          );
        }}
        sx={{
          '& .MuiFormLabel-root.Mui-focused': {
            bgcolor: '#fff',
            px: '4px',
          },
          '& .MuiAutocomplete-root .MuiFormControl-root': {
            position: 'relative',
          },
          '& .MuiAutocomplete-inputRoot.Mui-focused': {
            position: 'unset',
            height: '2.5em',
            overflowY: 'scroll',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'primary.main',
          },
          '& .MuiAutocomplete-inputRoot.Mui-focused fieldset': {
            border: 'none',
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            cursor: 'pointer',
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment ': {
            right: '20px',
          },
        }}
      />
      <input ref={inputFileRef} onChange={handleLoadFiles} style={{ display: 'none' }} multiple type="file" id="loadFiles" />
      <input ref={inputCdsRef} hidden onChange={handleLoadCDs} data-testid="loadCD" webkitdirectory="true" type="file" id="loadCD" />
    </>
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

export default AddTracksCard;
