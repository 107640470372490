import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const proceedingTicketsAdapter = createEntityAdapter();

const proceedingTicketsSlice = createSlice({
  name: 'proceedingTickets',
  initialState: proceedingTicketsAdapter.getInitialState(),
  reducers: {
    loadProceedingTickets: proceedingTicketsAdapter.setAll,
    editProceedingTickets: proceedingTicketsAdapter.updateOne,
    addProceedingTickets: proceedingTicketsAdapter.addOne,
    removeProceedingTickets: proceedingTicketsAdapter.removeOne,
  },
});

export const { selectById: selectProceedingTicketById, selectIds: selectProceedingTicketIds, selectAll: selectAllProceedingTickets, selectTotal: selectTotalProceedingTickets } = proceedingTicketsAdapter.getSelectors();

export const { loadProceedingTickets, editProceedingTickets, addProceedingTickets, removeProceedingTickets } = proceedingTicketsSlice.actions;

export default proceedingTicketsSlice;
