import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import compareByTime from '../utils/compareByTime';

const annotationsMarkersAdapter = createEntityAdapter({ sortComparer: compareByTime });

const annotationsMarkersSlice = createSlice({
  name: 'annotationsMarkers',
  initialState: annotationsMarkersAdapter.getInitialState(),
  reducers: {
    loadAnnotationsMarkers: annotationsMarkersAdapter.setMany,
    editAnnotationsMarker: annotationsMarkersAdapter.updateOne,
    editAnnotationsMarkers: annotationsMarkersAdapter.updateMany,
    addAnnotationsMarker: annotationsMarkersAdapter.addOne,
    removeAnnotationsMarker: annotationsMarkersAdapter.removeOne,
    removeAnnotationsMarkersByIds: annotationsMarkersAdapter.removeMany,
    removeAnnotationsMarkers: annotationsMarkersAdapter.removeAll,
  },
});

export const { selectById: selectAnnotationsMarkerById, selectAll: selectAllAnnotationsMarkers, selectTotal: selectTotalAnnotationsMarkers } = annotationsMarkersAdapter.getSelectors();

export const { loadAnnotationsMarkers, editAnnotationsMarker, editAnnotationsMarkers, addAnnotationsMarker, removeAnnotationsMarker, removeAnnotationsMarkersByIds, removeAnnotationsMarkers } = annotationsMarkersSlice.actions;

export default annotationsMarkersSlice;
