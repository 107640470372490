import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { IconButton, TableRow, TableCell } from '@mui/material';
import { Delete as DeleteIcon, PlayArrow } from '@mui/icons-material';
import { removeAnnotationsMarker, selectAnnotationsMarkerById } from '../../../../features/annotationsMarkers';
import MarkerForm from './MarkerForm';

function Marker({ id, playFromTime }) {
  const dispatch = useDispatch();
  const marker = useSelector((state) => selectAnnotationsMarkerById(state.annotationsMarkers, id));
  const [editMode, setEditMode] = useState(false);
  const deleteMarker = () => dispatch(removeAnnotationsMarker(id));

  const playFromMarkerTime = (e) => {
    e.stopPropagation();
    playFromTime(marker.time);
  };

  if (editMode) return <MarkerForm marker={marker} cdId="" recordingId={marker.recordingId} setMode={setEditMode} />;
  return (
    <TableRow onClick={() => setEditMode(true)} hover key={marker.id}>
      <TableCell width="5%">
        <IconButton size="small" data-testid="delete-button" onClick={playFromMarkerTime}>
          <PlayArrow />
        </IconButton>
      </TableCell>
      <TableCell width="20%">{marker.time ? dayjs(marker.time).format('LTS') : '-'}</TableCell>
      <TableCell width="20%">{marker.phase || '-'}</TableCell>
      <TableCell width="50%" sx={{ whiteSpace: 'pre-line' }}>
        {marker.note || '-'}
      </TableCell>
      <TableCell width="5%" align="right">
        <IconButton size="small" data-testid="delete-button" onClick={() => deleteMarker(marker.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Marker.propTypes = {
  id: PropTypes.string.isRequired,
  playFromTime: PropTypes.func.isRequired,
};

export default Marker;
