import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Divider, CircularProgress, Backdrop } from '@mui/material';
import { selectBulkProceedingsDateDialogLoading, selectIsBulkProceedingsDateDialogOpen } from '../features/bulkProceedingsSlice';
import BulkProceedingsCdData from './BulkProceedingsCdData';
import useCdData from '../hooks/useCdData';
import ReloadCdsDialog from './ReloadCdsDialog';

function BulkProceedingsDateDialog() {
  const { t } = useTranslation();
  const isBulkDateDialogOpen = useSelector(selectIsBulkProceedingsDateDialogOpen);
  const isBulkDateDialogLoading = useSelector(selectBulkProceedingsDateDialogLoading);
  const { cds, saveCds, cancelCds, closeHandler, existingCds, reloadCdsDialogOpen } = useCdData();

  const backdrop = (
    <Backdrop open={isBulkDateDialogLoading} sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <>
      <ReloadCdsDialog closeHandler={closeHandler} existingCds={existingCds} isOpen={reloadCdsDialogOpen} />
      <Dialog open={isBulkDateDialogOpen} fullWidth maxWidth="md">
        {backdrop}
        <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>{t('proceedings.dialogs.bulkUpload.dataDialog.title')}</DialogTitle>
        <Divider />
        <DialogContent>
          <BulkProceedingsCdData cds={cds} saveCd={saveCds} cancelCd={cancelCds} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="warning" variant="outlined" onClick={() => cancelCds()}>
            {t('proceedings.dialogs.buttons.abortAllCds')}
          </Button>
          <Button color="primary" variant="contained" onClick={() => saveCds()}>
            {t('proceedings.dialogs.buttons.approveAllCds')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BulkProceedingsDateDialog;
