import { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NodeCard from './NodeCard';
import TreeTable from './TreeTable';

function TreeView({ statusTree }) {
  const [selectedNode, setSelectedNode] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedNode(statusTree);
  }, [statusTree]);

  const rootRow = useMemo(
    () => ({
      id: statusTree.id,
      status: statusTree.status,
      created: statusTree.created,
      last_updated: statusTree.last_updated,
      original_file_name: statusTree.original_file_name,
      request_reference: statusTree.request_reference,
      children: [],
    }),
    [statusTree.id, statusTree.status, statusTree.created, statusTree.last_updated, statusTree.original_file_name, statusTree.request_reference]
  );

  return (
    <div>
      <NodeCard node={selectedNode} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ pl: '10px' }}>
              <Typography sx={{ width: '90px' }} variant="subtitle2">
                {t('jobs.status.label')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.date.created.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.date.lastUpdated.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.requestReference.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.originalFileName.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.progress.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.actions.label')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TreeTable rootRow={rootRow} rows={statusTree.children} onSelectRow={setSelectedNode} selectedRow={selectedNode} />
        </TableBody>
      </Table>
    </div>
  );
}

const nodeStructure = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  last_updated: PropTypes.string.isRequired,
  original_file_name: PropTypes.string,
  request_reference: PropTypes.string.isRequired,
  children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
});
nodeStructure.children = PropTypes.arrayOf(nodeStructure);

TreeView.propTypes = {
  statusTree: nodeStructure.isRequired,
};

export default TreeView;
