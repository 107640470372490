import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentActionButton from './DocumentActionButton';

function TrialCertificate({ ticketId, trialService, trialId, workAwayFlag }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Typography sx={{ width: '30%' }}>{trialService === 'RITIRO EX-POST' ? t('trials.form.editTrial.fields.withdrawalCertificate') : t('trials.form.editTrial.fields.attendanceCertificate')}</Typography>
      <DocumentActionButton actionType="print" fileType={trialService === 'RITIRO EX-POST' ? 'ARIT' : 'AP'} title={t('common.actions.print')} endpoint="certificate" trialId={trialId} workAwayFlag={workAwayFlag} />
      <DocumentActionButton actionType="download" fileType={trialService === 'RITIRO EX-POST' ? 'ARIT' : 'AP'} ticketId={ticketId} endpoint="certificate" trialId={trialId} workAwayFlag={workAwayFlag}>
        {t('common.actions.download', { what: 'pdf' })}
      </DocumentActionButton>
      <DocumentActionButton actionType="open" fileType={trialService === 'RITIRO EX-POST' ? 'ARIT' : 'AP'} ticketId={ticketId} endpoint="certificate" trialId={trialId} workAwayFlag={workAwayFlag}>
        {t('common.actions.open', { what: 'pdf' })}
      </DocumentActionButton>
    </Stack>
  );
}

TrialCertificate.defaultProps = {
  trialService: '',
  workAwayFlag: '',
};

TrialCertificate.propTypes = {
  ticketId: PropTypes.string.isRequired,
  trialId: PropTypes.string.isRequired,
  workAwayFlag: PropTypes.string,
  trialService: PropTypes.string,
};

export default TrialCertificate;
