import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, IconButton } from '@mui/material';
import { Undo } from '@mui/icons-material';
import BulkProceedingsUploadButton from './BulkProceedingsUploadButton';
import SingleProceedingUploadButton from './SingleProceedingUploadButton';

function ProceedingsByTrialHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    if (location.state) navigate('/trials'.concat(location.state.params));
    else navigate('/');
  };
  return (
    <Stack id="ProceedingsByTrialPageHeader" direction="row" justifyContent="space-between" alignItems="center" mb={1}>
      <IconButton variant="text" color="secondary" onClick={goBack} size="large">
        <Undo />
      </IconButton>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <SingleProceedingUploadButton />
        <BulkProceedingsUploadButton />
      </Stack>
    </Stack>
  );
}

export default ProceedingsByTrialHeader;
