import dayjs from 'dayjs';
import { formatTime, formatDateTime } from './dateTime';
import { formatTextWithPadding, formatColumns } from './utils';
import { reorderRecordings, buildTreeFromOrderedArray } from '../common/utils';

function formatCdAnnotations(annotations, tz = null) {
  if (!annotations) return '';
  const tzData = tz === null ? (annotations.information && annotations.information.timezone) || 'Europe/Rome' : tz;
  let txt = `${formatCdHeaders(annotations.information, tzData)}`;
  const reorderedRecordings = reorderRecordings(annotations.recordings);
  const passages = buildTreeFromOrderedArray(reorderedRecordings);
  txt += formatCdPassages(passages, tzData);
  return txt.replaceAll('\r', '').replaceAll('\n', '\r\n');
}

function formatCdHeaders(information, tz = 'Europe/Rome') {
  if (!information) return '';
  const padding = `\t${' '.repeat(44)}`;
  let txt = '';
  txt += formatColumns('\tCodice Dispositivo:', `${formatTextWithPadding(information.recorder || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tNome del Tribunale e dell'aula:`, `${formatTextWithPadding(information.other && information.other['Tribunale e Aula'] ? information.other['Tribunale e Aula'] : '', padding)}\n\n`, 45);
  txt += formatColumns(`\tCollegio Giudicante:`, `${formatTextWithPadding(information.rite || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tPubblico Ministero:`, `${formatTextWithPadding(information.prosecutor || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tPresidente:`, `${formatTextWithPadding(information.judge || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tData:`, `${formatTextWithPadding(information.startDate ? formatDateTime(information.startDate, tz) : '', padding)}\n\n`, 45);
  txt += formatColumns(`\tNumero di Registro Generale:`, `${formatTextWithPadding((information.other && information.other.NRG) || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tImputato:`, `${formatTextWithPadding((information.other && information.other.Imputato) || '', padding)}\n\n`, 45);
  txt += formatColumns(`\tNote a testo libero:`, `${formatTextWithPadding(information.note || '', padding)}\n\n`, 45);
  txt += '\n';
  return txt;
}

function formatCdPassages(passages, tz = 'Europe/Rome') {
  if (!passages || !passages.length) return '';
  let txt = '          CRONOLOGIA INTERVENTI\t\t\tNOTE AI MARKER\n          ---------------------\t\t\t--------------\n\n\n';
  for (const passage of passages) {
    txt += formatCdPassage(passage, tz);
  }
  return txt;
}

function formatCdPassage(passage, tz = 'Europe/Rome') {
  let txt = '';
  for (const section of passage.sections) {
    txt += `${formatCdSection(section, passage, tz)}`;
  }
  txt += `*************************************** Fine Brano n°${passage.passageNumber}***************************************\n\n`;
  return txt;
}

function formatCdSection(section, passage, tz = 'Europe/Rome') {
  let txt = formatCdRecording(section.recording, tz);
  txt += `***************************** Fine Sottobrano n° ${section.sectionNumber} del brano n° ${passage.passageNumber}*****************************\n\n\n`;
  return txt;
}

function formatCdRecording(recording, tz = 'Europe/Rome') {
  if (!recording) return '';
  return `${formatMarkersAndTurns(recording, tz)}\n`;
}

function interleaveAndSort(arrays) {
  const merged = [].concat(...arrays);
  return merged.sort((a, b) => a.time - b.time);
}

function formatMarkersAndTurns(recording, tz = 'Europe/Rome') {
  const turns = recording.turns || [];
  const formattedTurns = turns.map((turn) => {
    return { time: dayjs(turn.time).unix(), txt: formatTurn(turn, tz) };
  });
  const markers = recording.markers || [];
  const formattedMarkers = markers.map((marker) => {
    return { time: dayjs(marker.time || '1970-01-01T00:00:00+0000').unix(), txt: formatMarker(marker, tz) };
  });
  const interleavedAndSorted = interleaveAndSort([formattedTurns, formattedMarkers]);
  if (!interleavedAndSorted.length) return '';
  return `${interleavedAndSorted.map((a) => a.txt).join('\n\n')}\n\n`;
}

function formatTurn(turn, tz = 'Europe/Rome') {
  return `${formatTime(turn.time, tz)}  Canale ${turn.trackName || ''}`;
}

function formatMarker(marker, tz = 'Europe/Rome') {
  return formatColumns(`${formatTime(marker.time, tz)}  MARKER - ${marker.phase || ''}`, `${formatTextWithPadding(marker.note || '')}`, 48);
}

export { formatCdAnnotations, formatCdHeaders, formatCdPassage, formatCdSection, formatCdRecording, formatMarkersAndTurns, formatMarker, formatTurn };

export default formatCdAnnotations;
