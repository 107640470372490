import { isEmptyString } from '../common';
import { parseDate } from './dateparser';
import { parseKeyValues } from './keyvalueparser';

const validRGVariations = 'RG|R\\.G\\.|RGB|RG\\.|RG\\.?\\s*GIP\\.?';
const validRGNRVariations = 'N\\.R\\.|NR|RGNR|NR\\.|N\\.R|RGNG';
const extendedRGVariations = `${validRGVariations}|DIB|procedimento`;
const extendedRGNRVariations = `${validRGNRVariations}|PM|IMP`;
const detectRGRGNRRegex = new RegExp(`(?:\\*?\\s*(?:${validRGVariations})\\s*[=:-]?\\s*)(\\d+\\/\\d+)(?:\\s*(?:(?:(?:-?\\s*)\\*?\\s*(?:${validRGNRVariations})\\s*[=:-]?)|-)\\s*)(\\d+\\/\\d+)`, 'i');
const detectRGNRRGRegex = new RegExp(`(?:\\s*(?:\\*?\\s*(?:${validRGNRVariations})\\s*[=:-]?)\\s*)(\\d+\\/\\d+)(?:(?:-?\\s*)\\*?\\s*(?:${validRGVariations})\\s*[=:-]?\\s*)(\\d+\\/\\d+)`, 'i');
const detectBrokenRGRGNRRegex = new RegExp(`(?:\\*?\\s*(?:${extendedRGVariations})?\\s*[=:-]?\\s*)(\\d+\\/\\d+)(?:\\s*(?:(?:(?:-?\\s*)\\*?\\s*(?:${extendedRGNRVariations})\\s*[=:-]?)|-)\\s*)(\\d+\\/\\d+)`, 'i');
const detectBrokenRGNRRGRegex = /(?:\s*)(\d+\/\d+)(?:\s*[-\s]\s*)(\d+\/\d+)/i;
const detectBrokenRGRegex = new RegExp(`(?:\\*?\\s*(?:${extendedRGVariations})\\s*[=:-]?\\s*)(\\d+\\/\\d+)`, 'i');
function detectProceedingStartFromText(text) {
  let rg = null;
  let rgnr = null;
  let properRGRGNRFound = true;
  let match = text.match(detectRGRGNRRegex);
  if (match) {
    [, rg, rgnr] = match;
  } else {
    match = text.match(detectRGNRRGRegex);
    if (match) {
      [, rgnr, rg] = match;
    } else {
      properRGRGNRFound = false;
      match = text.match(detectBrokenRGRGNRRegex);
      if (match) {
        [, rg, rgnr] = match;
      } else {
        match = text.match(detectBrokenRGNRRGRegex);
        // TODO: check RG and RGNR consistency
        if (match) {
          [, rgnr, rg] = match;
        } else {
          match = text.match(detectBrokenRGRegex);
          if (match) {
            [, rg] = match;
          }
        }
      }
    }
  }
  if (rg != null) {
    if (rgnr != null) {
      return {
        type: properRGRGNRFound ? 'START' : 'MAYBESTART',
        RG: rg,
        RGNR: rgnr,
      };
    }
    return {
      type: properRGRGNRFound ? 'START' : 'MAYBESTART',
      RG: rg,
    };
  }
  return false;
}

function detectProceedingStart(text, phase = '') {
  const result = detectProceedingStartFromText(text);
  if (result) {
    return result;
  }
  if (isEmptyString(phase)) {
    return false;
  }
  return detectProceedingStartFromText(phase);
}

function detectProceedingPeople(text, isStartMarker = false) {
  const safeResults = parseKeyValues(text, true, isStartMarker);
  return safeResults.map(({ item }) => {
    const person = {
      role: item.key?.type,
      name: item.value,
    };
    if (item.persontitle?.gender) person.gender = item.persontitle.gender;
    if (item.persontitle?.title) person.title = item.persontitle.title;
    return person;
  });
}

const detectRinvioRegex = /(?:\b(?:E|ESITO)\s*[=:]?\s*|\b)(?:(?:(?:GIUDICE|SI)\s+)?RINVIA|RINVIATO|RINVIATA|RINVIANO|RINVIO|RINV|RIN|EINV|RINVVI|RUNVIO|RINIVIO|rnivo|irnvia)(?:\b|\s+|$)/i;
const detectSentenzaRegex = /(?:(?:E|ESITO)\s*[=:]?\s*)?(?:SENTENZA|SENT|SENT\.)(?:\b|\s+|$)/i;
const detectRestituzioneAttiRegex = /\b(?:(?:REST(?:ITUZIONE)?\s*)ATTI\s*(?:PM|GUP|P\.M\.)?|ATTI\s*(?:PM|GUP|P\.M\.))(?:\b|\s+|$)/i;
function detectProceedingEnd(text, phase = '', referenceDate = null, tz = 'Europe/Rome') {
  const matchRinvio = text.match(detectRinvioRegex);
  if (matchRinvio) {
    const date = parseDate(text, true, referenceDate, tz)[0];
    const result = {
      type: 'END',
      subtype: 'RINVIO',
    };
    if (date) {
      result.date = date;
    }
    return result;
  }
  const matchSentenza = text.match(detectSentenzaRegex);
  if (matchSentenza || phase === 'Sentenza') {
    return {
      type: 'END',
      subtype: 'SENTENZA',
    };
  }
  const matchAtti = text.match(detectRestituzioneAttiRegex);
  if (matchAtti) {
    return {
      type: 'END',
      subtype: 'ATTI',
    };
  }
  if (phase === 'Discussione finale') {
    return {
      type: 'END',
    };
  }
  return false;
}

export { detectProceedingStart, detectProceedingEnd, detectProceedingPeople };
