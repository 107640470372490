import Cookies from 'js-cookie';
import apiSlice from './apiSlice';

const apiJobSliceWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Job'] });

const apiJobSlice = apiJobSliceWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getJob: builder.query({
      query: (id) => `jobs/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Job', id }],
    }),
    getRootJobs: builder.query({
      query: (arg = {}) => {
        const { page = 1, psize = 10, reference = null, status = null } = arg;

        return {
          url: 'jobs/',
          method: 'GET',
          params: {
            page,
            psize,
            ...(reference && { request_reference: reference }),
            ...(status && { status }),
          },
        };
      },
      providesTags: [{ type: 'Job', id: 'ROOTS_LIST' }],
    }),
    getChildJobs: builder.query({
      query: ({ id, params = {} }) => {
        const { reference = null, status = null } = params;

        return {
          url: `jobs/${id}/children/`,
          method: 'GET',
          params: {
            ...(reference && { request_reference: reference }),
            ...(status && { status }),
          },
        };
      },
      providesTags: (result, error, id) => [{ type: 'Job', id: `CHILDREN_${id}` }],
    }),
    editJob: builder.mutation({
      query: (id, params) => ({
        url: `jobs/${id}/`,
        method: 'PUT',
        body: {
          params,
        },
        headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
      }),
      invalidatesTags: ['Job'],
    }),
    // User needs to create a child job and upload the file in the same action
    // TODO: handle errors
    createChildJob: builder.mutation({
      async queryFn({ parentId, reference = null, file = null, params }, queryApi, extraOptions, fetchWithBQ) {
        try {
          const createChildJobResponse = await fetchWithBQ({
            url: `jobs/${parentId}/children/`,
            body: {
              ...(file?.name && { original_file_name: file.name }),
              ...(reference && { request_reference: reference }),
              params,
            },
            method: 'POST',
            headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
          });
          if (createChildJobResponse.error) return { error: createChildJobResponse.error };

          const uploadInformation = createChildJobResponse.data.upload_information || null;
          if (!uploadInformation) return { data: { ...createChildJobResponse.data, fileUploadResponse: null } };

          const { url } = uploadInformation;
          const { method } = uploadInformation.options;
          const { contentType } = uploadInformation.content.type;
          const fileUploadResponse = await fetchWithBQ({
            url,
            method,
            body: file,
            headers: { 'Content-Type': contentType },
          });
          if (fileUploadResponse.error) return { error: fileUploadResponse.error };

          return { data: { ...createChildJobResponse.data } };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Caught error:', error);
          return { error };
        }
      },
      invalidatesTags: (result, error, { parentId }) => [
        { type: 'Job', id: `CHILDREN_${parentId}` },
        { type: 'Job', id: `STATUS_TREE_${parentId}` },
      ],
    }),
    createJob: builder.mutation({
      query: ({ reference = null, params }) => ({
        url: 'jobs/',
        method: 'POST',
        body: {
          ...(reference && { request_reference: reference }),
          params,
        },
        headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
      }),
      invalidatesTags: ['Job'],
    }),
    getJobStatusTree: builder.query({
      query: (id) => `jobs/${id}/status_tree/`,
      providesTags: (result, error, id) => [{ type: 'Job', id: `STATUS_TREE_${id}` }],
    }),
    setJobReady: builder.mutation({
      query: (id) => ({
        url: `jobs/${id}/set_ready/`,
        method: 'POST',
        headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Job', id: `READY_${id}` }],
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `jobs/${id}/`,
        method: 'DELETE',
        headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
      }),
      invalidatesTags: ['Job'],
    }),
    getJobUploadInfo: builder.query({
      query: (id) => `jobs/${id}/upload_information/`,
      providesTags: (result, error, id) => [{ type: 'Job', id: `UPLOAD_INFO_${id}` }],
    }),
    getJobDownloadInfo: builder.query({
      query: (id) => `jobs/${id}/download_information/`,
      providesTags: (result, error, id) => [{ type: 'Job', id: `DOWNLOAD_INFO_${id}` }],
    }),
    // user needs to upload the file to an existing job (with missing file)
    // TODO: handle errors
    uploadFileToExistingJob: builder.mutation({
      async queryFn({ id, reference = null, file = null }, queryApi, extraOptions, fetchWithBQ) {
        try {
          // TODO: check if it is possible to reuse the defined getJobUploadInfo endpoint instead of this.
          const getUploadInformationResponse = await fetchWithBQ({
            url: `jobs/${id}/upload_information/`,
            method: 'GET',
            headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
          });

          if (getUploadInformationResponse?.data) {
            const uploadInformation = getUploadInformationResponse.data.upload_information;
            const { url } = uploadInformation;
            const { method } = uploadInformation.options;
            const contentType = uploadInformation.content.type;
            const fileUploadResponse = await fetchWithBQ({
              url,
              body: file,
              method,
              headers: {
                'Content-Type': contentType,
                'X-CSRFToken': Cookies.get('csrftoken'),
              },
            });
            return { data: fileUploadResponse };
          }
          return { error: 'No upload information' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['Job'],
    }),
    getDowloadJobFileURL: builder.query({
      query: (id) => `jobs/${id}/download_information/`,
      transformResponse: (response) => {
        /* eslint-disable camelcase */
        const { download_information } = response;
        const { url } = download_information;
        return url || null;
        /* eslint-enable camelcase */
      },
      providesTags: (result, error, id) => [{ type: 'Job', id: `DOWNLOAD_INFO_${id}` }],
    }),
    downloadInputDocx: builder.mutation({
      async queryFn() {
        const fileName = 'demo.docx';
        const res = await fetch(`${import.meta.env.BASE_URL}/static/assets/${fileName}`);
        const blob = await res.blob();
        return { data: blob };
      },
    }),
    rescheduleJob: builder.mutation({
      query: ({ id, type = 'None' }) => ({
        url: `jobs/${id}/reschedule/`,
        method: 'POST',
        body: { restart: type },
        headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Job', id }],
    }),
  }),
});

export const {
  useGetJobQuery,
  useGetRootJobsQuery,
  useLazyGetRootJobsQuery,
  useGetChildJobsQuery,
  useEditJobMutation,
  useCreateJobMutation,
  useCreateChildJobMutation,
  useGetJobStatusTreeQuery,
  useSetJobReadyMutation,
  useDeleteJobMutation,
  useGetJobUploadInfoQuery,
  useGetJobDownloadInfoQuery,
  useUploadFileToExistingJobMutation,
  useGetDowloadJobFileURLQuery,
  useDownloadInputDocxMutation,
  useRescheduleJobMutation,
} = apiJobSlice;

export default apiJobSlice;
