import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, ListItem, ListItemIcon, Checkbox, ListItemText, Button } from '@mui/material';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { selectAllTracks } from '../features/tracksSlice';
import {
  selectBulkProceedingsRecordingsSelectFocus,
  selectBulkProceedingsRecordingsSelectLastItems,
  selectBulkProceedingsRecordingsSelectValues,
  setBulkProceedingsRecordingsSelectFocus,
  setBulkProceedingsRecordingsSelectValues,
} from '../features/bulkProceedingsSlice';
import useBulkProceedingsResults from '../hooks/useBulkProceedingsResults';
import { selectIsSingleProceedingDialogOpen } from '../features/singleProceedingSlice';

function RecordingsSelect() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let selectRef;
  const { filterProceedings, resetFilters } = useBulkProceedingsResults();
  const Tracks = useSelector((state) => selectAllTracks(state.tracks)).filter((trk) => trk.isMixer);
  const values = useSelector(selectBulkProceedingsRecordingsSelectValues);
  const [isOpen, setIsOpen] = useState(false);
  const isFocused = useSelector(selectBulkProceedingsRecordingsSelectFocus);
  const isSingleProceedingDialogOpen = useSelector(selectIsSingleProceedingDialogOpen);
  const lastItems = useSelector(selectBulkProceedingsRecordingsSelectLastItems);

  useEffect(() => {
    if (!isSingleProceedingDialogOpen && values && values.length > 0 && isFocused) selectRef.focus();
    dispatch(setBulkProceedingsRecordingsSelectFocus(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const onChange = useCallback(
    (newValues) => {
      dispatch(setBulkProceedingsRecordingsSelectValues(newValues));
    },
    [dispatch]
  );

  const sortTracks = useCallback((tracks) => {
    return tracks
      .sort((a, b) => {
        if (a.file?.name < b.file?.name) return -1;
        if (a.file?.name > b.file?.name) return 1;
        return 0;
      })
      .map((track) => {
        return { id: track.recordingId, label: track.fileName };
      });
  }, []);
  const sortedTracks = useMemo(() => sortTracks(Tracks), [sortTracks, Tracks]);
  const firstChoice = sortedTracks.find((st) => lastItems.includes(st.label))?.label;
  const search = (e) => {
    e.preventDefault();
    setIsOpen(false);
    filterProceedings();
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const addSearchIconToEndAdornment = (endAdornment) => {
    const children = React.Children.toArray(endAdornment.props.children);
    const button = (
      <Button variant="contained" onClick={search} key="searchIcon" sx={{ ml: 2, verticalAlign: 'middle' }} size="small">
        {t('common.actions.search')}
      </Button>
    );
    children.push(button);
    return React.cloneElement(endAdornment, {}, children);
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      open={isOpen}
      disabled={sortedTracks.length === 0}
      fullWidth
      componentsProps={{ popper: { placement: 'bottom' } }}
      disablePortal
      multiple
      limitTags={2}
      id="searchRecordings"
      options={sortedTracks}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={values}
      onOpen={() => setIsOpen(true)}
      onFocus={() => {
        if (!isSingleProceedingDialogOpen && isFocused && firstChoice) {
          setTimeout(() => {
            const target = document.querySelector(`[data-name-choice="${firstChoice}"]`);
            target.parentNode.scrollTop = target.offsetTop;
          }, 500);
        }
      }}
      onClose={() => {
        setIsOpen(false);
        filterProceedings();
      }}
      onChange={(e, newVal, reason) => {
        if (reason === 'clear') resetFilters();
        onChange(newVal);
      }}
      disableCloseOnSelect
      openOnFocus
      getOptionLabel={(option) => option.label || ''}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem {...props} key={option.id} data-name-choice={option.label}>
            <ListItemIcon>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </ListItem>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={t('recordings.addTracksForm.label')}
            inputRef={(input) => {
              selectRef = input;
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: addSearchIconToEndAdornment(params.InputProps.endAdornment),
              slotProps: { root: { style: { paddingRight: '9em' } } },
            }}
            placeholder={t('recordings.addTracksForm.placeholder')}
          />
        );
      }}
      sx={{
        width: '100%',
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          cursor: 'pointer',
        },
      }}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

export default RecordingsSelect;
