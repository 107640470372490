import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const annotationsRecordingsAdapter = createEntityAdapter();

const annotationsRecordingsSlice = createSlice({
  name: 'annotationsRecordings',
  initialState: annotationsRecordingsAdapter.getInitialState(),
  reducers: {
    loadAnnotationsRecordings: annotationsRecordingsAdapter.setMany,
    editAnnotationsRecordings: annotationsRecordingsAdapter.updateMany,
    removeAnnotationsRecordingsByIds: annotationsRecordingsAdapter.removeMany,
    removeAnnotationsRecordings: annotationsRecordingsAdapter.removeAll,
  },
});

export const { selectById: selectAnnotationsRecordingById, selectIds: selectAnnotationsRecordingsIds, selectAll: selectAllAnnotationsRecordings } = annotationsRecordingsAdapter.getSelectors();

export const { loadAnnotationsRecordings, editAnnotationsRecordings, removeAnnotationsRecordingsByIds, removeAnnotationsRecordings } = annotationsRecordingsSlice.actions;

export default annotationsRecordingsSlice;
