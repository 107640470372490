import { XMLParser } from 'fast-xml-parser';

const alwaysArray = [
  'Dibattimento.Cronologia.Brano',
  'Dibattimento.Cronologia.Brano.SubBrano',
  'Dibattimento.Cronologia.Brano.SubBrano.Intervento',
  'Dibattimento.Cronologia.Brano.SubBrano.Marker',
  'Dibattimento.Audio.Brano',
  'Dibattimento.Audio.Brano.SubBrano',
  'Dibattimento.Audio.Brano.SubBrano.Intervento',
  'Dibattimento.Audio.Brano.SubBrano.Marker',
];

const parser = new XMLParser({
  ignoreAttributes: false,
  trimValues: false,
  attributeNamePrefix: '@_',
  // eslint-disable-next-line no-unused-vars
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (alwaysArray.indexOf(jpath) !== -1) return true;
    return false;
  },
});

async function parseCronologiaFromXml(XMLdata) {
  if (!XMLdata) return null;
  return parser.parse(XMLdata);
}

export { parseCronologiaFromXml };

export default parseCronologiaFromXml;
