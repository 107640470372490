import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import BulkProceedingsCdCard from './BulkProceedingsCdCard';

function BulkProceedingsCdData({ cds, deleteCd, saveCd, cancelCd }) {
  return (
    <Stack component="section" id="BulkProceedingsCdUpload" direction="column" spacing={2}>
      {cds?.map((cd) => (
        <BulkProceedingsCdCard key={cd.id} cd={cd} deleteCd={deleteCd} saveCd={saveCd} cancelCd={cancelCd} />
      ))}
    </Stack>
  );
}

BulkProceedingsCdData.defaultProps = {
  deleteCd: null,
  saveCd: null,
  cancelCd: null,
};

BulkProceedingsCdData.propTypes = {
  cds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteCd: PropTypes.func,
  saveCd: PropTypes.func,
  cancelCd: PropTypes.func,
};

export default BulkProceedingsCdData;
