import { Box } from '@mui/material';
import JobList from './JobList';
import TDSConnector from '../../TDSConnector';
import Pagination from '../../Pagination';
import useJobs from '../../../hooks/useJobs';

function JobsPage() {
  const { count, page, pageSize, handlePageChange, handlePageSizeChange } = useJobs();

  return (
    <Box component="section" id="JobsPage" data-testid="JobsPage">
      <TDSConnector />
      <Pagination count={count} page={page} pageSize={pageSize} handlePageChange={handlePageChange} handlePageSizeChange={handlePageSizeChange} />
      <JobList />
    </Box>
  );
}

export { default as Job } from './Job';
export default JobsPage;
