import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Stack, Typography, Tooltip, Divider } from '@mui/material';
import TrialForm from './TrialForm';
import { closeTrialDialog, selectIsTrialDialogOpen, resetTrial } from '../features/trialEditingSlice';
import TrialEditSubmit from './TrialEditSubmit';
import useTrialEdit from '../hooks/useTrialEdit';

function TrialDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trial } = useTrialEdit();
  const { ticketId, status } = trial;
  const isTrialDialogOpen = useSelector(selectIsTrialDialogOpen);

  const closeDialog = () => {
    dispatch(closeTrialDialog());
    dispatch(resetTrial());
  };

  return (
    <Dialog onClose={(e, reason) => reason === 'backdropClick' && closeDialog()} open={isTrialDialogOpen} fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: 1, backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>{t('trials.dialogs.editTrial.title')}</Typography>
          <Tooltip title={t('trials.form.editTrial.fields.ticketId')}>
            <Typography>{ticketId}</Typography>
          </Tooltip>
          <Tooltip title={t('trials.form.editTrial.fields.status')}>
            <Typography>{status}</Typography>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <TrialForm />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={closeDialog}>
          {t('common.actions.close')}
        </Button>
        <TrialEditSubmit />
      </DialogActions>
    </Dialog>
  );
}

export default TrialDialog;
