import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Replay } from '@mui/icons-material';
import { useRescheduleJobMutation } from '../../../features/api/rest/apiJobSlice';
import { setAlertMessage } from '../../../features/layoutSlice';

function RescheduleJobButton({ job }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rescheduleJobTrigger] = useRescheduleJobMutation();

  const handleJobScheduleClick = async (e) => {
    e.stopPropagation();
    try {
      await rescheduleJobTrigger({ id: job.id, type: 'failed' }).unwrap();
    } catch (error) {
      dispatch(setAlertMessage({ text: t('jobs.creation.errors.settingJobReady.proceeding'), type: 'error' }));
    }
  };

  return (
    <IconButton title={t('jobs.actions.reschedule.label')} size="small" onClick={handleJobScheduleClick}>
      <Replay />
    </IconButton>
  );
}

RescheduleJobButton.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default RescheduleJobButton;
