import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const annotationsAdapter = createEntityAdapter({
  selectId: (annotation) => annotation.id,
});

const annotationsSlice = createSlice({
  name: 'annotations',
  initialState: annotationsAdapter.getInitialState(),
  reducers: {
    loadAnnotations: annotationsAdapter.setMany,
    removeAnnotations: annotationsAdapter.removeAll,
  },
});

export const { selectById: selectAnnotationById, selectIds: selectAnnotationIds, selectEntities: selectAnnotationEntities, selectAll: selectAllAnnotations, selectTotal: selectTotalAnnotations } = annotationsAdapter.getSelectors();

export const { loadAnnotations, removeAnnotations } = annotationsSlice.actions;

export default annotationsSlice;
