import { Box } from '@mui/material';
import TrialsNavigationBar from './TrialsNavigationBar';
import TrialsResultPage from './TrialsResultPage';
import TrialDialog from './TrialDialog';

function Trials() {
  return (
    <Box component="section" id="Trials">
      <TrialDialog />
      <TrialsNavigationBar />
      <TrialsResultPage />
    </Box>
  );
}

export default Trials;
