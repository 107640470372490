import apiSlice from './apiSlice';
import { getToken } from './configApi';
import processProfile from './utils/processProfile';

const apiProfileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      queryFn: async () => {
        try {
          const jwt = await getToken();
          const profile = processProfile(jwt);
          if (profile) {
            return { data: profile };
          }
          return { data: null };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetProfileQuery, useLazyGetProfileQuery } = apiProfileSlice;

export default apiProfileSlice;
