import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const cdsAdapter = createEntityAdapter();

const cdsSlice = createSlice({
  name: 'cds',
  initialState: cdsAdapter.getInitialState(),
  reducers: {
    loadCd: cdsAdapter.setOne,
    loadCds: cdsAdapter.setMany,
    editCd: cdsAdapter.updateOne,
    editCds: cdsAdapter.updateMany,
    addCd: cdsAdapter.addOne,
    removeCd: cdsAdapter.removeOne,
    removeCdsByIds: cdsAdapter.removeMany,
    removeCds: cdsAdapter.removeAll,
  },
});

export const { selectById: selectCdById, selectIds: selectCdIds, selectEntities: selectCdEntities, selectAll: selectAllCds, selectTotal: selectTotalCds } = cdsAdapter.getSelectors();

export const { loadCd, loadCds, editCd, editCds, addCd, removeCd, removeCdsByIds, removeCds } = cdsSlice.actions;

export default cdsSlice;
