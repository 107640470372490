import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from './Spinner';
import useProceedingResults from '../hooks/useProceedingResults';
import ProceedingResultCard from './ProceedingResultCard';
import { useGetProfileQuery as useGetTDSProfileQuery } from '../features/api/tds/apiProfileSlice';

function ProceedingsByTrialResult() {
  const { t } = useTranslation();
  const { filteredProceedings: proceedings, isFetching, isLoading, isError, trial } = useProceedingResults();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    if (location.state) navigate('/trials'.concat(location.state.params));
    else navigate('/');
  };
  const { hasSensitiveDataAccess } = useGetTDSProfileQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      hasSensitiveDataAccess: result?.currentData?.hasSensitiveDataAccess,
    }),
  });
  const createProceedingResultCard = (proceeding) => <ProceedingResultCard key={proceeding.id_procedimento} proceeding={proceeding} hasSensitiveDataAccess={hasSensitiveDataAccess} workAwayFlag={trial.trasferta} />;

  useEffect(() => {
    if (!isFetching && !isLoading && !trial) goBack();
  }, [trial, isFetching, isLoading]);

  if (isFetching || isLoading) return <Spinner />;
  if (isError) return <div>{t('proceedings.proceedingsList.messages.errors.loading')}</div>;

  return (
    <Stack component="section" id="ProceedingsByTrial" direction="column" my={2} spacing={1}>
      {proceedings?.map(createProceedingResultCard)}
    </Stack>
  );
}

export default ProceedingsByTrialResult;
