import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const loadedCdsAdapter = createEntityAdapter();

const loadedCdsSlice = createSlice({
  name: 'loadedCds',
  initialState: loadedCdsAdapter.getInitialState(),
  reducers: {
    addLoadedCd: loadedCdsAdapter.addOne,
    addLoadedCds: loadedCdsAdapter.addMany,
    removeLoadedCdsByIds: loadedCdsAdapter.removeMany,
    removeLoadedCds: loadedCdsAdapter.removeAll,
  },
});

export const { selectById: selectLoadedCdById, selectIds: selectLoadedCdIds, selectEntities: selectLoadedCdEntities, selectAll: selectAllLoadedCds, selectTotal: selectTotalLoadedCds } = loadedCdsAdapter.getSelectors();

export const { addLoadedCd, addLoadedCds, removeLoadedCd, removeLoadedCdsByIds, removeLoadedCds } = loadedCdsSlice.actions;

export default loadedCdsSlice;
