import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TextField, TableRow, TableCell, ClickAwayListener, MenuItem } from '@mui/material';
import TdsTimePicker from '../../../TdsTimePicker';
import { addAnnotationsTurn, editAnnotationsTurn } from '../../../../features/annotationsTurns';
import { setAlertMessage } from '../../../../features/layoutSlice';
import { computeTurnId } from '../../../../lib/parse-recordings-annotations/adjust';

function TurnForm({ turn, channels, refDate, recordingId, setMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [time, setTime] = useState(turn?.time || null);
  const [channel, setChannel] = useState(turn?.channel || '');

  const saveTurn = async () => {
    if (channel !== '' && time !== '') {
      if (turn) dispatch(editAnnotationsTurn({ id: turn.id, changes: { time, channel } }));
      else {
        const newTurn = { time, channel, recordingId };
        const newTurnId = await computeTurnId(newTurn, recordingId);
        const turnToLoad = { ...newTurn, id: newTurnId };
        dispatch(addAnnotationsTurn(turnToLoad));
      }
      setMode(false);
    } else dispatch(setAlertMessage({ text: t('annotationsEditor.errors.requiredFields'), type: 'warning', render: 'snack' }));
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={saveTurn}>
      <TableRow>
        <TableCell width="40%">
          <TdsTimePicker name="turnTime" label={t('annotationsEditor.recordings.turns.turn.time')} required onChange={(newVal) => setTime(newVal)} initialValue={time} refDate={refDate} format="LTS" />
        </TableCell>
        <TableCell colSpan={2} width="60%">
          <TextField
            fullWidth
            select
            inputProps={{ 'data-testid': 'trackName' }}
            label={t('annotationsEditor.recordings.turns.turn.trackName')}
            SelectProps={{
              MenuProps: { disablePortal: true },
            }}
            onChange={(e) => setChannel(e.target.value)}
            value={channel}
            variant="outlined"
          >
            {channels?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField inputProps={{ 'data-testid': 'trackName' }} label={t('annotationsEditor.recordings.turns.turn.trackName')} onChange={(e) => setChannel(e.target.value)} value={channel} variant="outlined" /> */}
        </TableCell>
      </TableRow>
    </ClickAwayListener>
  );
}

TurnForm.defaultProps = {
  turn: null,
  refDate: null,
};

TurnForm.propTypes = {
  turn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recordingId: PropTypes.string,
    channel: PropTypes.string,
    time: PropTypes.string,
  }),
  refDate: PropTypes.string,
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  recordingId: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};

export default TurnForm;
