import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Stack, DialogContentText, Typography, List, ListItem, ListItemText, ListItemAvatar, ListItemIcon } from '@mui/material';
import { Article, HeadsetMic } from '@mui/icons-material';
import { selectAllTracks } from '../features/tracksSlice';
import { selectAllOtherFiles } from '../features/otherFilesSlice';
import { selectProceedingFormOtherFiles, selectProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';

function FilesConfirmtionDialog({ closeHandler, isOpen }) {
  const { t } = useTranslation();

  const tracks = useSelector((state) => selectAllTracks(state.tracks));
  const proceedingRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const proceedingTracksToUpload = useMemo(() => tracks.filter((track) => proceedingRecordings?.includes(track.recordingId) && track.isMixer), [tracks, proceedingRecordings]);
  const otherFiles = useSelector((state) => selectAllOtherFiles(state.otherFiles));
  const proceedingOtherFiles = useSelector(selectProceedingFormOtherFiles);
  const proceedingOtherFilesToUpload = useMemo(() => otherFiles.filter((otherFile) => proceedingOtherFiles?.includes(otherFile.id)), [otherFiles, proceedingOtherFiles]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: 'warning.main', color: 'warning.contrastText' }}>{t('proceedings.dialogs.filesConfirmationDialog.title')}</DialogTitle>
      <Divider />
      <DialogContentText m={1}>{t('proceedings.dialogs.filesConfirmationDialog.text')}</DialogContentText>
      <DialogContent sx={{ py: 1, px: 2 }}>
        <DialogContentText>{t('proceedings.dialogs.filesConfirmationDialog.audioFilesText')}</DialogContentText>
        <List disablePadding>
          {proceedingTracksToUpload?.map((trk) => (
            <ListItem key={trk.file.name} disableGutters disablePadding>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <HeadsetMic fontSize="small" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={trk.file.name} />
            </ListItem>
          ))}
        </List>
        <DialogContentText>{t('proceedings.dialogs.filesConfirmationDialog.otherFilesText')}</DialogContentText>
        <List disablePadding>
          {proceedingOtherFilesToUpload?.map((of) => (
            <ListItem key={of.file.name} disableGutters disablePadding>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <Article fontSize="small" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={of.file.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContentText m={1}>{t('proceedings.dialogs.filesConfirmationDialog.question')}</DialogContentText>
      <Divider />
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={() => closeHandler(false)}>
          {t('common.actions.edit')}
        </Button>
        <Button color="primary" variant="contained" onClick={() => closeHandler(true)}>
          {t('common.actions.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FilesConfirmtionDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default FilesConfirmtionDialog;
