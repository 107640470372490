import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import JobRow from './JobRow';
import useJobs from '../../../hooks/useJobs';

function JobList() {
  const { t } = useTranslation();
  const { jobList, error } = useJobs();

  const tableStyles = {
    '& th': {
      borderBottom: 'none',
    },
    '& tbody tr': {
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
  };

  if (jobList && !error?.status) {
    return (
      <Table id="JobsTable" data-testid="JobsTable" sx={tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.status.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.date.created.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.date.lastUpdated.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.requestReference.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.originalFileName.label')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{t('jobs.progress.label')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobList.map((job) => (
            <JobRow key={job.id} id={job.id} job={job} />
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default JobList;
