import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from '../components/Layout';
import Login from '../components/Login';
import ResetPassword from '../components/ResetPassword';
import ChangePassword from '../components/ChangePassword';
import SignUp from '../components/SignUp';
import VerifyEmail from '../components/VerifyEmail';
import RequireAuth from '../components/RequireAuth';
import ProceedingsByTrial from '../components/ProceedingsByTrial';
import Trials from '../components/Trials';
import { Prints, Jobs, Job, Profile, ErrorPage, External } from '../components/Pages';
import config from './config';

const navigation = {
  items: [
    { id: 0, path: '/trials?statusId=2&orderby=startDate-asc', label: 'navigation.main.items.trials', permissions: ['customer', 'manager'] },
    { id: 1, path: '/prints', label: 'navigation.main.items.prints', permissions: ['manager'] },
    { id: 3, path: '/jobs', label: 'navigation.main.items.jobs', permissions: ['customer', 'manager'] },
    !!window.electronAPI && { id: 4, path: '/tds', label: 'navigation.main.items.tds', permissions: ['customer', 'manager'] },
    !!window.electronAPI && { id: 5, path: '/gtl', label: 'navigation.main.items.gtl', permissions: ['customer', 'manager'] },
  ].filter(Boolean),
};

function App() {
  return (
    <Routes>
      <Route element={<Layout hasMainNavBar navItems={navigation.items} maxContainerWidth="xxl" hasFooter />}>
        <Route element={<RequireAuth />}>
          <Route path="prints" element={<Prints />} />
          <Route index element={<Navigate to="trials?statusId=2&orderby=startDate-asc" />} />
          <Route path="trials" element={<Trials />} />
          <Route path="trials/:id/proceedings" element={<ProceedingsByTrial />} />
          <Route path="jobs">
            <Route index element={<Jobs />} />
            <Route path=":id" element={<Job />} />
          </Route>
          <Route path="profile" element={<Profile />} />
        </Route>
      </Route>
      {!!window.electronAPI && (
        <Route element={<Layout hasMainNavBar navItems={navigation.items} maxContainerWidth="xxl" maximizeContainer hasFooter />}>
          <Route element={<RequireAuth />}>
            {!!window.electronAPI && <Route path="tds" element={<External src={config.api.tds.homeUrl} />} />}
            {!!window.electronAPI && <Route path="gtl" element={<External src={config.api.gtl.homeUrl} />} />}
          </Route>
        </Route>
      )}
      <Route element={<Layout maxContainerWidth="sm" hasFooter />}>
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<SignUp />} />
        <Route path="registration/account-confirm-email" element={<VerifyEmail />} />
        <Route path="registration/account-confirm-email/:key" element={<VerifyEmail />} />
        <Route path="reset/password" element={<ResetPassword />} />
        <Route path="reset/password/:id/:token" element={<ChangePassword />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

export default App;
