import { Stack } from '@mui/material';
import ProceedingFilters from './ProceedingFilters';
import ProceedingsOrderBy from './ProceedingsOrderBy';

function ProceedingsByTrialNavbar() {
  return (
    <Stack component="aside" id="ProceedingsByTrialNavbar" data-testid="ProceedingsByTrialNavbar" spacing={1}>
      <ProceedingFilters color="primary" />
      <ProceedingsOrderBy color="primary" />
    </Stack>
  );
}

export default ProceedingsByTrialNavbar;
