import { useState, useEffect } from 'react';
import Proptype from 'prop-types';
import { Chip, Skeleton } from '@mui/material';

function StatusChip({ statusId, statusOptions, isLoading, isFetching, label, onDelete, color }) {
  const [statusLabel, setStatusLabel] = useState('');

  useEffect(() => {
    if (statusOptions) setStatusLabel(statusOptions.find((st) => st.id === statusId)?.descrizione);
  }, [statusOptions, statusId]);

  if (isLoading || isFetching) return <Skeleton width="4em" height="2em" animation="pulse" />;

  return statusId && <Chip variant="filled" color={color} label={`${label}: ${statusLabel}`} onDelete={onDelete} />;
}

StatusChip.defaultProps = {
  statusId: '',
  statusOptions: [],
  isLoading: false,
  isFetching: false,
  color: 'primary',
};

StatusChip.propTypes = {
  label: Proptype.string.isRequired,
  statusId: Proptype.string,
  statusOptions: Proptype.arrayOf(Proptype.shape({ id: Proptype.string, descrizione: Proptype.string })),
  isLoading: Proptype.bool,
  isFetching: Proptype.bool,
  onDelete: Proptype.func.isRequired,
  color: Proptype.string,
};

export default StatusChip;
