import { Box, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function JobProgressBar({ progress }) {
  if (!progress) return <Typography variant="body3">-</Typography>;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress data-testid="progressBar" sx={{ borderRadius: 5, height: 10 }} color={progress === '100' ? 'success' : 'secondary'} variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body3" color={progress === '100' ? 'text.success' : 'text.secondary'}>{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
}

JobProgressBar.defaultProps = {
  progress: null,
};

JobProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default JobProgressBar;
