import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

function ProceedingFormTextField({ name, label, initValue, defaultValue, onChange, sx, required, disabled, multiline, variant, type, placeholder, InputLabelProps, debounceInterval, size }) {
  const [value, setValue] = useState(() => (initValue ? initValue.toUpperCase() : ''));
  const debouncedOnChange = useDebouncedCallback(onChange, debounceInterval);

  useEffect(() => {
    if (value !== initValue) debouncedOnChange(value);
  }, [value, debouncedOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextField
      size={size}
      inputProps={{ 'data-testid': name }}
      sx={sx}
      id={name}
      name={name}
      multiline={multiline}
      required={required}
      value={value}
      defaultValue={defaultValue}
      onChange={(e) => setValue(e.target.value ? e.target.value.toUpperCase() : '')}
      label={label}
      variant={variant}
      type={type}
      placeholder={placeholder}
      InputLabelProps={InputLabelProps}
    />
  );
}

ProceedingFormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initValue: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  debounceInterval: PropTypes.number,
  size: PropTypes.string,
};

ProceedingFormTextField.defaultProps = {
  initValue: '',
  defaultValue: undefined,
  sx: undefined,
  required: false,
  disabled: false,
  multiline: false,
  variant: 'outlined',
  type: undefined,
  placeholder: undefined,
  InputLabelProps: undefined,
  debounceInterval: 600,
  size: 'medium',
};

export default ProceedingFormTextField;
