import { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { AudioContext } from './context';

function AudioProvider({ children }) {
  const audioRef = useRef(null);

  const playAudio = useCallback((newAudio) => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    audioRef.current = newAudio;
  }, []);

  const pauseAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    audioRef.current = null;
  }, []);

  const contextValue = useMemo(() => ({ playAudio, pauseAudio }), [playAudio, pauseAudio]);

  return <AudioContext.Provider value={contextValue}>{children}</AudioContext.Provider>;
}

AudioProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AudioProvider;
