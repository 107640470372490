import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const otherFilesAdapter = createEntityAdapter({
  selectId: (otherFile) => otherFile.id,
});

const otherFilesSlice = createSlice({
  name: 'otherFiles',
  initialState: otherFilesAdapter.getInitialState(),
  reducers: {
    loadOtherFiles: otherFilesAdapter.setMany,
    removeOtherFiles: otherFilesAdapter.removeAll,
    removeOtherFilesByIds: otherFilesAdapter.removeMany,
    editOtherFiles: otherFilesAdapter.updateOne,
  },
});

export const { selectById: selectOtherFileById, selectIds: selectOtherFilesIds, selectEntities: selectOtherFilesEntities, selectAll: selectAllOtherFiles, selectTotal: selectTotalOtherFiles } = otherFilesAdapter.getSelectors();

export const { loadOtherFiles, removeOtherFiles, removeOtherFilesByIds, editOtherFiles } = otherFilesSlice.actions;

export default otherFilesSlice;
