import PropTypes from 'prop-types';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircle as AccountCircleIcon, Email as EmailIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function ProfileShow({ profile }) {
  const { t } = useTranslation();

  const boxColor = { backgroundColor: 'hsl(207 100% 90% / 1)', borderRadius: '10px' };

  return (
    <List data-testid="ProfileShow">
      <Grid container>
        <Grid item xs={12} sx={{ ...boxColor, mb: 2 }}>
          <Grid container p={2}>
            <Grid item xs={12} sm={6}>
              <ListItem data-testid="Username">
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={t('profile.fields.username.label')} secondary={profile?.username} />
              </ListItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ ...boxColor, mb: 2 }}>
          <Grid container p={2}>
            <Grid item xs={12} sm={6}>
              <ListItem data-testid="Email">
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary={t('profile.fields.email.label')} secondary={profile?.email} />
              </ListItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
}

ProfileShow.defaultProps = {
  profile: {
    id: '',
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    hasSensitiveDataAccess: false,
    emailVerified: false,
    lastLogin: '',
  },
};

ProfileShow.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    hasSensitiveDataAccess: PropTypes.bool,
    emailVerified: PropTypes.bool,
    lastLogin: PropTypes.string,
  }),
};

export default ProfileShow;
