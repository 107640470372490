function formatTextWithPadding(input, padding = ' '.repeat(48)) {
  const lines = input.split('\n');
  const formattedLines = lines.map((line, index) => {
    if (index === 0) {
      return line;
    }
    if (index === lines.length - 1 && line === '') {
      return '\n';
    }
    return padding + line;
  });
  return formattedLines.join('\n');
}

function formatColumns(firstColumn, secondColumn, firstColumnWidth = 0, filler = ' ') {
  const firstColumnText = firstColumn.padEnd(firstColumnWidth, filler);
  return `${firstColumnText}${secondColumn}`;
}

export { formatTextWithPadding, formatColumns };

export default formatTextWithPadding;
