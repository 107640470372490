import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { selectProceedingFormRecordings } from '../features/proceedingFormSlice';
import Recording from './Recording';
import AddTracksCard from './AddTracksCard';

function ProceedingTracks() {
  const proceedingRecordings = useSelector(selectProceedingFormRecordings);

  return (
    <Stack spacing={1} direction="column">
      {proceedingRecordings?.map((recordingId) => (
        <Recording key={recordingId} recordingId={recordingId} />
      ))}
      <AddTracksCard />
    </Stack>
  );
}

export default ProceedingTracks;
