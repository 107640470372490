import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AccountCircle as AccountCircleIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography, Alert } from '@mui/material';
import { LoginSocialTds } from './custom-social-login/LoginSocialTds/index.tsx';
import TdsLoginButton from './custom-social-login-buttons/buttons/TdsLoginButton';
import { useLoginWithTdsMutation } from '../features/api/rest/apiAuthSlice';
import { setToken } from '../features/api/tds/configApi';
import { useLoginMutation } from '../features/api/tds/apiAuthSlice';
import apiSlice from '../features/api/rest/apiSlice';
import config from '../app/config';
import { setAlertMessage } from '../features/layoutSlice';
import { selectTDSAuth, setTDSAuth, setTranscriptionAuth } from '../features/authSlice';
import Spinner from './Spinner';
import { mapProfileData } from '../utils/profileUtils';

function Login() {
  const { t } = useTranslation();
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [loginWithTds, { isLoading: isLoginWithTdsFLoading }] = useLoginWithTdsMutation();
  const [logout] = apiSlice.useLogoutMutation();
  const { isAuth: isTDSAuth } = useSelector(selectTDSAuth);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [numLockOn, setNumLockOn] = useState(false);

  const handleKeyUp = (e) => {
    const capsLockIsOn = e.getModifierState('CapsLock');
    const numLockIsOn = e.getModifierState('NumLock');
    setCapsLockOn(capsLockIsOn);
    setNumLockOn(numLockIsOn);
  };

  const goToNextComponent = () => {
    const next = searchParams.get('next');
    let to = '/';
    if (next !== null) {
      window.location.replace(next);
    } else if (location.state?.next) {
      to = `${location.state?.next}${location.state?.nextSearch}`;
    }
    return <Navigate to={to} />;
  };

  const handleUserChange = ({ target }) => {
    setUser(target.value);
  };
  const handlePwdChange = ({ target }) => {
    setPwd(target.value);
  };
  const handleClickShowPassword = () => {
    setShowPwd((prevShowPwd) => !prevShowPwd);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((char) => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  function getUserData(token) {
    const data = decodeJwt(token);
    return data?.user;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let accessToken = null;
    try {
      const response = await login({ username: user, password: pwd }).unwrap();
      accessToken = response.response.token;
      dispatch(setTDSAuth({ isAuth: true, user: mapProfileData(getUserData(accessToken)) }));
    } catch (err) {
      return navigate(location, { state: { ...location.state } });
    }
    try {
      const response = await loginWithTds({ accessToken }).unwrap();
      dispatch(setTranscriptionAuth({ isAuth: true, user: mapProfileData(response.user) }));
    } catch (err) {
      dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.TDS.error') }));
    }
    dispatch(setAlertMessage({ text: t('auth.messages.login.success'), type: 'success' }));
    return null;
  };

  const handleTdsLoginSuccess = async (response) => {
    let accessToken = null;
    try {
      accessToken = response.data.code;
      await setToken(accessToken);
      dispatch(setTDSAuth({ isAuth: true, user: mapProfileData(getUserData(accessToken)) }));
    } catch (err) {
      return navigate(location, { state: { ...location.state } });
    }
    try {
      await loginWithTds({ accessToken }).unwrap();
      dispatch(setTranscriptionAuth({ isAuth: true, user: mapProfileData(response.data.user) }));
    } catch (err) {
      dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.TDS.error') }));
    }
    dispatch(setAlertMessage({ text: t('auth.messages.login.success'), type: 'success' }));
    return null;
  };

  const handleError = (err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };

  useEffect(() => {
    if (!isTDSAuth) logout();
  }, [dispatch, logout, isTDSAuth]);

  const tdsButton = (
    <Stack justifyContent="center" alignItems="center">
      <LoginSocialTds isOnlyGetCode redirect_uri={config.overrideLoginRedirectUrl || window.location.href} tdsLoginUrl={config.api.tds.loginUrl} tdsApiUrl={config.api.tds.url} onResolve={handleTdsLoginSuccess} onReject={handleError}>
        <TdsLoginButton style={{ width: '300px' }}>{t('auth.loginForm.fields.login.tds')}</TdsLoginButton>
      </LoginSocialTds>
    </Stack>
  );

  if (isLoginLoading || isLoginWithTdsFLoading) return <Spinner />;
  if (isTDSAuth) return goToNextComponent();

  return (
    <Stack component="section" id="Login" data-testid="Login" direction="column" spacing={2} sx={{ mt: 4 }}>
      <Typography variant="h1" align="center">
        {config.siteName}
      </Typography>

      <Stack component="form" direction="column" alignItems="center" onSubmit={handleSubmit}>
        <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="username">{t('auth.loginForm.fields.username.label')}</InputLabel>
          <OutlinedInput
            id="username"
            type="text"
            required
            value={user}
            onKeyUp={handleKeyUp}
            onChange={handleUserChange}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            }
            label={t('auth.loginForm.fields.username.label')}
          />
        </FormControl>

        <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="password">{t('auth.loginForm.fields.password.label')}</InputLabel>
          <OutlinedInput
            id="password"
            type={showPwd ? 'text' : 'password'}
            required
            value={pwd}
            onKeyUp={handleKeyUp}
            onChange={handlePwdChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={t('aria.icons.togglePasswordVisibility')} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            label={t('auth.loginForm.fields.password.label')}
          />
        </FormControl>

        {capsLockOn && (
          <Alert sx={{ mt: 2 }} severity="warning">
            {t('auth.loginForm.capsLockOn')}
          </Alert>
        )}

        {numLockOn && (
          <Alert sx={{ mt: 2 }} severity="warning">
            {t('auth.loginForm.numLockOn')}
          </Alert>
        )}

        <Grid>
          <Button type="submit" color="secondary" variant="contained" sx={{ p: 1, mt: 2, mb: 1 }}>
            {t('auth.loginForm.fields.login.label')}
          </Button>
        </Grid>
      </Stack>

      {tdsButton}
    </Stack>
  );
}

export default Login;
