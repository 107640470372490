import apiSlice from './apiSlice';
import { formatParameters } from './configApi';

const apiPeopleSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJudges: builder.query({
      query: ({ filters, orderby, page, pSize }) => ({
        url: '/getGiudici',
        method: 'GET',
        params: formatParameters({ filters, orderby, page, pSize }),
      }),
      transformResponse: (response) => response.giudici,
    }),
    getPublicProsecutors: builder.query({
      query: ({ filters, orderby, page, pSize }) => ({
        url: '/getPM',
        method: 'GET',
        params: formatParameters({ filters, orderby, page, pSize }),
      }),
      transformResponse: (response) => response.pm,
    }),
  }),
});

export const { useGetJudgesQuery, useGetPublicProsecutorsQuery } = apiPeopleSlice;

export default apiPeopleSlice;
