import PropTypes from 'prop-types';
import { Typography, Card, CardHeader, IconButton, Skeleton, Box, Grid, CardActions, Checkbox } from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AudioPlayer from './AudioPlayer';
import useProceedingStagedTrack from '../hooks/useProceedingStagedTrack';

function ProceedingStagedTrack({ trackId }) {
  const { t } = useTranslation();
  const { track, removeTrack, fileName, isLoading, isFetching, isError } = useProceedingStagedTrack(trackId);

  if (isError) return <Typography>{t('recordings.errors.fileFetching')}</Typography>;
  if (isLoading || isFetching) return <Skeleton variant="rectangular" height="3em" animation="pulse" />;

  return (
    <Card elevation={3}>
      <Box sx={{ bgcolor: 'warning.light', display: 'flex' }}>
        <CardHeader
          disableTypography
          sx={{ p: 0.5, pr: 1, flex: 1, '& .MuiCardHeader-avatar': { mr: 1 } }}
          title={
            <Grid container alignItems="center">
              <Grid typography="caption" item xs={7}>
                {fileName}
              </Grid>
              <Grid item xs={5}>
                <AudioPlayer size="small" file={track.file} />
              </Grid>
            </Grid>
          }
          avatar={
            <IconButton size="small" disabled>
              <ExpandMore />
            </IconButton>
          }
        />
        <CardActions sx={{ padding: 0 }} disableSpacing>
          <IconButton size="small" onClick={() => removeTrack()}>
            <Delete />
          </IconButton>
          <Checkbox checked disabled size="small" />
        </CardActions>
      </Box>
    </Card>
  );
}

ProceedingStagedTrack.propTypes = {
  trackId: PropTypes.string.isRequired,
};

export default ProceedingStagedTrack;
