import { t } from 'i18next';
import { setTDSAuth, setTDSNetWorkError, setTranscriptionNetWorkError, setTDSLastFullfilledTimeStamp, setTranscriptionLastFullfilledTimeStamp, setShouldLogoutASAPFromTDS, setTranscriptionAuth, setShouldLoginASAPOnTranscription } from '../../authSlice';
import apiTdsSlice from '../tds/apiSlice';
import { cleanAlertMessage, setAlertMessage } from '../../layoutSlice';
import appConfig from '../../../app/config';

const resetTdsApiSlice = (dispatch) => dispatch(apiTdsSlice.util.resetApiState());

export const hasTimeThresholdPassed = (thresholdMilliseconds, timestamp1, timestamp2) => {
  const diff = Math.abs(timestamp1 - timestamp2);

  if (diff <= thresholdMilliseconds) {
    return false;
  }
  return true;
};

const apiSessionMonitoringMiddleware = (storeAPI) => (next) => (action) => {
  // TDS
  if (action.type.startsWith('tds/')) {
    if (action.type.endsWith('/rejected')) {
      // TDS FAILURES
      const error = action.payload;

      if (action.type === 'tds/executeMutation/rejected' && action.meta.arg.endpointName === 'login') {
        // TDS LOGIN FAILED
        if (error?.status === 400 && error?.data.error === 'Invalid Login') storeAPI.dispatch(setAlertMessage({ text: t('auth.messages.login.errors.invalidCredentials'), type: 'error' }));
        if (error?.status === 'FETCH_ERROR') storeAPI.dispatch(setAlertMessage({ text: t('auth.messages.login.errors.connectionError'), type: 'error' }));
      } else if (error?.status === 401 || error?.status === 403) {
        // TDS SESSION EXPIRED
        resetTdsApiSlice(storeAPI.dispatch);
        storeAPI.dispatch(setTDSAuth({ isAuth: false, user: null }));
        storeAPI.dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.sessionExpired.error') }));
      } else if (error?.status === 'FETCH_ERROR') {
        // TDS CONNECTION LOST
        if (!storeAPI.getState().auth.TDS.netWorkError) {
          // TDS CONNECTION LOST FIRST TIME
          storeAPI.dispatch(setTDSNetWorkError(true));
          storeAPI.dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.connection.error') }));
        }
        if (hasTimeThresholdPassed(appConfig.api.backend.expirationThreshold, storeAPI.getState().auth.TDS.lastFullfilledTimeStamp, Date.now())) {
          // TDS CONNECTION LOST FOR TOO LONG --> LOGOUT
          if (!storeAPI.getState().auth.TDS.shouldLogoutASAP && storeAPI.getState().auth.TDS.isAuth) storeAPI.dispatch(setShouldLogoutASAPFromTDS(true));
        }
      }
    }

    if (action.type.endsWith('/fulfilled')) {
      storeAPI.dispatch(setTDSLastFullfilledTimeStamp(action.meta.fulfilledTimeStamp));
      // TDS SUCCESS
      if (action.meta.arg.endpointName === 'validateToken') {
        // TDS VALIDATE TOKEN CHECK
      } else if (action.type === 'tds/executeMutation/fulfilled' && action.meta.arg.endpointName === 'logout') {
        // TDS LOGOUT SUCCESS
        resetTdsApiSlice(storeAPI.dispatch);
      } else if (action.type === 'tds/executeMutation/fulfilled' && action.meta.arg.endpointName === 'login') {
        // TDS LOGIN SUCCESS
      }

      if (storeAPI.getState().auth.TDS.netWorkError) {
        // TDS CONNECTION RECOVERED
        storeAPI.dispatch(setTDSNetWorkError(false));
        storeAPI.dispatch(cleanAlertMessage());
      }
    }
  }

  if (action.type.startsWith('api/')) {
    // TRANSCRIPTION BACKEND
    if (action.type.endsWith('/rejected')) {
      // TRANSCRIPTION FAILURES
      const error = action.payload;

      if (action.type === 'api/executeMutation/rejected' && action.meta.arg.endpointName === 'logout') {
        // TRANSCRIPTION LOGOUT FAILED
      } else if (action.type === 'api/executeMutation/rejected' && action.meta.arg.endpointName === 'loginWithTds') {
        // TRANSCRIPTION LOGIN FAILED
      } else if (error?.status === 401) {
        // TRANSCRIPTION AUTH ERROR
        if (storeAPI.getState().auth.Transcription.netWorkError) storeAPI.dispatch(setTranscriptionNetWorkError(false));
        if (!storeAPI.getState().auth.Transcription.shouldLoginASAP) storeAPI.dispatch(setShouldLoginASAPOnTranscription(true));
        storeAPI.dispatch(setTranscriptionAuth({ isAuth: false, user: null }));
      } else if (error?.status === 'FETCH_ERROR') {
        // TRANSCRIPTION CONNECTION LOST
        if (!storeAPI.getState().auth.Transcription.netWorkError) {
          // TRANSCRIPTION CONNECTION LOST FIRST TIME
          storeAPI.dispatch(setTranscriptionNetWorkError(true));
          storeAPI.dispatch(setAlertMessage({ type: 'warning', text: t('auth.messages.transcriptionBackend.connectionError') }));
        }
        if (hasTimeThresholdPassed(appConfig.api.backend.expirationThreshold, storeAPI.getState().auth.Transcription.lastFullfilledTimeStamp, Date.now())) {
          // TRANSCRIPTION CONNECTION LOST FOR TOO LONG --> FORCE RELOGIN
          if (!storeAPI.getState().auth.Transcription.shouldLoginASAP) {
            storeAPI.dispatch(setShouldLoginASAPOnTranscription(true));
            storeAPI.dispatch(setTranscriptionAuth({ isAuth: false, user: null }));
          }
        }
      }
    }

    if (action.type.endsWith('/fulfilled')) {
      storeAPI.dispatch(setTranscriptionLastFullfilledTimeStamp(action.meta.fulfilledTimeStamp));
      // TRANSCRIPTION SUCCESS
      if (action.type === 'api/executeMutation/fulfilled' && action.meta.arg.endpointName === 'logout') {
        // TRANSCRIPTION LOGOUT SUCCESS
      } else if (action.type === 'api/executeMutation/fulfilled' && action.meta.arg.endpointName === 'loginWithTds') {
        // TRANSCRIPTION LOGIN SUCCESS
      }

      if (storeAPI.getState().auth.Transcription.netWorkError) {
        // TRANSCRIPTION CONNECTION RECOVERED
        storeAPI.dispatch(setTranscriptionNetWorkError(false));
        storeAPI.dispatch(cleanAlertMessage());
      }
    }
  }

  return next(action);
};

export default apiSessionMonitoringMiddleware;
