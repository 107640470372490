import PropTypes from 'prop-types';
import { Typography, Stack, Slider } from '@mui/material';

function PlayerBar({ size, trackProgress, duration, onScrub }) {
  function formatDuration(time) {
    const minute = Math.floor(time / 60);
    const secondLeft = Math.floor(time - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  return (
    <Stack width={1} spacing={size === 'small' ? 1 : 2} direction="row" alignItems="center">
      <Typography variant="body2">{formatDuration(trackProgress)}</Typography>
      <Slider size={size} valueLabelFormat={(value) => formatDuration(value)} onChange={(e, value) => onScrub(value)} valueLabelDisplay="auto" sx={{ borderRadius: 5, height: size === 'medium' ? 10 : 5 }} min={0} step={1} max={duration} value={trackProgress} />
      <Typography variant="body2">{formatDuration(duration - trackProgress)}</Typography>
    </Stack>
  );
}

PlayerBar.defaultProps = {
  size: 'medium',
};
PlayerBar.propTypes = {
  trackProgress: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onScrub: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default PlayerBar;
