import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, Typography } from '@mui/material';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import Proptypes from 'prop-types';
import AccountMenu from './AccountMenu';
import config from '../../app/config';
import { selectTDSAuth } from '../../features/authSlice';

export default function MainNavBar({ navItems, height }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const hasSensitiveDataAccess = useSelector(selectTDSAuth).user?.hasSensitiveDataAccess;
  const { t } = useTranslation();
  const userRole = hasSensitiveDataAccess ? 'manager' : 'customer';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Stack direction="column" sx={{ textAlign: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <IconButton color="inherit" aria-label={t('aria.icons.toggleDrawer')} onClick={handleDrawerToggle} sx={{ display: { sm: 'none' }, p: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {navItems.map(
          (item) =>
            item.permissions.includes(userRole) && (
              <ListItem key={item.id} disablePadding>
                <ListItemButton component={NavLink} to={item.path} sx={{ textAlign: 'center' }} style={({ isActive }) => (isActive ? { textDecoration: 'underline' } : { textDecoration: 'none' })}>
                  <ListItemText primary={t(item.label)} />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Stack>
  );

  return (
    <>
      <AppBar component="nav" color="primary" position="static" sx={{ '& .MuiToolbar-regular': { height } }}>
        <Toolbar>
          <IconButton color="inherit" aria-label={t('aria.icons.toggleDrawer')} edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, height: '40px' }}>
            <NavLink to="/" style={{ display: 'flex', alignItems: 'center', height: '100%', textDecoration: 'none', color: 'inherit' }}>
              <img src="favicon.svg" alt={config.siteName} style={{ height: '100%', width: 'auto', marginRight: '10px' }} />
              <Typography variant="h6" component="div" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                {config.siteName}
              </Typography>
            </NavLink>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map(
              (item) =>
                item.permissions.includes(userRole) && (
                  <Button component={NavLink} color="inherit" key={item.id} to={item.path} style={({ isActive }) => (isActive ? { textDecoration: 'underline' } : { textDecoration: 'none' })} sx={{ mr: 2 }}>
                    {t(item.label)}
                  </Button>
                )
            )}
          </Box>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

MainNavBar.defaultProps = {
  navItems: [],
  height: 65,
};

MainNavBar.propTypes = {
  navItems: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
      path: Proptypes.string,
      label: Proptypes.string,
      permissions: Proptypes.arrayOf(Proptypes.string),
    })
  ),
  height: Proptypes.number,
};
