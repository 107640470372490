import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectBulkProceedingsSubmittingForm, setBulkProceedingsPP, setBulkProceedingsRegistrar } from '../features/bulkProceedingsSlice';
import { selectProceeding, setProceedingFormField } from '../features/proceedingFormSlice';

export default function useProceedingForm() {
  const dispatch = useDispatch();
  const Proceeding = useSelector(selectProceeding);
  const isSubmitting = useSelector(selectBulkProceedingsSubmittingForm);

  const setField = useCallback(
    (fieldName, newValue) => {
      if (fieldName === 'registrar') dispatch(setBulkProceedingsRegistrar(newValue));
      if (fieldName === 'PM') dispatch(setBulkProceedingsPP(newValue));
      dispatch(setProceedingFormField({ [fieldName]: newValue }));
    },
    [dispatch]
  );

  return {
    isSubmitting,
    Proceeding,
    setField,
  };
}
