import Cookies from 'js-cookie';
import apiSlice from './apiSlice';

const apiAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: 'dj-rest-auth/login/',
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: { username, password },
      }),
    }),
    loginWithGoogleJWT: builder.mutation({
      query: ({ accessToken }) => ({
        url: 'dj-rest-auth/google-jwt/',
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: { access_token: accessToken },
      }),
    }),
    loginWithFacebook: builder.mutation({
      query: ({ accessToken }) => ({
        url: 'dj-rest-auth/facebook/',
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: { access_token: accessToken },
      }),
    }),
    loginWithTds: builder.mutation({
      query: ({ accessToken }) => ({
        url: 'dj-rest-auth/tds/',
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: { access_token: accessToken },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'dj-rest-auth/logout/',
        method: 'POST',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useLoginWithGoogleJWTMutation, useLoginWithFacebookMutation, useLoginWithTdsMutation, useLogoutMutation } = apiAuthSlice;

export default apiAuthSlice;
