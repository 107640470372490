import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBulkProceedingsDeleteCdsDialogLoading, setBulkProceedingsDeleteCdsDialogOpen } from '../features/bulkProceedingsSlice';
import { editProceedings, removeProceedingsByIds, selectAllProceedings } from '../features/proceedingsSlice';
import { removeRecordingsByIds, selectAllRecordings } from '../features/recordingsSlice';
import { removeMarkersByIds, selectAllMarkers } from '../features/markersSlice';
import { removeTurnsByIds, selectAllTurns } from '../features/turnsSlice';
import { removeTracksByIds, selectAllTracks } from '../features/tracksSlice';
import { removeOtherFilesByIds, selectAllOtherFiles } from '../features/otherFilesSlice';
import { removeLoadedCdsByIds, selectAllLoadedCds } from '../features/loadedCdsSlice';

export default function useDeleteCds() {
  const dispatch = useDispatch();

  const reduxRecordings = useSelector((state) => selectAllRecordings(state.recordings));
  const reduxProceedings = useSelector((state) => selectAllProceedings(state.proceedings));
  const reduxTracks = useSelector((state) => selectAllTracks(state.tracks));
  const reduxMarkers = useSelector((state) => selectAllMarkers(state.markers));
  const reduxTurns = useSelector((state) => selectAllTurns(state.turns));
  const reduxOtherFiles = useSelector((state) => selectAllOtherFiles(state.otherFiles));
  const cdsInfo = useSelector((state) => selectAllLoadedCds(state.loadedCds));

  /* DELETE CDS FUNCTIONS */
  const removeCdData = useCallback(
    (cdIds = []) => {
      const recordingsToRemoveIds = reduxRecordings?.filter((rec) => cdIds.includes(rec.cdId))?.map((rec) => rec.id);
      dispatch(removeRecordingsByIds(recordingsToRemoveIds));
      const otherFilesToRemoveIds = reduxOtherFiles?.filter((of) => cdIds.includes(of.cdId))?.map((of) => of.id);
      dispatch(removeOtherFilesByIds(otherFilesToRemoveIds));
      const tracksToRemoveIds = reduxTracks?.filter((trk) => recordingsToRemoveIds.includes(trk.recordingId))?.map((trk) => trk.file?.name);
      dispatch(removeTracksByIds(tracksToRemoveIds));
      const proceedingsToRemoveIds = reduxProceedings?.filter((prc) => prc.recordings.every((recId) => recordingsToRemoveIds.includes(recId)))?.map((prc) => prc.id);
      dispatch(removeProceedingsByIds(proceedingsToRemoveIds));
      const proceedingsToEdit = reduxProceedings?.filter((prc) => !proceedingsToRemoveIds.includes(prc.id) && (prc.recordings.some((recId) => recordingsToRemoveIds.includes(recId)) || prc.otherFiles.some((ofId) => otherFilesToRemoveIds.includes(ofId))));
      const toEditProceedings = [];
      proceedingsToEdit.forEach((prc) => {
        const newRecordings = [...prc.recordings.filter((recId) => !recordingsToRemoveIds.includes(recId))];
        const newSelectedRecordings = [...prc.selectedRecordings.filter((recId) => !recordingsToRemoveIds.includes(recId))];
        const newOtherFiles = [...prc.otherFiles.filter((ofId) => !otherFilesToRemoveIds.includes(ofId))];
        toEditProceedings.push({ id: prc.id, changes: { recordings: newRecordings, selectedRecordings: newSelectedRecordings, otherFiles: newOtherFiles } });
      });
      dispatch(editProceedings(toEditProceedings));
      const markersToRemoveIds = reduxMarkers?.filter((mrk) => recordingsToRemoveIds.includes(mrk.recordingId))?.map((mrk) => mrk.id);
      dispatch(removeMarkersByIds(markersToRemoveIds));
      const turnsToRemoveIds = reduxTurns?.filter((trn) => recordingsToRemoveIds.includes(trn.recordingId))?.map((trn) => trn.id);
      dispatch(removeTurnsByIds(turnsToRemoveIds));
    },
    [dispatch, reduxMarkers, reduxTurns, reduxRecordings, reduxProceedings, reduxTracks, reduxOtherFiles]
  );
  const deleteCds = (cdIds = []) => {
    dispatch(setBulkProceedingsDeleteCdsDialogLoading(true));
    const filteredCdsInfo = cdIds.length > 0 ? cdsInfo.filter((cd) => cdIds.includes(cd.id)) : cdsInfo;
    removeCdData(filteredCdsInfo.map((cd) => cd.id));
    dispatch(removeLoadedCdsByIds(filteredCdsInfo.map((cd) => cd.id)));
    if (cdsInfo.length === 1 || cdIds.length === 0) dispatch(setBulkProceedingsDeleteCdsDialogOpen(false));
    dispatch(setBulkProceedingsDeleteCdsDialogLoading(false));
  };

  return {
    cds: cdsInfo,
    deleteCds,
  };
}
