import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ExpandMore, ExpandLess, ArrowForwardOutlined, FileOpen, Edit, Album, Gavel } from '@mui/icons-material';
import { Card, CardActions, CardContent, IconButton, CardHeader, Tooltip, List, ListItem, ListItemText, Stack, Grid, Typography, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TooltipMenu from './TooltipMenu';
import { openTrialDialog, setEditingTrial } from '../features/trialEditingSlice';
import formatTrialToTrialEditingSlice from '../utils/formatTrialToTrialEditingSlice';
import ProceedingsWithReportBadge from './ProceedingsWithReportBadge';

function TrialCard({ trial }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const { t } = useTranslation();

  const listItemSx = {
    display: 'block',
    py: 0,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(openTrialDialog());
    dispatch(setEditingTrial(formatTrialToTrialEditingSlice(trial)));
  };

  const documentActionsMenuItems = [
    {
      fileType: trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      actionType: 'print',
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial.id,
      workAwayFlag: trial.trasferta,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.printWithdrawalCertificate') : t('trials.trialsList.trial.buttons.printAttendanceCertificate'),
    },
    {
      documentId: trial.upload_attestato_presenza,
      fileType: 'p',
      actionType: 'print',
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.printCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.printCompiledAttendanceCertificate'),
    },
    {
      fileType: trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      ticketId: trial.id_ticket,
      actionType: 'download',
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial.id,
      workAwayFlag: trial.trasferta,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.downloadWithdrawalCertificate') : t('trials.trialsList.trial.buttons.downloadAttendanceCertificate'),
    },
    {
      documentId: trial.upload_attestato_presenza,
      fileType: 'p',
      actionType: 'download',
      ticketId: trial.id_ticket,
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.downloadCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.downloadCompiledAttendanceCertificate'),
    },
    {
      fileType: trial.servizio === 'RITIRO EX-POST' ? 'ARIT' : 'AP',
      actionType: 'open',
      ticketId: trial.id_ticket,
      asMenuItem: true,
      endpoint: 'certificate',
      trialId: trial.id,
      workAwayFlag: trial.trasferta,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.openWithdrawalCertificate') : t('trials.trialsList.trial.buttons.openAttendanceCertificate'),
    },
    {
      documentId: trial.upload_attestato_presenza,
      fileType: 'p',
      actionType: 'open',
      ticketId: trial.id_ticket,
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: trial.servizio === 'RITIRO EX-POST' ? t('trials.trialsList.trial.buttons.openCompiledWithdrawalCertificate') : t('trials.trialsList.trial.buttons.openCompiledAttendanceCertificate'),
    },
  ];

  return (
    <Card elevation={3} sx={{ '& .MuiCardHeader-action': { margin: 0 } }}>
      <CardHeader
        sx={{ padding: 0.5, textTransform: 'uppercase', backgroundColor: 'secondary.light', color: 'secondary.contrastText' }}
        title={
          <List sx={{ display: 'flex', py: 0 }}>
            <ListItem sx={listItemSx}>
              <Tooltip title={t('trials.trialsList.trial.ticketId')} placement="bottom-start">
                <ListItemText primary={trial.id_ticket} />
              </Tooltip>
            </ListItem>
            <ListItem sx={listItemSx}>
              <Tooltip title={t('trials.trialsList.trial.status')} placement="bottom-start">
                <ListItemText primary={trial.stato_udienza} />
              </Tooltip>
            </ListItem>
            <ListItem sx={listItemSx}>
              <Tooltip title={t('trials.trialsList.trial.date')} placement="bottom-start">
                <ListItemText primary={trial.data_ora_inizio_udienza ? dayjs(trial.data_ora_inizio_udienza).format('L') : '-'} />
              </Tooltip>
            </ListItem>
            <ListItem sx={listItemSx}>
              <Tooltip title={t('trials.trialsList.trial.timeStart')} placement="bottom-start">
                <ListItemText primary={trial.data_ora_inizio_udienza ? dayjs(trial.data_ora_inizio_udienza).format('LT') : '-'} />
              </Tooltip>
            </ListItem>
            <ListItem sx={listItemSx}>
              <Tooltip title={t('trials.trialsList.trial.timeEnd')} placement="bottom-start">
                <ListItemText primary={trial.data_ora_fine_udienza ? dayjs(trial.data_ora_fine_udienza).format('LT') : '-'} />
              </Tooltip>
            </ListItem>
            <ListItem sx={listItemSx}>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                    <Tooltip title={t('trials.trialsList.trial.proceedings', { number: trial.n_procedimenti || 0 })}>
                      <Badge max={1000} showZero badgeContent={trial.n_procedimenti || 0}>
                        <Gavel color="action" />
                      </Badge>
                    </Tooltip>
                    <Tooltip title={t('trials.trialsList.trial.proceedingsToBeTranscribed', { number: trial.n_verbali || 0 })}>
                      <Badge max={1000} showZero badgeContent={trial.n_verbali || 0}>
                        <ProceedingsWithReportBadge />
                      </Badge>
                    </Tooltip>
                    <Tooltip title={t('trials.trialsList.trial.cdNumber', { number: trial.n_supporti || 0 })}>
                      <Badge showZero badgeContent={trial.n_supporti || 0}>
                        <Album color="action" />
                      </Badge>
                    </Tooltip>
                  </Stack>
                }
              />
            </ListItem>
          </List>
        }
        action={
          <Link to={`/trials/${trial.id}/proceedings`} state={{ params: `${location.search}` }} id={`proceedings${trial.id}`} data-testid={`proceedings${trial.id}`}>
            <IconButton size="small">
              <ArrowForwardOutlined />
            </IconButton>
          </Link>
        }
      />
      {detailsExpanded && (
        <CardContent sx={{ padding: 0.5 }}>
          <List sx={{ display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
            <ListItem sx={{ width: '33.3%', py: 0 }}>
              <ListItemText primary={t('trials.trialsList.trial.service')} secondary={trial.servizio || '-'} />
            </ListItem>
            <ListItem sx={{ width: '33.3%', py: 0 }}>
              <ListItemText primary={t('trials.trialsList.trial.workAway.label')} secondary={trial.trasferta === '1' ? t('trials.trialsList.trial.workAway.values.yes') : t('trials.trialsList.trial.workAway.values.no')} />
            </ListItem>
          </List>
          <List sx={{ display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
            <ListItem sx={listItemSx}>
              <ListItemText primary={t('trials.trialsList.trial.requestDateTime')} secondary={trial.data_ora_richiesta ? dayjs(trial.data_ora_richiesta).format('L LT') : '-'} />
            </ListItem>
            <ListItem sx={listItemSx}>
              <ListItemText primary={t('trials.trialsList.trial.requestSection')} secondary={trial.sezione_richiedente || '-'} />
            </ListItem>
            <ListItem sx={listItemSx}>
              <ListItemText primary={t('trials.trialsList.trial.requestCourt')} secondary={trial.tribunale_richiedente || '-'} />
            </ListItem>
          </List>
          <List sx={{ display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
            <ListItem sx={listItemSx}>
              <ListItemText primary={t('trials.trialsList.trial.operatorArrivalTime')} secondary={trial.ora_arrivo_operatore ? dayjs(trial.ora_arrivo_operatore, 'HH:mm:ss').format('LT') : '-'} />
            </ListItem>
          </List>
        </CardContent>
      )}
      <CardActions sx={{ padding: 0.5 }} disableSpacing>
        <IconButton onClick={handleClick} size="small">
          <FileOpen />
        </IconButton>
        <IconButton onClick={handleEdit} size="small">
          <Edit />
        </IconButton>
        <TooltipMenu documentActionItems={documentActionsMenuItems} anchorEl={anchorEl} handleClose={handleClose} />
        <Grid px={1} sx={{ textTransform: 'uppercase', color: 'gray' }} container alignItems="flex-start">
          <Grid item xs={3}>
            <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.court')}>
              <Typography variant="body2">{trial.tribunale || '-'}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.section')}>
              <Typography variant="body2">{trial.sezione || '-'}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.room')}>
              <Typography variant="body2">{trial.aula || '-'}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.rite')}>
              <Typography variant="body2">{trial.rito || '-'}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3} container direction="column">
            {trial.giudice_presidente && (
              <Grid item xs>
                <Tooltip placement="bottom-start" title={t('trials.trialsList.trial.presidentJudge')}>
                  <Typography variant="body2">{trial.giudice_presidente}</Typography>
                </Tooltip>
              </Grid>
            )}
            {trial.giudice_1 && (
              <Grid item xs>
                <Tooltip placement="bottom-start" title={t('proceedings.form.addProceeding.fields.honoraryJudge')}>
                  <Typography variant="body2">{trial.giudice_1}</Typography>
                </Tooltip>
              </Grid>
            )}
            {trial.giudice_2 && (
              <Grid item xs>
                <Tooltip placement="bottom-start" title={t('proceedings.form.addProceeding.fields.honoraryJudge')}>
                  <Typography variant="body2">{trial.giudice_2}</Typography>
                </Tooltip>
              </Grid>
            )}
            {trial.giudice_3 && (
              <Grid item xs>
                <Tooltip placement="bottom-start" title={t('proceedings.form.addProceeding.fields.honoraryJudge')}>
                  <Typography variant="body2">{trial.giudice_3}</Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <IconButton onClick={() => setDetailsExpanded(!detailsExpanded)} aria-expanded={detailsExpanded} size="small">
          {detailsExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </CardActions>
    </Card>
  );
}

TrialCard.defaultProps = {
  trial: {
    data_ora_fine_udienza: '',
    giudice_1: '',
    giudice_2: '',
    giudice_3: '',
    sezione_richiedente: '',
    tribunale_richiedente: '',
    note: '',
    n_procedimenti: '0',
    n_verbali: '0',
    n_supporti: '0',
    upload_attestato_presenza: null,
    ora_arrivo_operatore: null,
  },
};

TrialCard.propTypes = {
  trial: PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_ticket: PropTypes.string.isRequired,
    id_stato: PropTypes.string.isRequired,
    stato_udienza: PropTypes.string.isRequired,
    data_ora_richiesta: PropTypes.string.isRequired,
    data_ora_inizio_udienza: PropTypes.string.isRequired,
    data_ora_fine_udienza: PropTypes.string,
    tribunale: PropTypes.string.isRequired,
    sezione: PropTypes.string.isRequired,
    rito: PropTypes.string.isRequired,
    aula: PropTypes.string.isRequired,
    giudice_presidente: PropTypes.string.isRequired,
    giudice_1: PropTypes.string,
    giudice_2: PropTypes.string,
    giudice_3: PropTypes.string,
    servizio: PropTypes.string.isRequired,
    sezione_richiedente: PropTypes.string,
    tribunale_richiedente: PropTypes.string,
    trasferta: PropTypes.string.isRequired,
    note: PropTypes.string,
    upload_attestato_presenza: PropTypes.string,
    ora_arrivo_operatore: PropTypes.string,
    n_procedimenti: PropTypes.string,
    n_verbali: PropTypes.string,
    n_supporti: PropTypes.string,
  }),
};

export default TrialCard;
