import { useParams } from 'react-router-dom';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';

export default function useProceedingsUploadButton() {
  const { id } = useParams();

  const { currentData: trial, isLoadingTrial, isFetchingTrial } = useGetTrialQuery(id, { skip: !id });
  const { currentData: proceedings, isLoadingProceedings, isFetchingProceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });

  const proceedingsCount = proceedings?.length || 0;
  const maxProceedingsCount = trial && trial.n_procedimenti ? Number(trial.n_procedimenti) : 0;
  const disableUploadButton = !trial?.data_ora_fine_udienza || !trial?.n_procedimenti || !trial?.n_verbali || !trial?.n_supporti || !trial?.id_aula;
  const trialCompleted = maxProceedingsCount <= proceedingsCount;

  return {
    trial,
    isFetching: isFetchingTrial || isFetchingProceedings,
    isLoading: isLoadingTrial || isLoadingProceedings,
    disableUploadButton,
    trialCompleted,
  };
}
