// import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';

const initialProceeding = {
  trialId: '',
  proceedingId: '',
  ticketId: '',
  status: '',
  dateStart: '',
  timeStart: '',
  timeEnd: '',
  report: '',
  RG: '',
  RGNR: '',
  sectionId: '',
  courtId: '',
  riteTypeId: '',
  defendant: '',
  judge: '',
  judge1: '',
  judge2: '',
  judge3: '',
  PM: [],
  registrar: '',
  outcomeId: '',
  outcome: '',
  outcomeDate: '',
  note: '',
  tracks: [],
  otherFiles: [],
};

const options = {
  name: 'singleProceeding',
  initialState: {
    dialog: {
      open: false,
    },
    proceeding: initialProceeding,
    recordings: [],
    stagedFiles: [],
    submitting: false,
  },
  reducers: {
    setSingleProceedingSubmittingForm: (state, action) => ({
      ...state,
      submitting: action.payload,
    }),
    openSingleProceedingDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: true,
      },
    }),
    closeSingleProceedingDialog: (state) => ({
      ...state,
      dialog: {
        ...state.dialog,
        open: false,
      },
    }),
    setSingleProceedingTracks: (state, action) => ({
      ...state,
      proceeding: {
        ...state.proceeding,
        tracks: action.payload,
      },
    }),
    setSingleProceeding: (state, action) => ({
      ...state,
      proceeding: action.payload,
    }),
    setSingleProceedingRecordings: (state, action) => ({
      ...state,
      recordings: action.payload,
    }),
    setSingleProceedingStagedFiles: (state, action) => ({
      ...state,
      stagedFiles: action.payload,
    }),
    setSingleProceedingField: (state, action) => ({
      ...state,
      proceeding: {
        ...state.proceeding,
        ...action.payload,
      },
    }),
    setSingleProceedingOtherFiles: (state, action) => ({
      ...state,
      proceeding: {
        ...state.proceeding,
        otherFiles: action.payload,
      },
    }),
    resetSingleProceeding: (state) => ({
      ...state,
      proceeding: initialProceeding,
    }),
    resetSingleProceedingRecordings: (state, action) => ({
      ...state,
      recordings: [],
    }),
    resetSingleProceedingStagedFiles: (state, action) => ({
      ...state,
      stagedFiles: [],
    }),
  },
};

const singleProceedingSlice = createSlice(options);
export const selectIsSingleProceedingDialogOpen = (state) => state.singleProceeding.dialog.open;
export const selectSingleProceeding = (state) => state.singleProceeding.proceeding;
export const selectSingleProceedingTracks = (state) => state.singleProceeding.proceeding.tracks;
export const selectSingleProceedingOtherFiles = (state) => state.singleProceeding.proceeding.otherFiles;
export const selectSingleProceedingRecordings = (state) => state.singleProceeding.recordings;
export const selectSingleProceedingStagedFiles = (state) => state.singleProceeding.stagedFiles;
export const selectSingleProceedingSubmittingForm = (state) => state.singleProceeding.submitting;
export const {
  setSingleProceedingSubmittingForm,
  openSingleProceedingDialog,
  closeSingleProceedingDialog,
  setSingleProceeding,
  setSingleProceedingField,
  setSingleProceedingTracks,
  setSingleProceedingRecordings,
  setSingleProceedingStagedFiles,
  setSingleProceedingOtherFiles,
  resetSingleProceeding,
  resetSingleProceedingRecordings,
  resetSingleProceedingStagedFiles,
} = singleProceedingSlice.actions;

export default singleProceedingSlice;
