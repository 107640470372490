import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Spinner from './Spinner';
import useTrialsResults from '../hooks/useTrialsResult';
import TrialCard from './TrialCard';

function TrialsResultPage() {
  const { t } = useTranslation();
  const { trials, isFetching, isError } = useTrialsResults();

  if (isError) return <div>{t('trials.messages.loadingError')}</div>;
  if (isFetching) return <Spinner />;

  // todo: render skeleton on loading

  return (
    <Stack component="section" id="TrialsResultPage" direction="column" my={2} spacing={1}>
      {trials.map(createTrialCard)}
    </Stack>
  );
}
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
const createTrialCard = (trial) => <TrialCard key={trial.id} trial={trial} />;
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */

export default TrialsResultPage;
