import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import compareByTime from '../utils/compareByTime';

const annotationsTurnsAdapter = createEntityAdapter({ sortComparer: compareByTime });

const annotationsTurnsSlice = createSlice({
  name: 'annotationsTurns',
  initialState: annotationsTurnsAdapter.getInitialState(),
  reducers: {
    loadAnnotationsTurns: annotationsTurnsAdapter.setMany,
    editAnnotationsTurn: annotationsTurnsAdapter.updateOne,
    editAnnotationsTurns: annotationsTurnsAdapter.updateMany,
    addAnnotationsTurn: annotationsTurnsAdapter.addOne,
    removeAnnotationsTurn: annotationsTurnsAdapter.removeOne,
    removeAnnotationsTurnsByIds: annotationsTurnsAdapter.removeMany,
    removeAnnotationsTurns: annotationsTurnsAdapter.removeAll,
  },
});

export const { selectById: selectAnnotationsTurnsById, selectAll: selectAllAnnotationsTurns, selectTotal: selectTotalAnnotationsTurns } = annotationsTurnsAdapter.getSelectors();

export const { loadAnnotationsTurns, editAnnotationsTurn, editAnnotationsTurns, addAnnotationsTurn, removeAnnotationsTurn, removeAnnotationsTurnsByIds, removeAnnotationsTurns } = annotationsTurnsSlice.actions;

export default annotationsTurnsSlice;
