const trexFilesRegex = /^[^RT](.*)\.(txs|mp3|zip)$/i;
const trexMetadataFilesRegex = /^(.*)\.(txs)$/i;
const trexAudioFilesRegex = /^(.*)\.(mp3)$/i;
const trexZipFilesRegex = /^(.*)\.(zip)$/i;

async function detectCds(files) {
  const dirs = {};
  files.forEach((file) => {
    const match = file.name.match(trexFilesRegex);
    if (match) {
      const directory = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf(file.name));
      if (!(directory in dirs)) {
        dirs[directory] = { data: [], audio: {}, zip: {} };
      }
      if (file.name.match(trexMetadataFilesRegex)) {
        dirs[directory].data.push({ txs: file });
      } else if (file.name.match(trexAudioFilesRegex)) {
        dirs[directory].audio[file.name] = file;
      } else if (file.name.match(trexZipFilesRegex)) {
        dirs[directory].zip[file.name] = file;
      }
    }
  });
  return dirs;
}

export { detectCds };

export default detectCds;
