import apiSlice from './apiSlice';
import { formatParameters } from './configApi';

const apiCourtsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRooms: builder.query({
      query: ({ filters, orderby, page, pSize } = {}) => ({
        url: '/getAule',
        method: 'GET',
        params: formatParameters({ filters, orderby, page, pSize }),
      }),
      transformResponse: (response) => response.aule,
    }),
    getCourtTypes: builder.query({
      query: () => ({
        url: '/getTipiTribunali',
        method: 'GET',
      }),
      transformResponse: (response) => response.tipi_tribunali,
    }),
  }),
});

export const { useGetRoomsQuery, useGetCourtTypesQuery } = apiCourtsSlice;

export default apiCourtsSlice;
