import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import Recording from './Recording';
import { selectAllAnnotationsRecordings } from '../../../../features/annotationsRecordings';

function Recordings({ cdId, expandAll }) {
  const recordings = useSelector((state) => selectAllAnnotationsRecordings(state.annotationsRecordings));
  const recordingsByCdId = useMemo(() => recordings?.filter((recording) => recording.cdId === cdId), [cdId, recordings]);

  return (
    <Stack spacing={1} direction="column">
      {recordingsByCdId?.map((recording) => (
        <Recording key={recording.id} id={recording.id} isOpened={expandAll} />
      ))}
    </Stack>
  );
}

Recordings.propTypes = {
  cdId: PropTypes.string.isRequired,
  expandAll: PropTypes.bool.isRequired,
};

export default Recordings;
