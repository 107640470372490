import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Stack, Tooltip } from '@mui/material';
import useBulkProceedingsCdReinsertDialog from '../hooks/useBulkProceedingsCdReinsertDialog';

function BulkProceedingsCdReinsertDialog({ tryAgainCallback, openFor }) {
  const { t } = useTranslation();
  const { isOpen, missingFile, missingCd, handleClose, handleTryAgain } = useBulkProceedingsCdReinsertDialog(tryAgainCallback);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen !== '' && isOpen === openFor}>
      <DialogTitle sx={{ backgroundColor: 'warning.light', color: 'warning.contrastText' }}>{missingFile && t('recordings.errors.fileNotFound_file', { fileName: missingFile })}</DialogTitle>
      <DialogContent dividers>
        {missingCd && (
          <Stack spacing={1} alignItems="center" direction="row">
            <Typography>{t('proceedings.dialogs.bulkUpload.reinsertCdDialog.recoverCd')}</Typography>
            {missingCd.recorder && (
              <Tooltip title={t('proceedings.dialogs.bulkUpload.recorder')}>
                <Typography fontWeight="bold">{missingCd.recorder}</Typography>
              </Tooltip>
            )}
            {missingCd.court && (
              <Tooltip title={t('proceedings.dialogs.bulkUpload.court')}>
                <Typography fontWeight="bold">{missingCd.court}</Typography>
              </Tooltip>
            )}
            {missingCd.room && (
              <Tooltip title={t('proceedings.dialogs.bulkUpload.room')}>
                <Typography fontWeight="bold">{missingCd.room}</Typography>
              </Tooltip>
            )}
            {missingCd.startDate && (
              <Tooltip title={t('proceedings.dialogs.bulkUpload.recorded')}>
                <Typography fontWeight="bold">{missingCd.startDate ? dayjs(missingCd.startDate).format('L LT') : ''}</Typography>
              </Tooltip>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="warning" onClick={handleClose}>
          {t('common.actions.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleTryAgain}>
          {t('common.actions.tryAgain')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BulkProceedingsCdReinsertDialog.defaultProps = {
  tryAgainCallback: () => {},
};
BulkProceedingsCdReinsertDialog.propTypes = {
  tryAgainCallback: PropTypes.func,
  openFor: PropTypes.string.isRequired,
};

export default BulkProceedingsCdReinsertDialog;
