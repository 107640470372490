import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import JobProgressBar from './JobProgressBar';

dayjs.extend(LocalizedFormat);

function JobRow({ id, job }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/jobs/${id}`);
  };

  return (
    <TableRow id={`JobRow-${id}`} hover onClick={handleRowClick}>
      {/* STATUS */}
      <TableCell>
        <Tooltip title={t('jobs.status.label')} placement="bottom-start">
          <Typography variant="body3">{job.status ? t(`jobs.status.value.${job.status}`) : '-'}</Typography>
        </Tooltip>
      </TableCell>
      {/* CREATION DATE */}
      <TableCell>
        <Tooltip title={t('jobs.date.created.label')} placement="bottom-start">
          <Typography variant="body3">{job.created ? dayjs(job.created).format('L LT') : '-'}</Typography>
        </Tooltip>
      </TableCell>
      {/* LAST UPDATED */}
      <TableCell>
        <Tooltip title={t('jobs.date.lastUpdated.label')} placement="bottom-start">
          <Typography variant="body3">{job.last_updated ? dayjs(job.last_updated).format('L LT') : '-'}</Typography>
        </Tooltip>
      </TableCell>
      {/* REQUEST REFERENCE */}
      <TableCell>
        <Tooltip title={t('jobs.requestReference.label')} placement="bottom-start">
          <Typography variant="body3">{job.request_reference || '-'}</Typography>
        </Tooltip>
      </TableCell>
      {/* ORIGINAL FILE NAME */}
      <TableCell>
        <Tooltip title={t('jobs.originalFileName.label')} placement="bottom-start">
          <Typography variant="body3">{job.original_file_name || '-'}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <JobProgressBar progress={job.progress} />
      </TableCell>
    </TableRow>
  );
}

JobRow.defaultProps = {
  id: '',
  job: {
    id: '',
    status: '',
    created: '',
    last_updated: '',
    original_file_name: '',
    request_reference: '',
    progress: null,
  },
};

JobRow.propTypes = {
  id: PropTypes.string,
  job: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created: PropTypes.string,
    last_updated: PropTypes.string,
    original_file_name: PropTypes.string,
    request_reference: PropTypes.string,
    progress: PropTypes.number,
  }),
};

export default JobRow;
