import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { ExpandMore, ExpandLess, FileOpen as FileOpenIcon } from '@mui/icons-material';
import { dateTimeToTdsFormat } from '../../../utils/dateTimeHandlers';
import TooltipMenu from '../../TooltipMenu';
import ProceedingDeliverySignedCertificateLoadButton from '../../CertificateLoadButton';
import { useCloseProceedingMutation } from '../../../features/api/tds/apiProceedingsSlice';
import { setAlertMessage } from '../../../features/layoutSlice';

function PrintCard({ proceeding }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [closeProceedingTrigger] = useCloseProceedingMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const updateProceeding = async (deliveryCertificateId) => {
    try {
      await closeProceedingTrigger({
        id_procedimento: proceeding.id_procedimento,
        id_udienza: proceeding.id_udienza,
        data_ora_consegna: dateTimeToTdsFormat(dayjs(), 'dateTime'),
        upload_attestato_consegna: deliveryCertificateId,
        data_upload_attestato_consegna: dateTimeToTdsFormat(dayjs(), 'dateTime'),
      }).unwrap();
      dispatch(setAlertMessage({ text: t('prints.messages.proceedingClosed'), type: 'success', render: 'snack' }));
    } catch (e) {
      dispatch(setAlertMessage({ text: t('prints.messages.errors.closingProceeding'), type: 'error', render: 'snack' }));
    }
    handleClose();
  };
  const listItemSx = {
    display: 'block',
    py: 0,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const outcomeFormattedDateTime = proceeding.data_ora_rinvio ? dayjs(proceeding.data_ora_rinvio).format('L LT') : null;
  const documentActionsMenuItems = [
    {
      documentId: proceeding.upload_pdf_firmato,
      setPrinted: true,
      fileType: 'pdf',
      actionType: 'print',
      ticketId: proceeding.id_ticket,
      asMenuItem: true,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.printSignedReport'),
    },
    { documentId: proceeding.upload_pdf_firmato, fileType: 'pdf', actionType: 'download', ticketId: proceeding.id_ticket, asMenuItem: true, actionSuccessCallback: handleClose, label: t('proceedings.proceedingsList.proceeding.buttons.downloadSignedReport') },
    { documentId: proceeding.upload_pdf_firmato, fileType: 'pdf', actionType: 'open', ticketId: proceeding.id_ticket, asMenuItem: true, actionSuccessCallback: handleClose, label: t('proceedings.proceedingsList.proceeding.buttons.openSignedReport') },
    {
      fileType: 'AC',
      actionType: 'print',
      ticketId: proceeding.id_ticket,
      asMenuItem: true,
      endpoint: 'certificate',
      proceedingId: proceeding.id_procedimento,
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.printDeliveryCertificate'),
    },
    {
      proceedingId: proceeding.id_procedimento,
      ticketId: proceeding.id_ticket,
      actionType: 'download',
      fileType: 'AC',
      asMenuItem: true,
      endpoint: 'certificate',
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.downloadDeliveryCertificate'),
    },
    {
      proceedingId: proceeding.id_procedimento,
      ticketId: proceeding.id_ticket,
      actionType: 'open',
      fileType: 'AC',
      asMenuItem: true,
      endpoint: 'certificate',
      actionSuccessCallback: handleClose,
      label: t('proceedings.proceedingsList.proceeding.buttons.openDeliveryCertificate'),
    },
  ];
  const renderedItems = [
    <ProceedingDeliverySignedCertificateLoadButton
      key="LoadCertificateMenuItem"
      asMenuItem
      disabled={proceeding && proceeding.upload_attestato_consegna !== null}
      ticketId={proceeding.id_ticket}
      certificateType="ac"
      onUploadSuccessCallback={updateProceeding}
      text={t('proceedings.proceedingsList.proceeding.buttons.loadSignedDeliveryCertificate')}
    />,
  ];

  return (
    <Card elevation={3}>
      <CardHeader
        disableTypography
        sx={{ p: 0, textTransform: 'uppercase', backgroundColor: proceeding.flag_download_pdf_firmato === '1' ? 'warning.light' : 'primary.light', color: 'primary.contrastText' }}
        title={
          <List sx={{ display: 'flex', py: 0, alignItems: 'center' }}>
            {/* ID TRIAL TICKET */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.trialTicketId')} placement="bottom-start">
                <ListItemText primary={proceeding.id_ticket_udienza} />
              </Tooltip>
            </ListItem>
            {/* ID PROCEEDINGS TICKET */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.ticketId')} placement="bottom-start">
                <ListItemText primary={proceeding.id_ticket} />
              </Tooltip>
            </ListItem>
            {/* RG */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.RG')} placement="bottom-start">
                <ListItemText primary={proceeding.RG} />
              </Tooltip>
            </ListItem>
            {/* RGNR */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.RGNR')} placement="bottom-start">
                <ListItemText primary={proceeding.RGNR} />
              </Tooltip>
            </ListItem>
            {/* UPLOAD CERTIFICATE DATE */}
            <ListItem sx={listItemSx}>
              <Tooltip title={t('proceedings.proceedingsList.proceeding.uploadCertificateDate')} placement="bottom-start">
                <ListItemText
                  primary={
                    <Tooltip title={proceeding.scadenza_cartaceo ? dayjs(proceeding.scadenza_cartaceo).format('Z') : ''} placement="right">
                      <Typography component="span">{proceeding.scadenza_cartaceo ? dayjs(proceeding.scadenza_cartaceo).format('L LT') : '-'}</Typography>
                    </Tooltip>
                  }
                />
              </Tooltip>
            </ListItem>
          </List>
        }
      />
      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          {/* ACTION */}
          <Box>
            <Tooltip>
              <span>
                <IconButton onClick={handleClick} size="small" disabled={!proceeding.upload_pdf_firmato && !proceeding.upload_attestato_consegna}>
                  <FileOpenIcon />
                </IconButton>
              </span>
            </Tooltip>
            <TooltipMenu renderedItems={renderedItems} documentActionItems={documentActionsMenuItems} anchorEl={anchorEl} handleClose={handleClose} />
          </Box>
          <List sx={{ width: '100%', display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
            {/* UPLOAD SIGNED PDF DATE */}
            <ListItem sx={listItemSx}>
              <ListItemText
                sx={{ display: 'flex' }}
                primary={t('proceedings.proceedingsList.proceeding.signedPdf')}
                secondaryTypographyProps={{ ml: 1 }}
                secondary={
                  <Tooltip placement="right" title={proceeding.data_upload_pdf_firmato ? dayjs(proceeding.data_upload_pdf_firmato).format('Z') : ''}>
                    <Typography component="span">
                      {t('proceedings.proceedingsList.proceeding.uploadSignedPdfDate', { datetime: proceeding.data_upload_pdf_firmato ? dayjs(proceeding.data_upload_pdf_firmato).format('L LT') : '-', interpolation: { escapeValue: false } })}
                    </Typography>
                  </Tooltip>
                }
              />
            </ListItem>
            {/* DOWNLOAD SIGNED PDF DATE */}
            <ListItem sx={listItemSx}>
              <ListItemText
                secondary={
                  <Tooltip placement="right" title={proceeding.data_download_pdf_firmato ? dayjs(proceeding.data_download_pdf_firmato).format('Z') : ''}>
                    <Typography component="span">
                      {t('proceedings.proceedingsList.proceeding.downloadSignedPdfDate', { datetime: proceeding.data_download_pdf_firmato ? dayjs(proceeding.data_download_pdf_firmato).format('L LT') : '-', interpolation: { escapeValue: false } })}
                    </Typography>
                  </Tooltip>
                }
              />
            </ListItem>
            {/* COURT */}
            <ListItem sx={listItemSx}>
              {proceeding.tribunale && (
                <Tooltip title={t('proceedings.proceedingsList.proceeding.court')} placement="bottom-start">
                  <ListItemText primary={proceeding.tribunale} />
                </Tooltip>
              )}
            </ListItem>
            {/* SECTION */}
            <ListItem sx={listItemSx}>
              {proceeding.sezione && (
                <Tooltip title={t('proceedings.proceedingsList.proceeding.section')} placement="bottom-start">
                  <ListItemText primary={proceeding.sezione} />
                </Tooltip>
              )}
            </ListItem>
          </List>
          {/* ACTION */}
          <Box>
            <IconButton onClick={() => setDetailsExpanded(!detailsExpanded)} aria-expanded={detailsExpanded} size="small">
              {detailsExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Stack>
        {detailsExpanded && (
          <>
            <Divider variant="middle" />
            <List sx={{ display: 'flex', textTransform: 'uppercase' }} dense disablePadding>
              {/* STATUS */}
              <ListItem sx={listItemSx}>
                <ListItemText primary={t('proceedings.proceedingsList.proceeding.status')} secondary={proceeding.stato_procedimento} />
              </ListItem>
              {/* OUTCOME & OUTCAME DATE */}
              {proceeding.esito && outcomeFormattedDateTime && (
                <ListItem sx={listItemSx}>
                  <ListItemText primary={t('proceedings.proceedingsList.proceeding.outcome')} secondary={`${proceeding.esito} ${outcomeFormattedDateTime}`} />
                </ListItem>
              )}
              {/* JUDGES */}
              <ListItem sx={listItemSx}>
                <ListItemText primary={t('proceedings.proceedingsList.proceeding.judges')} />
                {proceeding.giudice_presidente && <ListItemText secondary={proceeding.giudice_presidente} />}
                {proceeding.giudice_1 && <ListItemText secondary={proceeding.giudice_1} />}
                {proceeding.giudice_2 && <ListItemText secondary={proceeding.giudice_2} />}
                {proceeding.giudice_3 && <ListItemText secondary={proceeding.giudice_3} />}
                {!proceeding.giudice_presidente && !proceeding.giudice_1 && !proceeding.giudice_2 && !proceeding.giudice_3 && <ListItemText secondary="-" />}
              </ListItem>
              {/* DEFENDANT */}
              <ListItem sx={listItemSx}>
                <ListItemText primary={t('proceedings.proceedingsList.proceeding.defendant')} secondary={proceeding.imputato} />
              </ListItem>
              {/* CHANCELLOR */}
              <ListItem sx={listItemSx}>
                <ListItemText primary={t('proceedings.proceedingsList.proceeding.chancellor')} secondary={proceeding.cancelliere} />
              </ListItem>
              {/* PM */}
              <ListItem sx={listItemSx}>
                <ListItemText primary={t('proceedings.proceedingsList.proceeding.pm')} />
                {proceeding.pms?.length > 0 ? proceeding.pms.map((pm) => <ListItemText key={pm.id} secondary={pm.name} />) : <ListItemText secondary="-" />}
              </ListItem>
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
}

PrintCard.defaultProps = {
  proceeding: {
    data_ora_inizio_udienza: '',
    upload_pdf_firmato: null,
    data_upload_pdf_firmato: null,
    upload_attestato_consegna: null,
    data_ora_consegna: null,
    data_upload_attestato_consegna: null,
    data_download_pdf_firmato: null,
    flag_download_pdf_firmato: '0',
    ora_inizio: null,
    ora_fine: null,
    flag_verbale: '0',
    imputato: '',
    tribunale: '',
    sezione: '',
    giudice_1: '',
    giudice_2: '',
    giudice_3: '',
    cancelliere: '',
    esito: '',
    data_ora_rinvio: null,
    scadenza_portale_definitivo: null,
    scadenza_cartaceo: null,
    upload_audio: [],
    n_audio: '0',
    durata_secondi: '0',
    pms: [],
  },
};

PrintCard.propTypes = {
  proceeding: PropTypes.shape({
    id_procedimento: PropTypes.string.isRequired,
    id_udienza: PropTypes.string.isRequired,
    id_ticket: PropTypes.string.isRequired,
    id_ticket_udienza: PropTypes.string.isRequired,
    id_stato_udienza: PropTypes.string.isRequired,
    id_stato_procedimento: PropTypes.string.isRequired,
    stato_procedimento: PropTypes.string.isRequired,
    data_ora_inizio_udienza: PropTypes.string,
    upload_attestato_consegna: PropTypes.string,
    data_ora_consegna: PropTypes.string,
    upload_pdf_firmato: PropTypes.string,
    data_upload_pdf_firmato: PropTypes.string,
    data_upload_attestato_consegna: PropTypes.string,
    data_download_pdf_firmato: PropTypes.string,
    flag_download_pdf_firmato: PropTypes.string,
    ora_inizio: PropTypes.string,
    ora_fine: PropTypes.string,
    flag_verbale: PropTypes.string,
    RG: PropTypes.string.isRequired,
    RGNR: PropTypes.string.isRequired,
    imputato: PropTypes.string,
    tribunale: PropTypes.string,
    sezione: PropTypes.string,
    giudice_presidente: PropTypes.string.isRequired,
    giudice_1: PropTypes.string,
    giudice_2: PropTypes.string,
    giudice_3: PropTypes.string,
    cancelliere: PropTypes.string,
    esito: PropTypes.string,
    data_ora_rinvio: PropTypes.string,
    scadenza_portale_definitivo: PropTypes.string,
    scadenza_cartaceo: PropTypes.string,
    upload_audio: PropTypes.arrayOf(
      PropTypes.shape({
        fileId: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        isMp3: PropTypes.bool.isRequired,
      })
    ),
    n_audio: PropTypes.string,
    durata_secondi: PropTypes.string,
    pms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    ids_pm: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default PrintCard;
