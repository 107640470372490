import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Chip, Skeleton, Typography, Box, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import FilterByString from '../../FilterByString';
import usePrintsResult from '../../../hooks/usePrintsResults';

function PrintsFilters({ color }) {
  const { t } = useTranslation();

  const { filteredPrints: prints, isLoading, isFetching, hasFilters, filters, viewFiltersValue, isError } = usePrintsResult();
  const [currentDownloadedSignedPdfFilter, setDownloadedSignedPdfFilter, resetDownloadedSignedPdfFilter] = filters.downloadedSignedPdf;
  const [currentTicketIdFilter, setTicketIdFilter, resetTicketIdFilter] = filters.ticketId;
  const [currentCourtFilter, setCourtFilter, resetCourtFilter] = filters.court;
  const [currentSectionFilter, setSectionFilter, resetSectionFilter] = filters.section;
  const [currentTrialTicketIdFilter, setTrialTicketIdFilter, resetTrialTicketIdFilter] = filters.trialTicketId;
  const { cleanAll } = filters;

  const [ticketIdViewValue, setTicketIdViewValue] = viewFiltersValue.ticketId;
  const [trialTicketIdViewValue, setTrialTicketIdViewValue] = viewFiltersValue.trialTicketId;
  const [courtViewValue, setCourtViewValue] = viewFiltersValue.court;
  const [sectionViewValue, setSectionViewValue] = viewFiltersValue.section;
  const [downloadedSignedPdfViewValue, setDownloadedSignedPdfViewValue] = viewFiltersValue.downloadedSignedPdf;
  const { cleanAllViewFilters } = viewFiltersValue;

  const handleInputChange = (newValue, updateFieldViewFn, updateFilterFn) => {
    updateFieldViewFn(newValue);
    updateFilterFn(newValue);
  };

  const handleCleanAll = () => {
    cleanAllViewFilters();
    cleanAll();
  };
  const handleDelete = (updateFieldViewFn, resetFilterFn) => {
    updateFieldViewFn();
    resetFilterFn();
  };

  if (isLoading || isFetching || isError) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton width="20%" height="4em" animation="pulse" />
        <Skeleton width="20%" height="4em" animation="pulse" />
        <Skeleton width="20%" height="4em" animation="pulse" />
        <Skeleton width="20%" height="4em" animation="pulse" />
        <Skeleton width="20%" height="4em" animation="pulse" />
      </Stack>
    );
  }
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        {/* TRIAL TICKET ID FILTER */}
        <FilterByString
          name="ticketId"
          label={t('prints.filters.labels.ticketId')}
          value={ticketIdViewValue}
          onInputChange={(newValue) => handleInputChange(newValue, setTicketIdViewValue, setTicketIdFilter)}
          options={[...new Set(prints.flatMap((proceeding) => proceeding.id_ticket || []))]}
          color={color}
        />
        {/* TRIAL TICKET ID FILTER */}
        <FilterByString
          name="trialTicketId"
          label={t('prints.filters.labels.trialTicketId')}
          value={trialTicketIdViewValue}
          onInputChange={(newValue) => handleInputChange(newValue, setTrialTicketIdViewValue, setTrialTicketIdFilter)}
          options={[...new Set(prints.flatMap((proceeding) => proceeding.id_ticket_udienza || []))]}
          color={color}
        />
        {/* COURT FILTER */}
        <FilterByString
          name="court"
          label={t('prints.filters.labels.court')}
          value={courtViewValue}
          onInputChange={(newValue) => handleInputChange(newValue, setCourtViewValue, setCourtFilter)}
          options={[...new Set(prints.flatMap((proceeding) => proceeding.tribunale || []))]}
          color={color}
        />
        {/* SECTION FILTER */}
        <FilterByString
          name="section"
          label={t('prints.filters.labels.section')}
          value={sectionViewValue}
          onInputChange={(newValue) => handleInputChange(newValue, setSectionViewValue, setSectionFilter)}
          options={[...new Set(prints.flatMap((proceeding) => proceeding.sezione || []))]}
          color={color}
        />
        {/* DOWNLOADED SIGNED PDF FILTER */}
        <FormControl variant="outlined" sx={{ flexGrow: 1 }} color={downloadedSignedPdfViewValue === 'yes' ? 'warning' : 'primary'}>
          <InputLabel>{t('prints.filters.labels.downloadedSignedPdf')}</InputLabel>
          <Select
            labelId="downloadedSignedPdf"
            id="downloadedSignedPdf"
            value={downloadedSignedPdfViewValue}
            onChange={(e) => handleInputChange(e.target.value, setDownloadedSignedPdfViewValue, setDownloadedSignedPdfFilter)}
            label={t('prints.filters.labels.downloadedSignedPdf')}
            color={downloadedSignedPdfViewValue === 'yes' ? 'warning' : 'primary'}
          >
            <MenuItem value="">{t('proceedings.proceedingsList.proceeding.downloadedSignedPdfFlag.values.all')}</MenuItem>
            <MenuItem value="yes">{t('proceedings.proceedingsList.proceeding.downloadedSignedPdfFlag.values.yes')}</MenuItem>
            <MenuItem value="no">{t('proceedings.proceedingsList.proceeding.downloadedSignedPdfFlag.values.no')}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {hasFilters && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
          <Typography marginRight={2}>{t('prints.filterBy')}:</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* TICKET ID CHIP */}
            {currentDownloadedSignedPdfFilter && (
              <Chip
                variant="filled"
                color={currentDownloadedSignedPdfFilter === 'yes' ? 'warning' : 'primary'}
                label={`${t('prints.filters.labels.downloadedSignedPdf')}: ${t(`proceedings.proceedingsList.proceeding.downloadedSignedPdfFlag.values.${currentDownloadedSignedPdfFilter === '' ? 'all' : currentDownloadedSignedPdfFilter}`)}`}
                onDelete={() => handleDelete(setDownloadedSignedPdfViewValue, resetDownloadedSignedPdfFilter)}
              />
            )}
            {/* TICKET ID CHIP */}
            {currentTicketIdFilter && <Chip variant="filled" color={color} label={`${t('prints.filters.labels.ticketId')}: ${currentTicketIdFilter}`} onDelete={() => handleDelete(setTicketIdViewValue, resetTicketIdFilter)} />}
            {/* TRIAL TICKET ID CHIP */}
            {currentTrialTicketIdFilter && <Chip variant="filled" color={color} label={`${t('prints.filters.labels.trialTicketId')}: ${currentTrialTicketIdFilter}`} onDelete={() => handleDelete(setTrialTicketIdViewValue, resetTrialTicketIdFilter)} />}
            {/* COURT CHIP */}
            {currentCourtFilter && <Chip variant="filled" color={color} label={`${t('prints.filters.labels.court')}: ${currentCourtFilter}`} onDelete={() => handleDelete(setCourtViewValue, resetCourtFilter)} />}
            {/* SECTION CHIP */}
            {currentSectionFilter && <Chip variant="filled" color={color} label={`${t('prints.filters.labels.section')}: ${currentSectionFilter}`} onDelete={() => handleDelete(setSectionViewValue, resetSectionFilter)} />}
          </Stack>
          {/* RESET ALL FILTERS BUTTON */}
          <IconButton sx={{ marginLeft: 'auto' }} variant="contained" onClick={handleCleanAll}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

PrintsFilters.defaultProps = {
  color: 'primary',
};

PrintsFilters.propTypes = {
  color: PropTypes.string,
};

export default PrintsFilters;
