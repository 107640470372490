import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Forward } from '@mui/icons-material';

function CertificateRenameDialog({ electronFlag, fileName, fileRename, isOpen, onClose, renameOnFileSystem, setRenameOnFileSystem }) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle bgcolor="orange">{t('trials.dialogs.certificateFile.title')}</DialogTitle>
      <DialogContent dividers sx={{ py: '2em' }}>
        <DialogContentText>{t('trials.dialogs.certificateFile.renameFileText')}</DialogContentText>
        <Stack margin={2} spacing={2} direction="row" alignItems="center" justifyContent="space-evenly">
          <Typography>{fileName}</Typography>
          <Forward />
          <Typography>{fileRename}</Typography>
        </Stack>
        {electronFlag && (
          <FormControlLabel
            control={<Checkbox data-testid="renameFileOnFileSystemCheckbox" color="error" checked={renameOnFileSystem} onChange={() => setRenameOnFileSystem(!renameOnFileSystem)} name="renameOnFileSystem" />}
            sx={{ color: 'red' }}
            label={t('trials.dialogs.certificateFile.renameFileOnFileSystemText')}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="warning" onClick={() => onClose(false)}>
          {t('common.actions.abort')}
        </Button>
        <Button variant="contained" color="warning" onClick={() => onClose(true)}>
          {t('common.actions.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CertificateRenameDialog.defaultProps = {
  fileName: '',
  fileRename: '',
  renameOnFileSystem: true,
  electronFlag: false,
};

CertificateRenameDialog.propTypes = {
  electronFlag: PropTypes.bool,
  fileName: PropTypes.string,
  fileRename: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renameOnFileSystem: PropTypes.bool,
  setRenameOnFileSystem: PropTypes.func.isRequired,
};

export default CertificateRenameDialog;
