import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { selectTDSAuth } from '../features/authSlice';

function ProfileAvatar({ size, picture }) {
  const profile = useSelector(selectTDSAuth).user;

  return (
    <Avatar
      data-testid="ProfileAvatar"
      imgProps={{ 'data-testid': 'ProfileAvatarImg' }}
      src={(picture && URL.createObjectURL(picture)) || profile?.profilePicture || ''}
      sx={{
        width: size === 'small' ? 40 : 150,
        height: size === 'small' ? 40 : 150,
        fontSize: size === 'small' ? 'auto' : 50,
      }}
    >
      {profile?.username?.charAt(0).toUpperCase()}
    </Avatar>
  );
}

ProfileAvatar.defaultProps = {
  picture: null,
  size: 'small',
};

ProfileAvatar.propTypes = {
  picture: PropTypes.instanceOf(Blob),
  size: PropTypes.string,
};

export default ProfileAvatar;
