import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../../app/config';

export const apiBaseUrl = config.api.tds.url;

export const formUrlEncode = (items) => {
  const formBody = Object.keys(items)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(items[key])}`)
    .join('&');
  return formBody;
};

const mapFieldNames = new Map();
mapFieldNames.set('sectionId', 'id_sezione');
mapFieldNames.set('courtId', 'id_tribunale');
mapFieldNames.set('statusId', 'id_stato');
mapFieldNames.set('ticketId', 'id_ticket');
mapFieldNames.set('trichId', 'id_trich');
mapFieldNames.set('srichId', 'id_srich');
mapFieldNames.set('trialId', 'id');
mapFieldNames.set('startDate', 'data_ora_inizio_udienza');
mapFieldNames.set('court', 'tribunale');
mapFieldNames.set('room', 'aula');
mapFieldNames.set('proceedingId', 'id_procedimento');
mapFieldNames.set('proceedingTicketId', 'id_ticket_procedimento');
mapFieldNames.set('proceedingStartingTime', 'ora_inizio_procedimento');

export const formatFieldsListParameter = (fields) => {
  return Object.keys(fields)
    .map((key) => `${mapFieldNames.has(key) ? mapFieldNames.get(key) : key}-${fields[key]}`)
    .join(',');
};

export const formatParameters = ({ filters, orderby, page, pSize }) => {
  const params = {};
  if (filters) {
    const filter = formatFieldsListParameter(filters);
    if (filter) {
      params.filter = filter;
    }
  }
  if (orderby) {
    const order = formatFieldsListParameter(orderby);
    if (order) {
      params.orderby = order;
    }
  }
  if (page) {
    params.page = page;
  }
  if (pSize) {
    params.limit = pSize;
  }
  return params;
};

export const getToken = async () => {
  return sessionStorage.getItem('tds-token');
};

export const setToken = async (token) => {
  sessionStorage.setItem('tds-token', token);
};

export const removeToken = async () => {
  return sessionStorage.removeItem('tds-token');
};

export const getProfile = async () => {
  const profile = sessionStorage.getItem('tds-profile');
  return profile ? JSON.parse(profile) : null;
};

export const setProfile = async (profile) => {
  sessionStorage.setItem('tds-profile', JSON.stringify(profile));
};

export const removeProfile = async () => {
  return sessionStorage.removeItem('tds-profile');
};

export const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: async (headers, query) => {
    const authResult = await getToken();
    if (authResult) {
      if (query.endpoint !== 'login') {
        headers.set('Authorization', `Bearer ${authResult}`);
      }
    }
    return headers;
  },
  validateStatus: (response, result) => response.status === 200 && !result.error,
});
