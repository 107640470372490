import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, LinearProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAddFileMutation } from '../features/api/tds/apiTrialsSlice';
import { setAlertMessage } from '../features/layoutSlice';
import CertificateRenameDialog from './CertificateRenameDialog';

function CertificateLoadButton({ ticketId, certificateType, onUploadSuccessCallback, asMenuItem, disabled, text }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [addFile, { isLoading: isLoadingAddFile }] = useAddFileMutation();
  const [isRenameFileDialogOpen, setIsRenameFileDialogOpen] = useState(false);
  const [renameOnFileSystem, setRenameOnFileSystem] = useState(true);
  const [certificateFile, setCertificateFile] = useState(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const checkFile = (e, file) => {
    if (file.name.localeCompare(`${ticketId}.pdf`, undefined, { sensitivity: 'base' })) {
      setCertificateFile(file);
      setIsRenameFileDialogOpen(true);
    } else uploadCertificate(file);
    e.target.value = null;
  };

  const onRenameFileDialogClose = (userChoice) => {
    if (userChoice) {
      const renamedFile = new File([certificateFile], `${ticketId}.pdf`, { type: 'application/pdf' });
      uploadCertificate(renamedFile);
    } else dispatch(setAlertMessage({ text: `${t('trials.form.editTrial.errors.wrongFile')} ${certificateFile.name}`, type: 'error', render: 'snack' }));
    setIsRenameFileDialogOpen(false);
  };

  const uploadCertificate = async (file) => {
    try {
      const response = await addFile({ file, type: certificateType }).unwrap();
      if (!!window.electronAPI && certificateFile?.path && renameOnFileSystem) window.electronAPI.renameFile(certificateFile.path, `${ticketId}.pdf`);
      onUploadSuccessCallback(response.fileId);
      setCertificateFile(null);
    } catch (err) {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.fileLoading'), type: 'error', render: 'snack' }));
    }
  };

  if (isLoadingAddFile && asMenuItem)
    return (
      <MenuItem data-testid="LoadCertificateMenuItem" key="LoadCertificateMenuItem">
        <LinearProgress color="primary" sx={{ width: '100%', height: 10, borderRadius: 5 }} />
      </MenuItem>
    );
  if (isLoadingAddFile) return <LinearProgress color="primary" sx={{ width: 2 / 5, height: 10, borderRadius: 5 }} />;

  return (
    <>
      <CertificateRenameDialog
        electronFlag={!!window.electronAPI}
        renameOnFileSystem={renameOnFileSystem}
        setRenameOnFileSystem={setRenameOnFileSystem}
        fileName={certificateFile && certificateFile.name}
        fileRename={ticketId && `${ticketId}.pdf`}
        isOpen={isRenameFileDialogOpen}
        onClose={onRenameFileDialogClose}
      />
      {asMenuItem ? (
        <MenuItem data-testid="LoadCertificateMenuItem" key="LoadCertificateMenuItem" onClick={handleClick} disabled={disabled}>
          <ListItemIcon>
            <Upload fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={text !== '' ? text : t('common.actions.upload', { what: 'pdf' })} />
        </MenuItem>
      ) : (
        <Button color="secondary" variant="contained" component="label" onClick={handleClick} disabled={disabled}>
          {text !== '' ? text : t('common.actions.upload', { what: 'pdf' })}
        </Button>
      )}
      <input ref={fileInputRef} data-testid="certificate" id="certificate" name="certificate" hidden onChange={(e) => e.target.files && checkFile(e, e.target.files[0])} type="file" accept=".pdf" />
    </>
  );
}

CertificateLoadButton.defaultProps = {
  certificateType: 'p',
  asMenuItem: false,
  disabled: false,
  text: '',
};

CertificateLoadButton.propTypes = {
  ticketId: PropTypes.string.isRequired,
  certificateType: PropTypes.string,
  onUploadSuccessCallback: PropTypes.func.isRequired,
  asMenuItem: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export default CertificateLoadButton;
