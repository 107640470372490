import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Stack, Card, CardHeader, IconButton, Backdrop, CircularProgress, Skeleton } from '@mui/material';
import { Delete, Download as DownloadIcon, Launch } from '@mui/icons-material';
import useOtherFile from '../hooks/useOtherFile';

function OtherFile({ otherFileId }) {
  const { otherFile, fetchFileFromIndexedDB, isDownloading, isLoading, removeOtherFile, downloadOtherFile, openFile } = useOtherFile(otherFileId);

  useEffect(() => {
    fetchFileFromIndexedDB();
  }, [fetchFileFromIndexedDB]);

  const backdrop = (
    <Backdrop open={isDownloading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  if (isLoading) return <Skeleton data-testid="loading-skeleton" variant="rectangular" height="3em" animation="pulse" />;
  return (
    <Card elevation={3}>
      <CardHeader
        sx={{ padding: 0.5 }}
        titleTypographyProps={{ variant: 'caption' }}
        title={otherFile?.fileName}
        avatar={
          <Stack direction="row" alignItems="center">
            <IconButton onClick={downloadOtherFile} title="dowload" disabled={isDownloading}>
              <DownloadIcon fontSize="small" />
              {backdrop}
            </IconButton>
            <IconButton onClick={(e) => openFile(e)}>
              <Launch fontSize="small" />
            </IconButton>
          </Stack>
        }
        action={
          <IconButton color="error" onClick={removeOtherFile}>
            <Delete />
          </IconButton>
        }
      />
    </Card>
  );
}

OtherFile.propTypes = {
  otherFileId: PropTypes.string.isRequired,
};

export default OtherFile;
