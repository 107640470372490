import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import printJS from 'print-js';
import { useLazyGetFileQuery, useLazyGetCertificateFileQuery, useLazyGetCertificateNameQuery } from '../features/api/tds/apiTrialsSlice';
import { setAlertMessage, openFileDialog, setFileDialog } from '../features/layoutSlice';
import apiProceedingsSlice from '../features/api/tds/apiProceedingsSlice';

function useDocumentAction({ fileType, documentId, actionType, ticketId, fileName, setPrinted, endpoint, trialId, proceedingId, workAwayFlag, actionSuccessCallback }) {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [getFile, { isFetching, isError }] = useLazyGetFileQuery();
  const [getCertificateFile, { isFetching: isFetchingCertificate, isError: isErrorCertificate }] = useLazyGetCertificateFileQuery();
  const [getCertificateName] = useLazyGetCertificateNameQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let extension;
  if (fileType === 'p' || fileType === 'AC' || fileType === 'ACR' || fileType === 'AP') {
    extension = 'pdf';
  }

  const printFile = async () => {
    try {
      let response;
      if (endpoint === 'certificate') {
        response = await getCertificateFile({ id: trialId || proceedingId, type: fileType, workAwayFlag }).unwrap();
      }
      if (documentId) {
        response = await getFile({ fileId: documentId, type: fileType, print: setPrinted }).unwrap();
      }
      printJS({
        printable: window.URL.createObjectURL(response.file),
        showModal: true,
        onLoadingStart: () => {
          setIsPrinting(true);
        },
        onPrintDialogClose: () => {
          setIsPrinting(false);
        },
        onAfterPrint: () => {
          setIsPrinting(false);
          if (actionSuccessCallback) actionSuccessCallback();
        },
      });
      dispatch(apiProceedingsSlice.util.invalidateTags([{ type: 'PrintList', id: documentId }]));
    } catch (error) {
      if (error.status === 404) dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.fileNotFound'), type: 'error' }));
      throw error;
    } finally {
      setIsPrinting(false);
    }
  };

  const downloadFile = async () => {
    if (!ticketId && !fileName) return;
    try {
      setIsDownloading(true);
      let response;
      let name;
      if (endpoint === 'certificate') {
        response = await getCertificateFile({ id: trialId || proceedingId, type: fileType, workAwayFlag }).unwrap();
        const nameRes = await getCertificateName({ ticketId, type: fileType, workAwayFlag }).unwrap();
        name = nameRes || `${ticketId}_${fileType}.${extension}`;
      }
      if (documentId) {
        response = await getFile({ fileId: documentId, type: fileType }).unwrap();
        name = ticketId ? `${ticketId}_${fileType}.${extension}` : fileName;
      }
      const url = URL.createObjectURL(response.file);
      const a = document.createElement('a');
      a.download = name;
      a.href = url;
      a.click();
      setIsDownloading(false);
      if (actionSuccessCallback) actionSuccessCallback();
    } catch (err) {
      setIsDownloading(false);
      if (err.status === 404) dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.fileNotFound'), type: 'error' }));
      throw err;
    }
  };

  const openFile = async () => {
    if (!ticketId && !fileName) return;
    try {
      setIsOpening(true);
      let response;
      let name;
      if (endpoint === 'certificate') {
        response = await getCertificateFile({ id: trialId || proceedingId, type: fileType, workAwayFlag }).unwrap();
        const nameRes = await getCertificateName({ ticketId, type: fileType, workAwayFlag }).unwrap();
        name = nameRes || `${ticketId}_${fileType}.${extension}`;
      }
      if (documentId) {
        response = await getFile({ fileId: documentId, type: fileType }).unwrap();
        name = ticketId ? `${ticketId}_${fileType}.${extension}` : fileName;
      }
      const url = URL.createObjectURL(response.file);
      dispatch(setFileDialog({ name, url }));
      dispatch(openFileDialog());
      setIsOpening(false);
      if (actionSuccessCallback) actionSuccessCallback();
    } catch (err) {
      setIsOpening(false);
      if (err.status === 404) dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.fileNotFound'), type: 'error' }));
      throw err;
    }
  };

  if (actionType === 'print') return [printFile, { isActing: isPrinting || isFetching || isFetchingCertificate, isError: isError || isErrorCertificate }];
  if (actionType === 'download') return [downloadFile, { isActing: isDownloading || isFetching || isFetchingCertificate, isError: isError || isErrorCertificate }];
  if (actionType === 'open') return [openFile, { isActing: isOpening || isFetching || isFetchingCertificate, isError: isError || isErrorCertificate }];

  return [null, { isActing: false, isError: false }];
}

export default useDocumentAction;
