import PropTypes from 'prop-types';
import { IconButton, Stack } from '@mui/material';
import { PlayArrow as PlayIcon, Pause as PauseIcon, Forward10 as ForwardTenIcon, Replay10 as ReplayTenIcon } from '@mui/icons-material';

function PlayerControls({ size, onForward10, onReplay10, onPlay, onPause, isPlaying }) {
  return (
    <Stack direction="row" spacing={size === 'small' ? 1 : 2} alignItems="center">
      <IconButton size={size} onClick={() => onReplay10()}>
        <ReplayTenIcon />
      </IconButton>
      {isPlaying ? (
        <IconButton size={size} onClick={() => onPause()}>
          <PauseIcon />
        </IconButton>
      ) : (
        <IconButton size={size} onClick={() => onPlay()}>
          <PlayIcon />
        </IconButton>
      )}
      <IconButton size={size} onClick={() => onForward10()}>
        <ForwardTenIcon />
      </IconButton>
    </Stack>
  );
}

PlayerControls.defaultProps = {
  size: 'medium',
};
PlayerControls.propTypes = {
  onForward10: PropTypes.func.isRequired,
  onReplay10: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

export default PlayerControls;
