import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Chip, Skeleton, Typography, Box, TextField } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import FilterByString from './FilterByString';
import FilterByStatus from './Filters/FilterByStatus';
import StatusChip from './Filters/StatusChip';
import useProceedingResults from '../hooks/useProceedingResults';

function ProceedingFilters({ color }) {
  const { t } = useTranslation();
  const { filteredProceedings: proceedings, proceedingsCount, proceedingsAll, isLoading, isFetching, hasFilters, filters, viewFilterValues } = useProceedingResults();
  const [currentTickedIdFilter, setTicketIdFilter, resetTicketIdFilter] = filters.ticketId;
  const [currentStatusIdFilter, setStatusIdFilter, resetStatusIdFilter, { statusOptions, isStatusOptionsLoading, isStatusOptionsFetching }] = filters.statusId;
  const [currentRGFilter, setRGFilter, resetRGFilter] = filters.RG;
  const [currentRGNRFilter, setRGNRFilter, resetRGNRFilter] = filters.RGNR;
  const [currentJudgeFilter, setJudgeFilter, resetJudgeFilter, { availableJudges }] = filters.judge;
  const [currentOutcomeFilter, setOutcomeFilter, resetOutcomeFilter] = filters.outcome;
  const [currentDefendantFilter, setDefendantFilter, resetDefendantFilter] = filters.defendant;
  const [currentChancellorFilter, setChancellorFilter, resetChancellorFilter] = filters.chancellor;
  const [currentPMFilter, setPMFilter, resetPMFilter] = filters.pm;
  const { cleanAll: cleanAllFilters } = filters;

  const [viewTicketIdFilter, setViewTicketIdFilter] = viewFilterValues.ticketId;
  const [viewStatusIdFilter, setViewStatusIdFilter] = viewFilterValues.statusId;
  const [viewRGFilter, setViewRGFilter] = viewFilterValues.RG;
  const [viewRGNRFilter, setViewRGNRFilter] = viewFilterValues.RGNR;
  const [viewJudgeFilter, setViewJudgeFilter] = viewFilterValues.judge;
  const [viewOutcomeFilter, setViewOutcomeFilter] = viewFilterValues.outcome;
  const [viewDefendantFilter, setViewDefendantFilter] = viewFilterValues.defendant;
  const [viewChancellorFilter, setViewChancellorFilter] = viewFilterValues.chancellor;
  const [viewPMFilter, setViewPMFilter] = viewFilterValues.pm;
  const { cleanAll: cleanAllViewFilters } = viewFilterValues;

  const handleInputChange = (newValue, updateFieldViewFn, updateFilterFn) => {
    updateFieldViewFn(newValue);
    updateFilterFn(newValue);
  };
  const handleDelete = (updateFieldViewFn, resetFilterFn) => {
    updateFieldViewFn('');
    resetFilterFn();
  };
  const handleCleanAll = () => {
    cleanAllViewFilters();
    cleanAllFilters();
  };

  if (proceedingsCount <= 1) {
    return null;
  }

  if (isLoading || isFetching || !proceedingsAll) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} marginY={2}>
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
        <Skeleton width="10em" height="3em" animation="pulse" />
      </Stack>
    );
  }
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        {/* TICKET ID FILTER */}
        <FilterByString
          size="small"
          name="ticketId"
          label={t('proceedings.proceedingsList.filters.ticketId.label')}
          value={viewTicketIdFilter}
          onInputChange={(newValue) => handleInputChange(newValue, setViewTicketIdFilter, setTicketIdFilter)}
          options={[...new Set(proceedings.flatMap((proceeding) => proceeding.id_ticket || []))]}
          color={color}
        />
        {/* STATUS FILTER */}
        <FilterByStatus
          size="small"
          name="statusId"
          label={t('proceedings.proceedingsList.filters.status.label')}
          value={viewStatusIdFilter}
          statusOptions={statusOptions}
          isLoading={isStatusOptionsLoading}
          isFetching={isStatusOptionsFetching}
          onChange={(newValue) => handleInputChange(newValue, setViewStatusIdFilter, setStatusIdFilter)}
          color={color}
        />
        {/* RG FILTER */}
        <FilterByString
          size="small"
          name="RG"
          label={t('proceedings.proceedingsList.filters.RG.label')}
          value={viewRGFilter}
          onInputChange={(newValue) => handleInputChange(newValue, setViewRGFilter, setRGFilter)}
          options={[...new Set(proceedings.flatMap((proceeding) => proceeding.RG || []))]}
          color={color}
        />
        {/* RGNR FILTER */}
        <FilterByString
          size="small"
          name="RGNR"
          label={t('proceedings.proceedingsList.filters.RGNR.label')}
          value={viewRGNRFilter}
          onInputChange={(newValue) => handleInputChange(newValue, setViewRGNRFilter, setRGNRFilter)}
          options={[...new Set(proceedings.flatMap((proceeding) => proceeding.RGNR || []))]}
          color={color}
        />
        {/* OUTCOME FILTER */}
        <TextField size="small" name="outcome" label={t('proceedings.proceedingsList.filters.outcome.label')} value={viewOutcomeFilter} onChange={(e) => handleInputChange(e.target.value, setViewOutcomeFilter, setOutcomeFilter)} />
        {/* JUDGE FILTER */}
        <FilterByString
          size="small"
          name="judge"
          label={t('proceedings.proceedingsList.filters.judge.label')}
          value={viewJudgeFilter}
          onInputChange={(newValue) => handleInputChange(newValue, setViewJudgeFilter, setJudgeFilter)}
          options={availableJudges}
          color={color}
        />
        {/* DEFENDANT FILTER */}
        <TextField size="small" name="defendant" label={t('proceedings.proceedingsList.filters.defendant.label')} value={viewDefendantFilter} onChange={(e) => handleInputChange(e.target.value, setViewDefendantFilter, setDefendantFilter)} />
        {/* CHANCELLOR FILTER */}
        <TextField size="small" name="chancellor" label={t('proceedings.proceedingsList.filters.chancellor.label')} value={viewChancellorFilter} onChange={(e) => handleInputChange(e.target.value, setViewChancellorFilter, setChancellorFilter)} />
        {/* PM FILTER */}
        <TextField size="small" name="pm" label={t('proceedings.proceedingsList.filters.pm.label')} value={viewPMFilter} onChange={(e) => handleInputChange(e.target.value, setViewPMFilter, setPMFilter)} />
      </Stack>
      {hasFilters && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography marginRight={2}>{t('proceedings.proceedingsList.filters.label')}:</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* TICKET ID CHIP */}
            {currentTickedIdFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.ticketId.label')}: ${currentTickedIdFilter}`} onDelete={() => handleDelete(setViewTicketIdFilter, resetTicketIdFilter)} />}
            {/* STATUS CHIP */}
            <StatusChip statusId={currentStatusIdFilter} statusOptions={statusOptions} label={t('proceedings.proceedingsList.filters.status.label')} onDelete={() => handleDelete(setViewStatusIdFilter, resetStatusIdFilter)} />
            {/* RG CHIP */}
            {currentRGFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.RG.label')}: ${currentRGFilter}`} onDelete={() => handleDelete(setViewRGFilter, resetRGFilter)} />}
            {/* RGNR CHIP */}
            {currentRGNRFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.RGNR.label')}: ${currentRGNRFilter}`} onDelete={() => handleDelete(setViewRGNRFilter, resetRGNRFilter)} />}
            {/* OUTCOME CHIP */}
            {currentOutcomeFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.outcome.label')}: ${currentOutcomeFilter}`} onDelete={() => handleDelete(setViewOutcomeFilter, resetOutcomeFilter)} />}
            {/* JUDGE CHIP */}
            {currentJudgeFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.judge.label')}: ${currentJudgeFilter}`} onDelete={() => handleDelete(setViewJudgeFilter, resetJudgeFilter)} />}
            {/* DEFENDANT CHIP */}
            {currentDefendantFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.defendant.label')}: ${currentDefendantFilter}`} onDelete={() => handleDelete(setViewDefendantFilter, resetDefendantFilter)} />}
            {/* CHANCELLOR CHIP */}
            {currentChancellorFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.chancellor.label')}: ${currentChancellorFilter}`} onDelete={() => handleDelete(setViewChancellorFilter, resetChancellorFilter)} />}
            {/* PM CHIP */}
            {currentPMFilter && <Chip variant="filled" color={color} label={`${t('proceedings.proceedingsList.filters.pm.label')}: ${currentPMFilter}`} onDelete={() => handleDelete(setViewPMFilter, resetPMFilter)} />}
          </Stack>
          {/* RESET ALL FILTERS BUTTON */}
          <IconButton sx={{ marginLeft: 'auto' }} variant="contained" onClick={handleCleanAll}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

ProceedingFilters.defaultProps = {
  color: 'primary',
};

ProceedingFilters.propTypes = {
  color: PropTypes.string,
};

export default ProceedingFilters;
