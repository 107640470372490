import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Stack, Typography, Tooltip, Backdrop, CircularProgress, Divider, Badge, Box } from '@mui/material';
import { Gavel } from '@mui/icons-material';
import ProceedingForm from './ProceedingForm';
import ProceedingFormSubmitButton from './ProceedingFormSubmitButton';
import useSingleProceedingDialog from '../hooks/useSingleProceedingDialog';

import ProceedingsWithReportBadge from './ProceedingsWithReportBadge';

function SingleProceedingDialog() {
  const { t } = useTranslation();

  const { trialId, trialTicketId, proceeding, isSubmitting, isSingleProceedingDialogOpen, closeDialog, proceedingsCount, maxProceedingsCount, proceedingsWithVerbalFlagCount, maxProceedingsWithVerbalFlagCount, trialCompleted, handleTrialCompleted } =
    useSingleProceedingDialog();

  useEffect(() => {
    if (isSingleProceedingDialogOpen && trialCompleted) handleTrialCompleted();
  }, [isSingleProceedingDialogOpen, trialCompleted, handleTrialCompleted]);

  const countColor = (count, totalCount, isTrial) => {
    if (Number(count) === Number(totalCount)) return isTrial ? 'secondary.dark' : 'primary.dark';
    if (Number(count) > Number(totalCount)) return 'error.main';
    return 'grey.500';
  };

  const backdrop = (
    <Backdrop open={isSubmitting} sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <Stack spacing={3} alignItems="center">
        <Typography variant="button">{t('proceedings.dialogs.saving')}</Typography>
        <CircularProgress color="inherit" />
      </Stack>
    </Backdrop>
  );

  return (
    <Dialog onClose={(e, reason) => reason === 'backdropClick' && !isSubmitting && closeDialog()} open={isSingleProceedingDialogOpen} fullWidth maxWidth="md">
      {backdrop}
      <DialogTitle sx={{ padding: 1, backgroundColor: 'primary.light', color: 'primary.contrastText' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {proceeding.ticketId ? <Typography>{t('proceedings.dialogs.editing.title')}</Typography> : <Typography>{t('proceedings.dialogs.singleUpload.title')}</Typography>}
          <Tooltip title={t('proceedings.form.addProceeding.fields.ticketId')}>
            <Typography>{proceeding.ticketId}</Typography>
          </Tooltip>
          <Tooltip title={t('proceedings.form.addProceeding.fields.status')}>
            <Typography>{proceeding.status}</Typography>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogTitle sx={{ padding: 1, backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Tooltip title={t('trials.trialsList.trial.ticketId')}>
            <Typography>{trialTicketId}</Typography>
          </Tooltip>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} pr={3}>
            <Tooltip title={t('trials.trialsList.trial.uploadedProceedingsCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsCount, maxProceedingsCount) }} component="span" variant="caption">
                      {proceedingsCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {maxProceedingsCount || 0}
                    </Typography>
                  </Box>
                }
              >
                <Gavel color="action" />
              </Badge>
            </Tooltip>
            {/* COUNT PROCEDIMENTI CARICATI DA VERBALIZZARE */}
            <Tooltip title={t('trials.trialsList.trial.proceedingsToBeTranscribedCounter')}>
              <Badge
                badgeContent={
                  <Box display="block">
                    <Typography sx={{ color: countColor(proceedingsWithVerbalFlagCount, maxProceedingsWithVerbalFlagCount) }} component="span" variant="caption">
                      {proceedingsWithVerbalFlagCount}
                    </Typography>
                    <Typography px={0.2} component="span" variant="caption">
                      /
                    </Typography>
                    <Typography component="span" variant="caption">
                      {maxProceedingsWithVerbalFlagCount || 0}
                    </Typography>
                  </Box>
                }
              >
                <ProceedingsWithReportBadge />
              </Badge>
            </Tooltip>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <ProceedingForm />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={closeDialog}>
          {t('common.actions.close')}
        </Button>
        <ProceedingFormSubmitButton trialId={trialId} />
      </DialogActions>
    </Dialog>
  );
}

export default SingleProceedingDialog;
