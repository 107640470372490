import { useDispatch, useSelector } from 'react-redux';
import {
  selectBulkProceedingsReinsertCdDialogMissingRecordingId,
  selectBulkProceedingsReinsertCdDialogFileMissing,
  selectIsBulkProceedingsReinsertCdDialogOpen,
  setBulkProceedingsReinsertCdDialog,
  setBulkProceedingsReinsertCdDialogMissingRecordingId,
  setBulkProceedingsReinsertCdDialogFileMissing,
} from '../features/bulkProceedingsSlice';
import { selectAllLoadedCds } from '../features/loadedCdsSlice';
import { selectRecordingById } from '../features/recordingsSlice';

export default function useBulkProceedingsCdReinsertDialog(tryAgainCallback) {
  const dispatch = useDispatch();

  const cdsInfo = useSelector((state) => selectAllLoadedCds(state.loadedCds));
  const isOpen = useSelector(selectIsBulkProceedingsReinsertCdDialogOpen);
  const missingFile = useSelector(selectBulkProceedingsReinsertCdDialogFileMissing);
  const missingRecordingId = useSelector(selectBulkProceedingsReinsertCdDialogMissingRecordingId);
  const recording = useSelector((state) => selectRecordingById(state.recordings, missingRecordingId));

  const handleClose = () => {
    dispatch(setBulkProceedingsReinsertCdDialogFileMissing(''));
    dispatch(setBulkProceedingsReinsertCdDialogMissingRecordingId(''));
    dispatch(setBulkProceedingsReinsertCdDialog(''));
  };

  const handleTryAgain = () => {
    handleClose();
    tryAgainCallback();
  };

  return {
    isOpen,
    missingFile,
    missingCd: recording ? cdsInfo.find((cd) => cd.id === recording.cdId) : null,
    handleClose,
    handleTryAgain,
  };
}
