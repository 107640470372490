import { Box, Container } from '@mui/material';
import Clock from './Clock';
import config from '../../app/config';

function Footer() {
  return (
    <Box component="footer" sx={{ width: '100vw', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="Footer">
      <Container maxWidth="xxl" sx={{ p: 0, display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end' }}>
        {config.features.clock && <Clock />}
      </Container>
    </Box>
  );
}

export default Footer;
