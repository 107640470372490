import { initDB as originalInitDB } from 'react-indexed-db-hook';

const TDSTicketsUploaderIndexedDBConfig = {
  name: 'TDSTicketsUploader',
  version: 2,
  objectStoresMeta: [
    {
      store: 'files',
      storeConfig: { keyPath: 'name' },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
        { name: 'file', keypath: 'file', options: { unique: false } },
      ],
    },
  ],
};

function createMissingSchemas(db, storeSchemas) {
  storeSchemas.forEach((storeSchema) => {
    if (!db.objectStoreNames.contains(storeSchema.store)) {
      const objectStore = db.createObjectStore(storeSchema.store, storeSchema.storeConfig);
      storeSchema.storeSchema.forEach((schema) => {
        objectStore.createIndex(schema.name, schema.keypath, schema.options);
      });
    }
  });
}

function applyMigrations(db, oldVersion, newVersion, storeSchemas) {
  return new Promise((resolve, reject) => {
    if (oldVersion > newVersion) {
      resolve(false);
    }
    if (oldVersion < newVersion) {
      for (const objectStoreName of db.objectStoreNames) {
        db.deleteObjectStore(objectStoreName);
      }
      // const deleteRequest = indexedDB.deleteDatabase(db.name);
      createMissingSchemas(db, storeSchemas);
      // deleteRequest.onsuccess = () => resolve(true);
      // deleteRequest.onerror = () => resolve(false);
    } else {
      resolve(true);
    }
  });
}

function upgradeDBIfNeeded(dbConfig = TDSTicketsUploaderIndexedDBConfig, upgradeFunction = applyMigrations) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbConfig.name, dbConfig.version);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const { oldVersion, newVersion } = event;

      if (upgradeFunction) {
        upgradeFunction(db, oldVersion, newVersion, dbConfig.objectStoresMeta)
          .then((upgradeSuccessful) => {
            if (!upgradeSuccessful) {
              db.close();
              reject(new Error('Error upgrading the IndexedDB database.'));
            }
          })
          .catch((error) => {
            db.close();
            reject(error);
          });
      }
    };

    request.onsuccess = () => {
      const db = request.result;
      db.close();
      if (!dbConfig.objectStoresMeta.every((storeSchema) => db.objectStoreNames.contains(storeSchema.store))) indexedDB.deleteDatabase(dbConfig.name);
      resolve();
    };

    request.onerror = (event) => {
      reject(new Error('Error opening or upgrading the IndexedDB database.'));
    };
  });
}

async function initDB(storeConfig = TDSTicketsUploaderIndexedDBConfig) {
  await upgradeDBIfNeeded(storeConfig);
  originalInitDB(storeConfig);
}

export default TDSTicketsUploaderIndexedDBConfig;

export { TDSTicketsUploaderIndexedDBConfig, applyMigrations, upgradeDBIfNeeded, initDB };
