function stringToCodePoints(inputString) {
  return [...inputString].map((char) => char.codePointAt(0));
}

function encodeToLatin1(inputString) {
  if (!inputString) return new Uint8Array();
  return Uint8Array.from(stringToCodePoints(inputString), (code) => {
    return code <= 255 ? code : 63;
  });
}

export { encodeToLatin1 };

export default encodeToLatin1;
