import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsSingleProceedingDialogOpen, closeSingleProceedingDialog, selectSingleProceedingSubmittingForm } from '../features/singleProceedingSlice';
import { resetProceedingFormSlice, selectProceeding, selectProceedingFormOtherFiles, selectProceedingFormRecordings, selectProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';
import { useGetProceedingsQuery } from '../features/api/tds/apiProceedingsSlice';
import { useGetTrialQuery } from '../features/api/tds/apiTrialsSlice';
import { setAlertMessage } from '../features/layoutSlice';
import { editProceeding } from '../features/proceedingsSlice';

function useSingleProceedingDialog() {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentData: trial, isLoadingTrial, isFetchingTrial } = useGetTrialQuery(id, { skip: !id });
  const { currentData: proceedings, isLoadingProceedings, isFetchingProceedings } = useGetProceedingsQuery({ trialId: id }, { skip: !id });
  const proceeding = useSelector(selectProceeding);
  const recordings = useSelector(selectProceedingFormRecordings);
  const selectedRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const otherFiles = useSelector(selectProceedingFormOtherFiles);
  const isSingleProceedingDialogOpen = useSelector(selectIsSingleProceedingDialogOpen);
  const isSubmitting = useSelector(selectSingleProceedingSubmittingForm);

  const proceedingsWithVerbalFlagCount = proceedings?.filter((proc) => proc.flag_verbale && proc.flag_verbale === '1')?.length || 0;
  const maxProceedingsWithVerbalFlagCount = trial && trial.n_verbali ? Number(trial.n_verbali) : 0;

  const proceedingsCount = proceedings?.length || 0;
  const maxProceedingsCount = trial && trial.n_procedimenti ? Number(trial.n_procedimenti) : 0;

  const proceedingsWithOutVerbalFlagCount = proceedings?.filter((proc) => proc.flag_verbale && proc.flag_verbale === '0')?.length || 0;
  const maxProceedingsWithOutVerbalFlagCount = maxProceedingsCount - maxProceedingsWithVerbalFlagCount;
  const trialCompleted = !proceeding.proceedingId && maxProceedingsCount <= proceedingsCount;

  const handleTrialCompleted = () => {
    dispatch(closeSingleProceedingDialog());
    dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'success', render: 'snack' }));
  };
  const closeDialog = () => {
    if (proceeding.proceedingId !== '') dispatch(editProceeding({ id: proceeding.proceedingId, changes: { ...proceeding, recordings, selectedRecordings, otherFiles } }));
    dispatch(resetProceedingFormSlice());
    dispatch(closeSingleProceedingDialog());
  };

  return {
    trialId: id,
    trialTicketId: trial?.id_ticket,
    proceeding,
    isSubmitting,
    isSingleProceedingDialogOpen,
    closeDialog,
    isFetching: isFetchingTrial || isFetchingProceedings,
    isLoading: isLoadingTrial || isLoadingProceedings,
    proceedingsCount,
    maxProceedingsCount,
    proceedingsWithVerbalFlagCount,
    maxProceedingsWithVerbalFlagCount,
    proceedingsWithOutVerbalFlagCount,
    maxProceedingsWithOutVerbalFlagCount,
    trialCompleted,
    handleTrialCompleted,
  };
}

export default useSingleProceedingDialog;
