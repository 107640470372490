import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { Button, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBulkProceedingsAddCdButton from '../hooks/useBulkProceedingsAddCdButton';

function BulkProceedingsAddCdButton({ sx, enableFirstOpening }) {
  const { t } = useTranslation();

  const { isLoading, isFetching, disableUploadButton, proceedingsCDTotal, handleLoadCDs, handleNewCdButtonClick } = useBulkProceedingsAddCdButton();

  const bulkAddCdButttonRef = useRef();

  const firstOpening = useCallback(() => {
    if (proceedingsCDTotal === 0 && bulkAddCdButttonRef.current !== null) {
      bulkAddCdButttonRef.current.click();
      bulkAddCdButttonRef.current = null;
    }
  }, [proceedingsCDTotal]);

  useEffect(() => {
    if (enableFirstOpening) firstOpening();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading || isFetching) return <Skeleton width="10em" height="3em" variant="rounded" />;

  return (
    <Button sx={sx} ref={bulkAddCdButttonRef} disabled={disableUploadButton} onClick={handleNewCdButtonClick} variant="contained" color="primary" component="label">
      {t('proceedings.dialogs.buttons.newCd')}
      <input hidden onChange={handleLoadCDs} data-testid="loadCD" webkitdirectory="true" type="file" id="loadCD" />
    </Button>
  );
}

BulkProceedingsAddCdButton.defaultProps = {
  enableFirstOpening: true,
  sx: null,
};

BulkProceedingsAddCdButton.propTypes = {
  enableFirstOpening: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default BulkProceedingsAddCdButton;
