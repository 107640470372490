import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Print as PrintIcon, Download as DownloadIcon, Launch as OpenIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import useDocumentAction from '../hooks/useDocumentAction';

function DocumentActionButton({ children, documentId, fileType, setPrinted, actionType, ticketId, fileName, title, asMenuItem, endpoint, trialId, proceedingId, workAwayFlag, actionSuccessCallback }) {
  const [actionTrigger, { isActing }] = useDocumentAction({ fileType, documentId, actionType, ticketId, fileName, setPrinted, endpoint, trialId, proceedingId, workAwayFlag, actionSuccessCallback });

  const icon = () => {
    if (actionType === 'print') return <PrintIcon fontSize="small" />;
    if (actionType === 'download') return <DownloadIcon fontSize="small" />;
    if (actionType === 'open') return <OpenIcon fontSize="small" />;
    return null;
  };
  const shouldBeDisabled = (!documentId && endpoint !== 'certificate') || isActing;
  const backdrop = (
    <Backdrop open={isActing} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} data-testid="action-backdrop">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  if (children && typeof children === 'string') {
    if (!asMenuItem)
      return (
        <Button variant="outlined" startIcon={icon()} onClick={actionTrigger} disabled={shouldBeDisabled}>
          {children}
          {backdrop}
        </Button>
      );

    return (
      <MenuItem onClick={actionTrigger} disabled={shouldBeDisabled} autoFocus>
        <ListItemIcon>{icon()}</ListItemIcon>
        <ListItemText>{children}</ListItemText>
        {backdrop}
      </MenuItem>
    );
  }
  return (
    <IconButton onClick={actionTrigger} title={title} disabled={shouldBeDisabled}>
      {icon()}
      {backdrop}
    </IconButton>
  );
}

DocumentActionButton.defaultProps = {
  documentId: null,
  ticketId: null,
  fileName: null,
  children: null,
  title: null,
  asMenuItem: false,
  setPrinted: false,
  endpoint: null,
  trialId: null,
  proceedingId: null,
  workAwayFlag: '',
  actionSuccessCallback: null,
};

DocumentActionButton.propTypes = {
  documentId: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  ticketId: PropTypes.string,
  fileName: PropTypes.string,
  children: PropTypes.string,
  title: PropTypes.string,
  asMenuItem: PropTypes.bool,
  setPrinted: PropTypes.bool,
  endpoint: PropTypes.string,
  trialId: PropTypes.string,
  proceedingId: PropTypes.string,
  workAwayFlag: PropTypes.string,
  actionSuccessCallback: PropTypes.func,
};

export default DocumentActionButton;
