import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Done as DoneIcon, Error as ErrorIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLoginWithTdsMutation } from '../features/api/rest/apiAuthSlice';
import { getToken } from '../features/api/tds/configApi';
import { setTranscriptionAuth, selectTranscriptionAuth } from '../features/authSlice';
import { setAlertMessage } from '../features/layoutSlice';

function TDSConnector() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loginWithTds, { isLoading }] = useLoginWithTdsMutation();
  const { isAuth: isTranscriptionAuth, netWorkError: isTranscriptionNetworkError } = useSelector(selectTranscriptionAuth);

  const handleLoginWithTds = async () => {
    try {
      const accessToken = await getToken();
      const response = await loginWithTds({ accessToken }).unwrap();
      dispatch(setTranscriptionAuth({ isAuth: true, user: response.user }));
      return dispatch(setAlertMessage({ text: t('auth.messages.transcriptionBackend.success'), type: 'success' }));
    } catch (err) {
      return dispatch(setAlertMessage({ text: t('auth.messages.transcriptionBackend.connectionError'), type: 'error' }));
    }
  };
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Typography variant="h6" component="h2">
          {isTranscriptionAuth && !isTranscriptionNetworkError && t('auth.messages.transcriptionBackend.logged')}
          {!isTranscriptionAuth && t('auth.messages.transcriptionBackend.unLogged')}
          {isTranscriptionNetworkError && isTranscriptionAuth && t('auth.messages.transcriptionBackend.connectionError')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          {isTranscriptionAuth && !isTranscriptionNetworkError && <DoneIcon color="success" ml={1} size="small" />}
          {(!isTranscriptionAuth || isTranscriptionNetworkError) && <ErrorIcon color="error" ml={1} size="small" />}
        </Box>
      </Stack>
      {isTranscriptionNetworkError && isTranscriptionAuth && (
        <Typography variant="h6" component="h2">
          {t('auth.messages.transcriptionBackend.attemptingLogin')}
        </Typography>
      )}
      {!isTranscriptionAuth && (
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <Button variant="contained" color="primary" onClick={handleLoginWithTds} disabled={isLoading}>
            {t('common.actions.connect')}
          </Button>
          {isLoading && <CircularProgress color="secondary" ml={1} size={20} />}
        </Stack>
      )}
    </>
  );
}

export default TDSConnector;
