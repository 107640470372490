import { judgesMapping } from '../../../utils/judgesMapping';
import apiSlice from './apiSlice';
import { formatParameters, formUrlEncode } from './configApi';
import { deserializeProceedings } from './utils/deserializeProceedings';

const apiSliceWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Proceedings', 'PrintList'] });

const apiProceedingsSlice = apiSliceWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProceedings: builder.query({
      query: ({ trialId, filters, orderby }) => ({
        url: '/getProcedimenti',
        method: 'GET',
        params: { id_udienza: trialId, ...formatParameters({ filters, orderby }) },
      }),
      providesTags: ['Proceedings'],
      transformResponse: (response, meta, arg) => deserializeProceedings(response.procedimenti),
    }),
    getProceedingStatus: builder.query({
      query: () => ({
        url: '/getStatiProcedimento',
        method: 'GET',
      }),
      transformResponse: (response) => response.stati_procedimento,
    }),
    getProceedingOutcomes: builder.query({
      query: () => ({
        url: '/getEsitiProcedimento',
        method: 'GET',
      }),
      transformResponse: (response) => response.esiti_procedimento,
    }),
    getPrints: builder.query({
      query: () => ({
        url: '/getStampe',
        method: 'GET',
      }),
      transformResponse: (response) => deserializeProceedings(response.procedimenti),
      providesTags: (result) => result && [...result.map(({ upload_pdf_firmato }) => ({ type: 'PrintList', id: upload_pdf_firmato })), { type: 'PrintList' }], // eslint-disable-line camelcase
    }),
    // On success it will return 200 HTTP code and a Json file with “success” set to True and “id_procedimento” set to the added/edited proceeding id
    sendProceeding: builder.mutation({
      /* eslint-disable camelcase */
      query: ({
        id_procedimento,
        id_udienza,
        ora_inizio,
        ora_fine,
        flag_verbale,
        RG,
        RGNR,
        imputato,
        id_giudice_presidente,
        id_giudice_1,
        id_giudice_2,
        id_giudice_3,
        cancelliere,
        id_esito,
        data_ora_rinvio,
        esito_altro,
        note,
        scadenza_portale_definitivo,
        scadenza_cartaceo,
        upload_audio,
        n_audio,
        durata_secondi,
        pm,
        upload_attestato_consegna,
        data_upload_attestato_consegna,
        data_ora_consegna,
      }) => ({
        url: '/sendProcedimento',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formUrlEncode({
          id_procedimento,
          id_udienza,
          // datetimes and times use SQL format.
          ora_inizio,
          ora_fine,
          // flag_verbale: 0 (transcription required) or 1 (transcription not required).
          flag_verbale,
          RG,
          RGNR,
          imputato,
          // Judges IDs: either integer or NULL.
          id_giudice_presidente,
          id_giudice_1,
          id_giudice_2,
          id_giudice_3,
          // cancelliere: should be “<DOTT.|DOTT.SSA|SIG.|SIG.RA> <name> <surname>”.
          cancelliere,
          id_esito,
          data_ora_rinvio,
          // esito_altro: notes enabled only when esito="Altro"
          esito_altro,
          note,
          scadenza_portale_definitivo,
          scadenza_cartaceo,
          // upload_audio: php-serialized version of an associative array mapping file ids to their duration in seconds. 0 means that the file is not an audio file. durations may use decimal numbers, not only integers.
          upload_audio,
          // n_audio: number of audio files in upload_audio.
          n_audio,
          // durata_secondi: is supposed to be the sum of durations of files in upload_audio.
          durata_secondi,
          // pm: is a php-serialized array of PM ids. The array is a normal array and not an associative one.
          pm,
          upload_attestato_consegna,
          data_upload_attestato_consegna,
          data_ora_consegna,
        }),
      }),
      /* eslint-enable camelcase */
      invalidatesTags: ['Proceedings'],
    }),
    getJudgesFields: builder.query({
      query: ({ riteTypeId }) => ({
        url: '/getCampiGiudici',
        method: 'GET',
        params: { id_tipo_rito: riteTypeId },
      }),
      transformResponse: (response) => judgesMapping(response.campi_giudici),
    }),
    closeProceeding: builder.mutation({
      /* eslint-disable camelcase */
      query: ({ id_procedimento, id_udienza, upload_attestato_consegna, data_ora_consegna, data_upload_attestato_consegna }) => ({
        url: '/closeProcedimento',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formUrlEncode({ id_procedimento, id_udienza, upload_attestato_consegna, data_ora_consegna, data_upload_attestato_consegna }),
      }),
      /* eslint-enable camelcase */
      invalidatesTags: ['PrintList'],
    }),
  }),
});

export const { useGetProceedingStatusQuery, useGetProceedingOutcomesQuery, useGetProceedingsQuery, useLazyGetProceedingsQuery, useSendProceedingMutation, useGetPrintsQuery, useGetJudgesFieldsQuery, useCloseProceedingMutation } = apiProceedingsSlice;

export default apiProceedingsSlice;

export const { useAddOrUpdateProceedingMutation } = apiProceedingsSlice;
