import { Stack } from '@mui/material';
import TrialFilters from './TrialFilters';
import OrderBy from './OrderBy';

function TrialsNavigationBar() {
  return (
    <Stack direction="column" component="aside" id="TrialsNavigationBar" spacing={1}>
      <TrialFilters color="secondary" />
      <OrderBy color="secondary" />
    </Stack>
  );
}

export default TrialsNavigationBar;
