import { Chip } from '@mui/material';
import { KeyboardArrowDown as DescendingIcon, KeyboardArrowUp as AscendingIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Proptype from 'prop-types';

function OrderByString({ onDelete, onClick, label, dir, color }) {
  const { t } = useTranslation();
  return dir ? <Chip variant="filled" label={t(label)} color={color} onDelete={onDelete} onClick={onClick} icon={dir === 'desc' ? <DescendingIcon /> : <AscendingIcon />} /> : <Chip label={t(label)} onClick={onClick} />;
}

OrderByString.defaultProps = {
  dir: '',
  color: 'primary',
};

OrderByString.propTypes = {
  label: Proptype.string.isRequired,
  dir: Proptype.string,
  onClick: Proptype.func.isRequired,
  onDelete: Proptype.func.isRequired,
  color: Proptype.string,
};

export default OrderByString;
