import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

function DefendantInput({ name, label, initValue, defendantsList, onChange, debounceInterval, size }) {
  const [defendant, setDefendant] = useState(() => (initValue ? initValue.toUpperCase() : ''));
  const debouncedOnChange = useDebouncedCallback(onChange, debounceInterval);

  useEffect(() => {
    if (defendant !== initValue) debouncedOnChange(defendant || '');
  }, [defendant, debouncedOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      size={size}
      id={name}
      options={defendantsList}
      value={defendant}
      freeSolo
      autoSelect
      onInputChange={(e, val) => setDefendant(val ? val.toUpperCase() : '')}
      onChange={(e, val) => setDefendant(val)}
      renderInput={(params) => <TextField {...params} required inputProps={{ ...params.inputProps, required: true, 'data-testid': name }} label={label} />}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

DefendantInput.defaultProps = {
  initValue: '',
  defendantsList: [],
  debounceInterval: 600,
  size: 'medium',
};
DefendantInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initValue: PropTypes.string,
  defendantsList: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  debounceInterval: PropTypes.number,
  size: PropTypes.string,
};

export default DefendantInput;
