import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useEditTrialMutation } from '../features/api/tds/apiTrialsSlice';
import { closeTrialDialog, resetTrial, selectEditingTrial } from '../features/trialEditingSlice';
import { setAlertMessage } from '../features/layoutSlice';
import { dateTimeToTdsFormat } from '../utils/dateTimeHandlers';

export default function useTrialEdit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const trial = useSelector(selectEditingTrial);
  const { trialId, roomId, timeStart, timeEnd, proceedingsCount, proceedingsCountToVerify, CDCount, certificateId, notes, operatorArrivalTime } = trial;
  const [editTrialTrigger, editTrialResult] = useEditTrialMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const trialCompleted = proceedingsCountToVerify === 0 && proceedingsCount === 0;

  const checkData = useCallback(() => {
    if (!dayjs(timeEnd).isValid()) {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.invalidDateTimeEnd'), type: 'error', render: 'snack' }));
      return false;
    }
    if (dayjs(timeEnd).isBefore(dayjs(timeStart))) {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.dateTimeEnd'), type: 'error', render: 'snack' }));
      return false;
    }
    if (proceedingsCount === '') {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.proceedingsCountRequired'), type: 'error', render: 'snack' }));
      return false;
    }
    if (proceedingsCountToVerify === '') {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.proceedingsCountToVerifyRequired'), type: 'error', render: 'snack' }));
      return false;
    }
    if (proceedingsCount < proceedingsCountToVerify) {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.proceedingsCount'), type: 'error', render: 'snack' }));
      return false;
    }
    if (CDCount === '') {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.cdCountRequired'), type: 'error', render: 'snack' }));
      return false;
    }
    if (proceedingsCountToVerify > 0 && CDCount <= 0) {
      dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.cdCount'), type: 'error', render: 'snack' }));
      return false;
    }
    if (!certificateId) {
      dispatch(setAlertMessage({ text: trial.service === 'RITIRO EX-POST' ? t('trials.form.editTrial.errors.compiledWithdrawalCertificate') : t('trials.form.editTrial.errors.compiledAttendanceCertificate'), type: 'error', render: 'snack' }));
      return false;
    }
    return true;
  }, [timeEnd, timeStart, proceedingsCount, proceedingsCountToVerify, certificateId, CDCount, trial.service, dispatch, t]);

  const handleTrialCompleted = useCallback(() => {
    dispatch(setAlertMessage({ text: t('proceedings.proceedingsList.trial.messages.completed'), type: 'success', render: 'snack' }));
  }, [dispatch, t]);

  const saveTrial = useCallback(
    async (e) => {
      e.preventDefault();
      if (checkData()) {
        setIsSubmitting(true);
        try {
          await editTrialTrigger({
            trialId,
            dateEnd: dateTimeToTdsFormat(timeEnd, 'dateTime'),
            roomId,
            proceedingsCount,
            proceedingsCountToVerify,
            CDCount,
            certificateId,
            note: notes,
            operatorArrivalTime: dateTimeToTdsFormat(operatorArrivalTime, 'time'),
          }).unwrap();
          if (trialCompleted) handleTrialCompleted();
        } catch (err) {
          dispatch(setAlertMessage({ text: t('trials.form.editTrial.errors.saving'), type: 'error' }));
        } finally {
          setIsSubmitting(false);
        }
        dispatch(closeTrialDialog());
        dispatch(resetTrial());
      }
    },
    [trialId, timeEnd, roomId, proceedingsCount, proceedingsCountToVerify, CDCount, certificateId, notes, operatorArrivalTime, trialCompleted, dispatch, t, editTrialTrigger, checkData, handleTrialCompleted]
  );

  return {
    trial,
    trialId,
    isSubmitting,
    saveTrial,
    editTrialTrigger,
    editTrialResult,
  };
}
