import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { FormControl, FormControlLabel, RadioGroup, FormLabel, Radio } from '@mui/material';
import { selectProceedingFormSelectedRecordings } from '../features/proceedingFormSlice';

function ReportRadioInput({ name, label, valueYes, valueNo, labelYes, labelNo, initValue, onChange, required, debounceInterval, size }) {
  const [report, setReport] = useState(() => initValue || '');
  const selectedRecordings = useSelector(selectProceedingFormSelectedRecordings);
  const debouncedOnChange = useDebouncedCallback(onChange, debounceInterval);

  useEffect(() => {
    if (selectedRecordings.length !== 0 && report === '') setReport('1');
  }, [selectedRecordings]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (initValue !== report) debouncedOnChange(report || '');
  }, [report, debouncedOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl sx={{ flexDirection: 'row', alignItems: 'center' }} required={required} error={report === ''}>
      <FormLabel sx={{ mr: 2 }} id={name}>
        {label}
      </FormLabel>
      <RadioGroup
        sx={{
          '& .MuiFormControlLabel-asterisk': {
            display: 'none',
          },
        }}
        row
        data-testid={name}
        name={name}
        value={report}
        onChange={(e) => setReport(e.target.value)}
      >
        <FormControlLabel required value={valueYes} control={<Radio size={size} />} label={labelYes} />
        <FormControlLabel required value={valueNo} control={<Radio size={size} />} label={labelNo} />
      </RadioGroup>
    </FormControl>
  );
}

ReportRadioInput.defaultProps = {
  initValue: '',
  debounceInterval: 600,
  required: false,
  size: 'medium',
};
ReportRadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelYes: PropTypes.string.isRequired,
  valueYes: PropTypes.string.isRequired,
  labelNo: PropTypes.string.isRequired,
  valueNo: PropTypes.string.isRequired,
  initValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  debounceInterval: PropTypes.number,
  size: PropTypes.string,
};

export default ReportRadioInput;
