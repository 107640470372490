import PropTypes from 'prop-types';
import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

function NodeCard({ node }) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '200px', mb: 2 }}>
      {node && (
        <CardContent>
          <Tooltip title={t('jobs.id.label')}>
            <Typography variant="subtitle2">{node.id}</Typography>
          </Tooltip>
          <Tooltip title={t('jobs.status.label')}>
            <Typography variant="subtitle2">{t(`jobs.status.value.${node.status}`)}</Typography>
          </Tooltip>
          <Tooltip title={t('jobs.date.created.label')}>
            <Typography variant="subtitle2">{node.created ? dayjs(node.created).format('L LT') : ''}</Typography>
          </Tooltip>
          <Tooltip title={t('jobs.date.lastUpdated.label')}>
            <Typography variant="subtitle2">{node.last_updated ? dayjs(node.last_updated).format('L LT') : ''}</Typography>
          </Tooltip>
          <Tooltip title={t('jobs.requestReference.label')}>
            <Typography variant="subtitle2">{node.request_reference}</Typography>
          </Tooltip>
          <Tooltip title={t('jobs.originalFileName.label')}>
            <Typography variant="subtitle2">{node.original_file_name}</Typography>
          </Tooltip>
        </CardContent>
      )}
    </Card>
  );
}

NodeCard.defaultProps = {
  node: {
    id: '',
    status: '',
    created: '',
    last_updated: '',
    original_file_name: '',
    request_reference: '',
  },
};

NodeCard.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created: PropTypes.string,
    last_updated: PropTypes.string,
    original_file_name: PropTypes.string,
    request_reference: PropTypes.string.isRequired,
  }),
};

export default NodeCard;
