import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Stack, Card, CardHeader, Grid, DialogContentText } from '@mui/material';

function ReloadCdsDialog({ closeHandler, existingCds, isOpen }) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: 'warning.main', color: 'warning.contrastText' }}>{t('proceedings.dialogs.reloadCdsDialog.title')}</DialogTitle>
      <Divider />
      <DialogContentText m={1}>{t('proceedings.dialogs.reloadCdsDialog.text')}</DialogContentText>
      <DialogContent sx={{ py: 1, px: 2 }}>
        <Stack direction="column" spacing={1}>
          {existingCds.map((cd) => (
            <Card elevation={3} key={cd.id}>
              <CardHeader
                sx={{ p: 0.5 }}
                titleTypographyProps={{ variant: 'body2' }}
                subheaderTypographyProps={{ variant: 'body2' }}
                title={
                  <Grid container>
                    <Grid item xs={3}>
                      {cd.recorder}
                    </Grid>
                    <Grid item xs={5}>
                      {cd.newStartDate ? dayjs(cd.newStartDate).format('L LT') : ''}
                    </Grid>
                    <Grid item xs={4}>
                      {cd.court}
                    </Grid>
                  </Grid>
                }
                subheader={cd.room}
              />
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogContentText m={1}>{t('proceedings.dialogs.reloadCdsDialog.question')}</DialogContentText>
      <Divider />
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={() => closeHandler(false)}>
          {t('common.actions.abort')}
        </Button>
        <Button color="primary" variant="contained" onClick={() => closeHandler(true)}>
          {t('common.actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReloadCdsDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  existingCds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ReloadCdsDialog;
