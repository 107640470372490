function getEnvVariable(key, defaultValue, metaEnvValue) {
  if (typeof window.runtimeConfig !== 'undefined' && window.runtimeConfig !== null && key in window.runtimeConfig) {
    return window.runtimeConfig[key];
  }
  if (typeof metaEnvValue !== 'undefined') {
    return metaEnvValue;
  }
  if (typeof defaultValue !== 'undefined') {
    return defaultValue;
  }
  return null;
}

const config = {
  debug: getEnvVariable('REACT_APP_DEBUG', !import.meta.env.PROD, import.meta.env.REACT_APP_DEBUG),
  api: {
    facebook: {
      clientid: getEnvVariable('REACT_APP_FACEBOOK_API_CLIENT_ID', '', import.meta.env.REACT_APP_FACEBOOK_API_CLIENT_ID),
    },
    google: {
      clientid: getEnvVariable('REACT_APP_GOOGLE_API_CLIENT_ID', '', import.meta.env.REACT_APP_GOOGLE_API_CLIENT_ID),
    },
    backend: {
      url: getEnvVariable('REACT_APP_API_URL', 'https://localhost:8000', import.meta.env.REACT_APP_API_URL),
      checkSessionInterval: import.meta.env.PROD ? 1000 * 60 * 5 : 1000 * 20,
      expirationThreshold: import.meta.env.PROD ? 1000 * 60 * 30 : 1000 * 60 * 1,
    },
    tds: {
      url: getEnvVariable('REACT_APP_TDS_API_URL', 'https://tds-dev.id1406.com/api', import.meta.env.REACT_APP_TDS_API_URL),
      loginUrl: getEnvVariable('REACT_APP_TDS_LOGIN_URL', 'https://tds-dev.id1406.com/login', import.meta.env.REACT_APP_TDS_LOGIN_URL),
      homeUrl: getEnvVariable('REACT_APP_TDS_HOME_URL', 'https://tds-dev.id1406.com/', import.meta.env.REACT_APP_TDS_HOME_URL),
    },
    gtl: {
      homeUrl: getEnvVariable('REACT_APP_GTL_HOME_URL', 'http://gtl.cooperativaverbatim.it/', import.meta.env.REACT_APP_GTL_HOME_URL),
    },
  },
  locales: ['en', 'it'],
  siteName: 'SAI ASR',
  overrideLoginRedirectUrl: getEnvVariable('REACT_APP_LOGIN_REDIRECT_URL', false, import.meta.env.REACT_APP_LOGIN_REDIRECT_URL),
  features: {
    clock: false,
  },
};

export default config;
