import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Typography } from '@mui/material';
import useProceedingFormSubmitButton from '../hooks/useProceedingFormSubmitButton';
import SimilarProceedingsDialog from './SimilarProceedingsDialog';
import FilesConfirmationDialog from './FilesConfirmationDialog';

function ProceedingFormSubmitButton({ trialId, color, variant, size, fullWidth, sx }) {
  const { t } = useTranslation();
  const { isSubmitting, isEditing, similarProceedings, filesConfirmationDialogOpen, similarProceedingsDialogOpen, submitProceeding, similarProceedingsDialogCloseHandler, filesConfirmationDialogCloseHandler } = useProceedingFormSubmitButton(trialId);

  return (
    <>
      <FilesConfirmationDialog isOpen={filesConfirmationDialogOpen} closeHandler={filesConfirmationDialogCloseHandler} />
      <SimilarProceedingsDialog similarProceedings={similarProceedings} isOpen={similarProceedingsDialogOpen} closeHandler={similarProceedingsDialogCloseHandler} />
      <Button sx={{ ...sx }} fullWidth={fullWidth} color={color} variant={variant} size={size} onClick={submitProceeding} disabled={isSubmitting}>
        <Typography>{isEditing ? t('common.actions.save') : t('common.actions.create')}</Typography>
        {isSubmitting && <CircularProgress size="1.5em" />}
      </Button>
    </>
  );
}

ProceedingFormSubmitButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  fullWidth: false,
  sx: {},
};

ProceedingFormSubmitButton.propTypes = {
  trialId: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default ProceedingFormSubmitButton;
