import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResendEmailVerificationMutation, useVerifyEmailMutation } from '../features/api/rest/apiSignUpSlice';
import { setAlertMessage } from '../features/layoutSlice';
import Spinner from './Spinner';

function VerifyEmail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key } = useParams();
  const [resendEmailVerification, { isLoading }] = useResendEmailVerificationMutation();
  const [verifyEmail, { isLoading: isLoadingVerify }] = useVerifyEmailMutation();
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const handleVerify = useCallback(async () => {
    try {
      await verifyEmail({ key }).unwrap();
      setIsEmailVerified(true);
      dispatch(setAlertMessage({ text: t('auth.messages.emailVerified.success'), type: 'success' }));
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
      dispatch(setAlertMessage({ text: t('auth.messages.emailVerified.error'), type: 'error' }));
    }
  }, [key, verifyEmail, dispatch, t]);

  useEffect(() => {
    if (key && !isEmailVerified) {
      handleVerify();
    }
  }, [key, isEmailVerified, handleVerify]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await resendEmailVerification({ email: location?.state?.email }).unwrap();
        dispatch(setAlertMessage({ text: t('auth.messages.emailSentAgain.success'), type: 'success' }));
      } catch (err) {
        console.log(err); // eslint-disable-line no-console
        dispatch(setAlertMessage({ text: t('auth.messages.emailSentAgain.error'), type: 'error' }));
      }
    },
    [resendEmailVerification, location, dispatch, t]
  );

  if (isLoading || isLoadingVerify) return <Spinner />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <Typography variant="h3">{t('auth.verifyEmail.heading')}</Typography>
      <Stack maxWidth="80%" textAlign="center">
        <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
          {t('auth.verifyEmail.message')}
        </Typography>
      </Stack>
      <Button onClick={handleSubmit} variant="contained" sx={{ mt: 1 }}>
        {t('auth.verifyEmail.submit')}
      </Button>
    </Box>
  );
}

export default VerifyEmail;
