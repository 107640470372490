import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, ListItem, ListItemIcon, ListItemText, Stack, TextField } from '@mui/material';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon, UploadFile, Launch, Edit } from '@mui/icons-material';
import useAddOtherFilesCard from '../hooks/useAddOtherFilesCard';

function AddOtherFilesCard() {
  const { t } = useTranslation();
  const {
    isOpen,
    options,
    otherFilesToAdd,
    setOtherFilesToAdd,
    renameDialogOpen,
    fileName,
    setFileName,
    renameOnFileSystem,
    setRenameOnFileSystem,
    handleAutocompleteOnChange,
    handleAutocompleteOnClose,
    handleAutocompleteOnOpen,
    confirmButtonClickHandler,
    handleInputFilesOnChange,
    openFilePreview,
    openRenameDialog,
    closeRenameDialog,
    editOtherFileName,
  } = useAddOtherFilesCard();

  useEffect(() => {
    const newOtherFilesToAdd = otherFilesToAdd.map((ot) => options.find((o) => ot.id === o.id) || ot);
    setOtherFilesToAdd(newOtherFilesToAdd);
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputFileRef = useRef();

  const addFileOptionClickHandler = (e) => {
    inputFileRef.current.click();
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const addConfirmButtonToEndAdornment = (endAdornment) => {
    const children = React.Children.toArray(endAdornment.props.children);
    const button = (
      <Button variant="contained" onClick={confirmButtonClickHandler} size="small" key="addFile" sx={{ ml: 2, verticalAlign: 'middle' }} disabled={otherFilesToAdd.length === 0}>
        {t('otherFiles.addFilesForm.addSelectedFiles')}
      </Button>
    );
    children.push(button);
    return React.cloneElement(endAdornment, {}, children);
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      <Dialog open={renameDialogOpen} fullWidth maxWidth="xs">
        <DialogTitle bgcolor="orange" sx={{ padding: 1 }}>
          {t('otherFiles.renameDialog.title')}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 1 }}>
          <TextField label={t('otherFiles.renameDialog.fieldLabel')} fullWidth name="fileRename" onChange={(e) => setFileName(e.target.value)} value={fileName} />
          {!!window.electronAPI && (
            <FormControlLabel
              control={<Checkbox data-testid="renameFileOnFileSystemCheckbox" color="error" checked={renameOnFileSystem} onChange={() => setRenameOnFileSystem(!renameOnFileSystem)} name="renameOnFileSystem" />}
              sx={{ color: 'red' }}
              label={t('trials.dialogs.certificateFile.renameFileOnFileSystemText')}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => closeRenameDialog()}>
            {t('common.actions.abort')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => editOtherFileName()}>
            {t('common.actions.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        size="small"
        open={isOpen}
        fullWidth
        multiple
        limitTags={3}
        id="searchTracks"
        options={options}
        value={otherFilesToAdd}
        onOpen={() => handleAutocompleteOnOpen()}
        onClose={() => handleAutocompleteOnClose()}
        onChange={(e, newVal) => handleAutocompleteOnChange(newVal)}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.file?.name || ''}
        renderOption={(props, option, { selected }) => {
          if (option === 'addFiles') {
            return (
              <ListItem key="addFiles" onClick={addFileOptionClickHandler} sx={{ paddingLeft: 3, cursor: 'pointer', '&:active': { bgcolor: '#eeeeee' } }}>
                <ListItemIcon>
                  <UploadFile />
                </ListItemIcon>
                <ListItemText sx={{ textTransform: 'uppercase' }}>{t('otherFiles.addFilesForm.addFiles')}</ListItemText>
              </ListItem>
            );
          }
          return (
            <ListItem
              {...props}
              key={option.file?.name}
              secondaryAction={
                <Stack direction="row">
                  {option.file && (
                    <IconButton onClick={(e) => openFilePreview(e, option.file)}>
                      <Launch fontSize="small" />
                    </IconButton>
                  )}
                  {option.file && option.file.type === 'application/pdf' && (
                    <IconButton onClick={(e) => openRenameDialog(e, option)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              }
            >
              <ListItemIcon>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              </ListItemIcon>
              <ListItemText>{option.file?.name}</ListItemText>
            </ListItem>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={t('otherFiles.addFilesForm.label')}
              InputProps={{
                ...params.InputProps,
                endAdornment: addConfirmButtonToEndAdornment(params.InputProps.endAdornment),
                slotProps: { root: { style: { paddingRight: '12em' } } },
              }}
              placeholder={t('otherFiles.addFilesForm.placeholder')}
            />
          );
        }}
        sx={{
          '& .MuiFormLabel-root.Mui-focused': {
            bgcolor: '#fff',
            px: '4px',
          },
          '& .MuiAutocomplete-root .MuiFormControl-root': {
            position: 'relative',
          },
          '& .MuiAutocomplete-inputRoot.Mui-focused': {
            position: 'unset',
            height: '2.5em',
            overflowY: 'scroll',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'primary.main',
          },
          '& .MuiAutocomplete-inputRoot.Mui-focused fieldset': {
            border: 'none',
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            cursor: 'pointer',
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment ': {
            right: '20px',
          },
        }}
      />
      <input ref={inputFileRef} onChange={handleInputFilesOnChange} style={{ display: 'none' }} multiple type="file" id="loadFiles" />
    </>
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

export default AddOtherFilesCard;
