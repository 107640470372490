import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editProceeding, selectAllProceedings } from '../features/proceedingsSlice';
import { selectBulkProceedingsRecordingsSelectValues, setBulkProceedingsRecordingsSelectValues } from '../features/bulkProceedingsSlice';

function useBulkProceedingsResults() {
  const dispatch = useDispatch();

  const Proceedings = useSelector((state) => selectAllProceedings(state.proceedings));
  const recordingsSelectValues = useSelector(selectBulkProceedingsRecordingsSelectValues);

  const filteredProceedings = useMemo(
    () => (recordingsSelectValues.length > 0 ? Proceedings?.filter((proceeding) => recordingsSelectValues?.filter((value) => proceeding.recordings?.includes(value.id)).length > 0) : Proceedings),
    [Proceedings, recordingsSelectValues]
  );
  const filterProceedings = useCallback(() => {
    Proceedings.forEach((proceeding) => {
      const toSelectRecordings = [];
      proceeding.recordings.forEach((recordingId) => {
        if (recordingsSelectValues.map((rsv) => rsv.id).includes(recordingId)) toSelectRecordings.push(recordingId);
      });
      dispatch(editProceeding({ id: proceeding.id, changes: { selectedRecordings: toSelectRecordings } }));
    });
  }, [dispatch, Proceedings, recordingsSelectValues]);

  const resetFilters = useCallback(() => {
    dispatch(setBulkProceedingsRecordingsSelectValues([]));
  }, [dispatch]);

  return {
    filteredProceedings,
    filterProceedings,
    resetFilters,
  };
}

export default useBulkProceedingsResults;
