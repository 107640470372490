import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Stack, Divider, Typography, TextField } from '@mui/material';
import TdsTimePicker from './TdsTimePicker';
import ProceedingTracks from './ProceedingTracks';
import Spinner from './Spinner';
import useProceedingForm from '../hooks/useProceedingForm';
import ProceedingOtherFiles from './ProceedingOtherFiles';
import PublicProsecutorsSelect from './PublicProsecutorsSelect';
import RegistrarInput from './RegistrarInput';
import OutcomesSelect from './OutcomesSelect';
import ProceedingFormTextField from './ProceedingFormTextField';
import ProceedingJudges from './ProceedingJudges';
import DefendantInput from './DefendantInput';
import BulkProceedingsCdReinsertDialog from './BulkProceedingsCdReinsertDialog';
import ProceedingStagedTracks from './ProceedingStagedTracks';
import ProceedingStagedOtherFiles from './ProceedingStagedOtherFiles';
import ReportRadioInput from './ReportRadioInput';
import TdsDateTimePicker from './TdsDateTimePicker';

function ProceedingForm() {
  const { t } = useTranslation();

  const { Proceeding, setField, isSubmitting } = useProceedingForm();
  const {
    trialId,
    proceedingId,
    PM,
    sectionId,
    courtId,
    registrar,
    dateStart,
    timeStart,
    timeEnd,
    report,
    RG,
    RGNR,
    defendant,
    defendantsList,
    judge,
    judge1,
    judge2,
    judge3,
    outcomeId,
    outcome,
    outcomeDate,
    outcomeNotes,
    paperDeliveryDate,
    pdfDeliveryDate,
    note,
  } = Proceeding;

  if (isSubmitting) return <Spinner />;

  return (
    <>
      <Stack spacing={1} mx={2} my={1} direction="column">
        <TextField size="small" inputProps={{ 'data-testid': 'trialId' }} id="trialId" name="trialId" disabled required defaultValue={trialId} label={t('proceedings.form.addProceeding.fields.trial')} />
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <TdsTimePicker
            size="small"
            name="timeStart"
            sx={{ width: 1 / 2 }}
            label={t('proceedings.form.addProceeding.fields.timeStart')}
            required
            onChange={(newVal) => setField('timeStart', newVal)}
            initialValue={timeStart}
            refDate={dateStart}
            error={dayjs(timeEnd).isBefore(dayjs(timeStart))}
            helperText={t('proceedings.form.addProceeding.errors.dateTimeStart')}
          />
          <Typography>-</Typography>
          <TdsTimePicker
            size="small"
            name="timeEnd"
            sx={{ width: 1 / 2 }}
            label={t('proceedings.form.addProceeding.fields.timeEnd')}
            required
            onChange={(newVal) => setField('timeEnd', newVal)}
            initialValue={timeEnd}
            refDate={dateStart}
            error={dayjs(timeEnd).isBefore(dayjs(timeStart))}
            helperText={t('proceedings.form.addProceeding.errors.dateTimeEnd')}
          />
        </Stack>
        <Divider />
        <ReportRadioInput
          size="small"
          initValue={report}
          onChange={(newVal) => setField('report', newVal)}
          required
          name="report"
          labelYes={t('proceedings.form.addProceeding.fields.report.values.yes')}
          valueYes="1"
          valueNo="0"
          labelNo={t('proceedings.form.addProceeding.fields.report.values.no')}
          label={t('proceedings.form.addProceeding.fields.report.label')}
        />
        <Divider />
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <ProceedingFormTextField size="small" inputProps={{ 'data-testid': 'RG' }} sx={{ width: 1 / 2 }} name="RG" required initValue={RG} onChange={(newVal) => setField('RG', newVal)} label={t('proceedings.form.addProceeding.fields.RG')} />
          <ProceedingFormTextField size="small" inputProps={{ 'data-testid': 'RGNR' }} sx={{ width: 1 / 2 }} name="RGNR" required initValue={RGNR} onChange={(newVal) => setField('RGNR', newVal)} label={t('proceedings.form.addProceeding.fields.RGNR')} />
        </Stack>
        <Divider />
        <DefendantInput size="small" label={t('proceedings.form.addProceeding.fields.defendant')} name="defendant" initValue={defendant} defendantsList={defendantsList} onChange={(newVal) => setField('defendant', newVal)} />
        <ProceedingJudges trialId={trialId} judge={judge} judge1={judge1} judge2={judge2} judge3={judge3} />
        <PublicProsecutorsSelect size="small" initValues={PM} setPublicProsecutors={(newVal) => setField('PM', newVal)} sectionId={sectionId} courtId={courtId} />
        <RegistrarInput size="small" registrarText={registrar} setRegistrarText={(newVal) => setField('registrar', newVal)} />
        <Divider />
        <OutcomesSelect
          size="small"
          required
          outcome={{ id: outcomeId, description: outcome || '', date: outcomeDate, notes: outcomeNotes }}
          setOutcome={(oc) => {
            setField('outcomeId', oc.id);
            setField('outcome', oc.description);
            setField('outcomeDate', oc.date);
            setField('outcomeNotes', oc.notes);
          }}
          label={t('proceedings.form.addProceeding.fields.outcome')}
          labelNotes={t('proceedings.form.addProceeding.fields.outcomeNotes')}
          labelDate={t('proceedings.form.addProceeding.fields.outcomeDate')}
          name="outcome"
        />
        <Divider />
        <Typography variant="body1">{t('proceedings.form.addProceeding.fields.recordings.toUpload')}</Typography>
        <ProceedingStagedTracks />
        <ProceedingTracks />
        <Typography variant="body1">{t('otherFiles.toUpload')}</Typography>
        <ProceedingStagedOtherFiles />
        <ProceedingOtherFiles />
        <Divider />
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <TdsDateTimePicker
            size="small"
            name="paperDeliveryDate"
            sx={{ width: 1 / 2 }}
            label={t('proceedings.form.addProceeding.fields.paperDeliveryDate')}
            onChange={(newVal) => setField('paperDeliveryDate', newVal)}
            initialValue={paperDeliveryDate}
            referenceDate={dayjs().hour(13).minute(0)}
          />
          <TdsDateTimePicker
            size="small"
            name="pdfDeliveryDate"
            sx={{ width: 1 / 2 }}
            label={t('proceedings.form.addProceeding.fields.pdfDeliveryDate')}
            onChange={(newVal) => setField('pdfDeliveryDate', newVal)}
            initialValue={pdfDeliveryDate}
            referenceDate={dayjs().hour(14).minute(0)}
          />
        </Stack>
        <Divider />
        <ProceedingFormTextField size="small" inputProps={{ 'data-testid': 'notes' }} name="notes" multiline initValue={note} onChange={(newVal) => setField('note', newVal)} label={t('proceedings.form.addProceeding.fields.notes')} />
      </Stack>
      <BulkProceedingsCdReinsertDialog openFor={proceedingId} />
    </>
  );
}

export default ProceedingForm;
