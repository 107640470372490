function formatOtherFiles(otherFilesList) {
  const formattedOtherFiles = [];
  otherFilesList?.forEach((file) => {
    formattedOtherFiles.push(formatOtherFile(file));
  });
  return formattedOtherFiles;
}
function formatOtherFile(file) {
  const formattedOtherFile = {};
  if (file) {
    formattedOtherFile.id = file.name;
    formattedOtherFile.file = file;
    formattedOtherFile.fileName = file.name || '';
    formattedOtherFile.type = file.type;
  }
  return formattedOtherFile;
}

export { formatOtherFile, formatOtherFiles };

export default formatOtherFiles;
