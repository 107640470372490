import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTDSAuth, selectTranscriptionAuth } from '../../../features/authSlice';
import ProfileShow from './ProfileShow';
import TDSConnector from '../../TDSConnector';

function Profile() {
  const { user: profileTDS } = useSelector(selectTDSAuth);
  const { isAuth: isTranscriptionAuth, user: profile, netWorkError: isNetworkError } = useSelector(selectTranscriptionAuth);
  return (
    <Box component="section" id="profile" data-testid="Profile" sx={{ width: '100%', height: '100%' }}>
      <ProfileShow profile={profileTDS} isFetching={!profileTDS} isTDS />
      <TDSConnector />
      {isTranscriptionAuth && !isNetworkError && <ProfileShow profile={profile} />}
    </Box>
  );
}

export default Profile;
