import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexedDB } from 'react-indexed-db-hook';
import { selectProceedingFormOtherFiles, setProceedingFormOtherFiles } from '../features/proceedingFormSlice';
import { editOtherFiles, loadOtherFiles, selectAllOtherFiles } from '../features/otherFilesSlice';
import { formatOtherFiles } from '../utils/formatOtherFiles';
import { openFileDialog, setAlertMessage, setFileDialog } from '../features/layoutSlice';
import { updateFile, renameFile, storeFile } from '../utils/fileReader';

export default function useAddOtherFilesCard() {
  const otherFiles = useSelector((state) => selectAllOtherFiles(state.otherFiles));
  const proceedingOtherFiles = useSelector(selectProceedingFormOtherFiles);
  const { add, update } = useIndexedDB('files');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [otherFilesToAdd, setOtherFilesToAdd] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [preview, setPreview] = useState(false);

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [otherFileToRename, setOtherFileToRename] = useState(null);
  const [renameOnFileSystem, setRenameOnFileSystem] = useState(true);

  const storeFilesOnIndexedDB = useCallback((files) => Promise.all(files.map(async (file) => storeFile(file, add))), [add]);

  const sortOtherFiles = useCallback((othF) => {
    return othF.sort((a, b) => {
      if (a.file?.name < b.file?.name) return -1;
      if (a.file?.name > b.file?.name) return 1;
      return 0;
    });
  }, []);

  const options = useMemo(() => {
    const opts = [];
    const sortedOtherFiles = sortOtherFiles(otherFiles);
    sortedOtherFiles.forEach((othf) => {
      if (!proceedingOtherFiles?.includes(othf.id)) opts.push(othf);
    });
    opts.push('addFiles');
    return opts;
  }, [proceedingOtherFiles, sortOtherFiles, otherFiles]);

  const addOtherFile = () => {
    dispatch(setProceedingFormOtherFiles(proceedingOtherFiles.concat(otherFilesToAdd.map((othF) => othF.id))));
    setOtherFilesToAdd([]);
  };

  const checkAlreadyExistOtherFiles = useCallback(
    (files) => {
      const toRemove = [];
      const toKeep = [];
      files.forEach((file) => {
        otherFiles.find((othFile) => othFile.id === file.id) ? toRemove.push(file) : toKeep.push(file);
      });
      return { toKeep, toRemove };
    },
    [otherFiles]
  );

  const handleAutocompleteOnChange = (newVal) => setOtherFilesToAdd(sortOtherFiles(newVal));
  const handleAutocompleteOnOpen = () => setIsOpen(true);
  const handleAutocompleteOnClose = () => {
    if (!preview) {
      setIsOpen(false);
      addOtherFile();
    } else setPreview(false);
  };

  const handleInputFilesOnChange = useCallback(
    async (e) => {
      const files = Object.values(e.target.files);
      const formattedOtherFiles = formatOtherFiles(files);
      const { toKeep, toRemove } = checkAlreadyExistOtherFiles(formattedOtherFiles);
      if (toKeep.length > 0) {
        dispatch(loadOtherFiles(toKeep));
        storeFilesOnIndexedDB(toKeep.map((otherFile) => otherFile.file));
        setOtherFilesToAdd(sortOtherFiles(otherFilesToAdd.concat(toKeep)));
        setIsOpen(true);
      }
      if (toRemove.length > 0) dispatch(setAlertMessage({ text: t('otherFiles.errors.alreadyUploaded', { files: toRemove.map((f) => f.file.name).join(',') }), type: 'error', render: 'snack' }));
    },
    [dispatch, sortOtherFiles, t, checkAlreadyExistOtherFiles, otherFilesToAdd, storeFilesOnIndexedDB]
  );

  const confirmButtonClickHandler = (e) => {
    e.preventDefault();
    setIsOpen(false);
    addOtherFile();
  };

  const openFilePreview = (e, file) => {
    e.stopPropagation();
    setPreview(true);
    const url = URL.createObjectURL(file);
    dispatch(setFileDialog({ name: file.name, url }));
    dispatch(openFileDialog());
  };

  const openRenameDialog = (e, otherFile) => {
    e.stopPropagation();
    setPreview(true);
    setRenameDialogOpen(true);
    setFileName(otherFile.file.name);
    setOtherFileToRename(otherFile);
  };

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
    setFileName('');
    setOtherFileToRename(null);
  };

  const editOtherFileName = async () => {
    if (fileName !== '') {
      const renamedFile = await renameFile(otherFileToRename.file, fileName, !!window.electronAPI && renameOnFileSystem);
      await updateFile(renamedFile, otherFileToRename.id, update);
      dispatch(editOtherFiles({ id: otherFileToRename.id, changes: { file: renamedFile, fileName } }));
      closeRenameDialog();
    } else {
      dispatch(setAlertMessage({ text: t('otherFiles.errors.fileNameEmpty'), type: 'error', render: 'snack' }));
    }
  };

  return {
    isOpen,
    options,
    otherFilesToAdd,
    setOtherFilesToAdd,
    renameDialogOpen,
    fileName,
    setFileName,
    renameOnFileSystem,
    setRenameOnFileSystem,
    handleAutocompleteOnChange,
    handleAutocompleteOnClose,
    handleAutocompleteOnOpen,
    confirmButtonClickHandler,
    handleInputFilesOnChange,
    openFilePreview,
    openRenameDialog,
    closeRenameDialog,
    editOtherFileName,
  };
}
