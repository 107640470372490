import { Box, Button, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from '../features/api/rest/apiPasswordSlice';
import Spinner from './Spinner';
import { setAlertMessage } from '../features/layoutSlice';

function ChangePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);
  const dispatch = useDispatch();

  const { id, token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleClickShowPassword = (labelPassword) => {
    if (labelPassword === 'password1') setShowPwd1(!showPwd1);
    else setShowPwd2(!showPwd2);
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    try {
      await changePassword({ new_password1: password, new_password2: confirmPassword, uid: id, token }).unwrap(); // eslint-disable-line camelcase
      dispatch(setAlertMessage({ text: 'auth.messages.passwordUpdated.success', type: 'success' }));
      navigate('/login');
    } catch (err) {
      dispatch(setAlertMessage({ text: 'auth.messages.passwordUpdated.error', variant: 'error' }));
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <Typography variant="h3">{t('auth.changePassword.heading')}</Typography>
      <Stack component="form" onSubmit={handleSumbit}>
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          {t('auth.changePassword.form.fields.newPassword.label')}
        </Typography>
        <FormControl>
          <OutlinedInput
            type={showPwd1 ? 'text' : 'password'}
            placeholder={t('auth.changePassword.form.fields.newPassword.hint')}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={t('aria.icons.togglePasswordVisibility')} onClick={() => handleClickShowPassword('password1')} onMouseDown={() => handleClickShowPassword('password1')} edge="end">
                  {showPwd1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          {t('auth.changePassword.form.fields.confirmPassword.label')}
        </Typography>
        <FormControl>
          <OutlinedInput
            type={showPwd2 ? 'text' : 'password'}
            placeholder={t('auth.changePassword.form.fields.confirmPassword.hint')}
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label={t('aria.icons.togglePasswordVisibility')} onClick={() => handleClickShowPassword('password2')} onMouseDown={() => handleClickShowPassword('password2')} edge="end">
                  {showPwd2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
          {t('auth.changePassword.form.fields.submit')}
        </Button>
      </Stack>
    </Box>
  );
}

export default ChangePassword;
