import Proptypes from 'prop-types';
import config from '../../../app/config';

function ExternalPage({ src }) {
  // eslint-disable-next-line react/no-unknown-property
  return <webview partition="electron" src={src} style={{ width: '100%', height: '100%' }} />;
}

ExternalPage.defaultProps = {
  src: config.api.tds.homeUrl,
};

ExternalPage.propTypes = {
  src: Proptypes.string,
};
export default ExternalPage;
