import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Card, CardHeader, IconButton, Skeleton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import useProceedingStagedOtherFile from '../hooks/useProceedingStagedOtherFile';
import DocumentActionButton from './DocumentActionButton';

function ProceedingStagedOtherFile({ fileId }) {
  const { t } = useTranslation();
  const { fileName, handleDelete, isFetching, isError } = useProceedingStagedOtherFile(fileId);

  if (isError) return <Typography>{t('otherFiles.errors.fileFetching')}</Typography>;
  if (isFetching) return <Skeleton variant="rounded" height="8em" animation="pulse" />;

  return (
    <Card sx={{ bgcolor: 'warning.light' }} elevation={3}>
      <CardHeader
        sx={{ p: 0.5 }}
        titleTypographyProps={{ variant: 'caption' }}
        title={fileName}
        avatar={
          <Stack direction="row" alignItems="center">
            <DocumentActionButton documentId={fileId} actionType="download" fileType="" title="download" fileName={fileName} />
            <DocumentActionButton documentId={fileId} actionType="open" fileType="" title="open" fileName={fileName} />
          </Stack>
        }
        action={
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        }
      />
    </Card>
  );
}

ProceedingStagedOtherFile.propTypes = {
  fileId: PropTypes.string.isRequired,
};

export default ProceedingStagedOtherFile;
