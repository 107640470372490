import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetPrintsQuery } from '../features/api/tds/apiProceedingsSlice';
import {
  setTicketId,
  getTicketId,
  cleanTicketId,
  setCourt,
  getCourt,
  cleanCourt,
  getSection,
  setSection,
  cleanSection,
  setDownloadedSignedPdf,
  getDownloadedSignedPdf,
  cleanDownloadedSignedPdf,
  getTrialTicketId,
  cleanTrialTicketId,
  setTrialTicketId,
} from '../features/printsFilterSlice';

function usePrintsResult() {
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();

  const { currentData: prints, isFetching, isLoading, isSuccess, isError } = useGetPrintsQuery();

  // get filters from store to filter the results on the client
  let filteredPrints = prints;
  const currentTicketId = useSelector(getTicketId);
  const currentTrialTicketId = useSelector(getTrialTicketId);
  const currentCourt = useSelector(getCourt);
  const currentSection = useSelector(getSection);
  const currentDownloadedSignedPdf = useSelector(getDownloadedSignedPdf);

  // check if there are filters to apply
  const hasFilters = currentTrialTicketId || currentTicketId || currentDownloadedSignedPdf || currentCourt || currentSection;

  // filter the results on the client
  if (currentTicketId) filteredPrints = filteredPrints?.filter((proceeding) => proceeding.id_ticket?.toLowerCase().includes(currentTicketId.toLowerCase()));
  if (currentTrialTicketId) filteredPrints = filteredPrints?.filter((proceeding) => proceeding.id_ticket_udienza?.toLowerCase().includes(currentTrialTicketId.toLowerCase()));
  if (currentCourt) filteredPrints = filteredPrints?.filter((proceeding) => proceeding.tribunale?.toLowerCase().includes(currentCourt.toLowerCase()));
  if (currentSection) filteredPrints = filteredPrints?.filter((proceeding) => proceeding.sezione?.toLowerCase().includes(currentSection.toLowerCase()));
  if (currentDownloadedSignedPdf) filteredPrints = filteredPrints?.filter((proceeding) => proceeding.flag_download_pdf_firmato === (currentDownloadedSignedPdf === 'yes' ? '1' : '0'));

  const [downloadedSignedPdfViewValue, setDownloadedSignedPdfViewValue] = useState('');
  const [ticketIdViewValue, setTicketIdViewValue] = useState('');
  const [trialTicketIdViewValue, setTrialTicketIdViewValue] = useState('');
  const [courtViewValue, setCourtViewValue] = useState('');
  const [sectionViewValue, setSectionViewValue] = useState('');

  const cleanAllViewFilters = () => {
    setDownloadedSignedPdfViewValue('');
    setTicketIdViewValue('');
    setTrialTicketIdViewValue('');
    setCourtViewValue('');
    setSectionViewValue('');
  };

  const [timer, setTimer] = useState(null);
  const timeoutUpdate = (setStateFn, setSearchFn, value, timeout) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(setStateFn(value));
      setSearchFn(value.toUpperCase());
    }, timeout);
    setTimer(newTimer);
  };

  // ticketId search setter
  const ticketIdSearchSetter = (newVal) => {
    if (!newVal) {
      search.delete('ticketId');
      setSearch(search);
      return dispatch(cleanTicketId());
    }
    search.set('ticketId', newVal);
    setSearch(search);
    return dispatch(setTicketId(newVal));
  };
  // ticketId setter
  const ticketIdSetter = (newVal, timeout = 1000) => timeoutUpdate(setTicketId, ticketIdSearchSetter, newVal, timeout);
  // ticketId resetter
  const ticketIdResetter = () => {
    if (search.has('ticketId')) {
      search.delete('ticketId');
      setSearch(search);
    }
    dispatch(cleanTicketId());
  };

  // trialTicketId search setter
  const trialTicketIdSearchSetter = (newVal) => {
    if (!newVal) {
      search.delete('trialTicketId');
      setSearch(search);
      return dispatch(cleanTrialTicketId());
    }
    search.set('trialTicketId', newVal);
    setSearch(search);
    return dispatch(setTrialTicketId(newVal));
  };
  // trialTicketId setter
  const trialTicketIdSetter = (newVal, timeout = 1000) => timeoutUpdate(setTrialTicketId, trialTicketIdSearchSetter, newVal, timeout);
  // trialTicketId resetter
  const trialTicketIdResetter = () => {
    if (search.has('trialTicketId')) {
      search.delete('trialTicketId');
      setSearch(search);
    }
    dispatch(cleanTrialTicketId());
  };

  // court search setter
  const courtSearchSetter = (newVal) => {
    if (!newVal) {
      search.delete('court');
      setSearch(search);
      return dispatch(cleanCourt());
    }
    search.set('court', newVal);
    setSearch(search);
    return dispatch(setCourt(newVal));
  };
  // court setter
  const courtSetter = (newVal, timeout = 1000) => timeoutUpdate(setCourt, courtSearchSetter, newVal, timeout);
  // court resetter
  const courtResetter = () => {
    if (search.has('court')) {
      search.delete('court');
      setSearch(search);
    }
    dispatch(cleanCourt());
  };

  // section search setter
  const sectionSearchSetter = (newVal) => {
    if (!newVal) {
      search.delete('section');
      setSearch(search);
      return dispatch(cleanSection());
    }
    search.set('section', newVal);
    setSearch(search);
    return dispatch(setSection(newVal));
  };
  // section setter
  const sectionSetter = (newVal, timeout = 1000) => timeoutUpdate(setSection, sectionSearchSetter, newVal, timeout);
  // section resetter
  const sectionResetter = () => {
    if (search.has('section')) {
      search.delete('section');
      setSearch(search);
    }
    dispatch(cleanSection());
  };

  // downloadedSignedPdf search setter
  const downloadedSignedPdfSetter = (newVal) => {
    if (!newVal) {
      search.delete('downloadedSignedPdf');
      setSearch(search);
      return dispatch(cleanDownloadedSignedPdf());
    }
    search.set('downloadedSignedPdf', newVal);
    setSearch(search);
    return dispatch(setDownloadedSignedPdf(newVal));
  };
  const downloadedSignedPdfResetter = () => {
    if (search.has('downloadedSignedPdf')) {
      search.delete('downloadedSignedPdf');
      setSearch(search);
    }
    dispatch(cleanDownloadedSignedPdf());
  };

  // all filters resetter
  const allFiltersResetter = () => {
    ticketIdResetter();
    trialTicketIdResetter();
    courtResetter();
    sectionResetter();
    downloadedSignedPdfResetter();
  };

  // on page load set filters from URL, if any are set
  useEffect(() => {
    if (search.has('ticketId')) {
      dispatch(setTicketId(search.get('ticketId')));
      setTicketIdViewValue(search.get('ticketId'));
    } else dispatch(cleanTicketId());

    if (search.has('trialTicketId')) {
      dispatch(setTrialTicketId(search.get('trialTicketId')));
      setTrialTicketIdViewValue(search.get('trialTicketId'));
    } else dispatch(cleanTrialTicketId());

    if (search.has('court')) {
      dispatch(setCourt(search.get('court')));
      setCourtViewValue(search.get('court'));
    } else dispatch(cleanCourt());

    if (search.has('section')) {
      dispatch(setSection(search.get('section')));
      setSectionViewValue(search.get('section'));
    } else dispatch(cleanSection());

    if (search.has('downloadedSignedPdf')) {
      dispatch(setDownloadedSignedPdf(search.get('downloadedSignedPdf')));
      setDownloadedSignedPdfViewValue(search.get('downloadedSignedPdf'));
    } else dispatch(cleanDownloadedSignedPdf());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    filteredPrints: filteredPrints || [],
    isFetching,
    isLoading,
    isSuccess,
    isError,
    hasFilters,
    filters: {
      ticketId: [currentTicketId, ticketIdSetter, ticketIdResetter],
      trialTicketId: [currentTrialTicketId, trialTicketIdSetter, trialTicketIdResetter],
      court: [currentCourt, courtSetter, courtResetter],
      section: [currentSection, sectionSetter, sectionResetter],
      downloadedSignedPdf: [currentDownloadedSignedPdf, downloadedSignedPdfSetter, downloadedSignedPdfResetter],
      cleanAll: allFiltersResetter,
    },
    viewFiltersValue: {
      ticketId: [ticketIdViewValue, setTicketIdViewValue],
      trialTicketId: [trialTicketIdViewValue, setTrialTicketIdViewValue],
      court: [courtViewValue, setCourtViewValue],
      section: [sectionViewValue, setSectionViewValue],
      downloadedSignedPdf: [downloadedSignedPdfViewValue, setDownloadedSignedPdfViewValue],
      cleanAllViewFilters,
    },
  };
}

export default usePrintsResult;
